import { Switch } from 'antd-mobile';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { SWITH_MODE_ACTION } from '@/store/action/type';
import localCache from '@/utils/localCache';
import classNames from 'classnames';
import logSend from '@/utils/aLog';

import './index.less';

export default function WorkbenchSwitchCard() {
    const { t: translate } = useTranslation();
    const dispatch = useDispatch();

    const switchWorkBench = useSelector((state) => state?.switchModeAction?.switchWorkBench);

    // 接管录音
    const switchTakeoverRecord = useSelector((state) => state?.switchModeAction?.switchTakeoverRecord);
    function handleTakeoverRecordChange(checked) {
        dispatch({
            type: SWITH_MODE_ACTION.switchTakeoverRecord,
            data: checked,
        });
        localCache.set('switchTakeoverRecord', checked);
        logSend({
            action: 'switchTakeoverRecord',
            data: { checked },
        });
    }

    // 所有录音
    const switchAllRecord = useSelector((state) => state?.switchModeAction?.switchAllRecord);
    function handleAllRecordChange(checked) {
        dispatch({
            type: SWITH_MODE_ACTION.switchAllRecord,
            data: checked,
        });
        localCache.set('switchAllRecord', checked);
        logSend({
            action: 'switchAllRecord',
            data: { checked },
        });
    }

    return (
        <div className={classNames('workbench-switch-card', { disabled: !switchWorkBench })}>
            <h3 className="title">{translate('settings.workbenchRecordTitle')}</h3>
            <h4 className="sub-title">{translate('settings.allRecord')}</h4>
            <div className="switch_wrap">
                <p className="tip">{translate('settings.allRecordDetail')}</p>
                <Switch
                    className="customSwitch"
                    checked={switchAllRecord}
                    disabled={!switchWorkBench}
                    onChange={handleAllRecordChange}
                />
            </div>
            <h4 className="sub-title">{translate('settings.takeOverRecord')}</h4>
            <p className="tip">{translate('settings.takeOverRecordDetail')}</p>
            <div className="switch_wrap">
                <Switch
                    className="customSwitch"
                    checked={switchTakeoverRecord}
                    disabled={!switchWorkBench}
                    onChange={handleTakeoverRecordChange}
                />
            </div>
        </div>
    );
}
