import { useSelector } from 'react-redux';
import reStart from '@/assets/parking/icons/icon_apa_continue.png';
import apaStart from '@/assets/parking/icons/icon_single_apa_start.png';
import './index.less';

const operate_icon_map = {
    SELECTING: {
        iconD: apaStart,
        icon: apaStart,
        txt: 'Park In',
    },
    PARKING_PAUSE: {
        iconD: reStart,
        icon: reStart,
        txt: 'Resume APA',
    },
    // STANDBY: {
    //     iconD: reStart,
    //     icon: reStart,
    //     txt: 'APA Reset',
    // },
};

export default function OperateBtns(props) {
    const userMode = useSelector((state) => state?.parking?.userMode);
    return (
        <>
            {props?.name ? (
                <button
                    disabled={props?.disabled ? 'disabled' : ''}
                    onClick={props?.handler}
                    className={`p-operate-btn ${userMode == 'D' ? 'd' : 'sr'} ${
                        props?.disabled ? 'disabled' : 'active'
                    } ${operate_icon_map[props?.name]?.icon ? 'highlight' : ''} ${
                        operate_icon_map[props?.name]?.class ? 'restart' : ''
                    }`}
                >
                    {operate_icon_map[props?.name]?.icon ? (
                        <img
                            src={
                                userMode == 'D'
                                    ? operate_icon_map[props?.name]?.iconD
                                    : operate_icon_map[props?.name]?.icon
                            }
                            alt="btn_icon"
                        />
                    ) : (
                        ''
                    )}
                    <span>{operate_icon_map[props?.name]?.txt}</span>
                </button>
            ) : (
                ''
            )}
        </>
    );
}
