import localCache from '@/utils/localCache';

// const vcruise = localStorage.getItem('vcruise_string') || 'OFF'
const INIT_STATE = {
    systemWarning: 'OFF',
};

export default function SetSystemWarning(state = INIT_STATE, action) {
    switch (action.type) {
        case 'setSystemWarningSwitch':
            localCache.set('system_warning_switch', action.data);
            return {
                ...state,
                ...{
                    systemWarning: action.data,
                },
            };
        default:
            return state;
    }
}
