/**
 * @description 获取workflow部署状态
 * @param {object} workflowStatus workflow状态
 * @returns {number} 0: 部署失败 1: 部署成功 2: 部署中
 */
export function getWorkflowDeployStatus(workflowStatus) {
    // 部署中
    if (!workflowStatus.is_completed) return 2;
    // 部署失败
    if (workflowStatus.nodes.some((item) => item.node_status === 3)) return 0;
    // 部署完成
    return 1;
}
