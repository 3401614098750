import { TipsMapConfig, TOAST_CONFIG, AudioMap, ReportMap } from './alertMap';
import store from '@/store/index';
import { PARKING_LPNP_EXIT } from '@/store/action/parkingType';
import { singelPointReport } from '@/utils/report';
import reportEventName from '@/constant/reportEventName';
import { AppFn } from '@/utils/native';
import localCache from '@/utils/localCache';
import logSend from '@/utils/aLog';
export const isShowTips = (s) => {
    //LPNP相关
    const list = [
        'CRUISING',
        'PARKING',
        'LEARNING',
        'APA_SELECTING',
        'APA_PARKING',
        'APA_PARKING_PAUSE',
        'NEARBY_SEARCHING',
    ];
    //apa 相关
    const apaList = ['SEARCHING', 'SELECTING', 'PARKING_PAUSE', 'PARKING'];
    const result = list.includes(s) || apaList.includes(s);
    return result;
};

//与状态相关持续展示的文本内容
export const getTipsTxt = ({ status, track_id, count }) => {
    console.log('xxxxx-TipsMapConfig[status]', TipsMapConfig[status]);
    switch (status) {
        case 'LEARNING':
            return TipsMapConfig['LEARNING'];
        case 'APA_SELECTING':
            logSend({
                action: 'HMI_APA_SELECTING_LOG',
                data: {
                    status,
                    track_id,
                    count,
                },
            });
            return count > 0 ? TipsMapConfig['APA_SELECTING'] : 'No parking spots, please park in manually';
        default:
            return TipsMapConfig[status];
    }
};

export const getToastAndAudioText = (t, noticeData) => {
    switch (t) {
        case 'lpnp_cruising_turn':
            if (noticeData?.direction == 'left') {
                return {
                    txt: TOAST_CONFIG[t]?.lText,
                    audioName: AudioMap[`${t}_left`],
                };
            }
            if (noticeData?.direction == 'right') {
                return {
                    txt: TOAST_CONFIG[t]?.rText,
                    audioName: AudioMap[`${t}_right`],
                };
            }
            if (noticeData?.direction === 'straight') {
                return {
                    txt: TOAST_CONFIG[t]['straight'],
                    audioName: AudioMap[`${t}_straight`],
                };
            }
            break;

        case 'lpnp_learning_ramp':
            if (noticeData?.state == 'enter') {
                return {
                    txt: '',
                    audioName: AudioMap[`${t}_enter`],
                };
            }
            if (noticeData?.state == 'exit') {
                return {
                    txt: TOAST_CONFIG[t]?.exit,
                    audioName: AudioMap[`${t}_exit`],
                };
            }
            break;
        case 'lpnp_cruising_ramp':
            if (noticeData?.state == 'enter') {
                return {
                    txt: TOAST_CONFIG[t]?.enter,
                    audioName: AudioMap[`${t}_enter`],
                };
            }
            if (noticeData?.state == 'exit') {
                return {
                    txt: TOAST_CONFIG[t]?.exit,
                    audioName: AudioMap[`${t}_exit`],
                };
            }
            break;
        case 'lpnp_drive_to_map_starting_point':
            return {
                txt: '',
                audioName: AudioMap[t],
            };
        case 'lpnp_exit':
            // 通知接管
            store.dispatch({
                type: PARKING_LPNP_EXIT.lpnpExit,
                data: true,
            });
            return {
                txt: TOAST_CONFIG[t],
                audioName: AudioMap[t],
            };
        // 避让提醒
        case 'lpnp_interaction':
            return {
                txt: TOAST_CONFIG[t][noticeData.info],
                audioName: AudioMap[`${t}_${noticeData.info}`],
            };
        // 窄道通行提示
        case 'nrp_notice':
            return {
                txt: TOAST_CONFIG[t][noticeData.state],
                audioName: AudioMap[`${t}_${noticeData.state}`],
            };
        default:
            return {
                txt: TOAST_CONFIG[t],
                audioName: AudioMap[t],
            };
    }
};

export const handleFloorReport = (noticeData, noticeType, parkMode, isOnRamp, preRamp) => {
    const comNoticeType = noticeType + noticeData?.state;
    if (parkMode === 'LEARNING') {
        ReportMap[comNoticeType + '_learning'] && reportHandle(comNoticeType + '_learning');
    } else if (parkMode === 'CRUISING') {
        ReportMap[comNoticeType + '_cruising'] && reportHandle(comNoticeType + '_cruising');
    } else if (parkMode === 'NEARBY') {
        ReportMap[comNoticeType + '_nearby'] && reportHandle(comNoticeType + '_nearby');
    }

    if (parkMode === 'CRUISING' && isOnRamp && !preRamp) {
        reportHandle('lpnp_cruising_on_ramp_cruising');
        logSend({
            action: 'HMI_lpnp_cruising_on_ramp_cruising',
            data: {
                noticeData,
                noticeType,
                parkMode,
                isOnRamp,
                preRamp,
            },
        });
    }

    if (parkMode === 'NEARBY' && isOnRamp && !preRamp) {
        reportHandle('lpnp_cruising_on_ramp_nearby');
        logSend({
            action: 'HMI_lpnp_cruising_on_ramp_nearby',
            data: {
                noticeData,
                noticeType,
                parkMode,
                isOnRamp,
                preRamp,
            },
        });
    }
};

export const fisrtUpper = (str) => {
    return str.slice(0, 1).toUpperCase() + str.slice(1).toLowerCase();
};

//根据消息类型，上报各阶段埋点
export const reportHandle = (noticeType) => {
    if (ReportMap[noticeType]) {
        singelPointReport({
            eventName: ReportMap[noticeType].eventName,
            timestamp: new Date().getTime(),
            text: ReportMap[noticeType].text,
        });
    }
};

export const reportHandleHack = (audioName) => {
    if (audioName === 'lpnp_cruising_turn_left.mp3') {
        singelPointReport({
            eventName: reportEventName.HMI_LPNP_TURNING_VIEW,
            timestamp: new Date().getTime(),
            reportData: {
                '0x2F': 'Turn left',
            },
        });
    } else if (audioName === 'lpnp_cruising_turn_right.mp3') {
        singelPointReport({
            eventName: reportEventName.HMI_LPNP_TURNING_VIEW,
            timestamp: new Date().getTime(),
            reportData: {
                '0x30': 'Turn right',
            },
        });
    } else if (audioName?.includes('lpnp_interaction')) {
        singelPointReport({
            eventName: ReportMap[audioName]?.eventName,
            timestamp: new Date().getTime(),
            text: ReportMap[audioName]?.text,
        });
    }
};

export const alertAudioPlay = ({ audioName, noticeType, noticeLevel, speed_bumps_count, parkingMode }) => {
    console.log('xxxxxx-audio', audioName, noticeType, noticeLevel, parkingMode);
    switch (audioName) {
        case 'lpnp_start_learning.mp3':
        case 'lpnp_start_cruising.mp3':
            AppFn.playLocalAudio(audioName, 'Highest');
            break;
        case 'lpnp_speed_bump_inc.mp3':
            if (speed_bumps_count <= 1) {
                AppFn.playLocalAudio(audioName, 'Important');
            }
            break;
        case 'lpnp_cruising_turn_left.mp3':
        case 'lpnp_cruising_turn_right.mp3':
            AppFn.playLocalAudio(audioName, 'Important');
            break;

        default:
            if (
                parkingMode !== 'NEARBY' &&
                (noticeType == 'lpnp_start_apa_selecting' || noticeType == 'lpnp_apa_selecting_without_parking_slot')
            ) {
                logSend({
                    action: 'HMI_APA_SELECTING_NOT_NEARBY',
                    data: {
                        audioName,
                        noticeType,
                        noticeLevel,
                        speed_bumps_count,
                        parkingMode,
                    },
                });
                localCache.get('lpnp_start_apa_selecting', (data) => {
                    if (!data) {
                        localCache.set('lpnp_start_apa_selecting', true);
                        audioName && AppFn.playLocalAudio(audioName, fisrtUpper(noticeLevel));
                    }
                });
            } else {
                logSend({
                    action: 'HMI_APA_SELECTING_NEARBY',
                    data: {
                        audioName,
                        noticeType,
                        noticeLevel,
                        speed_bumps_count,
                        parkingMode,
                    },
                });
                //沿途泊入模式下，不播报这2个语音
                if (
                    ['lpnp_approaching_destination', 'lpnp_space_occupied_unable_continue'].includes(noticeType) &&
                    parkingMode === 'NEARBY'
                ) {
                    return;
                }
                audioName && AppFn.playLocalAudio(audioName, fisrtUpper(noticeLevel));
            }
            break;
    }
};
