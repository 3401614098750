import { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { getCarInfo } from '../api';
import { useIsParking, useBoxid } from '../hook';
import { useTranslation } from 'react-i18next';
import { InformationCircleOutline } from 'antd-mobile-icons';
import { Modal, Space, Tag } from 'antd-mobile';
import FM from '@/utils/nativeSubAndPub';
import { commonScoketMap } from '../../../constant/socketMap';
import isCNFn from '@/utils/isCN';
import './index.less';

const isCN = isCNFn();

export default function CarInfo() {
    const { t: translate } = useTranslation();
    const isParking = useIsParking();
    const box_id = useBoxid();
    const ip = useSelector((state) =>
        isParking ? state?.featureModelParking?.body?.ip : state?.featureModelDriving?.baseData?.body?.ip,
    );
    const carType = useSelector((state) => state?.featureModelCommon?.carType) || 'Devcar';
    const [carPlate, setCarPlate] = useState('--');
    useEffect(async () => {
        if (!box_id) return;
        let params = {
            car_plate: box_id,
            page: 1,
            page_size: 10,
        };
        let res = await getCarInfo(params);
        if (res?.code == 200) {
            setCarPlate(res?.data?.vehicle_list[0]?.car_plate);
        }
    }, [box_id]);

    const [carTypeList, setcarTypeList] = useState([]);
    useEffect(() => {
        window.HMI_get_common_message_callback = function (res) {
            setcarTypeList(res?.car_type_list || []);
        };
        FM.sendToHMICommon(commonScoketMap.workbench_config, {}, 'HMI_get_common_message_callback');
    }, []);
    const modalContainer = useRef();
    function openCarTypeListModal() {
        Modal.show({
            closeOnMaskClick: true,
            showCloseButton: true,
            getContainer: modalContainer.current,
            bodyClassName: 'car-info-tip-modal',
            title: '工作台支持远光灯录制车型',
            content: (
                <Space
                    wrap
                    justify={carTypeList.length ? 'start' : 'center'}
                    style={{ '--gap': '.2rem', width: '100%' }}
                >
                    {carTypeList.map((item) => (
                        <Tag key={item}>{item}</Tag>
                    ))}
                    {!carTypeList.length && <span className="tip">暂无数据</span>}
                </Space>
            ),
        });
    }

    return (
        <div className="work-bench-base" ref={modalContainer}>
            <h4>
                {translate('workBench.vehicleInfo')}
                {isCN && (
                    <span className="icon-box">
                        <InformationCircleOutline onClick={openCarTypeListModal} />
                    </span>
                )}
            </h4>
            <div className="work-bench-base-content">
                <div className="work-bench-base-content-item">
                    <h5>{translate('workBench.vehicleLicense')}</h5>
                    <p>{carPlate}</p>
                </div>
                <div className="work-bench-base-content-item">
                    <h5>{translate('workBench.vehicleHOST')}</h5>
                    <p>{box_id ? box_id : '--'}</p>
                </div>
                <div className="work-bench-base-content-item">
                    <h5>IP</h5>
                    <p>{carType === 'Devcar' && ip && ip !== 'Failed to get IP of vpn' ? ip : 'Searching IP...'}</p>
                </div>
            </div>
        </div>
    );
}
