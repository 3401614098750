import React, { useState, useEffect } from 'react';
import { Switch } from 'antd-mobile';
import CardLayout from '../../../components/cardLayout';
import { useSelector } from 'react-redux';
import { SocketMap } from '@/constant/socketMap';
import FM from '@/utils/nativeSubAndPub';
import '@/components/slider/smartDrivingSetting/components/cardStyle/index.less';

let timer = null;
export default function RctbCard() {
    const rctb_switch = useSelector((state) => state?.featureModelDriving?.switch?.adas?.rctb_switch);
    const [rctbSwitch, setRctbSwitch] = useState(rctb_switch === 'OPEN');

    // rctb开关
    useEffect(() => {
        rctb_switch && setRctbSwitch(rctb_switch === 'OPEN');
        if (timer) {
            clearTimeout(timer);
            timer = null;
        }
    }, [rctb_switch]);

    const switchRctb = async (value) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            setRctbSwitch(rctb_switch === 'OPEN');
        }, 2 * 1000);
        FM.sendToHMIDriving(
            SocketMap.adas_rctb_control,
            {
                rctb_switch: value ? 'OPEN' : 'CLOSE',
            },
            'drive_node_res_fail_cb',
        );
    };

    const leftDom = (
        <div className={'left_dom_wrap'}>
            <div className={'switch_wrap'}>
                <div className={'content_wrap'}>
                    <div className={'text_wrap'}>RCTB</div>
                </div>
                <Switch checked={rctbSwitch} onChange={(checked) => switchRctb(checked)} className={'customSwitch'} />
            </div>
        </div>
    );

    const rightDom = <></>;

    return (
        <>
            <CardLayout leftDom={leftDom} rightDom={rightDom} value={rctbSwitch} />
        </>
    );
}
