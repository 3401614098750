import { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { apaTipsMapConfig, TOAST_CONFIG } from './alertMap';
import {
    getTipsTxt,
    getToastAndAudioText,
    reportHandle,
    reportHandleHack,
    alertAudioPlay,
    handleFloorReport,
} from './fn';
import { TOAST_TIME, isParkingHome } from '@/components/parkingContainer/config';
import useShowApa from '@/components/parkingContainer/hooks/useShowApaAndLpnp';
import './index.less';

export default function ParkingTips() {
    const status = useSelector((state) => state?.featureModelParking.lpnp?.state);
    const apaStatus = useSelector((state) => state?.featureModelParking.apa?.state);
    const remainingDistance = useSelector((state) => state?.featureModelParking.apa?.remainingDistance);
    const gear = useSelector((state) => state?.featureModelParking?.body?.gear);

    const canSearching = useSelector((state) => state?.featureModelParking?.apa?.canSearching);
    const isOnRamp = useSelector((state) => state?.featureModelParking?.lpnp?.condition?.isOnRamp);
    const isParkingComplete = useSelector((state) => state?.featureModelParking?.lpnp?.condition?.isParkingComplete);

    // const switch_mode = useSelector((state) => state?.featureModelParking?.switch?.lpnpSwitch);
    const isShowGuide = useSelector((state) => state?.parking?.isShowGuide);
    const userMode = useSelector((state) => state?.parking?.userMode);
    const noticeData = useSelector((state) => state?.featureModelParking?.alert?.data);
    const noticeType = useSelector((state) => state?.featureModelParking?.alert?.type);
    const noticeLevel = useSelector((state) => state?.featureModelParking?.alert?.level);
    const [isShowAPA, notShowLpnp] = useShowApa(); //是否展示apa
    const speed_bumps_count = useSelector((state) => state?.featureModelParking.lpnp?.statistic?.speedBumpsCount);
    const mviz_msg = useSelector((state) => state?.parking?.mvizParkData);
    const apa_available_parking_slots_count = useSelector(
        (state) => state?.featureModelParking.lpnp?.statistic?.apaAvailableParkingSlotsCount,
    );
    const parkingMode = useSelector((state) => state?.featureModelParking?.hmi?.parkingMode);
    const speed = useSelector((state) => state?.featureModelParking?.body?.speed);

    // const [isShowToast, setShowToast] = useState(false);
    const [Toast, setToast] = useState(''); //3s后消失的文本内容
    const [longToast, setLongToast] = useState(''); //长展示文本内容

    const timerRef = useRef();
    const preRamp = useRef(false);

    const toastHandler = (txt) => {
        // setShowToast(true);
        txt && setToast(txt);
        clearTimeout(timerRef.current);
        timerRef.current = setTimeout(() => {
            setToast('');
            // setShowToast(false);
        }, TOAST_TIME);
    };
    const notNeedToast = () => !(TOAST_CONFIG[noticeType] || TOAST_CONFIG[noticeType] === '');

    const checkVaildAlert = () => getToastAndAudioText(noticeType, noticeData);

    const handleTipsAndAudio = (noticeType, noticeData) => {
        console.log('语音notice', noticeType, noticeData);
        // alert通用打点
        reportHandle(noticeType);

        // 过滤不需要播报信号
        if (notNeedToast()) return;

        // 非config中alert忽略
        if (!checkVaildAlert()) return;

        // 获取toast文案和音频名称
        let { txt, audioName } = getToastAndAudioText(noticeType, noticeData);
        if (parkingMode === 'NEARBY' && noticeType === 'lpnp_start_apa_parking') {
            audioName = 'lpnp_start_parking.mp3';
        }
        // alert 左右转向打点
        reportHandleHack(audioName);

        // 处理toast
        toastHandler(txt);

        // 处理语音播报

        alertAudioPlay({ audioName, noticeType, noticeLevel, speed_bumps_count, parkingMode });
    };

    // StartParkingNoticeHack(handleTipsAndAudio);
    useEffect(() => {
        console.log('mmmmx-xxx', noticeType);
        if (!isShowGuide && isParkingHome()) {
            handleTipsAndAudio(noticeType, noticeData);
            if (!notShowLpnp) {
                let lpnpLongToast = getTipsTxt({
                    status,
                    track_id: mviz_msg?.track_id,
                    count: apa_available_parking_slots_count,
                });

                handleFloorReport(noticeData, noticeType, parkingMode, isOnRamp, preRamp);
                if (isOnRamp !== preRamp.current) {
                    preRamp.current = isOnRamp;
                }
                //如果在坡道，优先展示坡道长显
                if (isOnRamp) {
                    lpnpLongToast = 'Driving on the ramp';
                }
                //泊入后不展示文案
                if (isParkingComplete && status === 'LEARNING') {
                    lpnpLongToast = '';
                }
                if (['PARKING', 'APA_PARKING', 'PARKING_PAUSE', 'PARKING_OUT'].includes(status)) {
                    lpnpLongToast = lpnpLongToast + getParkingDistance(status);
                }
                if (noticeType === 'apa_start_parking_out' && noticeData?.brake_override) {
                    lpnpLongToast = 'Prepare to park out, please release the brake';
                }

                lpnpLongToast && setLongToast(lpnpLongToast);
                if (
                    (status === 'STANDBY' || status === 'NOT-IN') &&
                    (apaStatus === 'STANDBY' || apaStatus === 'NOT-IN')
                ) {
                    setLongToast('');
                }
            }
        }
    }, [
        noticeType,
        noticeData?.direction,
        noticeData?.info,
        noticeData?.brake_override,
        status,
        parkingMode,
        noticeData?.state,
        isOnRamp,
        isParkingComplete,
        remainingDistance,
        userMode,
    ]);

    const getParkingDistance = (status) => {
        let distanceText = '';
        if (
            ['APA_PARKING', 'APA_PARKING_PAUSE', 'PARKING', 'PARKING_OUT'].includes(status) &&
            typeof remainingDistance === 'number'
        ) {
            if (gear === 'D') {
                distanceText = ` (keep going ${remainingDistance.toFixed(1)}m)`;
            } else if (gear === 'R') {
                distanceText = ` (keep reversing ${remainingDistance.toFixed(1)}m)`;
            }
        }

        return distanceText;
    };

    //APA无法根据状态获取长显文案
    useEffect(() => {
        //满足apa展示条件
        if (isShowAPA) {
            if (speed >= 25) {
                setLongToast(apaTipsMapConfig['SPEED_FAST']);
            } else {
                if (apaTipsMapConfig['SPEED_FAST'] === longToast) {
                    setLongToast('');
                }

                if (apaStatus === 'SEARCHING') {
                    if (apa_available_parking_slots_count > 0) {
                        setLongToast(apaTipsMapConfig['FIND_SPACE']);
                        return;
                    } else if (apaTipsMapConfig['FIND_SPACE'] === longToast) {
                        setLongToast('');
                        return;
                    }
                }
                let apaText = apaTipsMapConfig[apaStatus];

                if (['PARKING', 'PARKING_PAUSE', 'PARKING_OUT'].includes(apaStatus)) {
                    apaText = apaText + getParkingDistance(apaStatus);
                }
                apaText && setLongToast(apaText);
                if (apaStatus === 'NOT_IN' || apaStatus === 'STANDBY') {
                    setLongToast('');
                }
            }
        }
    }, [speed, apa_available_parking_slots_count, canSearching, apaStatus, remainingDistance]);

    useEffect(() => {
        //如果当前c,并且长显示的内容为apa的，直接清空
        if (userMode === 'C') {
            (apaTipsMapConfig[apaStatus] ||
                [
                    apaTipsMapConfig['CAN_SEARCHING'],
                    apaTipsMapConfig['SPEED_FAST'],
                    apaTipsMapConfig['FIND_SPACE'],
                ].includes(longToast)) &&
                setLongToast('');
        }
    }, [userMode]);
    return (
        <>
            {
                <div className={classNames(['parking-tips', { sr: userMode === 'C' }])}>
                    <div className="wrap">
                        <p>{Toast || longToast}</p>
                    </div>
                </div>
            }
        </>
    );
}
