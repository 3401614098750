import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Switch } from 'antd-mobile';
import CardLayout from '../../../components/cardLayout';
import tla from '@/assets/settingComNew/activeSafety/tla.png';
import { cusToast } from '@/components/customToast';
import { useSelector } from 'react-redux';
import { SocketMap } from '@/constant/socketMap';
import FM from '@/utils/nativeSubAndPub';
import '@/components/slider/smartDrivingSetting/components/cardStyle/index.less';
import { singelPointReport } from '@/utils/report';
import reportEventName from '@/constant/reportEventName';

let timer = null;
export default function TlaCard() {
    const { t: translate } = useTranslation();
    const TLA_switch = useSelector((state) => state?.featureModelDriving?.switch?.adas?.tla_switch) || 'OFF';
    const [TLASwitch, setTLASwitch] = useState(TLA_switch === 'ACTIVE');

    // TAL信号灯开关
    useEffect(() => {
        setTLASwitch(TLA_switch === 'ACTIVE');
        if (timer) {
            clearTimeout(timer);
            timer = null;
        }
    }, [TLA_switch]);

    const switchTAL = async (value) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            setTLASwitch(TLA_switch === 'ACTIVE');
        }, 2 * 1000);
        FM.sendToHMIDriving(
            SocketMap.TALSwitchControl,
            {
                tal_switch: value ? 'ACTIVE' : 'OFF',
            },
            'drive_node_res_fail_cb',
        );

        reportDataFunc(value ? 'ACTIVE' : 'OFF');
    };
    // 数据上报
    const reportDataFunc = (value) => {
        singelPointReport({
            eventName: reportEventName.HMI_TLA_SWITCH_CLICK,
            timestamp: new Date().getTime(),
            reportData: {
                TLA: value,
            },
        });
    };

    const leftDom = (
        <div className={'left_dom_wrap'}>
            <div className={'switch_wrap'}>
                <div className={'content_wrap'}>
                    <div className={'text_wrap'}>TLA</div>
                    <div className={'text_wrap_detail'}>{translate('settings.TLASwitch')}</div>
                </div>
                <Switch checked={TLASwitch} onChange={(checked) => switchTAL(checked)} className={'customSwitch'} />
            </div>
        </div>
    );

    const rightDom = (
        <div className={'right_dom_wrap'}>
            <img src={tla} />
        </div>
    );

    return (
        <>
            <CardLayout leftDom={leftDom} rightDom={rightDom} value={TLASwitch} />
        </>
    );
}
