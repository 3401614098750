import { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Space, Dialog } from 'antd-mobile';
import { HOT_AREA_TYPE } from '@/store/action/type';
import { ROUTE_TEST_URL } from '@/constant/config';
import http from '@/utils/http';
import { getCookie } from '@/utils/tools';
import './index.less';
import { AppFn } from '@/utils/native';
import { useTranslation } from 'react-i18next';

const packageInfo = require('./../../../../package.json');

export default function TestAssistant(props) {
    const [loading, setLoading] = useState(false);
    const [routeTest, setRouteTest] = useState({});
    const [errorTip, setErrorTip] = useState('');
    const maf_version_state = useSelector((state) => state?.featureModelDriving?.baseData?.hmi?.maf_version);
    const box_id = useSelector((state) => state?.featureModelDriving?.baseData?.body?.boxid);
    const ip = useSelector((state) => state?.featureModelDriving?.baseData?.body?.ip);
    const hmi_version = useSelector((state) => state?.featureModelDriving?.baseData?.hmi?.hmi_version);
    const ota_version = useSelector((state) => state?.featureModelDriving?.baseData?.hmi?.ota_version);
    // ua信息
    const userAgent = window.navigator.userAgent;
    // appVersion信息
    const regVersion = /hmi_appVersion\(([^\)]+)\)/;
    const appVersion = regVersion.exec(userAgent) ? regVersion.exec(userAgent)[1] : '';
    // 车端版本号
    const otaVersion = ota_version ? `${ota_version?.split('-')[1]}-${ota_version?.split('-')[2]}` : '';
    const carType =
        useSelector(
            (state) => state?.featureModelDriving?.baseData?.hmi?.car_type || state?.featureModelCommon?.carType,
        ) || '';
    // const carType = getCookie('carType') || '';
    const dispatch = useDispatch();
    // 热区相关
    const testAssistantModalDom = useRef(null);
    const area = useSelector((state) => state?.layout?.Area);
    const switchMvizState = useSelector((state) => state?.switchModeAction?.switchMvizState);
    const robonodeReady = useSelector((state) => state?.robonode?.ready);
    const [isRestart, setIsRestart] = useState(false);
    const [isClose, setIsClose] = useState(false);

    const { t: translate } = useTranslation();

    const routeTestData = [
        {
            smartDriveTitle: 'NP',
            smartDriveKey: 'hnp',
            smartDriveValue: [
                {
                    projectKey: 'activation_speed_within_threshold',
                    projectName: 'testAssistant.activationSpeed',
                },
                {
                    projectKey: 'lane_change_point_not_missed',
                    projectName: 'testAssistant.lastLaneChange',
                },
                {
                    projectKey: 'localization_on_map',
                    projectName: 'testAssistant.localizationOnMap',
                },
                {
                    projectKey: 'map_odd_outside_threshold',
                    projectName: 'testAssistant.mapOdd',
                },
                {
                    projectKey: 'navi_on',
                    projectName: 'testAssistant.naviOn',
                },
                {
                    projectKey: 'lat_acceleration_within_threshold',
                    projectName: 'testAssistant.latAcceleration',
                },
                {
                    projectKey: 'normal_width',
                    projectName: 'testAssistant.normalWidth',
                },
                {
                    projectKey: 'not_crossing_lane',
                    projectName: 'testAssistant.notCrossing',
                },
                {
                    projectKey: 'on_route',
                    projectName: 'testAssistant.onRoute',
                },
                {
                    projectKey: 'routing_complete',
                    projectName: 'testAssistant.routingComplete',
                },
                {
                    projectKey: 'steering_angle_within_threshold',
                    projectName: 'testAssistant.steering',
                },
            ],
        },
        {
            smartDriveTitle: 'CP',
            smartDriveKey: 'pilot',
            smartDriveValue: [
                {
                    projectKey: 'activation_speed_within_threshold',
                    projectName: 'testAssistant.activationSpeed',
                },
                {
                    projectKey: 'lane_boundary_clear',
                    projectName: 'testAssistant.laneBoundaryClear',
                },
                {
                    projectKey: 'lat_acceleration_within_threshold',
                    projectName: 'testAssistant.latAcceleration',
                },
                {
                    projectKey: 'normal_lane',
                    projectName: 'testAssistant.normalLane',
                },
                {
                    projectKey: 'normal_width',
                    projectName: 'testAssistant.normalWidth',
                },
                {
                    projectKey: 'not_crossing_lane',
                    projectName: 'testAssistant.notCrossing',
                },
                {
                    projectKey: 'relative_degree_within_limit',
                    projectName: 'testAssistant.relativeDegree',
                },
                {
                    projectKey: 'steering_angle_within_threshold',
                    projectName: 'testAssistant.steering',
                },
            ],
        },
        {
            smartDriveTitle: 'ACC',
            smartDriveKey: 'acc',
            smartDriveValue: [
                {
                    projectKey: 'lat_acceleration_within_threshold',
                    projectName: 'testAssistant.latAcceleration',
                },
                {
                    projectKey: 'steering_angle_within_threshold',
                    projectName: 'testAssistant.steering',
                },
                {
                    projectKey: 'activation_speed_within_threshold',
                    projectName: 'testAssistant.activationSpeed',
                },
            ],
        },
    ];

    useEffect(() => {
        searchRouteTestData();
    }, []);

    useEffect(() => {
        const testAssistantModalArea = testAssistantModalDom?.current?.getBoundingClientRect();
        const testAssistantModalInfo = `${testAssistantModalArea?.x}, ${testAssistantModalArea?.y}, ${testAssistantModalArea?.width}, ${testAssistantModalArea?.height}`;
        typeof testAssistantModalArea !== 'undefined' &&
            dispatch({
                type: HOT_AREA_TYPE.testAssistantDom,
                data: testAssistantModalInfo,
            });
    }, [area?.isHorizontal, testAssistantModalDom?.current?.getBoundingClientRect()?.width, switchMvizState]);

    const handleCancel = () => {
        props.onCancel('close');
        const testAssistantModalArea = testAssistantModalDom?.current?.getBoundingClientRect();
        typeof testAssistantModalArea !== 'undefined' &&
            dispatch({
                type: HOT_AREA_TYPE.testAssistantDom,
                data: '',
            });
    };

    const searchRouteTestData = () => {
        setLoading(true);
        http.get(ROUTE_TEST_URL)
            .then((res) => {
                setRouteTest({ ...res });
                setLoading(false);
            })
            .catch((e) => {
                console.log(e);
                setErrorTip(translate('testAssistant.interfaceFail'));
                setRouteTest({});
                setLoading(false);
                AppFn.pushNoticeData(JSON.stringify({ eventName: 'Web_TestAssistant_Failed', data: {} }));
            });
    };

    const shutdownChange = async () => {
        // 关机 ----> shutdown
        const result = await Dialog.confirm({
            content: <p style={{ fontSize: '0.26rem' }}>{translate('testAssistant.shutDownContent')}</p>,
            confirmText: <p style={{ fontSize: '0.26rem' }}>{translate('confirm')}</p>,
            cancelText: <p style={{ fontSize: '0.26rem' }}>{translate('cancel')}</p>,
            closeOnMaskClick: true,
        });
        if (result) {
            setIsClose(true);
            console.log('关机');
            AppFn.setServerSwitch('shutdown');
        } else {
            setIsClose(false);
        }
    };

    const restartChange = async () => {
        // 重启 ----> reboot
        const result = await Dialog.confirm({
            content: <p style={{ fontSize: '0.3rem' }}>{translate('testAssistant.restartContent')}</p>,
            confirmText: <p style={{ fontSize: '0.26rem' }}>{translate('confirm')}</p>,
            cancelText: <p style={{ fontSize: '0.26rem' }}>{translate('cancel')}</p>,
            closeOnMaskClick: true,
        });
        if (result) {
            setIsRestart(true);
            console.log('重启');
            AppFn.setServerSwitch('reboot');
        } else {
            setIsRestart(false);
        }
    };

    return (
        <div className="test-modal-mask" ref={testAssistantModalDom}>
            <div className="setting-modal-bg" onClick={() => handleCancel()}></div>
            <div className="test-modal-box">
                <div className="modal-com">
                    <div className="modal-title">
                        <div className="iconfont close" onClick={() => handleCancel()}>
                            {'\ue621'}
                        </div>
                        <div className="title-text">{translate('sideBar.assistant')}</div>
                    </div>
                    <div className="modal-data">
                        <div className="data-header">
                            <div className="search">
                                <Button
                                    color="primary"
                                    className="search-button"
                                    onClick={searchRouteTestData}
                                    loading={loading}
                                    loadingText={translate('testAssistant.checking')}
                                >
                                    {translate('testAssistant.check')}
                                </Button>
                                <div className="question">{translate('testAssistant.question')}</div>
                                {!routeTest?.acc && <div className="data-error">{errorTip}</div>}
                            </div>
                            <div>
                                <div className="title-message">
                                    <div className="message-data">
                                        <p className="message-title">{translate('testAssistant.keyInformation')}</p>
                                        <p className="app-version messageCommon">
                                            {`${translate('appVersion')}：`}
                                            <span>{appVersion}</span>
                                        </p>
                                        {/* <p className="base-version messageCommon">
                                            {`${translate('softwareVersion')}：`}
                                            <span>{maf_version_state}</span>
                                        </p> */}
                                        <p className="h5-version messageCommon">
                                            {`${translate('h5Version')}：`}
                                            <span>{packageInfo?.version}</span>
                                        </p>
                                        {hmi_version && (
                                            <p className="base-version messageCommon">
                                                {`${translate('hmiVersion')}：`}
                                                <span>{hmi_version}</span>
                                            </p>
                                        )}
                                        <p className="mf-version messageCommon">
                                            {`${translate('mfVersion')}：`}
                                            <span>{otaVersion}</span>
                                        </p>
                                        <p className="box-id messageCommon">
                                            {`${translate('boxId')}：`}
                                            <span>{`${box_id || ''}${carType ? `（${carType}）` : ''}`}</span>
                                        </p>
                                        <p className="box-ip messageCommon">
                                            {`${translate('carIp')}：`}
                                            <span>
                                                {carType?.toLowerCase() === 'Robocar'?.toLowerCase()
                                                    ? `${ip || ''}（a面）`
                                                    : ip}
                                            </span>
                                        </p>
                                    </div>
                                </div>

                                <div className={'server_trigger'}>
                                    <Space style={{ gap: '0.5rem' }}>
                                        {/*点击关机按钮 & robonode处于正常连接状态是，进行loading*/}
                                        <Button
                                            color={'primary'}
                                            size={'large'}
                                            onClick={shutdownChange}
                                            loading={isClose && robonodeReady}
                                            loadingText={translate('testAssistant.shuttingDown')}
                                            className={'button_wrap'}
                                        >
                                            {translate('testAssistant.shutDown')}
                                        </Button>
                                        {/*点击重启按钮 & robonode处于正常连接状态时，进行loading*/}
                                        <Button
                                            color={'primary'}
                                            size={'large'}
                                            onClick={restartChange}
                                            loading={isRestart && robonodeReady}
                                            loadingText={translate('testAssistant.restarting')}
                                            className={'button_wrap'}
                                        >
                                            {translate('testAssistant.restart')}
                                        </Button>
                                    </Space>
                                </div>
                            </div>
                        </div>
                        {routeTest?.acc && (
                            <div
                                className="data-content"
                                style={{ flexDirection: area.isHorizontal === 'vertical' ? 'column' : 'row' }}
                            >
                                {routeTestData.map((item) => (
                                    <div className="data-item" key={item.smartDriveKey}>
                                        <div
                                            className={`data-item-title ${
                                                Object.values(routeTest[item.smartDriveKey]).includes(0) ? 'waring' : ''
                                            }`}
                                        >
                                            {`${item.smartDriveTitle}：
                                                            ${
                                                                Object.values(routeTest[item.smartDriveKey]).includes(0)
                                                                    ? `${translate('testAssistant.fail')}`
                                                                    : Object.values(
                                                                          routeTest[item.smartDriveKey],
                                                                      ).includes(-1)
                                                                    ? `${translate('testAssistant.notUse')}`
                                                                    : `${translate('testAssistant.well')}`
                                                            }`}
                                        </div>
                                        <div className="data-item-list">
                                            <ul className="data-item-ul">
                                                {item.smartDriveValue.map((itemValue) => (
                                                    <li
                                                        className={`data-item-normal ${
                                                            routeTest[item.smartDriveKey][itemValue.projectKey] === 1 ||
                                                            routeTest[item.smartDriveKey][itemValue.projectKey] === -1
                                                                ? ''
                                                                : 'waring'
                                                        }`}
                                                        key={itemValue.projectKey}
                                                    >
                                                        {`${translate(itemValue.projectName)}：
                                                                            ${
                                                                                routeTest[item.smartDriveKey][
                                                                                    itemValue.projectKey
                                                                                ] === 1
                                                                                    ? `${translate(
                                                                                          'testAssistant.well',
                                                                                      )}`
                                                                                    : routeTest[item.smartDriveKey][
                                                                                          itemValue.projectKey
                                                                                      ] === -1
                                                                                    ? `${translate(
                                                                                          'testAssistant.notUse',
                                                                                      )}`
                                                                                    : `${translate(
                                                                                          'testAssistant.fail',
                                                                                      )}`
                                                                            }`}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
