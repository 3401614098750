import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PNPInfoTips from '../hmiInfo/index';
import FM from '@/utils/nativeSubAndPub';
import { cusToast } from '@/components/customToast';
import store from '@/store/index';
import { MVIZ_APA_PARKING_DATA } from '@/store/action/parkingType';
import { Switch } from 'antd-mobile';
import logSend from '@/utils/aLog';
import { HOT_AREA_TYPE } from '@/store/action/type';
import { AppFn } from '@/utils/native';
import localAudioNames from '@/constant/localAudioName';
import close from '@/assets/parking/close.png';
import { useNavigate, useLocation } from 'react-router-dom';
import { LPNP_CMD, DEBUG_CMD, STATUS_MAP, DirectionMap } from './config';
import {
    active_select,
    disabled_select,
    start_searching,
    start_parking,
    stop_parking,
    resume_parking,
    start_searching_out,
    exit_apa,
    select_parking_slot,
    select_parking_direction,
} from './fn';
import './index.less';
const { playLocalAudio } = AppFn;

export default function DebugBtn() {
    const parking_out_direction = useSelector((state) => state?.featureModelParking?.apaDebug?.canParkingOutDirection);
    const debug_state = useSelector((state) => state?.featureModelParking?.apaDebug?.state || 'UNKNOW');
    const apaStatus = useSelector((state) => state?.featureModelParking?.apa?.state);
    const mviz_msg = useSelector((state) => state?.parking?.mvizParkData);
    const track_id = useSelector((state) => state?.parking?.mvizParkData?.track_id);
    const isHotAll = useSelector((state) => state.hotArea.isHotAll);
    const noticeType = useSelector((state) => state?.featureModelParking?.alert?.type);

    //apa debug返回按钮
    const debugBack = useSelector((state) => state.hotArea.debugBack);
    //apa debug操作区
    const debugOperator = useSelector((state) => state.hotArea.debugOperator);

    const backRef = useRef(null);
    const operatorRef = useRef(null);

    const [curDirection, setCurDirection] = useState('');
    const [showAlert, setShowAlert] = useState(false);
    const [rpa, setRpa] = useState(true);
    const [rpaCmd, setRpaCmd] = useState('rpa');

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const setCurDir = (val) => {
        if (apaStatus !== 'SEARCHING_OUT') return;
        setCurDirection(val);
        select_parking_direction(rpaCmd, val);
    };
    const showDebugAlert = () => {
        setShowAlert(true);
        store.dispatch({
            type: HOT_AREA_TYPE.isHotAll,
            data: true,
        });
    };
    const cancel = () => {
        setShowAlert(false);
        setCurDirection('');
        store.dispatch({
            type: HOT_AREA_TYPE.isHotAll,
            data: false,
        });
    };
    const comfirm = async () => {
        if (!curDirection) {
            return cusToast.show({
                content: 'Please select parking out direction!',
            });
        }
        const cmdDta = {
            cmd: DEBUG_CMD.START_PARKING_OUT,
            parking_out_direction: curDirection,
            debug: true,
        };
        if (rpaCmd) {
            cmdDta.rpa = rpaCmd;
        }
        FM.sendToHMIParking(LPNP_CMD, cmdDta, 'apa_control_cb9');
    };
    window.apa_control_cb9 = (res) => {
        if (res?.data?.code === 0) {
            cancel();
            return cusToast.show({
                content: 'Start Park Out!',
            });
        }
        setCurDirection('');
        cusToast.show({
            content: 'Park Out Failed!',
        });
    };
    const notHandleMsg = (obj) => {
        return !obj?.hasOwnProperty('mviz_msg');
    };

    useEffect(() => {
        setTimeout(() => {
            const operatorArea = operatorRef?.current?.getBoundingClientRect();
            const backArea = backRef?.current?.getBoundingClientRect();
            const backAreaInfo = `${backArea?.x}, ${backArea?.y}, ${backArea?.width}, ${backArea?.height}`;
            const operatorAreaInfo = `${operatorArea?.x}, ${operatorArea?.y}, ${operatorArea?.width}, ${operatorArea?.height}`;
            dispatch({
                type: HOT_AREA_TYPE.debugBackArea,
                data: backAreaInfo,
            });
            dispatch({
                type: HOT_AREA_TYPE.debugOperatorArea,
                data: operatorAreaInfo,
            });
        }, 300);
    }, [rpa]);
    // APA泊出语音提示
    useEffect(() => {
        if (noticeType === 'apa_park_out_take_over') {
            playLocalAudio('apa_park_out_take_over.mp3', 'Normal');
        }
    }, [noticeType]);

    useEffect(() => {
        const HotArea = [];
        debugBack !== '' && HotArea.push(debugBack);
        debugOperator !== '' && HotArea.push(debugOperator);
        AppFn.setHotArea(HotArea, isHotAll);
    }, [isHotAll, debugBack, debugOperator]);

    // const onFocus = () => {
    //     const inputElement = window.document.getElementById('input');
    //     const settingContent = document.querySelector('.setting-content');
    //     const inputRect = inputElement.getBoundingClientRect();
    //     const inputTop = inputRect.top + settingContent.scrollTop;

    //     settingContent.scrollTo({ top: inputTop - 50, behavior: 'smooth' });

    // };
    useEffect(() => {
        let momenta_mviz_msg = function (data) {
            if (data instanceof MessageEvent) {
                // 浏览器端处理逻辑
                if (notHandleMsg(data?.data)) return;
                store.dispatch({
                    type: MVIZ_APA_PARKING_DATA.mvizData,
                    data: data?.data,
                });
                console.log('xxx momenta_mviz_msg event', data);
            } else {
                logSend({
                    action: 'mviz_to_hmi_apa',
                    data,
                });
                // mviz消息处理逻辑
                console.log('xxx momenta_mviz_msg', data);
                try {
                    store.dispatch({
                        type: MVIZ_APA_PARKING_DATA.mvizData,
                        data: JSON.parse(data?.mviz_msg),
                    });
                    playLocalAudio(localAudioNames.SELECT_CAR_SITE_D, 'Normal');
                } catch (error) {
                    console.log('vv-111222', error);
                    store.dispatch({
                        type: MVIZ_APA_PARKING_DATA.mvizData,
                        data: {},
                    });
                }
            }
        };
        window['momenta_mviz_msg'] = momenta_mviz_msg;
        window.addEventListener('message', momenta_mviz_msg, false);
    }, []);

    useEffect(() => {
        if (!mviz_msg?.track_id) return;
        if (!['SEARCHING', 'SELECTING'].includes(apaStatus)) return;
        select_parking_slot(rpaCmd, mviz_msg.track_id);
    }, [mviz_msg?.track_id]);

    return (
        <div className="setting-mask">
            <div ref={operatorRef} className="setting-content">
                <div
                    className="back-btn"
                    onClick={() => {
                        navigate(`/home/parking${location?.search}`);
                    }}
                >
                    Exit Debug
                </div>

                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div className="group">
                        <div className="debug-btn" onClick={() => exit_apa(rpaCmd)}>
                            Exit APA
                        </div>
                        <div className="debug-btn" onClick={() => start_searching(rpaCmd)}>
                            Park In Mode
                        </div>
                        <div className="debug-btn" onClick={() => start_searching_out(rpaCmd)}>
                            Park Out Mode
                        </div>
                    </div>

                    <div className="group">
                        <div className="debug-btn" onClick={() => active_select(rpaCmd)}>
                            Start Paking Selection
                        </div>
                        <div className="debug-btn" onClick={() => disabled_select(rpaCmd)}>
                            End Parking Selection
                        </div>
                        <div className="debug-btn" onClick={() => start_parking(mviz_msg, rpaCmd)}>
                            Park In {mviz_msg?.track_id}
                        </div>
                        <div className="debug-btn" onClick={() => showDebugAlert()}>
                            Park Out
                        </div>
                    </div>

                    <div className="group">
                        <div className="debug-btn" onClick={() => stop_parking(rpaCmd)}>
                            Terminate
                        </div>
                        <div className="debug-btn" onClick={() => resume_parking(rpaCmd)}>
                            Resume
                        </div>
                        <div className="debug-btn">
                            <Switch
                                checked={rpa}
                                onChange={(val) => {
                                    setRpa(val);
                                    if (!val) {
                                        setRpaCmd('');
                                    } else {
                                        setRpaCmd('rpa');
                                    }
                                }}
                            />
                            <span style={{ paddingLeft: '.15rem' }}>Rpa Switch</span>
                        </div>
                    </div>
                </div>

                {/* {rpa && (
                        <Input
                            id="input"
                            // onFocus={onFocus}
                            style={{
                                background: '#fff',
                                height: '.5rem',
                                margin: '.15rem',
                                borderRadius: '.05rem',
                                '--font-size': '.24rem',
                            }}
                            value={rpaCmd}
                            onChange={(val) => {
                                setRpaCmd(val);
                            }}
                        />
                    )} */}
            </div>
            <div className="debug-work-status">
                <div size="small" color="primary" fill="outline">
                    {STATUS_MAP[debug_state]}
                </div>
            </div>
            {showAlert ? (
                <div className="debug-alert-mask">
                    <div className="debug-alert-content">
                        <h3>Please select parking in direction</h3>
                        <i onClick={() => cancel()} className="debug-alert-close">
                            <img src={close} alt="close" />
                        </i>
                        <div className="debug-direction-wrapper">
                            {parking_out_direction?.length
                                ? parking_out_direction.map((item, index) => {
                                      return (
                                          <div
                                              key={index}
                                              onClick={() => {
                                                  setCurDir(item);
                                              }}
                                              className={`${curDirection == item ? 'active' : ''}`}
                                          >
                                              <div className="debug-option">{DirectionMap[item]}</div>
                                          </div>
                                      );
                                  })
                                : ''}

                            {!parking_out_direction?.length ? <span>No available parking out direction</span> : ''}
                        </div>
                        {parking_out_direction?.length ? (
                            <div className="debug-btn-wrap">
                                <div className="debug-option" onClick={() => cancel()}>
                                    Cancel
                                </div>
                                <div className="debug-option" onClick={() => comfirm()}>
                                    Park Out
                                </div>
                            </div>
                        ) : (
                            ''
                        )}
                    </div>
                </div>
            ) : (
                ''
            )}
            <PNPInfoTips />
        </div>
    );
}
