import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { singelPointReport } from '@/utils/report';
import reportEventName from '@/constant/reportEventName';
import { SocketMap } from '@/constant/socketMap';
import FM from '@/utils/nativeSubAndPub';
import './index.less';

let rctaAndRctbTimer = null;
let rpcmTimer = null;
let rvbTimer = null;
export default function CustomRimCard() {
    const dispatch = useDispatch();
    const flag = useSelector((state) => state?.smartDrivingSettingMsg?.flag);
    const { t: translate } = useTranslation();
    // 按钮键值对
    const settingOptions = translate('customRIMSetting.settingOptions', { returnObjects: true });
    // RCTA/RCTB 后方横向穿行辅助
    const rcta_rctb_switch = useSelector((state) => state?.featureModelDriving?.switch?.adas?.rcta_rctb_switch);
    const [settingRCTAChange, setSettingRCTAChange] = useState('OFF');
    // RPCM 后向行人碰撞缓解
    const rpcm_switch = useSelector((state) => state?.featureModelDriving?.switch?.adas?.rpcm_switch);
    const [settingRPCMChange, setSettingRPCMChange] = useState('OFF');
    // RVB 后方碰撞提醒与制动
    const rvb_switch = useSelector((state) => state?.featureModelDriving?.switch?.adas?.rvb_switch);
    const [settingRVBChange, setSettingRVBChange] = useState('OFF');
    // RIM 开关
    const rim_switch = useSelector((state) => state?.featureModelDriving?.switch?.adas?.rim_switch);

    useEffect(() => {
        rcta_rctb_switch && setSettingRCTAChange(rcta_rctb_switch === 'OPEN' ? 'ON' : 'OFF');
        rpcm_switch && setSettingRPCMChange(rpcm_switch === 'OPEN' ? 'ON' : 'OFF');
        rvb_switch && setSettingRVBChange(rvb_switch === 'OPEN' ? 'ON' : 'OFF');
        if (rctaAndRctbTimer) {
            clearTimeout(rctaAndRctbTimer);
            rctaAndRctbTimer = null;
        }
        if (rpcmTimer) {
            clearTimeout(rpcmTimer);
            rpcmTimer = null;
        }
        if (rvbTimer) {
            clearTimeout(rvbTimer);
            rvbTimer = null;
        }
        if (rcta_rctb_switch !== 'OPEN' && rpcm_switch !== 'OPEN' && rvb_switch !== 'OPEN') {
            sendControl(SocketMap.adas_rim_control, {
                rim_switch: 'CLOSE',
            });
        }
    }, [rcta_rctb_switch, rpcm_switch, rvb_switch]);

    const customSwitchReminder = () => {
        if (rim_switch !== 'OPEN') {
            const text = translate('customRIMSetting.onRIMFirst');
            dispatch({
                type: 'setUpMsg',
                data: {
                    tooltipMsg: text,
                    flag: !flag,
                },
            });
            return true;
        }
        return false;
    };

    const handleRctaAndRctbMode = (value) => {
        const isRimOpen = customSwitchReminder();
        if (isRimOpen) return;
        clearTimeout(rctaAndRctbTimer);
        rctaAndRctbTimer = setTimeout(() => {
            setSettingRCTAChange(rcta_rctb_switch === 'OPEN' ? 'ON' : 'OFF');
        });
        sendControl(SocketMap.adas_rcta_rctb_control, {
            rcta_rctb_switch: value === 'ON' ? 'OPEN' : 'CLOSE',
        });
        // 数据埋点
        reportDataFunc({
            flag: 'rcta_rctb_switch_click',
            value,
            repName: reportEventName.HMI_RCTA_RCTB_SWITCH_CLICK,
        });
    };

    const handleRpcmMode = (value) => {
        const isRimOpen = customSwitchReminder();
        if (isRimOpen) return;
        clearTimeout(rpcmTimer);
        rpcmTimer = setTimeout(() => {
            setSettingRPCMChange(rpcm_switch === 'OPEN' ? 'ON' : 'OFF');
        });
        sendControl(SocketMap.adas_rpcm_control, {
            rpcm_switch: value === 'ON' ? 'OPEN' : 'CLOSE',
        });
        // 数据埋点
        reportDataFunc({
            flag: 'rpcm_switch_click',
            value,
            repName: reportEventName.HMI_RPCM_SWITCH_CLICK,
        });
    };

    const handleRvbMode = (value) => {
        const isRimOpen = customSwitchReminder();
        if (isRimOpen) return;
        clearTimeout(rvbTimer);
        rvbTimer = setTimeout(() => {
            setSettingRVBChange(rvb_switch === 'OPEN' ? 'ON' : 'OFF');
        });
        sendControl(SocketMap.adas_rvb_control, {
            rvb_switch: value === 'ON' ? 'OPEN' : 'CLOSE',
        });
        // 数据埋点
        reportDataFunc({
            flag: 'rvb_switch_click',
            value,
            repName: reportEventName.HMI_RVB_SWITCH_CLICK,
        });
    };

    const sendControl = async (path, params) => {
        FM.sendToHMIDriving(path, params, 'drive_node_res_fail_cb');
    };

    // 数据上报
    const reportDataFunc = (params) => {
        const { flag, value, repName } = params;
        let dataObj = {};
        dataObj[flag] = value;
        singelPointReport({
            eventName: repName,
            timestamp: new Date().getTime(),
            reportData: dataObj,
        });
    };

    return (
        <div className={`custom_ldw_card_wrap ${rim_switch === 'OPEN' ? '' : 'custom_ldw_not_active'}`}>
            <div className={'title_wrap'}>{translate('customRIMSetting.customRIM')}</div>

            {/*RCTA/RCTB 后方横向穿行辅助 ----> 0102 Victor要求下掉RIM子开关中的RCTA/RCTB按钮*/}
            {/*<div className={'sensitivity_wrap'}>*/}
            {/*    <p className="subtitle_wrap">{translate('customRIMSetting.rctaAndRctbSwitch')}</p>*/}
            {/*    <div className={'select_box'}>*/}
            {/*        {settingOptions.length &&*/}
            {/*            settingOptions.map((item, index) => {*/}
            {/*                return (*/}
            {/*                    <span*/}
            {/*                        key={index}*/}
            {/*                        className={`select_box_item ${settingRCTAChange === item.value ? 'active' : ''}`}*/}
            {/*                        style={{ width: '50%' }}*/}
            {/*                        onClick={() => handleRctaAndRctbMode(item.value)}*/}
            {/*                    >*/}
            {/*                        {item.label}*/}
            {/*                    </span>*/}
            {/*                );*/}
            {/*            })}*/}
            {/*    </div>*/}
            {/*</div>*/}

            {/*RPCM 后向行人碰撞缓解*/}
            <div className={'sensitivity_wrap'}>
                <p className="subtitle_wrap">{translate('customRIMSetting.rpcmSwitch')}</p>
                <div className={'select_box'}>
                    {settingOptions.length &&
                        settingOptions.map((item, index) => {
                            return (
                                <span
                                    key={index}
                                    className={`select_box_item ${settingRPCMChange === item.value ? 'active' : ''}`}
                                    style={{ width: '50%' }}
                                    onClick={() => handleRpcmMode(item.value)}
                                >
                                    {item.label}
                                </span>
                            );
                        })}
                </div>
            </div>

            {/*RVB 后方碰撞提醒与制动*/}
            <div className={'sensitivity_wrap'}>
                <p className="subtitle_wrap">{translate('customRIMSetting.rvbSwitch')}</p>
                <div className={'select_box'}>
                    {settingOptions.length &&
                        settingOptions.map((item, index) => {
                            return (
                                <span
                                    key={index}
                                    className={`select_box_item ${settingRVBChange === item.value ? 'active' : ''}`}
                                    style={{ width: '50%' }}
                                    onClick={() => handleRvbMode(item.value)}
                                >
                                    {item.label}
                                </span>
                            );
                        })}
                </div>
            </div>
        </div>
    );
}
