import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Switch } from 'antd-mobile';
import CardLayout from '../../../components/cardLayout';
import slif from '@/assets/settingComNew/activeSafety/slif.png';
import { cusToast } from '@/components/customToast';
import { useSelector } from 'react-redux';
import { SocketMap } from '@/constant/socketMap';
import FM from '@/utils/nativeSubAndPub';
import '@/components/slider/smartDrivingSetting/components/cardStyle/index.less';
import { singelPointReport } from '@/utils/report';
import reportEventName from '@/constant/reportEventName';

let timer = null;
export default function TsrCard() {
    const { t: translate } = useTranslation();
    const tsr_switch = useSelector((state) => state?.featureModelDriving?.switch?.adas?.tsr?.tsr_switch);
    const [SLIFSwitch, setSLIFSwitch] = useState(tsr_switch === 'OPEN');

    // TAL信号灯开关
    useEffect(() => {
        tsr_switch && setSLIFSwitch(tsr_switch === 'OPEN');
        if (timer) {
            clearTimeout(timer);
            timer = null;
        }
    }, [tsr_switch]);

    // swtich: SLIF/TSR
    const switchSLIFChange = (value) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            setSLIFSwitch(tsr_switch === 'OPEN');
        }, 2 * 1000);
        SLIFControl(value);
        reportDataFunc(value);
    };

    // 通知底层：SLIF/TSR
    const SLIFControl = async (value) => {
        FM.sendToHMIDriving(
            SocketMap.adasTsrControl,
            {
                tsr_switch: value ? 'OPEN' : 'CLOSE',
            },
            'drive_node_res_fail_cb',
        );
    };
    // 数据上报
    const reportDataFunc = (value) => {
        singelPointReport({
            eventName: reportEventName.HMI_TSR_SWITCH_CLICK,
            timestamp: new Date().getTime(),
            reportData: {
                SLIF: value ? 'OPEN' : 'CLOSE',
            },
        });
    };

    const leftDom = (
        <div className={'left_dom_wrap'}>
            <div className={'switch_wrap'}>
                <div className={'content_wrap'} style={{ marginBottom: '.14rem' }}>
                    {/* <div className={"text_wrap"} style={{paddingTop: "0"}}>SLIF</div>
                    <div className={"text_wrap_detail"}>{translate('AEBFCW.SLIFText')}</div> */}
                    <div className={'text_wrap'}>TSR</div>
                    <div className={'text_wrap_detail'}>{translate('AEBFCW.TSRText')}</div>
                </div>
                <Switch
                    checked={SLIFSwitch}
                    onChange={(checked) => switchSLIFChange(checked)}
                    className={'customSwitch'}
                />
            </div>
        </div>
    );

    const rightDom = (
        <div className={'right_dom_wrap'}>
            <img src={slif} />
        </div>
    );

    return (
        <>
            <CardLayout leftDom={leftDom} rightDom={rightDom} value={SLIFSwitch} />
        </>
    );
}
