import { useSelector } from 'react-redux';
import LPNP from '@/assets/parking/icons/icon_apa_start.png';

import './index.less';

const operate_icon_map = {
    CRUISING: {
        icon: LPNP,
        txt: 'LPNP Cruising',
        subT: 'Search on the way',
        iconSize: {
            height: '.63rem',
        },
    },
    NEARBY_SEARCHING: {
        icon: LPNP,
        txt: 'Searching on the way',
        subT: 'LPNP cruise',
        iconSize: {
            height: '.63rem',
        },
    },
};

export default function SwitchBtn(props) {
    const userMode = useSelector((state) => state?.parking?.userMode);
    return (
        <div className="change-btn-all-status">
            <div onClick={props?.handler} className={`p-status-item ${userMode == 'D' ? 'd' : 'sr'}`}>
                <img
                    className="p-status-icon"
                    style={operate_icon_map[props.name]?.iconSize}
                    src={operate_icon_map[props.name]?.icon}
                    alt="status_icon"
                />

                <div className="p-status-txt-wrap">
                    <span className={`p-status-txt ${userMode == 'D' ? 'd' : 'sr'}`}>
                        {operate_icon_map[props.name]?.txt}
                    </span>
                    {operate_icon_map[props.name]?.subT ? (
                        <span className={`p-status-sub-txt ${userMode == 'D' ? 'd' : 'sr'}`}>
                            {operate_icon_map[props.name]?.subT}
                        </span>
                    ) : (
                        ''
                    )}
                </div>
            </div>
        </div>
    );
}
