import React from 'react';
import LpnpCard from './lpnp.js';
import APACard from './APACard';

import './index.less';

export default function ParkingModule() {
    return (
        <React.Fragment>
            <div className={'safety_modular_wrap'}>
                <div className={'left_wrap'}>
                    <LpnpCard />
                    {/* <APACard /> */}
                </div>
                <div className={'right_wrap'}>{/* <LpnpCard /> */}</div>
            </div>
        </React.Fragment>
    );
}
