import { WORKBENCH_ACTION } from '../action/type';

const defaultState = {
    isOpen: false,
};

export default function workbench(state = defaultState, action) {
    switch (action.type) {
        case WORKBENCH_ACTION.open:
            return { ...state, isOpen: action.data };
        default:
            return state;
    }
}
