import { ParkingSocketMap } from '@/constant/socketMap';
import FM from '@/utils/nativeSubAndPub';

export default function usePedestriansSettingCommon() {
    // 通知底层：行人增强开关
    const pedestriansControl = async (pedestriansvalue) => {
        FM.sendToHMIParking(
            ParkingSocketMap.avm_control,
            {
                pedestrians: pedestriansvalue,
            },
            null,
        );
    };

    return {
        pedestriansControl,
    };
}
