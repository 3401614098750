import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { MVIZ_APA_PARKING_DATA } from '@/store/action/parkingType';
import localAudioNames from '@/constant/localAudioName';
import logSend from '@/utils/aLog';

import { AppFn } from '@/utils/native';
const { playLocalAudio } = AppFn;

export default function useSetUserSelect() {
    const dispatch = useDispatch();

    useEffect(() => {
        // C版本选车位
        window.notifyPickedParkingSpace = function (data) {
            console.log('111222-c版本选车位', data);
            logSend({
                action: 'selectCarSite',
                data,
            });
            dispatch({
                type: MVIZ_APA_PARKING_DATA.mvizData,
                data: {
                    num: data.code,
                    track_id: data.track_id,
                },
            });
            playLocalAudio(localAudioNames.SELECT_CAR_SITE_D, 'Normal');
        };
    }, []);
}
