import React, { useEffect, useState, useRef, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import dayjs from 'dayjs';
// import CanvasCircularCountdown from 'canvas-circular-countdown';
import FM from '@/utils/nativeSubAndPub';
import { HOT_AREA_TYPE } from '@/store/action/type';
import { cusToast } from '@/components/customToast';
import { SocketMap } from '@/constant/socketMap';
import logSend from '@/utils/aLog';
import successImg from '@/assets/workbench/record_done.png';
import { SWITH_MODE_ACTION } from '@/store/action/type';

import wvoiceImg from '@/assets/workbench/record_wvoice.png';
import bvoiceImg from '@/assets/workbench/record_bvoice.png';
import recordingImg from '@/assets/workbench/recording.gif';

import { singelPointReport } from '@/utils/report';
import workBenchEventName from '@/constant/workBenchEventName';

import { AppFn } from '@/utils/native';
import classNames from 'classnames';
import { useIsParking } from '../hook';
// import localAudioNames from '@/constant/localAudioName';

import { useSetHotArea } from '@/hook/useSetHotArea';

import './index.less';

const CricleOptions = {
    c_width: 64, // 画布的宽度
    c_height: 64, // 画布的高度
    radius: 30, // 圆圈的直径
    lineWidth: 3, // 圆圈的宽度
    backround: '#dddddd', // 圆圈的底色
    oneRound: 360, //周长
    lineColor: '#1677ff',
    interTime: 50, //执行的阶段时间，数组越少越流畅，但越耗资源，单位：毫秒
};

const { recordAudioFile, wbPlayAudioCallback } = AppFn;

const FilterConfig = {
    create_filter: {
        available: true, // 只能为true，否则不触发数采
        filter_param: {
            filter_type: 'event', // 只能填event，否则不触发数采
            filter_name: 'hmi_console_event', // 这个名字会被包含到最终的包名中
            collect_type: 'msd-case', // 1. collect_type 对照表
            event_filter_param: {
                sticky: true,
                before: 15, // 没有特殊需求就是15 15
                after: 15,
            },
        },
        link_eap: true, // 需要关联jira则为true
    },
};

const needBreakList = ['manual_recording', 'dbw_disabled_takeover', 'light_recording'];

export default React.memo(function Record() {
    const isParking = useIsParking();
    const userMode = useSelector((state) => state?.parking?.userMode);
    const box_id = useSelector((state) =>
        isParking ? state?.featureModelParking?.body?.boxId : state?.featureModelDriving?.baseData?.body?.boxid,
    );
    const filter_meta = useSelector((state) =>
        isParking ? state?.featureModelParking?.filterMeta : state?.featureModelDriving?.filterMeta,
    );
    const carType = useSelector(
        (state) =>
            state?.featureModelParking?.hmi?.carType ||
            state?.featureModelDriving?.baseData?.hmi?.car_type ||
            state?.featureModelCommon?.carType ||
            'Devcar',
    );

    //获取录音时间
    const recordTimes = useSelector((state) => state?.switchModeAction?.recordTimes);

    const isRecording = useSelector((state) => state?.switchModeAction?.isRecording);
    const themeColorState = useSelector((state) => state?.themeAction?.themeColorState);

    const [showSucess, setShowSuccess] = useState(false);

    let fileRef = useRef();
    let metaRef = useRef();
    let cutDownRef = useRef();
    let recordClickRef = useRef(false);
    let drawTimer = useRef();
    let startFunRef = useRef();
    const startRecordTimeStamp = useRef();
    const dispatch = useDispatch();

    const getRecordSecond = () => Math.round((Date.now() - startRecordTimeStamp.current) / 1000);

    window.endRecordAudio = function () {
        //上报验证有没有执行
        logSend({
            action: 'HMIEndRecord',
            data: {
                filter_meta: {
                    ...metaRef?.current,
                    execTime: Date.now(),
                    recordTimes: getRecordSecond(), // 上报录音时长设置参数
                },
            },
        });
        // setRecordEffect(false)
    };
    const localTest = () => {
        // 模拟播放
        if (isRecording) {
            return;
        }

        wbPlayAudioCallback(null, JSON.stringify({ type: 'duplex', data: 'start_record_reminder.mp3' }));

        fileRef.current = 'MKZ-EG7840_event_dbw_disabled_takeover_20221205-170701';
        setFileName('MKZ-EG7840_event_dbw_disabled_takeover_20221205-170701');
        // refMock.current = true
        metaRef.current = {
            type: 'startRecord',
            file_name: 'MKZ-EG7840_event_dbw_disabled_takeover_20221205-170701', // fileRef?.current,
            meta_data: {
                boxid: 'MKZ-EG7840',
                name: 'hmi_console_event',
                type: 'event',
                plate_num: '苏EG7840',
                time_sec: dayjs(),
                time_zone: dayjs().format(), //fmp需要带时区时间
                task_id: '',
                duration: recordTimes, //端上处理录音时长
            },
        };
        recordAudioFile('startRecordAudio', JSON.stringify(metaRef?.current));
        startRecordTimeStamp.current = Date.now();
        drawCircle();
        dispatch({
            type: SWITH_MODE_ACTION.isRecording,
            data: true,
        });
        // 模拟打断
        // setTimeout(()=>{
        //   clearInterval(drawTimer.current)
        //   window?.momentaJsBridge?.dispatch(
        //     "playLocalAudio",
        //     null,
        //     JSON.stringify({type: "duplex",data: "start_new_record_reminder.mp3"})
        //   );
        //   setRecordEffect(false)
        //   initCountdown(CricleOptions)
        //   setTimeout(()=>{
        //     setRecordEffect(true)
        //     drawCircle()
        //     fff()
        //   },1500)
        // },5000)

        //   function fff(){ setTimeout(()=>{
        //     setRecordEffect(false)
        //     setShowSuccess(true)
        //     dispatch({
        //       type: SWITH_MODE_ACTION.isRecording,
        //       data: false
        //     })
        //     //播放开始的音效
        //   window?.momentaJsBridge?.dispatch(
        //     "playLocalAudio",
        //     null,
        //     JSON.stringify({type: "duplex",data: "end_record_reminder.mp3"})
        //   );
        //     setTimeout(()=>{
        //       setShowSuccess(false)
        //       // initCountdown(CricleOptions)
        //     },1000)
        //   },(recordTimes+1)*1000)
        // }
        /*业务逻辑，以上代码模拟用勿删*/
    };

    const play_tip_callback = () => {
        startRecordTimeStamp.current = Date.now();
        recordAudioFile('startRecordAudio', JSON.stringify(metaRef?.current));
        singelPointReport({
            eventName: workBenchEventName.WB_event_record_click,
            timestamp: new Date().getTime(),
            reportData: {
                filter_name: metaRef?.current?.meta_data?.name,
                bag_time: metaRef?.current?.meta_data?.time_sec,
                carType,
            },
        });
        console.log('tttt,begin-record', JSON.stringify(metaRef));

        logSend({
            action: 'HMIStartRecord',
            data: {
                filter_meta: metaRef?.current,
                execTime: Date.now(),
            },
        });
        drawCircle(); //触发真实播放
        dispatch({
            type: SWITH_MODE_ACTION.isRecording,
            data: true,
        });
    };

    const recoedComplete = () => {
        // 极端情况下，最后一个定时器任务是录音完成的任务放到了待执行队列，此时触发了打断逻辑，已经结束上次录音，准备开始新录音
        // 此时startFunRef.current有值，再次执行定时器队列中的任务会导致先调用新录音的结束
        if (startFunRef.current) return;
        logSend({
            action: 'HMRecordTimes',
            data: {
                filter_meta: {
                    ...metaRef?.current,
                    recordTimes, //设置录音时长参数
                    Time: getRecordSecond(),
                },
            },
        });
        //录音中禁止修改开关
        dispatch({
            type: SWITH_MODE_ACTION.isRecording,
            data: false,
        });
        const meta_data = metaRef?.current?.meta_data ? { ...metaRef?.current?.meta_data } : {};
        meta_data.endType = 0; //录音结束

        recordAudioFile(
            'endRecordAudio',
            JSON.stringify({
                type: 'stopRecord',
                file_name: fileRef?.current,
                meta_data,
            }),
        );
        console.log(
            'tttt--end-正常',
            JSON.stringify({
                type: 'stopRecord',
                file_name: fileRef?.current,
                meta_data,
            }),
        );
        //结束录制音效
        wbPlayAudioCallback(null, JSON.stringify({ type: 'duplex', data: 'end_record_reminder.mp3' }));
    };

    const initCountdown = (options) => {
        let { c_width, c_height, radius, backround, lineWidth } = options;
        const canvas = document.getElementById('countdown-canvas');
        const ctx = canvas.getContext('2d');

        // 动态设置canvas 宽高
        if (window.devicePixelRatio) {
            canvas.style.width = c_width / 100 + 'rem';
            canvas.style.height = c_height / 100 + 'rem';
            canvas.height = c_height * window.devicePixelRatio;
            canvas.width = c_width * window.devicePixelRatio;
            ctx.scale(window.devicePixelRatio, window.devicePixelRatio);
        }

        ctx.save();
        ctx.clearRect(0, 0, c_width, c_height);
        ctx.save();
        //画出圆环的底色
        ctx.translate(c_width / 2, c_height / 2);
        ctx.beginPath();
        ctx.strokeStyle = backround;
        ctx.lineWidth = lineWidth;
        ctx.arc(0, 0, radius, 0, Math.PI * 2);
        ctx.stroke();
        ctx.restore();
        ctx.save();
        cutDownRef.current = ctx;
    };

    // 触发录音动画
    const drawCircle = () => {
        // 防止极端场景触发录音打断未触发清除定时器。触发第二次录音时上一次的定时器录音任务放到了待执行队列，无法清除录音任务，导致绘制进度定时器无法清除，重复执行录音完成逻辑。
        clearInterval(drawTimer.current);
        const allTimes = recordTimes;
        const { c_width, c_height, radius, lineColor, lineWidth, interTime, oneRound } = CricleOptions;
        let remainTime = allTimes * 1000;
        let timeangle = 0;

        const canvas = document.getElementById('countdown-canvas');
        const ctx = canvas.getContext('2d');

        function loopExecution(angle) {
            ctx.translate(c_width / 2, c_height / 2);
            ctx.rotate(-Math.PI / 2);
            ctx.beginPath();
            ctx.strokeStyle = lineColor;
            ctx.lineWidth = lineWidth;
            ctx.arc(0, 0, radius, 0, (Math.PI / 180) * angle);
            ctx.stroke();
            ctx.restore();
            ctx.save();
        }

        drawTimer.current = setInterval(() => {
            timeangle = oneRound - remainTime / ((allTimes * 1000) / 360);
            timeangle = timeangle >= 0 ? timeangle : 0; // 偶现负数情况
            loopExecution(timeangle);
            if (remainTime > 0) {
                remainTime = remainTime - interTime;
            } else {
                //重置录音初始状态
                setShowSuccess(true);
                clearInterval(drawTimer.current);
                initCountdown(CricleOptions);
                recoedComplete();
                setTimeout(() => {
                    setShowSuccess(false);
                }, 1000);
            }
        }, interTime);
    };
    const start_record_cmd = () => {
        //测试用
        // localTest()
        recordClickRef.current = false;
        // 手动按键不能打断
        if (isRecording) {
            cusToast.show({
                content: 'Wait for recording to complete',
                duration: 2000,
            });
            singelPointReport({
                eventName: workBenchEventName.WB_record_break_event,
                timestamp: new Date().getTime(),
                reportData: metaRef.current,
            });
            return;
        }
        logSend({
            action: 'RecordClick',
            data: {},
        });
        FM.sendToHMIDriving(SocketMap.event_trigger, {
            ...FilterConfig,
        });
        //如果手动触发1.3s后没有效果，则给出提示
        setTimeout(() => {
            console.log('tttt-定时器', recordClickRef.current);
            if (!recordClickRef.current) {
                console.log('tttt-定时器触发', recordClickRef.current);
                cusToast.show({
                    content: 'Recording activation failed',
                    duration: 2000,
                });
            }
        }, 1500);
    };

    useEffect(() => {
        window.notifyPullFileList = function (res) {
            try {
                let data = JSON.parse(JSON.stringify(res));
                dispatch({
                    type: 'notifyPullFileList',
                    data: data?.fileList,
                });
            } catch (err) {
                cusToast.show({
                    content: 'Format analysis error',
                    duration: 2000,
                });
            }
        };
        return () => {
            // 工作台关闭重置状态
            dispatch({
                type: SWITH_MODE_ACTION.isRecording,
                data: false,
            });
            clearInterval(drawTimer.current);
            clearTimeout(startFunRef.current);
        };
    }, []);

    //默认圆初始化
    useEffect(() => {
        initCountdown(CricleOptions);
    }, [recordTimes]);

    const boxid_formate = (id) => {
        return id == 'unknown' || !id ? box_id : id;
    };

    const setFileName = (v) => {
        return `${boxid_formate(v?.boxId)}_event_${v?.name}_${v?.timeSec}`;
        // .add(8, "h")
    };

    const setMetaInfo = (notice_record) => {
        fileRef.current = setFileName(notice_record);
        const tempData = {
            type: 'startRecord',
            file_name: fileRef?.current,
            meta_data: {
                boxid: boxid_formate(notice_record?.boxId),
                name: notice_record?.name,
                type: 'event',
                plate_num: notice_record?.plateNum,
                time_sec: notice_record?.timeSec,
                time_zone: dayjs(notice_record?.timeStamp * 1000).format(), //fmp需要带时区时间
                task_id: '',
                duration: recordTimes, //端上处理录音时长
            },
        };
        return tempData;
    };

    const switchTakeoverRecord = useSelector((state) => state?.switchModeAction?.switchTakeoverRecord);
    useEffect(() => {
        // 空值不处理
        if (!filter_meta?.timeStamp || !filter_meta?.name) return;

        // 如果取不到boxid直接返回
        if (!boxid_formate(filter_meta?.boxId)) {
            logSend({
                action: 'getBoxIdFail',
                data: {
                    filter_meta,
                    car_box_id: box_id,
                },
            });
            return;
        } else {
            logSend({
                action: 'recordFlagAction',
                data: {
                    filter_meta,
                    car_box_id: box_id,
                },
            });
        }
        console.log('tttt-原始值', JSON.stringify(filter_meta));

        /*
            1）hmi_console_event -hmi录音按钮触发
            2)manual_recording  - 司机拨杆触发
            3)dbw_disabled_takeover- 掉自动等车端信号触发
            4)light_recording - 打灯触发录包
        */

        const recordType = filter_meta?.name;

        // 接管录音关闭，不触发录音
        if (!switchTakeoverRecord && recordType === 'dbw_disabled_takeover') return;

        //TODO: 如果dbw_disabled_takeover，manual_recording同一时间触发，保证dbw_disabled_takeover一定触发。
        if (
            metaRef.name !== 'dbw_disabled_takeover' &&
            recordType === 'dbw_disabled_takeover' &&
            filter_meta?.timeStamp === metaRef.time_sec
        ) {
            logSend({
                action: 'hackSameStamp',
                data: {
                    filter_meta,
                },
            });
            return;
        }
        //hmi_event_console不打断高优筛选器
        if (isRecording && !needBreakList.includes(recordType)) {
            return;
        } else if (isRecording && needBreakList.includes(recordType)) {
            // 说明有开始录音尚未执行就开始了下一次，此时基础数据重置，因为都没有正式开始
            if (startFunRef.current) {
                metaRef.current = setMetaInfo(filter_meta);
                console.log('tttt-连续执行', metaRef.current);
                clearTimeout(startFunRef.current);
                startFunRef.current = undefined;
            } else {
                //处理录音打断
                const meta_data = metaRef?.current?.meta_data ? { ...metaRef?.current?.meta_data } : {};
                meta_data.duration = getRecordSecond();
                meta_data.endType = -1; //被打断结束
                recordAudioFile(
                    'endRecordAudio',
                    JSON.stringify({
                        type: 'stopRecord',
                        file_name: fileRef?.current,
                        meta_data,
                    }),
                );
                console.log(
                    'tttt--end',
                    JSON.stringify({
                        type: 'stopRecord',
                        file_name: fileRef?.current,
                        meta_data,
                    }),
                );
                logSend({
                    action: 'HMIInterruptRecord',
                    data: {
                        filter_meta: {
                            ...metaRef?.current,
                            execTime: Date.now(),
                        },
                    },
                });

                metaRef.current = setMetaInfo(filter_meta); // 打断结束后,重置设置录音开始基础数据
                clearInterval(drawTimer.current);
                //播放开始新录音
                wbPlayAudioCallback(null, JSON.stringify({ type: 'duplex', data: 'start_new_record_reminder.mp3' }));
                initCountdown(CricleOptions);
            }
            startFunRef.current = setTimeout(() => {
                play_tip_callback();
                startFunRef.current = undefined;
            }, 1500);

            return;
        }

        if (filter_meta && filter_meta.timeStamp) {
            // time_stamp返回的是秒，需要转成毫秒
            filter_meta.timeSec = dayjs(filter_meta?.timeStamp * 1000).format('YYYYMMDD-HHmmss');
        }
        if (filter_meta?.plateNum) {
            recordClickRef.current = true; //手动触发有效标识
            metaRef.current = setMetaInfo(filter_meta);
            // clearInterval(drawTimer.current) //清理旧录音动画
            //播放开始的音效
            wbPlayAudioCallback(null, JSON.stringify({ type: 'duplex', data: 'start_record_reminder.mp3' }));
            // console.log('tttt-第一次触发',startFunRef.current)
            // initCountdown(CricleOptions)// 在初始化一次
            if (startFunRef.current) {
                clearTimeout(startFunRef.current);
                startFunRef.current = undefined;
            }
            startFunRef.current = setTimeout(() => {
                play_tip_callback(); //录音开始后的瞬间掉自动，触发第二次录音，所以有可能在打断时候并未触发第一次录音
                startFunRef.current = undefined;
            }, 1100);

            // oss log
            logSend({
                action: 'receiveFilterMetaInfo',
                data: {
                    filter_meta: metaRef.current,
                },
            });
        }
    }, [filter_meta?.timeStamp, filter_meta?.name]);

    // recorder hotArea
    const { nodeRef: refCb } = useSetHotArea(HOT_AREA_TYPE.recordStateDom, { mode: 'watch' });

    //暗黑模式判定
    const recordDefaultImg =
        (themeColorState === 'black' && !isParking) || (isParking && userMode === 'D') ? bvoiceImg : wvoiceImg;
    return (
        <div
            className={classNames('record-wrapper', userMode === 'D' ? 'd' : 'sr', {
                dark: themeColorState === 'black' && !isParking,
                parking: isParking,
            })}
            ref={refCb}
        >
            <div onClick={start_record_cmd} className="demo1-bg1">
                <div className="record-icon">
                    <canvas style={{ display: showSucess ? 'none' : 'block' }} id="countdown-canvas"></canvas>
                    <div>
                        <img
                            className="recording"
                            src={showSucess ? successImg : isRecording ? recordingImg : recordDefaultImg}
                            alt="record"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
});
