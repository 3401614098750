import { HMI_MODE_KEY } from '@/constant/HMIMode';
import { useLayoutEffect, useEffect } from 'react';
import localCache from '@/utils/localCache';
import { useDispatch } from 'react-redux';
import { USER_MODE } from '@/store/action/parkingType';
import { SWITH_MODE_ACTION, THEME_ACTION } from '@/store/action/type';
import setHMIMode from '@/utils/setHMIMode';
import logSend from '@/utils/aLog';

export function useInitLocalCache() {
    const dispatch = useDispatch();

    useEffect(() => {
        // 工作台录音时长
        localCache.get('workbench_reocrd_times', (data) => {
            dispatch({
                type: SWITH_MODE_ACTION.recordTimes,
                data: data || 15,
            });
        });

        // Mtour: UNP switch
        localCache.get('unp_switch', (data) => {
            const default_unp_switch = data || false;
            dispatch({
                type: SWITH_MODE_ACTION.switchUnpState,
                data: default_unp_switch,
            });
        });
        // NP用户路测采集  switch
        localCache.get('warning_automatic', (data) => {
            const warning_automatic = data || 'OFF';
            dispatch({
                type: 'switchStatus',
                data: warning_automatic,
            });
        });

        // 感知模式 switch
        localCache.get('vcruise_string', (data) => {
            const vcruise = data || 'OFF';
            dispatch({
                type: 'setVcruiseSwitch',
                data: vcruise,
            });
        });

        // TFL switch
        localCache.get('is_cp_tfl_switch_state', (data) => {
            const is_cp_tfl_switch_state = data || 'CLOSE';
            dispatch({
                type: SWITH_MODE_ACTION.switchTFLState,
                data: is_cp_tfl_switch_state,
            });
        });

        // 智驾播报模式：
        localCache.get('driving_broadcast_switch', (data) => {
            const default_broadcast_switch = data || 'Alert';
            dispatch({
                type: SWITH_MODE_ACTION.switchDrivingBroadcast,
                data: default_broadcast_switch,
            });
        });

        // i18n
        localCache.get('i18nLang', (data) => {
            const i18nValue = data || 'zh-CN';
            dispatch({
                type: THEME_ACTION.i18nState,
                data: i18nValue,
            });
        });

        // switch_mode
        localCache.get('switch_mode', (data) => {
            const switch_mode_local = data || 'testMode';
            dispatch({
                type: SWITH_MODE_ACTION.switchModeState,
                data: switch_mode_local,
            });
        });
        // 跟车时距开关
        localCache.get('follow_car_switch', (data) => {
            const default_follow_car_switch = data || false;
            dispatch({
                type: SWITH_MODE_ACTION.switchFollowCarState,
                data: default_follow_car_switch,
            });
        });

        // 告警提醒
        localCache.get('system_warning_switch', (data) => {
            const default_system_warning_switch = data || false;
            dispatch({
                type: 'setSystemWarningSwitch',
                data: default_system_warning_switch,
            });
        });
        // 接管录音
        localCache.get('switchTakeoverRecord', (data) => {
            const checked = typeof data === 'boolean' ? data : true;
            dispatch({
                type: SWITH_MODE_ACTION.switchTakeoverRecord,
                data: checked,
            });
            logSend({
                action: 'switchTakeoverRecord',
                data: { checked },
            });
        });
        // 工作台所有录音
        localCache.get('switchAllRecord', (data) => {
            const checked = typeof data === 'boolean' ? data : true;
            dispatch({
                type: SWITH_MODE_ACTION.switchAllRecord,
                data: checked,
            });
            logSend({
                action: 'switchAllRecord',
                data: { checked },
            });
        });
        // 语音小助手: 开关
        localCache.get('assistant_switch', (data) => {
            const default_assistant_switch = data || false;
            dispatch({
                type: SWITH_MODE_ACTION.switchAssistant,
                data: default_assistant_switch,
            });
        });
        // 快捷选路
        localCache.get('shortcut_route_witch', (data) => {
            dispatch({
                type: SWITH_MODE_ACTION.switchShortcutRouteWitch,
                data: data || false,
            });
        });
    }, []);

    useLayoutEffect(() => {
        // HMI应用模式
        localCache.get(HMI_MODE_KEY, (data) => {
            const mode = data || 'D';
            dispatch({
                type: USER_MODE,
                data: mode,
            });
            setHMIMode(mode);
        });

        // Mviz布局
        localCache.get('switch_mviz_layout', (data) => {
            const mviz_layout_local = data || 'left';
            dispatch({
                type: SWITH_MODE_ACTION.switchMvizState,
                data: mviz_layout_local,
            });
        });

        // 主题 themeColor
        localCache.get('themeColor', (data) => {
            const themeColorValue = data;
            // const themeColorValue = 'black' || data;
            dispatch({
                type: THEME_ACTION.themeColorState,
                data: themeColorValue,
            });
        });
    }, []);
}
