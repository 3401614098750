import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import localAudioNames from '@/constant/localAudioName';
import { useTranslation } from 'react-i18next';
import { NATIVE_ACTION } from '@/store/action/type';
import { SocketMap } from '@/constant/socketMap';
import { cusToast } from '@/components/customToast';
import store from '@/store';
import { AppFn } from '@/utils/native';
import FM from '@/utils/nativeSubAndPub';

import hnpErrorTip from '@/assets/hnp/hnp_error_tip.png';
import { widgetQueue, widgetType } from '@/utils/widgetQueue';
import { batchReport } from '@/utils/report';
import reportEventName from '@/constant/reportEventName';
import quit_img from '@/assets/drivingLevel/quit_img.png';
import cp_active from '@/assets/resume/cp_active.png';
import np_active from '@/assets/resume/np_active.png';
import tips_img from '@/assets/MRC/tips_img.png';
import accControlImg from '@/assets/drivingLevel/acc_control_steer.png';
import { TAKE_OVER_ACTION, WIDGET_ZERO_ACTION } from '@/store/action/type';
import DrivingLevelWidget from './drivingLevelWidget/index';
import localCache from '@/utils/localCache';

const { playLocalAudio } = AppFn;

/*const noticeDrivingCodeAudio = {
    "-2": "",
    "-1": "",
    0: "",
    1: localAudioNames.DEMO_1_ON_LANE,
    3: localAudioNames.DEMO_3_ROAD_COMPLEX,
    4: localAudioNames.DEMO_4_TOO_FAST,
    5: "",
    6: "",
    7: localAudioNames.DEMO_7_SPEED,
    8: "",
    9: "",
    10: "",
    11: localAudioNames.DEMO_11_LANE_DERROR,
    12: localAudioNames.DEMO_12_NO_LANE,
    13: localAudioNames.DEMO_13_CAR_BODY,
    14: "",
    15: localAudioNames.DEMO_15_NAVIGATION_CANCEL,
    16: localAudioNames.DEMO_16_POSITION_UNUSUAL,
    17: localAudioNames.DEMO_17_18_NOT_LANE,
    18: '',
    19: "",
    20: "",
    21: "",
    22: "",
    23: "",
    24: ""
}*/

// Native 进入/退出 HNP
window.changeHnpEvent = function (data) {
    store.dispatch({
        type: NATIVE_ACTION.hnpChangeState,
        data: data,
    });
};

let timer = null; // 定时器句柄

export default function DrivingLevelNotice(props) {
    const hnp_state = useSelector((state) => state?.featureModelDriving?.baseData?.os?.driving?.automatic_state);
    // 代表当前正在运行的智驾等级
    const running_mode = useSelector((state) => state?.featureModelDriving?.baseData?.os?.driving?.running_mode);
    // const position_state = useSelector(state => state?.featureModelDriving?.baseData?.os?.position_state);
    const notice_driving_state = useSelector(
        (state) => state?.featureModelDriving?.drivingState?.drivingModeChange?.state,
    );
    const notice_driving_running_mode = useSelector(
        (state) => state?.featureModelDriving?.drivingState?.drivingModeChange?.runningMode,
    );
    // 0510 产品定义 notice_driving_code: 匹配不到，当作-1来兜底
    const allCode = [
        -2, -1, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28,
        29, 30, 31, 32, 33, 36, 37,
    ];
    const notice_driving_code = useSelector((state) => {
        let code = state?.featureModelDriving?.drivingState?.drivingModeChange?.code;
        if (typeof code === 'number') {
            if (!allCode.includes(code)) {
                return -1;
            } else {
                return code;
            }
        }
    });
    const hnpChangeState = useSelector((state) => state?.nativeAction?.hnpChangeState?.hnp_state);
    // 按键进自动信号
    const widget_self_driving = useSelector(
        (state) => state?.featureModelDriving?.drivingState?.carButton?.data?.selfDriving,
    );
    // 当前智驾状态
    const automatic_state = useSelector((state) => state?.featureModelDriving?.baseData?.os?.driving?.automatic_state);
    // 智驾设置中开关状态
    const cpSwitch = useSelector(
        (state) => state?.featureModelDriving?.switch?.driving?.running_mode?.operate_drive_mode?.cp,
    );
    const hnpSwitch = useSelector(
        (state) => state?.featureModelDriving?.switch?.driving?.running_mode?.operate_drive_mode?.hnp,
    );
    const unpSwitch = useSelector(
        (state) => state?.featureModelDriving?.switch?.driving?.running_mode?.operate_drive_mode?.unp,
    );
    // 代表当前可进入的智驾等级
    const widget_highest_running_mode = useSelector(
        (state) => state?.featureModelDriving?.drivingState?.highestRunningMode,
    );
    // odd是否展示
    const oddComState = useSelector((state) => state?.widget0Data?.oddComState);
    const { t: translate } = useTranslation();
    const [hnpTips, setHnpTips] = useState(false);
    let pre_hnp_state = useRef(hnp_state);
    // const i18nLang = window.localStorage.getItem('i18nLang');
    const i18nLang = useSelector((state) => state?.themeAction?.i18nState);

    const [textState, setTextState] = useState('');
    const [reasonState, setReasonState] = useState('');
    const [imgIconState, setImgIcon] = useState('');
    const [isKeyLevel, setIsKeyLevel] = useState(false);
    const dispatch = useDispatch();
    const levelTimer = useRef(null);
    const keyLevelTimer = useRef(null);
    const drivingModeChange = useSelector((state) => state?.func?.drivingModeChange);
    const switchDrivingBroadcast = useSelector((state) => state?.switchModeAction?.switchDrivingBroadcast);

    // 切换HNP
    const changeHnpEvent = async (v) => {
        FM.sendToHMIDriving(
            SocketMap.drivingControl,
            {
                state: v,
            },
            'driving_control_cb',
        );
    };

    // Native 修改HNP状态调hminode
    useEffect(() => {
        if (hnpChangeState !== 'ACTIVE' && hnpChangeState !== 'OFF') return;
        changeHnpEvent(hnpChangeState);
    }, [hnpChangeState]);

    // 智驾退出
    /* useEffect(() => {
        if ((hnp_state === 'ACTIVE' && running_mode === 'HNP' && position_state < 4) || (hnp_state === 'ACTIVE' && running_mode === 'CP' && position_state < 3)) {
            setHnpTips(true)
            setTimeout(() => setHnpTips(false), 2000)
        }
        pre_hnp_state.current = hnp_state
    }, [ hnp_state, position_state, running_mode ]);*/

    // 升降级通知
    useEffect(() => {
        let text = '';
        let audio = '';
        let reasonText = '';
        let imgIcon = '';
        // code: 手动
        let manual_code = [6, 8, 9, 14, 15, 24, 33].includes(notice_driving_code);
        //code: 不展示原因
        let hide_reason = [-2, -1, 2, 1, 3, 10, 11, 12, 13, 16, 17, 19, 20, 21, 22, 23, 25].includes(
            notice_driving_code,
        );
        // code: 展示原因
        let show_reason = [4, 5, 7, 18, 26, 27, 28, 29, 30, 31, 32, 36, 37].includes(notice_driving_code);
        // code: 语音音效 手动降级 & 系统预期降级时时播报音效
        let manual_system_expect = [
            -1, 6, 8, 9, 14, 15, 18, 19, 20, 21, 22, 23, 24, 26, 27, 28, 29, 30, 31, 32, 33, 36, 37,
        ].includes(notice_driving_code);
        // code: 语音音效 统意外降级时播报警音
        let system_accident = [-2, 2, 1, 3, 4, 5, 7, 10, 11, 12, 13, 16, 17, 25, 36].includes(notice_driving_code);
        // code对应的原因：
        let code_text = `${translate(`notice_driving_code.${notice_driving_code?.toString()}`)}`;

        if (notice_driving_state === 'ACTIVE') {
            // M9.2 1016 智驾播报：简洁（音效 默认）、详细（音效+语音）
            if (['HNP', 'UNP'].includes(notice_driving_running_mode)) {
                // 手动驾驶到HNP/UNP开启
                /*playLocalAudio(localAudioNames.DEMO_NP_ENGAGE_0716, 'Highest');
                setTimeout(() => {
                    playLocalAudio(localAudioNames.HNP_ACTIVATED, 'Highest', 'voice');
                }, 1000);*/
            } else if (notice_driving_running_mode === 'CP') {
                // 手动驾驶到CP开启
                /*playLocalAudio(localAudioNames.HNP_TO_CP, 'Highest');
                setTimeout(() => {
                    playLocalAudio(localAudioNames.CP_ACTIVATED, 'Highest', 'voice');
                }, 1000);*/
            } else if (notice_driving_running_mode === 'ACC') {
                // 手动驾驶到ACC开启
                /*playLocalAudio(localAudioNames.CP_TO_ACC, 'Highest');
                setTimeout(() => {
                    playLocalAudio(localAudioNames.ACC_ACTIVATED, 'Highest', 'voice');
                }, 1000);*/
            } else if (notice_driving_running_mode === 'HNP_PLUS') {
                // NP到NP_PLUS
                /* playLocalAudio(localAudioNames.NP_TO_NP_PLUS, 'Highest');
                setTimeout(() => {
                    playLocalAudio(localAudioNames.HNP_PLUS_ACTIVATED, 'Highest', 'voice');
                }, 1000);*/
            }
        }

        // 升级
        if (notice_driving_state === 'UPPER') {
            /*if(notice_driving_running_mode === 'HNP_PLUS'){
                // NP升级到HNP_PLUS
                playLocalAudio(localAudioNames.NP_TO_NP_PLUS, 'Highest');
            }else*/ if (['HNP', 'UNP'].includes(notice_driving_running_mode)) {
                // CP升级到HNP
                /*playLocalAudio(localAudioNames.CP_TO_HNP, 'Highest');
                setTimeout(() => {
                    playLocalAudio(localAudioNames.HNP_ACTIVATED, 'Highest', 'voice');
                }, 1000);*/
            } else if (notice_driving_running_mode === 'CP') {
                // ACC升级到CP
                /* playLocalAudio(localAudioNames.HNP_TO_CP, 'Highest');
                setTimeout(() => {
                    playLocalAudio(localAudioNames.CP_ACTIVATED, 'Highest', 'voice');
                }, 1000);*/
            }
        }

        if (notice_driving_state === 'QUIT' || notice_driving_state === 'LOWER') {
            // 退出
            if (notice_driving_state === 'QUIT') {
                if (['HNP', 'UNP'].includes(notice_driving_running_mode)) {
                    // HNP\UNP退出手动驾驶
                    //M9.1 1013 处理文案展示
                    if (manual_code) {
                        text = '';
                        imgIcon = '';
                    } else if (hide_reason) {
                        text = `NP ${translate(`quited`)}`;
                        imgIcon = quit_img;
                    } else if (show_reason) {
                        text = `NP ${translate(`quited`)}`;
                        reasonText = code_text;
                        imgIcon = quit_img;
                    }

                    // M9.1 1013处理语音音效处理
                    // M9.2 1016 智驾播报：简洁（音效 默认）、详细（音效+语音）
                    if (manual_system_expect) {
                        // 手动降级 & 系统预期降级时时播报音效
                        /*playLocalAudio(localAudioNames.HNP_DISENGAGE, 'Highest');
                        setTimeout(() => {
                            playLocalAudio(localAudioNames.QUIT_HNP_EXPECT, 'Highest', 'voice');
                        }, 1000);*/
                    } else if (system_accident) {
                        //系统意外降级时播报警音
                        /*playLocalAudio(localAudioNames.BIBIBI_AUDIO, 'Highest');
                        setTimeout(() => {
                            playLocalAudio(localAudioNames.QUIT_HNP_ACCIDENT, 'Highest', 'voice');
                        }, 1000);*/
                    }
                    /*/!**
                     * 0909产品要求下掉 19、20、21、22、23，
                     * 19～23需要展示文案
                     *!/
                    if (!([-2,-1,0,5,6,8,9,14,24].includes(notice_driving_code))) {
                        text = translate(`notice_driving_code.${notice_driving_code.toString()}`)
                            ? `${translate(`notice_driving_code.${notice_driving_code.toString()}`) + `，NP ${translate(`quited`)}`}`
                            : `NP ${translate(`quited`)}`;
                    }
                    else {
                        /!**
                         * 0902产品要求下掉
                         *!/
                        // text = `NP ${translate(`quited`)}`;
                    }*/
                } else if (notice_driving_running_mode === 'CP') {
                    // CP退出到手动驾驶
                    //M9.1 1013 处理文案展示
                    if (manual_code) {
                        text = '';
                        imgIcon = '';
                    } else if (hide_reason) {
                        text = `CP ${translate(`quited`)}`;
                        imgIcon = quit_img;
                    } else if (show_reason) {
                        text = `CP ${translate(`quited`)}`;
                        reasonText = code_text;
                        imgIcon = quit_img;
                    }

                    // M9.1 1013处理语音音效处理
                    // M9.2 1016 智驾播报：简洁（音效 默认）、详细（音效+语音）
                    if (manual_system_expect) {
                        // 手动降级 & 系统预期降级时时播报音效
                        /*playLocalAudio(localAudioNames.CP_DISENGAGE, 'Highest');
                        setTimeout(() => {
                            playLocalAudio(localAudioNames.QUIT_CP_EXPECT, 'Highest', 'voice');
                        }, 1000);*/
                    } else if (system_accident) {
                        //系统意外降级时播报警音
                        /*playLocalAudio(localAudioNames.BIBIBI_AUDIO, 'Highest');
                        setTimeout(() => {
                            playLocalAudio(localAudioNames.QUIT_CP_ACCIDENT, 'Highest', 'voice');
                        }, 1000);*/
                    }

                    /* /!**
                     * 0909产品要求下掉 19、20、21、22、23，
                     * 19～23需要展示文案
                     *!/
                    // CP退出到手动驾驶
                    if (!([-2,-1,0,5,6,8,9,14,24].includes(notice_driving_code))) {
                        text = translate(`notice_driving_code.${notice_driving_code.toString()}`)
                            ? `${translate(`notice_driving_code.${notice_driving_code.toString()}`) + `，CP ${translate(`quited`)}`}`
                            : `CP ${translate(`quited`)}`;
                    }
                    else {
                        /!**
                         * 0902产品要求下掉
                         *!/
                        // text = `CP ${translate(`quited`)}`;
                    }
                    playLocalAudio(localAudioNames.CP_DISENGAGE, 'Highest');*/
                } else if (notice_driving_running_mode === 'ACC') {
                    // ACC退到手动驾驶
                    //M9.1 1013 处理文案展示
                    if (manual_code) {
                        text = '';
                        imgIcon = '';
                    } else if (hide_reason) {
                        text = `ACC ${translate(`quited`)}`;
                        imgIcon = quit_img;
                    } else if (show_reason) {
                        text = `ACC ${translate(`quited`)}`;
                        reasonText = code_text;
                        imgIcon = quit_img;
                    }

                    // M9.1 1013处理语音音效处理
                    // M9.2 1016 智驾播报：简洁（音效 默认）、详细（音效+语音）
                    if (manual_system_expect) {
                        // 手动降级 & 系统预期降级时时播报音效
                        /*playLocalAudio(localAudioNames.QUIT_ACC, 'Highest');
                        setTimeout(() => {
                            playLocalAudio(localAudioNames.QUIT_ACC_EXPECT, 'Highest', 'voice');
                        }, 1000);*/
                    } else if (system_accident) {
                        //系统意外降级时播报警音
                        /*playLocalAudio(localAudioNames.BIBIBI_AUDIO, 'Highest');
                        setTimeout(() => {
                            playLocalAudio(localAudioNames.QUIT_ACC_ACCIDENT, 'Highest', 'voice');
                        }, 1000);*/
                    }

                    /* /!**
                     * 0909产品要求下掉 19、20、21、22、23，
                     * 19～23需要展示文案
                     *!/
                    // ACC退出
                    if (!([-2,-1,0,5,6,8,9,14,24].includes(notice_driving_code))) {
                        text = translate(`notice_driving_code.${notice_driving_code.toString()}`)
                            ? `${translate(`notice_driving_code.${notice_driving_code.toString()}`) + `，ACC ${translate(`quited`)}`}`
                            : `ACC ${translate(`quited`)}`;
                    }
                    else {
                        /!**
                         * 0902产品要求下掉
                         *!/
                        // text = `ACC ${translate(`quited`)}`;
                    }
                    playLocalAudio(localAudioNames.QUIT_ACC, 'Highest');*/
                } else if (notice_driving_running_mode === 'HNP_PLUS') {
                    // HNP_PLUS退出到手动驾驶
                    //M9.1 1013 处理文案展示
                    if (manual_code) {
                        text = '';
                        imgIcon = quit_img;
                    } else if (hide_reason) {
                        text = `NP+ ${translate(`quited`)}`;
                        imgIcon = quit_img;
                    } else if (show_reason) {
                        text = `NP+ ${translate(`quited`)}`;
                        reasonText = code_text;
                        imgIcon = quit_img;
                    }

                    // M9.1 1013处理语音音效处理
                    // M9.2 1016 智驾播报：简洁（音效 默认）、详细（音效+语音）
                    if (manual_system_expect) {
                        // 手动降级 & 系统预期降级时时播报音效
                        /*playLocalAudio(localAudioNames.NP_PLUS_DISENGAGE, 'Highest');
                        setTimeout(() => {
                            playLocalAudio(localAudioNames.QUIT_HNP_PLUS_EXPECT, 'Highest', 'voice');
                        }, 1000);*/
                    } else if (system_accident) {
                        //系统意外降级时播报警音
                        /*playLocalAudio(localAudioNames.BIBIBI_AUDIO, 'Highest');
                        setTimeout(() => {
                            playLocalAudio(localAudioNames.QUIT_HNP_PLUS_ACCIDENT, 'Highest', 'voice');
                        }, 1000);*/
                    }
                    /*playLocalAudio(localAudioNames.NP_PLUS_DISENGAGE, 'Highest');
                    /!**
                     * 0909产品要求下掉 19、20、21、22、23，
                     * 19～23需要展示文案
                     *!/
                    // ACC退出
                    if (!([-2,-1,0,5,6,8,9,14,24].includes(notice_driving_code))) {
                        text = translate(`notice_driving_code.${notice_driving_code.toString()}`)
                            ? `${translate(`notice_driving_code.${notice_driving_code.toString()}`) + `，NP+ ${translate(`quited`)}`}`
                            : `NP+ ${translate(`quited`)}`;
                    }
                    else {
                        /!**
                         * 0902产品要求下掉
                         *!/
                        // text = `NP+ ${translate(`quited`)}`;
                    }*/
                }
            }

            // 降级
            if (notice_driving_state === 'LOWER') {
                const notice_driving_Text = ['HNP', 'UNP'].includes(notice_driving_running_mode)
                    ? 'NP'
                    : notice_driving_running_mode;
                const runningModeEntered =
                    i18nLang !== 'en-US'
                        ? `${notice_driving_Text} ${translate(`entered`)}`
                        : // : `${notice_driving_Text} ${translate(`entered`)}`;
                          `${notice_driving_Text} ${translate(`entered`)}`;
                if (notice_driving_running_mode === 'CP') {
                    // HNP降级到CP
                    //M9.1 1013 处理文案展示
                    if (manual_code) {
                        text = '';
                        imgIcon = '';
                    } else if (hide_reason) {
                        text = `${runningModeEntered}`;
                        imgIcon = cp_active;
                    } else if (show_reason) {
                        text = `${runningModeEntered}`;
                        reasonText = code_text;
                        imgIcon = cp_active;
                    }

                    // M9.2 1016 智驾播报：简洁（音效 默认）、详细（音效+语音）
                    if (switchDrivingBroadcast === 'Voice') {
                        // 详细模式
                        if (manual_system_expect) {
                            /*playLocalAudio(localAudioNames.HNP_TO_CP, 'Highest');
                            setTimeout(() => {
                                playLocalAudio(localAudioNames.HNP_TO_CP_EXPECT, 'Highest', 'voice');
                            }, 1000);*/
                        } else if (system_accident) {
                            /*playLocalAudio(localAudioNames.HNP_TO_CP, 'Highest');
                            setTimeout(() => {
                                playLocalAudio(localAudioNames.HNP_TO_CP_ACCIDENT, 'Highest', 'voice');
                            }, 1000);*/
                        }
                    } else {
                        // 默认模式，简洁
                        if (manual_system_expect || system_accident) {
                            // playLocalAudio(localAudioNames.HNP_TO_CP, 'Highest');
                        }
                    }

                    /*  /!**
                     * 0909产品要求下掉 19、20、21、22、23，
                     * 19～23需要展示文案
                     *!/
                    // HNP降级到CP
                    if (!([-2,-1,0,5,6,8,9,14,24].includes(notice_driving_code))) {
                        text = translate(`notice_driving_code.${notice_driving_code.toString()}`)
                            ? `${translate(`notice_driving_code.${notice_driving_code.toString()}`) + `，${runningModeEntered}`}`
                            : `${runningModeEntered}`;
                    }
                    else {
                        /!**
                         * 0902产品要求下掉
                         *!/
                        // text = `${translate(`entered`)} CP`;
                    }
                    playLocalAudio(localAudioNames.HNP_TO_CP, 'Highest');*/
                } else if (notice_driving_running_mode === 'ACC') {
                    // CP降级到ACC
                    //M9.1 1013 处理文案展示
                    if (manual_code) {
                        text = '';
                        imgIcon = '';
                    } else if (hide_reason) {
                        text = `${runningModeEntered}`;
                        imgIcon = quit_img;
                        // 加接管方向盘弹窗
                        /*dispatch({
                            type: TAKE_OVER_ACTION.takerOverState,
                            data: {
                                takerOverState: true,
                                img: accControlImg,
                                text: translate('takeOverTips'),
                            },
                        });*/
                    } else if (show_reason) {
                        text = `${runningModeEntered}`;
                        reasonText = code_text;
                        imgIcon = quit_img;
                        // 加接管方向盘弹窗
                        /*dispatch({
                            type: TAKE_OVER_ACTION.takerOverState,
                            data: {
                                takerOverState: true,
                                img: accControlImg,
                                text: translate('takeOverTips'),
                            },
                        });*/
                    }
                    batchReport({
                        eventName: reportEventName.HMI_LOWERTOACC_VIEW,
                        timestamp: new Date().getTime(),
                        reportData: {
                            code: notice_driving_code,
                            widget: text ? true : false,
                            takeOver: manual_code ? false : true,
                        },
                    });

                    // M9.2 1016 智驾播报：简洁（音效 默认）、详细（音效+语音）
                    if (switchDrivingBroadcast === 'Voice') {
                        // 详细模式
                        if (manual_system_expect) {
                            /*playLocalAudio(localAudioNames.CP_TO_ACC, 'Highest');
                            setTimeout(() => {
                                playLocalAudio(localAudioNames.CP_TO_ACC_EXPECT, 'Highest', 'voice');
                            }, 1000);*/
                        } else if (system_accident) {
                            /* playLocalAudio(localAudioNames.CP_TO_ACC, 'Highest');
                            setTimeout(() => {
                                playLocalAudio(localAudioNames.CP_TO_ACC_ACCIDENT, 'Highest', 'voice');
                            }, 1000);*/
                        }
                    } else {
                        // 默认模式，简洁
                        if (manual_system_expect || system_accident) {
                            // playLocalAudio(localAudioNames.CP_TO_ACC, 'Highest');
                        }
                    }

                    /* /!**
                     * 0909产品要求下掉 19、20、21、22、23，
                     * 19～23需要展示文案
                     *!/
                    // CP降级到ACC
                    if (!([-2,-1,0,5,6,8,9,14,24].includes(notice_driving_code))) {
                        text = translate(`notice_driving_code.${notice_driving_code.toString()}`)
                            ? `${translate(`notice_driving_code.${notice_driving_code.toString()}`) + `，${runningModeEntered}`}`
                            : `${runningModeEntered}`;
                    }
                    else {
                        /!**
                         * 0902产品要求下掉
                         *!/
                        // text = `${translate(`entered`)} ACC`;
                    }
                    playLocalAudio(localAudioNames.CP_TO_ACC, 'Highest');*/
                } else if (notice_driving_running_mode === 'HNP') {
                    // NP+降级到HNP
                    //M9.1 1013 处理文案展示
                    if (manual_code) {
                        text = '';
                        imgIcon = '';
                    } else if (hide_reason) {
                        text = `${runningModeEntered}`;
                        imgIcon = np_active;
                    } else if (show_reason) {
                        text = `${runningModeEntered}`;
                        reasonText = code_text;
                        imgIcon = np_active;
                    }

                    // M9.2 1016 智驾播报：简洁（音效 默认）、详细（音效+语音）
                    if (manual_system_expect || system_accident) {
                        /*playLocalAudio(localAudioNames.DEMO_NP_ENGAGE_0716, 'Highest');
                        setTimeout(() => {
                            playLocalAudio(localAudioNames.HNP_PLUS_TO_HNP, 'Highest', 'voice');
                        }, 1000);*/
                    }

                    /* /!**
                     * 0909产品要求下掉 19、20、21、22、23，
                     * 19～23需要展示文案
                     *!/
                    // NP+降级到HNP
                    if (!([-2,-1,0,5,6,8,9,14,24].includes(notice_driving_code))) {
                        text = translate(`notice_driving_code.${notice_driving_code.toString()}`)
                            ? `${translate(`notice_driving_code.${notice_driving_code.toString()}`) + `，${runningModeEntered}`}`
                            : `${runningModeEntered}`;
                    }
                    else {
                        /!**
                         * 0902产品要求下掉
                         *!/
                        // text = `${translate(`entered`)} NP`;
                    }
                    playLocalAudio(localAudioNames.DEMO_NP_ENGAGE_0716, 'Highest');*/
                }
            }
            //M9.1 1013 意外降级展示弹框
            if (notice_driving_state === 'QUIT') {
                /*if (system_accident) {
                    /!* setHnpTips(true)
                    setTimeout(() => setHnpTips(false), 3000);*!/
                    dispatch({
                        type: TAKE_OVER_ACTION.takerOverState,
                        data: {
                            takerOverState: true,
                            img: '',
                            text: '',
                        },
                    });
                }*/
            }
        }
        if (text) {
            clearTimeout(levelTimer.current);
            levelTimer.current = null;

            setTextState(text);
            setReasonState(reasonText);
            setImgIcon(imgIcon);
        } else {
            !levelTimer.current &&
                (levelTimer.current = setTimeout(() => {
                    setTextState('');
                    setReasonState('');
                    setImgIcon('');
                    // code发生时如果odd没销毁，就隐藏odd组件
                    if (oddComState) {
                        dispatch({
                            type: WIDGET_ZERO_ACTION.oddComState,
                            data: false,
                        });
                    }
                }, 5000));
        }

        clearTimeout(timer);
        timer = null;

        /* if (text && text !== '') {
            widgetQueue({widgetNumber: 2, type: widgetType.RELEGATION, widgetContent: {
                    content: text,
                }})
        }
        else {
            timer = setTimeout(() => {
                widgetQueue({widgetNumber: 2, type: widgetType.RELEGATION, widgetContent: null})
            }, 2 * 1000)
        }*/
    }, [notice_driving_code, notice_driving_state, notice_driving_running_mode, switchDrivingBroadcast]);

    // 数据上报
    // todo UNP待增加上报处理
    useEffect(() => {
        let reportData = {};
        let reportName = '';
        /*notice_driving_state: 升级/降级
          before_state:
          current_state: notice_driving_running_mode
          notice_driving_code: notice_driving_code*/
        const LowerList = {
            HNP: 'HNP_PLUS',
            CP: 'HNP',
            ACC: 'CP',
        };
        const UpperList = {
            HNP_PLUS: 'HNP',
            HNP: 'CP',
            UNP: 'CP',
            CP: 'ACC',
        };
        if (notice_driving_state === 'QUIT') {
            reportName = reportEventName.HMI_DOWNGRAD_VIEW;
            reportData['notice_driving_state'] = 'LOWER';
            reportData['before_state'] = notice_driving_running_mode;
            reportData['current_state'] = 'MANUAL';
            reportData['notice_driving_code'] = notice_driving_code;
        } else if (notice_driving_state === 'LOWER') {
            reportName = reportEventName.HMI_DOWNGRAD_VIEW;
            reportData['notice_driving_state'] = 'LOWER';
            reportData['before_state'] =
                notice_driving_running_mode === 'UNP' ? 'UNP' : LowerList[notice_driving_running_mode];
            reportData['current_state'] = notice_driving_running_mode;
            reportData['notice_driving_code'] = notice_driving_code;
        } else if (notice_driving_state === 'ACTIVE') {
            reportName = reportEventName.HMI_UPGRADE_VIEW;
            reportData['notice_driving_state'] = 'UPPER';
            reportData['before_state'] = 'MANUAL';
            reportData['current_state'] = notice_driving_running_mode;
            if (notice_driving_running_mode === 'HNP_PLUS') {
                reportData['before_state'] = 'HNP';
                reportData['current_state'] = notice_driving_running_mode;
            }
        } else if (notice_driving_state === 'UPPER') {
            reportName = reportEventName.HMI_UPGRADE_VIEW;
            reportData['notice_driving_state'] = 'UPPER';
            reportData['before_state'] = UpperList[notice_driving_running_mode];
            reportData['current_state'] = notice_driving_running_mode;
        }

        reportName &&
            batchReport({
                eventName: reportName,
                timestamp: new Date().getTime(),
                reportData,
            });
    }, [notice_driving_code, notice_driving_state, notice_driving_running_mode]);

    useEffect(() => {
        // 如果同时发生了降级或者quit到手动不展示此信号
        if (notice_driving_state === 'LOWER' || notice_driving_state === 'QUIT') return;
        let selfDrivingText = '',
            selfDrivingReason = '',
            selfDrivingImgIcon = tips_img;
        if (widget_self_driving === 'IN') {
            if (running_mode === 'MANUAL' && automatic_state === 'OFF') {
                // todo acc无法激活
                selfDrivingText = `ACC ${translate('updateFail.updateFailText')}`;
                selfDrivingReason = '';
            } else if (running_mode === 'ACC' && automatic_state === 'ACTIVE') {
                if (cpSwitch === 'OFF') {
                    // CP无法激活 设置中打开CP
                    selfDrivingText = `CP ${translate('updateFail.updateFailText')}`;
                    selfDrivingReason = `${translate('updateFail.enableCP')}`;
                } else if (!['HNP', 'UNP', 'CP'].includes(widget_highest_running_mode)) {
                    // CP无法激活
                    selfDrivingText = `CP ${translate('updateFail.updateFailText')}`;
                    selfDrivingReason = '';
                }
            } else if (running_mode === 'CP' && automatic_state === 'ACTIVE') {
                if (hnpSwitch === 'OFF') {
                    // NP无法激活 设置中打开NP
                    selfDrivingText = `NP ${translate('updateFail.updateFailText')}`;
                    selfDrivingReason = `${translate('updateFail.enableNP')}`;
                } else if (!['HNP', 'UNP', 'HNP_PLUS'].includes(widget_highest_running_mode)) {
                    // NP无法激活
                    selfDrivingText = `NP ${translate('updateFail.updateFailText')}`;
                    selfDrivingReason = '';
                }
            }
        }
        if (selfDrivingText) {
            clearTimeout(keyLevelTimer.current);
            keyLevelTimer.current = null;
            setTextState(selfDrivingText);
            setReasonState(selfDrivingReason);
            setImgIcon(selfDrivingImgIcon);
            // playLocalAudio(localAudioNames.SELF_DRIVING, 'Important');
            setIsKeyLevel(true);
        } else {
            !keyLevelTimer.current &&
                (keyLevelTimer.current = setTimeout(() => {
                    setTextState('');
                    setReasonState('');
                    setImgIcon('');
                    setIsKeyLevel(false);
                    // code发生时如果odd没销毁，就隐藏odd组件
                    if (oddComState) {
                        dispatch({
                            type: WIDGET_ZERO_ACTION.oddComState,
                            data: false,
                        });
                    }
                }, 3000));
        }
    }, [
        widget_self_driving,
        running_mode,
        automatic_state,
        cpSwitch,
        hnpSwitch,
        widget_highest_running_mode,
        notice_driving_state,
    ]);

    /**
     * 除了ACC active其他智驾状态active时打断无法激活提示
     */
    useEffect(() => {
        if (isKeyLevel && automatic_state === 'ACTIVE' && running_mode !== 'ACC') {
            clearTimeout(keyLevelTimer.current);
            keyLevelTimer.current = null;
            setTimeout(() => {
                setTextState('');
                setReasonState('');
                setImgIcon('');
                setIsKeyLevel(false);
                // code发生时如果odd没销毁，就隐藏odd组件
                if (oddComState) {
                    dispatch({
                        type: WIDGET_ZERO_ACTION.oddComState,
                        data: false,
                    });
                }
            }, 3000);
        }
        // }
    }, [running_mode, automatic_state, isKeyLevel]);

    return (
        <React.Fragment>
            {/* <div className="driving_level_notice_wrapper">
                {hnpTips && <div className='tipsItem'>
                    <img src={ hnpErrorTip }></img>
                    <p>{translate("takeOverTips")}</p>
                </div>}
            </div>*/}
            <DrivingLevelWidget
                textState={textState}
                reasonState={reasonState}
                imgIconState={imgIconState}
            ></DrivingLevelWidget>
        </React.Fragment>
    );
}
