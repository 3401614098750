const localAudioNames = {
    // 提醒拨杆语音
    LANE_CHANGE_WARNING: 'lane_change_warning.mp3',
    // 变道提示音
    LANE_CHANGE_BEEP: 'lane_change_beep.mp3',
    // ACC退出
    QUIT_ACC: 'quit_acc.mp3',
    // 手动驾驶ACC开启、CP降级到ACC
    CP_TO_ACC: 'cp_to_acc.mp3',
    // 退出HNP到手动驾驶
    HNP_DISENGAGE: 'hnp_disengage.mp3',
    // HNP降级到CP、手动驾驶到CP开启
    HNP_TO_CP: 'hnp_to_cp.mp3',
    // CP升级到HNP
    CP_TO_HNP: 'cp_to_hnp.mp3',
    // np已开启
    DEMO_NP_ENGAGE_0716: 'cp_to_hnp.mp3',
    // CP退出到手动驾驶
    CP_DISENGAGE: 'cp_disengage.mp3',
    // NP升级到NP_PLUS
    NP_TO_NP_PLUS: 'np_to_np_plus.mp3',
    // NP_PLUS退出到手动驾驶
    NP_PLUS_DISENGAGE: 'np_plus_disengage.mp3',
    // 退到手动驾驶警音
    QUIT_MANUAL_WARNING: 'quit_manual_warning.mp3',
    // 转向灯一直亮着，请及时关闭
    TURN_SIGNAL_STILL_ON: 'turn_signal_still_on.mp3',

    /**
     * 降级到MD
     */
    // 手动降级 & 系统预期降级: "NP已退出"
    QUIT_HNP_EXPECT: 'quit_hnp_expect.mp3',
    // 系统意外降级: "请立即接管，NP已退出"
    QUIT_HNP_ACCIDENT: 'quit_hnp_accident.mp3',
    // 手动降级 & 系统预期降级:  "CP已退出"
    QUIT_CP_EXPECT: 'quit_cp_expect.mp3',
    // 系统意外降级: "请立即接管，CP已退出"
    QUIT_CP_ACCIDENT: 'quit_cp_accident.mp3',
    // 手动降级 & 系统预期降级: "ACC已退出"
    QUIT_ACC_EXPECT: 'quit_acc_expect.mp3',
    // 系统意外降级: "请立即接管，ACC已退出"
    QUIT_ACC_ACCIDENT: 'quit_acc_accident.mp3',
    // 手动降级 & 系统预期降级: NP+已退出
    QUIT_HNP_PLUS_EXPECT: 'quit_hnp_plus_expect.mp3',
    // 系统意外降级: "请立即接管，NP+已退出"
    QUIT_HNP_PLUS_ACCIDENT: 'quit_hnp_plus_accident.mp3',

    /**
     * 降级到ACC
     */
    // 手动降级 & 系统预期降级:  "已进入ACC"
    CP_TO_ACC_EXPECT: 'cp_to_acc_expect.mp3',
    // 系统意外降级:  "请控制方向，已进入ACC"
    CP_TO_ACC_ACCIDENT: 'cp_to_acc_accident.mp3',

    /**
     * 降级到cp
     */
    // 手动降级 & 系统预期降级:  "已进入CP"
    HNP_TO_CP_EXPECT: 'hnp_to_cp_expect.mp3',
    // 系统意外降级:  "请关注行驶路线，已进入CP"
    HNP_TO_CP_ACCIDENT: 'hnp_to_cp_accident.mp3',

    /**
     * 降级到np
     */
    // 系统意外降级 + 手动降级 & 系统预期降级:  "已进入NP，请保持手扶方向盘"
    HNP_PLUS_TO_HNP: 'hnp_plus_to_hnp.mp3',

    /**
     * 手动进入&升级
     */
    // 进入ACC: "ACC已开启"
    ACC_ACTIVATED: 'acc_activated.mp3',
    // 进入CP: "CP已开启"
    CP_ACTIVATED: 'cp_activated.mp3',
    // 进入NP: "NP已开启"
    HNP_ACTIVATED: 'hnp_activated.mp3',
    // 进入NNP+: "NP+已开启"
    HNP_PLUS_ACTIVATED: 'hnp_plus_activated.mp3',

    // demo测试
    // 即将汇入主路注意路况
    DEMO_RAMP_OUT: 'demo_ramp_out.mp3',
    // 即将进入匝道注意路况
    DEMO_RAMP: 'demo_ramp.mp3',
    // 前方1000米进匝道
    DEMO_1000_RAMP: 'demo_1000_ramp.mp3',
    // 前方500米进匝道
    DEMO_500_RAMP: 'demo_500_ramp.mp3',
    // 前方200米进匝道
    DEMO_200_RAMP: 'demo_200_ramp.mp3',
    // 1000米后有道路变化 NP将退出
    DEMO_1000_ROAD_CONSTRUCTION: 'demo_1000_road_construction.mp3',
    // 500米后道路变化 NP将退出
    DEMO_500_ROAD_CONSTRUCTION: 'demo_500_road_construction.mp3',
    // 200米后有道路变化 NP将退出
    DEMO_200_ROAD_CONSTRUCTION: 'demo_200_road_construction.mp3',
    // 道路变化 NP将退出
    DEMO_0_ROAD_CONSTRUCTION: 'demo_0_road_construction.mp3',
    // 即将有道路变化 NP将退出
    DEMO_100_ROAD_CONSTRUCTION: 'demo_100_road_construction.mp3',
    // 1000米后有收费站，NP将退出
    DEMO_1000_TOLL: 'demo_1000_toll.mp3',
    // 500米后有收费站，NP将退出
    DEMO_500_TOLL: 'demo_500_toll.mp3',
    // 200米后有收费站，NP将退出
    DEMO_200_TOLL: 'demo_200_toll.mp3',
    // 即将到达收费站，NP将退出
    DEMO_100_TOLL: 'demo_100_toll.mp3',
    // 到达收费站，NP将退出
    DEMO_0_TOLL: 'demo_0_toll.mp3',
    // 1000米后有闸机，NP将退出
    DEMO_1000_GATE: 'demo_1000_gate.mp3',
    // 500米后有闸机，NP将退出
    DEMO_500_GATE: 'demo_500_gate.mp3',
    // 200米后有闸机，NP将退出
    DEMO_200_GATE: 'demo_200_gate.mp3',
    // 即将到达闸机，NP将退出
    DEMO_100_GATE: 'demo_100_gate.mp3',
    // 到达闸机，NP将退出
    DEMO_0_GATE: 'demo_0_gate.mp3',
    // 1000米后将到达领航路段终点，NP将退出
    DEMO_1000_DESTINATION: 'demo_1000_destination.mp3',
    // 500米后将到达领航路段终点，NP将退出
    DEMO_500_DESTINATION: 'demo_500_destination.mp3',
    // 200米后将到达领航路段终点，NP将退出
    DEMO_200_DESTINATION: 'demo_200_destination.mp3',
    // 即将到达领航路段终点，NP将退出
    DEMO_100_DESTINATION: 'demo_100_destination.mp3',
    // 到达领航路段终点，NP将退出
    DEMO_0_DESTINATION: 'demo_0_destination.mp3',
    // 1000米到达终点，NP将退出
    DEMO_1000_TOUR_END: 'demo_1000_tour_end.mp3',
    // 500米到达终点，NP将退出
    DEMO_500_TOUR_END: 'demo_500_tour_end.mp3',
    // 200米到达终点，NP将退出
    DEMO_200_TOUR_END: 'demo_200_tour_end.mp3',
    // 1000米后领航路段将结束NP将退出
    DEMO_1000_EXIT_ODD: 'demo_1000_exit_odd.mp3',
    // 500米后领航路段将结束NP将退出
    DEMO_500_EXIT_ODD: 'demo_500_exit_odd.mp3',
    // 200米后领航路段将结束NP将退出
    DEMO_200_EXIT_ODD: 'demo_200_exit_odd.mp3',
    // 100米后领航路段将结束NP将退出
    DEMO_100_EXIT_ODD: 'demo_100_exit_odd.mp3',
    DEMO_0_EXIT_ODD: 'demo_0_exit_odd.mp3',
    // 前方有障碍物，1公里后NP将退出
    DEMO_1000_DYNAMIC_EVENT: 'demo_1000_dynamic_event.mp3',
    // 接近障碍物，200米后NP将退出
    DEMO_200_DYNAMIC_EVENT: 'demo_200_dynamic_event.mp3',
    // 错过最晚变道点
    DEMO_LATEST_LANE_CHANGE_DISTANCE: 'demo_lastest_lane_change_distance.mp3',
    // 错过最晚变道点 变道有点难，建议手动变道
    DEMO_HARD_LANE_CHANGE_DISTANCE: 'demo_hard_lane_change_distance.mp3',
    // 错过最晚变道点 偏离
    DEMO_DEVIATE_LANE_CHANGE_DISTANCE: 'demo_deviate_lane_change_distance.mp3',
    // 开启自动驾驶
    DEMO_OPEN_AUTOPILOT: 'demo_open_autopilot.mp3',
    // 当前可开启np
    DEMO_OPEN_AUTOPILOT_NP: 'demo_open_autopilot_np.mp3',
    // 当前可开启cp
    DEMO_OPEN_AUTOPILOT_CP: 'demo_open_autopilot_cp.mp3',
    // 数采盘未插好
    DEMO_DATA_NOTINSERTED: 'demo_data_notinserted.mp3',
    // 确认式向左变道
    DEMO_CONFIRM_LANE_CHANGE_LEFT: 'demo_confirm_lane_change_left.mp3',
    // 确认式向右变道
    DEMO_CONFIRM_LANE_CHANGE_RIGHT: 'demo_confirm_lane_change_right.mp3',
    // CP模式下开启主动超车开关
    CP_AUTONOMOUS_SWITCH: 'cp_autonomous_switch.mp3',

    // code语音
    // 控制方向有点难
    DEMO_CODE1_WHEELHARD: 'demo_code1_wheelhard.mp3',
    // 车速原因
    DEMO_CODE2_SPEED: 'demo_code2_speed.mp3',
    // 导航取消
    DEMO_CODE4_NAVIGATION_CANCEL: 'demo_code4_navigation_cancel.mp3',
    // 定位信号弱，请小心驾驶
    DEMO_CODE6_POSITION_SIGNAL: 'demo_code6_position_signal.mp3',
    // 不在领航路段
    DEMO_CODE7_CODE8_DESTINATION: 'demo_code7_code8_destionation.mp3',
    // 压线
    DEMO_CODE10_ON_LANE: 'demo_code10_on_lane.mp3',
    // 车道异常
    DEMO_CODE9_CODE11_LANE_ERROR: 'demo_code9_code11_lane_error.mp3',
    // 看不到车道线
    DEMO_CODE12_CODE13_NO_LANE: 'demo_code12_code13_no_lane.mp3',
    // 踩油门时间较长
    DEMO_CODE19_GAS_OVERIDE: 'demo_code19_gas_overide.mp3',
    // 长时间未握方向盘
    DEMO_CODE20_HAND_OFF: 'demo_code20_hand_off.mp3',
    // 当前有点难
    DEMO_CODE3_CODE14_CODE22_CODE23_TOOHARD: 'demo_code3_code14_code22_code23_toohard.mp3',
    // 跟车有点难
    DEMO_CODE21_FOLLOW_CAR_HARD: 'demo_code21_follow_car_hard.mp3',

    // 0：进匝道 进匝道提前变道
    ON_RAMP: 'on_ramp.mp3',
    // 1：出匝道汇入主路 即将汇入主路
    OFF_RAMP: 'off_ramp.mp3',
    // 2：避开汇流区 避开汇流区变道
    AVOID_CONFLUENCE_AREA: 'avoid_confluence_area.mp3',
    // 3：超车变道 即将超车
    OVERTAKING_LANE_CHANGE: 'overtaking_lane_change.mp3',
    // 4：驶入更优车道 驶入更优车道
    DRIVE_BETTER_LANE: 'drive_better_lane.mp3',
    // 即将避开大型车辆
    // AVOID_CART: 'avoid_cart.mp3',
    // trigger_reason_tips
    LANE_CHANG_TIPS: 'lane_change_tips.mp3',
    // 即将进入匝道，提前变道
    LANE_CHANG_TRIGGER_REASON: 'lane_change_trigger_reason.mp3',
    // 前方进匝道，即将连续变道，小心后方来车
    LANE_CHANG_TRIGGER_REASON_MANY: 'lane_change_trigger_reason_many.mp3',

    // trigger reason V3
    MAIN_ROAD_NAVI: 'main_road_navi.mp3', // 3：主路导航变道---进匝道提前变道
    SHUNT_NAVI: 'shunt_navi.mp3', // 4：分流导航变道，右1 分流进匝道--进匝道
    CONFLUENCE_NAVI: 'confluence_navi.mp3', // 5：汇流导航变道，汇入主路--汇入主路
    WITHIN_RAMP_NAVI: 'within_ramp_navi.mp3', // 6：匝道内导航变道-- 匝道导航变道
    CONGESTION_NAVI: 'congestion_navi.mp3', // 7：拥堵导航变道 --- 拥堵变道
    BIG_CAR_OVERTAKING_LEFT: 'big_car_overtaking_left.mp3', // 8：大车向左超车变道---向左超越大车
    BIG_CAR_OVERTAKING_RIGHT: 'big_car_overtaking_right.mp3', // 9：大车向右超车变道---向右超越大车
    SMALL_CAR_OVERTAKING_LEFT: 'small_car_overtaking_left.mp3', // 10：小车向左超车变道---向左超车
    SMALL_CAR_OVERTAKING_RIGHT: 'small_car_overtaking_right.mp3', // 11：小车向右超车变道---向右超车
    CONTINUATION_OVERTAKING: 'continuation_overtaking.mp3', // 12：AL2 超车变道---向左连续超车
    STATIC_OVERTAKING: 'static_overtaking.mp3', // 13：静止车超车变道---避让静止车
    ADVANCE_AVOID_CONFLUENCE_AREA: 'advance_avoid_confluence_area.mp3', // 14：避让汇流点变道 (规避汇流点、高速匝道入口)--- 提前避让汇流区
    AVOIDING_CONSTRUCTION_LANE: 'avoiding_construction_lane.mp3', // 15：避让施工车道变道 --- 避让施工车道
    AVOIDING_OBSTACLE: 'avoid_obstacle.mp3', // 10001: 变道绕行----即将变道绕行
    LANE_CHANGE_NAVI: 'lane_change_navi.mp3', // UNP 10002: 导航变道--导航变道 + HNP 6：匝道内导航变道-- 导航变道
    LANE_CHANGE_AVOID_VRU: 'lane_change_avoid_vru.mp3', // UNP 10003（没有确认式）: 避让行人---

    // 确认式变道语音
    CONFIRM_UNKNOWN_LEFT: 'confirm_unknown_left.mp3', // 1：未知变道类型向左
    CONFIRM_UNKNOWN_RIGHT: 'confirm_unknown_right.mp3', // 1：未知变道类型向右
    CONFIRM_NAVI_LEFT: 'confirm_navi_left.mp3', // 3：主路导航变道、6：匝道内导航变道、7：拥堵导航变道---进匝道提前变道向左
    CONFIRM_NAVI_RIGHT: 'confirm_navi_right.mp3', // 3：主路导航变道---进匝道提前变道向右
    CONFIRM_CONFLUENCE_NAVI_LEFT: 'confirm_confluence_navi_left.mp3', // 5：汇流导航变道，汇入主路--汇入主路向左
    CONFIRM_CONFLUENCE_NAVI_RIGHT: 'confirm_confluence_navi_right.mp3', // 5：汇流导航变道，汇入主路--汇入主路向
    CONFIRM_OVERTAKING_LEFT: 'confirm_overtaking_left.mp3', // 8：大车向左超车变道、9：大车向右超车变道、10：小车向左超车变道、11：小车向右超车变道、12：AL2 超车变道、13：静止车超车变道---向左超越大车
    CONFIRM_OVERTAKING_RIGHT: 'confirm_overtaking_right.mp3', // 8：大车向左超车变道、9：大车向右超车变道、10：小车向左超车变道、11：小车向右超车变道、12：AL2 超车变道、13：静止车超车变道---向右超越大车
    CONFIRM_CONFLUENCE_AREA_LEFT: 'confirm_confluence_area_left.mp3', // 14：避让汇流点变道 (规避汇流点、高速匝道入口)--- 提前避让汇流区
    CONFIRM_CONFLUENCE_AREA_RIGHT: 'confirm_confluence_area_right.mp3', // 14：避让汇流点变道 (规避汇流点、高速匝道入口)--- 提前避让汇流区
    CONFIRM_FAIL_REASON: 'confirm_fail_reason.mp3', // 失败告警
    CONFIRM_AVOIDING_CONSTRUCTION_LANE_LEFT: 'confirm_avoiding_construction_lane_left.mp3', // 15：避让施工车道变道 --- 避让施工车道向左
    CONFIRM_AVOIDING_CONSTRUCTION_LANE_RIGHT: 'confirm_avoiding_construction_lane_right.mp3', // 15：避让施工车道变道 --- 避让施工车道向右

    //目标车道语音
    BUSLANE: 'bus_lane.mp3', // 公交车道

    // 请注意后方车辆
    RCW_BEHIND: 'rcw_behind.mp3',

    // new notice_driving_code
    DEMO_1_ON_LANE: 'demo_1_on_lane.mp3',
    DEMO_3_ROAD_COMPLEX: 'demo_3_road_complex.mp3',
    DEMO_4_TOO_FAST: 'demo_4_too_fast.mp3',
    DEMO_5_GAS_OVERIDE: 'demo_5_gas_overide.mp3',
    DEMO_7_SPEED: 'demo_7_speed.mp3',
    DEMO_11_LANE_DERROR: 'demo_11_lane_error.mp3',
    DEMO_12_NO_LANE: 'demo_12_no_lane.mp3',
    DEMO_13_CAR_BODY: 'demo_13_car_body.mp3',
    DEMO_15_NAVIGATION_CANCEL: 'demo_15_navigation_cancel.mp3',
    DEMO_16_POSITION_UNUSUAL: 'demo_16_position_unusual.mp3',
    DEMO_17_18_NOT_LANE: 'demo_17_18_not_lane.mp3',

    // adas提醒音效: bibibi
    BIBIBI_WARNING: 'bibibi_warning.mp3',
    // AMAP 莲花山油门防误踩 ---> 请踩刹车
    AMAP_BREAKING: 'amap_breaking.mp3',
    // NRP 莲花山窄道辅助功能 ---> 前方狭窄路段，请谨慎驾驶
    WIDTH_PASSABLE: 'width_passable.mp3',
    // NRP 莲花山窄道辅助功能 ---> 前方狭窄路段，不可通行
    WIDTH_UNPASSABLE: 'width_unpassable.mp3',
    // NRP 莲花山窄道辅助功能 ---> 前方限高，请谨慎驾驶
    HEIGHT_PASSABLE: 'height_passable.mp3',
    // NRP 莲花山窄道辅助功能 ---> 请注意前方高度，不可通行
    HEIGHT_UNPASSABLE: 'height_unpassable.mp3',
    // NRP 莲花山窄道辅助功能 ---> 限宽狭窄可通过，不可控车
    WIDTH_PASSABLE_UNCONTROLLABLE: 'width_uncontrollable.mp3',

    // NRP 窄路通行 ---> 前方窄道自动通行可用，请刹停车辆
    NRP_STAND_BY: 'nrp_stand_by.mp3',
    // NRP 窄路通行 ---> 前方窄道自动通行可用，请按键激活
    NRP_PRE_ACTIVE: 'nrp_pre_active.mp3',
    // NRP 窄路通行 ---> 自动通行中
    NRP_ACTIVE: 'nrp_active.mp3',
    // NRP 窄路通行 ---> 自动通行完成
    NRP_COMPLETE: 'nrp_complete.mp3',
    // NRP 窄路通行 ---> 自动通行退出，请接管车辆
    NRP_MRM: 'nrp_mrm.mp3',

    // 前方有障碍物，请小心驾驶 + bibibi混合音频
    CONICAL_BARREL_AUTO_AVOID: 'conical_barrel_auto_avoid.mp3',
    // 前方有障碍物，请立即接管 + bibibi混合音频
    CONICAL_BARREL_NO_AVOID: 'conical_barrel_no_avoid.mp3',
    // 选择车位音效
    SELECT_CAR_SITE: 'mixkit-correct-answer-tone-2870.mp3',
    // 开始录音
    START_RECORD_REMINDER: 'mixkit-correct-answer-tone-2870.mp3',
    // 跟车较远，请注意踩油门
    GREATER_THAN_FOLLOW_CAR: 'greater_than_follow_car.mp3',
    // 跟车较近，请注意踩刹车
    LESS_THAN_FOLLOW_CAR: 'less_than_follow_car.mp3',
    // MRC 安全停车中，请立刻接管
    MRC_TAKE_OVER: 'MRC_take_over.mp3',
    // MRC BIbi音效
    BIBIBI_AUDIO: 'bibibi_audio.mp3',
    // 手动升级失败
    SELF_DRIVING: 'self_driving_fail.mp3',
    // TOR dengdeng音效
    DENGDENG_WARNING: 'dengdeng_warning.mp3',
    // D版打磨选车位音效
    SELECT_CAR_SITE_D: 'car_site_has_been_selected.mp3',
    // LPNP推荐入口未解锁提示用户解锁使用
    LPNP_ENTRY_RECOMMEND_NOT_UNLOCK: 'lpnp_entry_recommend_not_unlock_tip.mp3',
    // LPNP推荐入口已解锁提示用户使用LPNP
    LPNP_ENTRY_RECOMMEND_UNLOCKED: 'lpnp_entry_recommend_unlocked_tips.mp3',
    // LPNP默认选中推荐车位语音提示
    LPNP_CAR_SITE_HAS_SELECTED: 'lpnp_car_site_has_selected.mp3',
    // CP后车碰撞报警和加速提示
    CP_REAR_CAR_COLLISION: 'bibibi_warning.mp3',
    // CP车道线压线提醒音效
    CP_CROSS_BOUNDARY_AUDIO: 'bibibi_warning.mp3',
    // CP车道线压线提醒语音
    CP_CROSS_BOUNDARY_VOICE: 'cp_cross_boundary.mp3',
    // CP道路边缘碰撞提醒 level 1
    CP_ROAD_BOUNDARY_COLLISION_VOICE_1: 'cp_road_boundary_collision_1.mp3',
    // CP道路边缘碰撞提醒 level 2
    CP_ROAD_BOUNDARY_COLLISION_VOICE_2: 'cp_road_boundary_collision_2.mp3',
    // UNP 路口通行左转
    UNP_TURNING_LEFT_HEAD: 'unp_turning_left_head.mp3',
    // UNP 路口通行右转
    UNP_TURNING_RIGHT_HEAD: 'unp_turning_right_head.mp3',

    // 智驾设置页面switch开关打开音效
    SETTING_SWITCH_ON: 'setting_switch_on.mp3',
    SETTING_SWITCH_OFF: 'setting_switch_off.mp3',
};

export default localAudioNames;
