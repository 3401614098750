import React, { useState, useEffect } from 'react';
import { Grid } from 'antd-mobile';
import './index.less';
import { AppFn } from '@/utils/native';
import localAudioNames from '@/constant/localAudioName';
const { playLocalAudio } = AppFn;

export default function CardLayout(props) {
    const [isFirstRender, setIsFirstRender] = useState(true);
    useEffect(() => {
        if (isFirstRender) {
            setIsFirstRender(false);
        } else {
            if (props.value) {
                playLocalAudio(localAudioNames.SETTING_SWITCH_ON, 'Low');
            } else {
                playLocalAudio(localAudioNames.SETTING_SWITCH_OFF, 'Low');
            }
        }
    }, [props.value]);

    return (
        <div className={'card_wrap'}>
            <Grid columns={2}>
                <Grid.Item>{props?.leftDom}</Grid.Item>
                <Grid.Item>{props?.rightDom}</Grid.Item>
            </Grid>
        </div>
    );
}
