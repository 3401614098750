import { ParkingSocketMap } from '@/constant/socketMap';
import FM from '@/utils/nativeSubAndPub';

export default function useVehicleOutlineSettingCommon() {
    // 通知底层：车身线开关
    const vehicleOutlineControl = async (vehicleOutlineValue) => {
        FM.sendToHMIParking(
            ParkingSocketMap.avm_control,
            {
                tireTracksVehicleOutline: vehicleOutlineValue,
            },
            null,
        );
    };

    return {
        vehicleOutlineControl,
    };
}
