import { useEffect, useState } from 'react';
import { Button, Modal, Space, Checkbox, SpinLoading, Radio } from 'antd-mobile';
import ReportItem from '../Report/ReportItem';
import ImgPreviewModal from '../Report/ImgPreviewModal';
import { useAudioPlay } from '../hook';
import { getMarkTypeEnum, updateEventMark } from '../api';
import Toast from '@/components/workbench/Toast';
import AdditionalRecordModal from '../Report/AdditionalRecordModal';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

import './index.less';

const checkBoxStyle = { '--font-size': '.28rem', '--icon-size': '.32rem' };
const taskTypeList = [
    {
        label: '默认',
        value: 'hmi_workbench_tag',
    },
    {
        label: 'PNP',
        value: 'hmi_workbench_tag_pnp',
    },
];
const checkListCache = {};

export default function EventTagModal({ visible, onClose, item, workBenchClose }) {
    const { t: translate } = useTranslation();
    const workBenchRecording = useSelector((state) => state?.switchModeAction?.isRecording); // 工作台的录音状态
    const [imgUrl, setImgUrl] = useState('');
    const [previewFlag, setPreviewFlag] = useState(false);
    const { handleAudio, reset, playEffect } = useAudioPlay();

    useEffect(() => {
        if (!visible && playEffect.current) reset();
    }, [visible]);

    const [taskType, setTaskType] = useState(localStorage['hmi_event_tag_task_type'] || 'hmi_workbench_tag');
    const [checkList, setCheckList] = useState([
        {
            value: '体感很不舒服',
            description: '',
        },
        {
            value: '固定频率问题',
            description: '',
        },
        {
            value: '很危险',
            description: '',
        },
        {
            value: '新问题',
            description: '',
        },
        {
            value: '策略很傻',
            description: '',
        },
        {
            value: '复现问题',
            description: '',
        },
    ]);
    useEffect(() => {
        if (checkListCache[taskType]) {
            setCheckList(checkListCache[taskType]);
            return;
        }
        getMarkTypeEnum({ col_name: taskType }).then((res) => {
            if (res.code === 200) {
                checkListCache[taskType] = res.data.enum_list;
                setCheckList(res.data.enum_list || []);
            }
        });
    }, [taskType]);

    const [markType, setMarkType] = useState([]);
    const [isSerious, setIsSerious] = useState(false);

    const [loading, setLoading] = useState(false);

    // function switchData(type) {
    //     if (playEffect.current) reset();
    //     setLoading(true);
    //     // TODO:请求接口逻辑
    //     setLoading(false);
    // }

    useEffect(() => {
        if (!item || !visible) return;
        setIsSerious(item.is_serious);
        setMarkType(item.event_labels ? item.event_labels.split(',') : []);
    }, [item, visible]);

    function save() {
        if (!markType.length) {
            Toast.info(translate('workBench.eventTag.updateTip'));
            return;
        }
        setLoading(true);
        const params = {
            id: item.id,
            event_labels: markType.join(),
            is_serious: isSerious,
        };
        updateEventMark(params)
            .then((res) => {
                if (res.code !== 200) {
                    Toast.info(res.message);
                    return;
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const [additionalRecordModalFlag, setAdditionalRecordModalFlag] = useState(false);

    const additionalBtn = () => {
        if (workBenchRecording) {
            Toast.info(translate('workBench.report.addRecordToast'), 2000);
            return;
        }
        if (playEffect.current) {
            reset();
        }
        setAdditionalRecordModalFlag(true);
    };

    const Contnet = (
        <div className="event-tag-container">
            {loading && (
                <div className="loading-container">
                    <SpinLoading style={{ '--size': '.44rem' }} />
                </div>
            )}
            <div className="content">
                <div className="event-info">
                    <h3>{translate('workBench.eventTag.eventMark')}</h3>
                    <ReportItem
                        className="report-detail"
                        item={item}
                        onImgClick={(url) => {
                            setImgUrl(url);
                            setPreviewFlag(true);
                        }}
                        isPlay={playEffect.current}
                        onPlayAudio={() =>
                            handleAudio(item?.voice_content?.url, item?.voice_content?.duration, item?.id)
                        }
                        onAdditional={additionalBtn}
                    />
                    <ImgPreviewModal visible={previewFlag} url={imgUrl} onClose={() => setPreviewFlag(false)} />
                    <AdditionalRecordModal
                        visible={additionalRecordModalFlag}
                        onClose={() => setAdditionalRecordModalFlag(false)}
                        onInterrupt={() => {
                            setAdditionalRecordModalFlag(false);
                            workBenchClose();
                        }}
                        recordData={item}
                    />
                </div>
                <div className="evnet-mark">
                    <h3>{translate('workBench.eventTag.markType')}</h3>
                    <Radio.Group
                        value={taskType}
                        onChange={(val) => {
                            localStorage['hmi_event_tag_task_type'] = val;
                            setTaskType(val);
                        }}
                    >
                        <Space className="check-group" style={{ '--gap': '.15rem' }} wrap>
                            {taskTypeList.map(({ value, label }) => (
                                <Radio key={value} value={value} style={checkBoxStyle}>
                                    {label}
                                </Radio>
                            ))}
                        </Space>
                    </Radio.Group>

                    <Checkbox.Group value={markType} onChange={(val) => setMarkType(val)}>
                        <Space className="check-group" style={{ '--gap': '.15rem', marginTop: '.4rem' }} wrap>
                            {checkList.map(({ value }) => (
                                <Checkbox key={value} value={value} style={checkBoxStyle}>
                                    {value}
                                </Checkbox>
                            ))}
                        </Space>
                    </Checkbox.Group>
                    <Checkbox
                        checked={isSerious}
                        onChange={(val) => setIsSerious(val)}
                        block
                        style={{ ...checkBoxStyle, marginTop: '.4rem' }}
                    >
                        {translate('workBench.eventTag.serious')}
                    </Checkbox>
                    {item?.event_labels && (
                        <div className="mark-status">
                            {translate('workBench.eventTag.marked')}
                            <time>{dayjs(item.updated_at).format('YYYY-MM-DD HH:mm:ss')}</time>
                        </div>
                    )}
                </div>
            </div>
            <Space className="btn-box" justify="center" style={{ '--gap': '.15rem' }}>
                <Button onClick={onClose}>{translate('workBench.eventTag.return')}</Button>
                {/* TODO: 暂时不做，后期看反馈迭代 */}
                {/* <Button onClick={() => switchData('prev')}>{translate('workBench.eventTag.prev')}</Button>
                <Button onClick={() => switchData('next')}>{translate('workBench.eventTag.next')}</Button> */}
                <Button onClick={save}>{translate('workBench.eventTag.confirm')}</Button>
            </Space>
        </div>
    );
    return (
        <Modal
            visible={visible}
            content={Contnet}
            bodyStyle={{ maxWidth: '80vw' }}
            closeOnMaskClick
            onClose={onClose}
        ></Modal>
    );
}
