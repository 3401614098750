import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { ParkingSocketMap } from '@/constant/socketMap';
import FM from '@/utils/nativeSubAndPub';

import { singelPointReport } from '@/utils/report';
import reportEventName from '@/constant/reportEventName';
import { HOT_AREA_TYPE } from '@/store/action/type';
import './index.less';
import { AppFn } from '@/utils/native';
import localAudioNames from '@/constant/localAudioName';
import { useTranslation } from 'react-i18next';
import localCache from '@/utils/localCache';

const { playLocalAudio } = AppFn;

const _lpnp_entry_count = 11;

export default function LPNPEntry() {
    const { t: translate } = useTranslation();
    const status = useSelector((state) => state?.featureModelParking?.lpnp?.state);
    const is_show = useSelector((state) => state?.featureModelDriving?.lpnpRecommendEntrypoint?.isShow);
    const is_map_built = useSelector((state) => state?.featureModelDriving?.lpnpRecommendEntrypoint?.isMapBuilt);
    const [condition, setCondition] = useState('');
    const [isShowEntry, setShowEntry] = useState(true);
    const entryTimer = useRef();
    const entryCount = useRef(_lpnp_entry_count);
    const lpnpEntry = useRef(null);
    const lpnpEntryRect = lpnpEntry?.current?.getBoundingClientRect();
    const [timeCount, setTimeCount] = useState('10');
    const userMode = useSelector((state) => state?.parking?.userMode);

    const StatusTxtMap = useRef({
        TO_LEARNING: translate('parking.useLPLPTip.unlock'),
        TO_PARKING: translate('parking.useLPLPTip.use'),
    });

    const dispatch = useDispatch();

    const reset = () => {
        localCache.set('lpnp_entry_closed', true);
        clearInterval(entryTimer.current);
        entryCount.current = _lpnp_entry_count;
    };

    const handleToParking = async () => {
        FM.sendToHMIParking(
            ParkingSocketMap.pilot_kind_control,
            {
                cmd: 'PARKING',
            },
            'pilot_kind_control_cb_parking',
        );
    };
    //此处有重复掉用，且回调不一样
    window.pilot_kind_control_cb_parking = (res) => {
        if (res?.data?.code === 0) {
            reset();
            setShowEntry(false);
            singelPointReport({
                eventName: reportEventName.HMI_USE_LPNP_CONFIRM_CLICK,
                timestamp: new Date().getTime(),
                reportData: {
                    user_select: 0,
                },
            });
        }
    };

    const handleHideEntry = () => {
        reset();
        setShowEntry(false);
        singelPointReport({
            eventName: reportEventName.HMI_USE_LPNP_CONFIRM_CLICK,
            timestamp: new Date().getTime(),
            reportData: {
                user_select: 1,
            },
        });
    };

    const handleTimer = () => {
        entryTimer.current = setInterval(() => {
            if (entryCount.current == 1) {
                setShowEntry(false);
                return reset();
            }
            entryCount.current = entryCount.current - 1;
            setTimeCount(entryCount.current - 1);
        }, 1000);
    };

    useEffect(() => {
        // NOT_IN/PASSIVE状态 清除标识
        if (status == 'NOT_IN' || status == 'PASSIVE' || !is_show) {
            localCache.set('lpnp_entry_closed', false);
            setShowEntry(true);
            setCondition('');
        }
    }, [status, is_show]);

    useEffect(() => {
        if (is_show && !is_map_built) {
            singelPointReport({
                eventName: reportEventName.HMI_USE_LPNP_VIEW,
                timestamp: new Date().getTime(),
                reportData: {
                    status: 0,
                },
            });
            localCache.get('lpnp_entry_closed', (data) => {
                if (data) return;
                playLocalAudio(localAudioNames.LPNP_ENTRY_RECOMMEND_NOT_UNLOCK, 'Important');
            });
            return setCondition('TO_LEARNING');
        }

        if (is_show && is_map_built) {
            singelPointReport({
                eventName: reportEventName.HMI_USE_LPNP_VIEW,
                timestamp: new Date().getTime(),
                reportData: {
                    status: 1,
                },
            });
            localCache.get('lpnp_entry_closed', (data) => {
                if (data) return;
                playLocalAudio(localAudioNames.LPNP_ENTRY_RECOMMEND_UNLOCKED, 'Important');
            });
            return setCondition('TO_PARKING');
        }
    }, [is_show, is_map_built]);

    // lpnpEntry hotArea
    useEffect(() => {
        const lpnpEntryInfo = `${lpnpEntryRect?.x}, ${lpnpEntryRect?.y}, ${lpnpEntryRect?.width}, ${lpnpEntryRect?.height}`;
        console.log('lpnpEntryInfo', lpnpEntryInfo);
        dispatch({
            type: HOT_AREA_TYPE.LPNPEntryDom,
            data: lpnpEntryInfo,
        });
        return () => {
            dispatch({
                type: HOT_AREA_TYPE.LPNPEntryDom,
                data: '',
            });
        };
    }, [
        condition,
        is_show,
        is_map_built,
        lpnpEntryRect?.x,
        lpnpEntryRect?.y,
        lpnpEntryRect?.width,
        lpnpEntryRect?.height,
    ]);

    useEffect(() => {
        localCache.get('lpnp_entry_closed', (data) => {
            setShowEntry(!data);
            handleTimer();
        });
    }, []);

    return (
        <>
            {condition && isShowEntry && is_show ? (
                <div ref={lpnpEntry} className={`lpnp_entry ${userMode === 'C' ? 'C' : 'D'}`}>
                    <div className="lpnp_entry_content">
                        <p className="lpnp_entry_txt">
                            {StatusTxtMap.current[is_map_built ? 'TO_PARKING' : 'TO_LEARNING']}
                        </p>
                        <div className="lpnp_entry_btns">
                            <div onClick={() => handleToParking()} className="lpnp_entry_btn lpnp_entry_confirm">
                                {translate('parking.useLPLPTip.yes')}
                            </div>
                            <div onClick={() => handleHideEntry()} className="lpnp_entry_btn lpnp_entry_cancel">
                                {translate('parking.useLPLPTip.no')} {timeCount > 0 && <>({timeCount}s)</>}
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                ''
            )}
        </>
    );
}
