import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Switch } from 'antd-mobile';
import { SocketMap } from '@/constant/socketMap';
import FM from '@/utils/nativeSubAndPub';
import { cusToast } from '@/components/customToast';
// import { customFilterVersion } from '@/constant/filterVersionMap';
import { singelPointReport } from '@/utils/report';
import reportEventName from '@/constant/reportEventName';
import DriveStyle from './customiseCruise/drivingStyle';
import LaneChangeStyle from './customiseCruise/laneChangeStyle';
import LaneChangeConfirm from './customiseNavigation/laneChangeConfirm';
import FunctionModeSwitch from './customiseNavigation/functionModeSwitch';
import AutoLaneChangeSwitch from './customiseCruise/autoLaneChangeSwitch';
import AutoLaneChangeSwitchEU from './customiseCruise/autoLaneChangeSwitchEU';
import cpWhite from '@/assets/slider/cp_white.jpg';
import npWhite from '@/assets/slider/np_white.jpg';
import cpBlack from '@/assets/slider/cp_black.jpg';
import npBlack from '@/assets/slider/np_black.jpg';
import TFLSwitch from './customiseCruise/tflSwitch';
import AccCpTakeoverSwitch from './customiseCruise/accCpTakeoverSwitch';
import CpWarningSwitch from './customiseCruise/cpWarningSwitch';
// import LaneChangeBeep from "./customiseNavigation/laneChangeBeep";
import PreferredlaneCard from './PreferredlaneCard';
import './index.less';
import RoundaboutSwitch from './customiseCruise/roundaboutSwitch';
import { AppFn } from '@/utils/native';
import localAudioNames from '@/constant/localAudioName';
const { playLocalAudio } = AppFn;

export default function DrivingSwitchModule() {
    const dispatch = useDispatch();
    const flag = useSelector((state) => state?.smartDrivingSettingMsg?.flag);
    const hmi_version = useSelector((state) => state?.featureModelDriving?.baseData?.hmi?.hmi_version);
    // 针对2.7.3和2.9.2的判断
    // const isFilterVersion = hmi_version?.includes('HARZ') || false;
    // 0625 EU CP合入主线更改 跟着主线的value走
    const i18nLang = useSelector((state) => state?.themeAction?.i18nState);
    // 老版本有该字段，UNP共版本之后没有该字段
    const release_platform = useSelector((state) => state?.featureModelDriving?.baseData?.hmi?.release_platform);
    // toast文案
    // const [switchToast, setSwitchToast] = useState('');
    // 是否展示toast
    // const [showToast, setShowToast] = useState(false);
    // 最高智驾
    const switch_running_mode_state = useSelector(
        (state) => state?.featureModelDriving?.switch?.driving?.running_mode?.switch_running_mode,
    );
    const operate_drive_mode = useSelector(
        (state) => state?.featureModelDriving?.switch?.driving?.running_mode?.operate_drive_mode,
    );
    // cp开关
    const [cpSwitch, setCpSwitch] = useState(operate_drive_mode?.cp === 'ON' ? true : false);
    // np开关
    const [hnpSwitch, setHnpSwitch] = useState(operate_drive_mode?.hnp === 'ON' ? true : false);
    // unp开关
    const [unpSwitch, setUnpSwitch] = useState(operate_drive_mode?.unp === 'ON' ? true : false);
    // 当前智驾
    const running_mode = useSelector((state) => state?.featureModelDriving?.baseData?.os?.driving?.running_mode);
    const automatic_state = useSelector((state) => state?.featureModelDriving?.baseData?.os?.driving?.automatic_state);
    // 主题色
    const themeColorState = useSelector((state) => state?.themeAction?.themeColorState);
    // tfl-switch按钮
    const tfl_switch = useSelector((state) => state?.featureModelDriving?.switch?.driving?.cp_tfl_stop_control?.switch);
    // roundabout开关
    const roundabout_switch = useSelector(
        (state) => state?.featureModelDriving?.switch?.driving?.roundabout_info_control?.switch,
    );
    // CP告警开关
    const cp_warning_switch = useSelector(
        (state) => state?.featureModelDriving?.switch?.driving?.cp_warning_control?.switch,
    );
    // 碰撞风险提示按钮
    const acc_cp_switch = useSelector(
        (state) => state?.featureModelDriving?.switch?.driving?.collision_alert_control?.switch,
    );
    // np模式切换开关
    const switch_function_mode = useSelector(
        (state) => state?.featureModelDriving?.switch?.driving?.np_function_mode?.switch_function_mode,
    );
    // featureModel返回的版本过滤
    const confirmFunction = useSelector((state) => state?.featureModelDriving?.laneChange?.confirmFunction);
    // CP自主变道
    const cp_autonomous_switch = useSelector(
        (state) => state?.featureModelDriving?.switch?.driving?.lane_change_mode?.cp_autonomous_switch,
    );
    // 驾驶风格开关
    const driving_style_switch = useSelector(
        (state) => state?.featureModelDriving?.switch?.driving?.driving_style?.driving_style_switch,
    );
    // 变道风格开关
    const lane_change_style_switch = useSelector(
        (state) => state?.featureModelDriving?.switch?.driving?.driving_style?.lane_change_style_switch,
    );
    // 偏好车道开关
    const preferred_lane_switch = useSelector(
        (state) => state?.featureModelDriving?.switch?.driving?.lane_change_mode?.preferred_lane_switch,
    );
    const eu_cp_autonomous = ['AUTONOMOUS_CLOSE', 'AUTONOMOUS_RECOMMEND', 'AUTONOMOUS_INITIALTIVE'].includes(
        cp_autonomous_switch,
    );
    const isEuEmpty =
        typeof driving_style_switch === 'undefined' &&
        typeof lane_change_style_switch === 'undefined' &&
        !cp_autonomous_switch &&
        !eu_cp_autonomous &&
        !tfl_switch &&
        !roundabout_switch;
    const { t: translate } = useTranslation();
    const [isFirstRender, setIsFirstRender] = useState(true);
    const toastTimer = useRef(null);

    // CP开关
    const cpSwitchChange = (value) => {
        if (value === cpSwitch) return;
        // 智驾运行时，NP或CP开关都不支持调节
        if (['HNP_PLUS', 'HNP', 'UNP', 'CP', 'ACC'].indexOf(running_mode) > -1 && automatic_state === 'ACTIVE') {
            const text = translate('settings.manualDrivingMode');
            dispatch({
                type: 'setUpMsg',
                data: {
                    tooltipMsg: text,
                    flag: !flag,
                },
            });
            return;
        }
        // 数据上报
        reportDataFunc({ type: 'CP', value });
        // 关闭CP同时关闭NP
        if (value) {
            operateDriveMode(value, hnpSwitch, unpSwitch);
        } else {
            operateDriveMode(value, false, false);
            // reportDataFunc({ type: 'HNP', value: false });
            // reportDataFunc({ type: 'UNP', value: false });
        }
        // setCpSwitch(value);
    };

    // NP开关
    const hnpSwitchChange = (value) => {
        if (value === hnpSwitch) return;
        // 智驾运行时，NP或CP开关都不支持调节
        if (['HNP_PLUS', 'HNP', 'UNP', 'CP', 'ACC'].indexOf(running_mode) > -1 && automatic_state === 'ACTIVE') {
            const text = translate('settings.manualDrivingMode');
            dispatch({
                type: 'setUpMsg',
                data: {
                    tooltipMsg: text,
                    flag: !flag,
                },
            });
            return;
        }
        // 提示请打开CP不可开启NP
        // if (value && !cpSwitch) {
        //     const text = translate('settings.cruisePilot');
        //     dispatch({
        //         type: 'setUpMsg',
        //         data: {
        //             tooltipMsg: text,
        //             flag: !flag,
        //         },
        //     });
        //     return;
        // }
        if (value) {
            operateDriveMode(true, value, unpSwitch);
        } else {
            operateDriveMode(cpSwitch, value, false);
        }
        // setHnpSwitch(value);
        // 数据上报
        // reportDataFunc({ type: 'HNP', value });
    };

    // UNP开关
    const unpSwitchChange = (value) => {
        if (release_platform) return;
        if (value === unpSwitch) return;
        // 智驾运行时，UNP或CP开关都不支持调节
        if (['HNP_PLUS', 'HNP', 'UNP', 'CP', 'ACC'].indexOf(running_mode) > -1 && automatic_state === 'ACTIVE') {
            const text = translate('settings.manualDrivingMode');
            dispatch({
                type: 'setUpMsg',
                data: {
                    tooltipMsg: text,
                    flag: !flag,
                },
            });
            return;
        }
        if (value) {
            operateDriveMode(true, true, value);
        } else {
            operateDriveMode(cpSwitch, hnpSwitch, value);
        }
        // setUnpSwitch(value);
        // 数据上报
        // reportDataFunc({ type: 'UNP', value });
    };

    // 向底层传数据
    const operateDriveMode = async (cpValue, hnpValue, unpValue) => {
        // setCpSwitch(cpValue);
        // setHnpSwitch(hnpValue);
        // setUnpSwitch(unpValue);
        if (release_platform) {
            let runningModeState = 'HNP';
            if (cpValue && hnpValue) {
                runningModeState = 'HNP';
            } else if (cpValue && !hnpValue) {
                runningModeState = 'CP';
            } else if (!cpValue && !hnpValue) {
                runningModeState = 'ACC';
            }
            FM.sendToHMIDriving(
                SocketMap.operateDriveMode,
                {
                    switch_running_mode: runningModeState,
                },
                'drive_node_res_fail_cb',
            );
        } else {
            FM.sendToHMIDriving(
                SocketMap.operateDriveMode,
                {
                    cp: cpValue ? 'ON' : 'OFF',
                    hnp: hnpValue ? 'ON' : 'OFF',
                    unp: unpValue ? 'ON' : 'OFF',
                },
                'drive_node_res_fail_cb',
            );
        }
        reportDataFunc(cpValue, hnpValue, unpValue);
    };

    // 数据上报
    const reportDataFunc = (cpValue, hnpValue, unpValue) => {
        // const { type, value } = params;
        singelPointReport({
            eventName: reportEventName.HMI_AD_MODE_CLICK,
            timestamp: new Date().getTime(),
            reportData: {
                CP: cpValue ? 'ON' : 'OFF',
                HNP: hnpValue ? 'ON' : 'OFF',
                UNP: unpValue ? 'ON' : 'OFF',
            },
        });
    };

    // 监听hminode返回值进行开关渲染
    useEffect(() => {
        if (operate_drive_mode?.cp || operate_drive_mode?.hnp || operate_drive_mode?.unp) {
            setCpSwitch(operate_drive_mode?.cp === 'ON' ? true : false);
            setHnpSwitch(operate_drive_mode?.hnp === 'ON' ? true : false);
            setUnpSwitch(operate_drive_mode?.unp === 'ON' ? true : false);
        }
    }, [operate_drive_mode?.cp, operate_drive_mode?.hnp, operate_drive_mode?.unp]);

    useEffect(() => {
        if (isFirstRender) {
            setIsFirstRender(false);
        } else {
            if (cpSwitch) {
                playLocalAudio(localAudioNames.SETTING_SWITCH_ON, 'Low');
            } else {
                playLocalAudio(localAudioNames.SETTING_SWITCH_OFF, 'Low');
            }
        }
    }, [cpSwitch]);

    useEffect(() => {
        if (isFirstRender) {
            setIsFirstRender(false);
        } else {
            if (hnpSwitch) {
                playLocalAudio(localAudioNames.SETTING_SWITCH_ON, 'Low');
            } else {
                playLocalAudio(localAudioNames.SETTING_SWITCH_OFF, 'Low');
            }
        }
    }, [hnpSwitch]);

    useEffect(() => {
        if (isFirstRender) {
            setIsFirstRender(false);
        } else {
            if (unpSwitch) {
                playLocalAudio(localAudioNames.SETTING_SWITCH_ON, 'Low');
            } else {
                playLocalAudio(localAudioNames.SETTING_SWITCH_OFF, 'Low');
            }
        }
    }, [unpSwitch]);

    const isDisabled = !(hnpSwitch || unpSwitch);

    return (
        <div className="switch-module-container">
            {/* CP */}
            <div className="switch-box">
                <div
                    className="main-swtich"
                    style={{ backgroundImage: `url(${themeColorState === 'black' ? cpBlack : cpWhite})` }}
                >
                    <div className="switch-type">CP</div>
                    <div className="switch-title">{translate('settings.cruiseAssist')}</div>
                    <Switch
                        checked={cpSwitch}
                        onChange={(checked) => cpSwitchChange(checked)}
                        className="common-switch"
                    />
                </div>
                <div
                    className={`customise-content ${!cpSwitch && 'is_disabled'} ${
                        i18nLang === 'en-US' && (isEuEmpty ? 'is-filter-empty' : 'is-filter-en')
                    }`}
                >
                    <div className="customise-title">{translate('settings.customizeCp')}</div>
                    {/* 德国2.9.2和2.7.3版本，德国版本中有四个功能存在样式功能逻辑判断 */}
                    {i18nLang === 'en-US' ? (
                        <>
                            {isEuEmpty ? (
                                <div className="customist-tip">{translate('settings.tipCp')}</div>
                            ) : (
                                <>
                                    {typeof driving_style_switch !== 'undefined' && (
                                        <DriveStyle isDisabled={!cpSwitch} />
                                    )}
                                    {typeof lane_change_style_switch !== 'undefined' && (
                                        <LaneChangeStyle isDisabled={!cpSwitch} />
                                    )}
                                    {/* 0625 EU CP合入主线更改 跟着主线的value走 */}
                                    {cp_autonomous_switch && eu_cp_autonomous && (
                                        <AutoLaneChangeSwitchEU isDisabled={!cpSwitch} />
                                    )}
                                    {/* <AutoLaneChangeSwitch isDisabled={!cpSwitch} />*/}
                                    {tfl_switch && <TFLSwitch isDisabled={!cpSwitch} />}
                                    {roundabout_switch && <RoundaboutSwitch isDisabled={!cpSwitch} />}
                                </>
                            )}
                        </>
                    ) : (
                        <>
                            {/*因为当前只有碰撞提示按钮，当没有碰撞提示按钮的信号时，显示"功能开发中，敬请期待"*/}
                            {!acc_cp_switch && !cp_warning_switch && (
                                <div className="customist-tip">{translate('settings.tipCp')}</div>
                            )}
                            {/* 11.1下线1214 */}
                            {/* <DriveStyle isDisabled={!cpSwitch} /> */}
                            {/* 12.30共版本中暂时下掉 */}
                            {/* <AutoLaneChangeSwitch isDisabled={!cpSwitch} /> */}
                            {/* {tfl_switch && <TFLSwitch isDisabled={!cpSwitch} />} */}
                            {/* 11.1下线1214 */}
                            {/* <LaneChangeStyle isDisabled={!cpSwitch} /> */}
                        </>
                    )}
                    {/*德国和国内都有的功能*/}
                    {acc_cp_switch && <AccCpTakeoverSwitch />}
                    {cp_warning_switch && <CpWarningSwitch isDisabled={!cpSwitch} />}
                </div>
            </div>
            {/* HNP */}
            <div className="switch-box">
                <div
                    className="main-swtich"
                    style={{ backgroundImage: `url(${themeColorState === 'black' ? npBlack : npWhite})` }}
                >
                    <div className="switch-type">HNP</div>
                    <div className="switch-title">{translate('settings.highwayNavigationPilot')}</div>
                    <Switch
                        checked={hnpSwitch}
                        onChange={(checked) => hnpSwitchChange(checked)}
                        className="common-switch"
                    />
                </div>
                <div className={`customise-content ${!hnpSwitch && 'is_disabled'}`}>
                    <div className="customise-title">{translate('settings.customizeHnp')}</div>
                    {confirmFunction !== 'hide_confirm' ? (
                        <>
                            <LaneChangeConfirm isDisabled={!hnpSwitch} />
                        </>
                    ) : (
                        <>
                            {/*{!switch_function_mode && (*/}
                            {/*    <div className="customist-tip">{translate('settings.tipCp')}</div>*/}
                            {/*)}*/}
                            {<div className="customist-tip">{translate('settings.tipCp')}</div>}
                            {/* 12.30共版本中暂时下掉确认式变道功能 */}
                            {/* <LaneChangeConfirm isDisabled={!npSwitch} /> */}
                        </>
                    )}
                    {/*{switch_function_mode && <FunctionModeSwitch isDisabled={!hnpSwitch} />}*/}
                    {/* 隐藏变道提示音 */}
                    {/* <LaneChangeBeep /> */}
                </div>
            </div>
            {/* UNP开关 */}
            {operate_drive_mode?.unp && (
                <div className="switch-box">
                    <div
                        className="main-swtich"
                        style={{ backgroundImage: `url(${themeColorState === 'black' ? npBlack : npWhite})` }}
                    >
                        <div className="switch-type">UNP</div>
                        <div className="switch-title">{translate('settings.urbanNavigationPilot')}</div>
                        <Switch
                            checked={unpSwitch}
                            onChange={(checked) => unpSwitchChange(checked)}
                            className="common-switch"
                        />
                    </div>
                    <div className="container-bottom">
                        <div className={`customise-content ${!unpSwitch && 'is_disabled'}`}>
                            <div className="customise-title">{translate('settings.customizeUnp')}</div>
                            <div className="customist-tip">{translate('settings.tipCp')}</div>
                        </div>
                    </div>
                </div>
            )}
            {/* 偏好车道设置 */}
            {operate_drive_mode?.hnp === 'ON' && preferred_lane_switch && <PreferredlaneCard />}
            {/*NP模式下DDLD/HDMAP热切换*/}
            {switch_function_mode && (
                <div className="switch-box">
                    <div className={`customise-content`} style={{ marginTop: 0, maxHeight: '3.06rem' }}>
                        <div className="customise-title">{translate('settings.customHotSwap')}</div>
                        <FunctionModeSwitch isDisabled={isDisabled} />
                    </div>
                </div>
            )}
        </div>
    );
}
