import React, { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Switch } from 'antd-mobile';
import store from '@/store';
import { HOT_AREA_TYPE } from '@/store/action/type';
import useLPNPSettingCommon from './hooks/lpnpHook.js';
import useMEBSettingCommon from './hooks/mebHook.js';
import usePTSSettingCommon from './hooks/ptsHook.js';
import useChassisSettingCommon from './hooks/chassisHook.js';
import usePedestriansSettingCommon from './hooks/pedestriansHook.js';
import useCPMSettingCommon from './hooks/cpmHook.js';
import useTracksFrontSettingCommon from './hooks/tracksFrontHook.js';
import useTracksRearSettingCommon from './hooks/tracksRearHook.js';
import useVehicleOutlineSettingCommon from './hooks/vehicleOutlineHook.js';
import useBumperLineSettingCommon from './hooks/bumperLineHook.js';
import useVehicleTransparencySettingCommon from './hooks/vehicleTransparencyHook.js';
import useSyntheticBackgroundSettingCommon from './hooks/syntheticBackgroundHook.js';
import closeD from '@/assets/parking/d_new/cancel_d.png';
import closeSR from '@/assets/parking/d_new/close.png';
import HMIMode from '@/components/slider/smartDrivingSetting/roadTestComponent/otherSettings/HMIMode';
import ArpSwitch from '../userSetting/arpSwitch/index';
import ArpAttributeSwitch from '../userSetting/arpAttributeSwitch/index';
import LightSwitch from '../userSetting/carLightSwitch/index';
import localCache from '@/utils/localCache/index.js';
import { useTranslation } from 'react-i18next';
import { SWITH_MODE_ACTION } from '@/store/action/type';
import './index.less';

export default function LPNPSetting() {
    const { t: translate } = useTranslation();
    const userMode = useSelector((state) => state?.parking?.userMode);
    const sceneRef = useRef(null);
    const mtour = useSelector((state) => state?.switchModeAction?.switchUnpState);
    const lpnp_switch = useSelector((state) => state?.featureModelParking?.switch?.lpnpSwitch);
    const meb_switch = useSelector((state) => state?.featureModelParking?.switch?.mebSwitch);

    const is_pts_data_back = useSelector((state) => state.FeatureModelAvm?.is_pts_data_back);
    const pts_switch = useSelector((state) => state?.FeatureModelAvm?.is_switch_on);
    const pts_mute = useSelector((state) => state?.FeatureModelAvm?.is_mute);
    const pts_side_protection = useSelector((state) => state?.FeatureModelAvm?.is_side_protection_on);
    const is_chassis_transparent = useSelector((state) => state?.FeatureModelAvm?.is_chassis_transparent);
    const pedestrians = useSelector((state) => state?.FeatureModelAvm?.pedestrians);
    const tireTracksFront = useSelector((state) => state?.FeatureModelAvm?.tireTracksFront);
    const tireTracksRear = useSelector((state) => state?.FeatureModelAvm?.tireTracksRear);
    const tireTracksVehicleOutline = useSelector((state) => state?.FeatureModelAvm?.tireTracksVehicleOutline);
    const tireTracksBumperLine = useSelector((state) => state?.FeatureModelAvm?.tireTracksBumperLine);
    const egoVehicleTransparency = useSelector((state) => state?.FeatureModelAvm?.egoVehicleTransparency);
    const showSyntheticBackground = useSelector((state) => state?.FeatureModelAvm?.showSyntheticBackground);
    const arp_switch_state = useSelector((state) => state?.featureModelParking?.switch?.arpSwitch);
    const arp_attribute_state = useSelector(
        (state) => state?.featureModelParking?.switch?.arpCollisionAvoidanceControlType,
    );
    const cpm_switch = useSelector((state) => state?.FeatureModelHmi?.cpm_switch);

    const [lpnpSwitchState, setLpnpSwitchState] = useState(lpnp_switch === 'OPEN');
    const [mebSwitchState, setMebSwitchState] = useState(meb_switch === 'OPEN');
    const [ptsSwitchState, setPtsSwitchState] = useState(pts_switch);
    const [ptsMuteState, setMuteSwitchState] = useState(pts_mute);
    const [ptsSideProtectionState, setPtsSideProtectionState] = useState(pts_side_protection);
    const [chassisTransparentState, setChassisTransparentState] = useState(is_chassis_transparent);
    const [pedestriansState, setPedestriansState] = useState(pedestrians ? true : false);
    const [cpmSwitchState, setCpmSwitchState] = useState(cpm_switch ? true : false);
    const [tireTracksFrontState, setTireTracksFrontState] = useState(tireTracksFront ? true : false);
    const [tireTracksRearState, setTireTracksRearState] = useState(tireTracksRear ? true : false);
    const [vehicleOutlineState, setVehicleOutlineState] = useState(tireTracksVehicleOutline ? true : false);
    const [bumperLineState, setBumperLineState] = useState(tireTracksBumperLine ? true : false);
    const [vehicleTransparencyState, setVehicleTransparencyState] = useState(egoVehicleTransparency ? true : false);
    const [syntheticBackgroundState, setSyntheticBackgroundState] = useState(showSyntheticBackground ? true : false);

    // TODO: 三个开关展示取决于cmd: avm_data是否有数据返回
    const { LPNPControl } = useLPNPSettingCommon();
    const { MEBControl } = useMEBSettingCommon();
    const { PTSControl } = usePTSSettingCommon();
    const { chassisTransparentControl } = useChassisSettingCommon();
    const { pedestriansControl } = usePedestriansSettingCommon();
    const { CPMControl } = useCPMSettingCommon();
    const { tracksFrontControl } = useTracksFrontSettingCommon();
    const { tracksRearControl } = useTracksRearSettingCommon();
    const { vehicleOutlineControl } = useVehicleOutlineSettingCommon();
    const { bumperLineControl } = useBumperLineSettingCommon();
    const { vehicleTransparencyControl } = useVehicleTransparencySettingCommon();
    const { syntheticBackgroundControl } = useSyntheticBackgroundSettingCommon();

    const dispatch = useDispatch();
    const timer = useRef(null);
    const timerMeb = useRef(null);
    const timerPts = useRef(null);
    const timerPtsMute = useRef(null);
    const timerPtsSideProtect = useRef(null);
    const timerChassisTransparent = useRef(null);
    const timerPedestrians = useRef(null);
    const timerCpm = useRef(null);

    const timerTracksFront = useRef(null);
    const timerTracksRear = useRef(null);
    const timerVehicleOutline = useRef(null);
    const timerBumperLine = useRef(null);
    const timerVehicleTransparency = useRef(null);
    const timerSyntheticBackground = useRef(null);
    const sceneArea = sceneRef?.current?.getBoundingClientRect();
    const [loading, setLoading] = useState(false);

    const [mebLoading, setMebLoading] = useState(false);
    const [ptsLoading, setPTSLoading] = useState(false);
    const [ptsMuteLoading, setPTSMuteLoading] = useState(false);
    const [ptsSideProtectLoading, setSideProtectLoading] = useState(false);
    const [chassisTransparentLoading, setChassisTransparentLoading] = useState(false);
    const [pedestriansLoading, setPedestriansLoading] = useState(false);
    const [cpmLoading, setCpmLoading] = useState(false);
    const [tracksFrontLoading, setTracksFrontLoading] = useState(false);
    const [tracksRearLoading, setTracksRearLoading] = useState(false);
    const [vehicleOutlineLoading, setVehicleOutlineLoading] = useState(false);
    const [bumperLineLoading, setBumperLineLoading] = useState(false);
    const [vehicleTransparencyLoading, setVehicleTransparencyLoading] = useState(false);
    const [syntheticBackgroundLoading, setSyntheticBackgroundLoading] = useState(false);

    useEffect(() => {
        lpnp_switch && setLpnpSwitchState(lpnp_switch === 'OPEN');
        if (timer.current) {
            clearTimeout(timer.current);
            timer.current = null;
        }
        setLoading(false);
    }, [lpnp_switch]);

    const switchLPNPChange = () => {
        clearTimeout(timer.current);
        setLoading(true);
        timer.current = setTimeout(() => {
            setLoading(false);
            setLpnpSwitchState(lpnp_switch === 'OPEN');
        }, 2 * 1000);
        LPNPControl(lpnpSwitchState ? 'CLOSE' : 'OPEN');
    };

    useEffect(() => {
        meb_switch && setMebSwitchState(meb_switch === 'OPEN');
        if (timerMeb.current) {
            clearTimeout(timerMeb.current);
            timerMeb.current = null;
        }
        setMebLoading(false);
    }, [meb_switch]);

    useEffect(() => {
        setPtsSwitchState(pts_switch ? true : false);
        if (timerPts.current) {
            clearTimeout(timerPts.current);
            timerPts.current = null;
        }
        setPTSLoading(false);
    }, [pts_switch]);

    useEffect(() => {
        setMuteSwitchState(pts_mute ? true : false);
        if (timerPtsMute.current) {
            clearTimeout(timerPtsMute.current);
            timerPtsMute.current = null;
        }
        setPTSMuteLoading(false);
    }, [pts_mute]);

    useEffect(() => {
        setPtsSideProtectionState(pts_side_protection ? true : false);
        if (timerPtsSideProtect.current) {
            clearTimeout(timerPtsSideProtect.current);
            timerPtsSideProtect.current = null;
        }
        setSideProtectLoading(false);
    }, [pts_side_protection]);

    useEffect(() => {
        setChassisTransparentState(is_chassis_transparent ? true : false);
        if (timerChassisTransparent.current) {
            clearTimeout(timerChassisTransparent.current);
            timerChassisTransparent.current = null;
        }
        setChassisTransparentLoading(false);
    }, [is_chassis_transparent]);

    useEffect(() => {
        setPedestriansState(pedestrians ? true : false);
        if (timerPedestrians.current) {
            clearTimeout(timerPedestrians.current);
            timerPedestrians.current = null;
        }
        setPedestriansLoading(false);
    }, [pedestrians]);

    useEffect(() => {
        cpm_switch && setCpmSwitchState(cpm_switch ? true : false);
        if (timerCpm.current) {
            clearTimeout(timerCpm.current);
            timerCpm.current = null;
        }
        setCpmLoading(false);
    }, [cpm_switch]);

    useEffect(() => {
        setTireTracksFrontState(tireTracksFront ? true : false);
        if (timerTracksFront.current) {
            clearTimeout(timerTracksFront.current);
            timerTracksFront.current = null;
        }
        setTracksFrontLoading(false);
    }, [tireTracksFront]);

    useEffect(() => {
        setTireTracksRearState(tireTracksRear ? true : false);
        if (timerTracksRear.current) {
            clearTimeout(timerTracksRear.current);
            timerTracksRear.current = null;
        }
        setTracksRearLoading(false);
    }, [tireTracksRear]);

    useEffect(() => {
        setVehicleOutlineState(tireTracksVehicleOutline ? true : false);
        if (timerVehicleOutline.current) {
            clearTimeout(timerVehicleOutline.current);
            timerVehicleOutline.current = null;
        }
        setVehicleOutlineLoading(false);
    }, [tireTracksVehicleOutline]);

    useEffect(() => {
        setBumperLineState(tireTracksBumperLine ? true : false);
        if (timerBumperLine.current) {
            clearTimeout(timerBumperLine.current);
            timerBumperLine.current = null;
        }
        setBumperLineLoading(false);
    }, [tireTracksBumperLine]);

    useEffect(() => {
        setVehicleTransparencyState(egoVehicleTransparency ? true : false);
        if (timerVehicleTransparency.current) {
            clearTimeout(timerVehicleTransparency.current);
            timerVehicleTransparency.current = null;
        }
        setVehicleTransparencyLoading(false);
    }, [egoVehicleTransparency]);

    useEffect(() => {
        setSyntheticBackgroundState(showSyntheticBackground ? true : false);
        if (timerSyntheticBackground.current) {
            clearTimeout(timerSyntheticBackground.current);
            timerSyntheticBackground.current = null;
        }
        setSyntheticBackgroundLoading(false);
    }, [showSyntheticBackground]);

    const switchMEBChange = () => {
        clearTimeout(timerMeb.current);
        setMebLoading(true);
        timerMeb.current = setTimeout(() => {
            setMebLoading(false);
            setMebSwitchState(meb_switch === 'OPEN');
        }, 2 * 1000);
        MEBControl(mebSwitchState ? 'CLOSE' : 'OPEN');
    };

    const switchPtsChange = () => {
        clearTimeout(timerPts.current);
        setPTSLoading(true);
        timerPts.current = setTimeout(() => {
            setPTSLoading(false);
            setPtsSwitchState(pts_switch);
        }, 2 * 1000);
        PTSControl(ptsSwitchState ? false : true, ptsMuteState, ptsSideProtectionState);
    };

    const switchPtsMuteChange = () => {
        clearTimeout(timerPtsMute.current);
        setPTSMuteLoading(true);
        timerPtsMute.current = setTimeout(() => {
            setPTSMuteLoading(false);
            setMuteSwitchState(pts_mute);
        }, 2 * 1000);
        PTSControl(ptsSwitchState, ptsMuteState ? false : true, ptsSideProtectionState);
    };

    const switchPtsSideProtectChange = () => {
        clearTimeout(timerPtsSideProtect.current);
        setSideProtectLoading(true);
        timerPtsSideProtect.current = setTimeout(() => {
            setSideProtectLoading(false);
            setPtsSideProtectionState(pts_mute);
        }, 2 * 1000);
        PTSControl(ptsSwitchState, ptsMuteState, ptsSideProtectionState ? false : true);
    };

    // chassisTransparentControl
    const switchChassisTransparentChange = () => {
        clearTimeout(timerChassisTransparent.current);
        setChassisTransparentLoading(true);
        timerChassisTransparent.current = setTimeout(() => {
            setChassisTransparentLoading(false);
            setChassisTransparentState(is_chassis_transparent);
        }, 2 * 1000);
        chassisTransparentControl(chassisTransparentState ? false : true);
    };

    // pedestrians
    const switchPedestriansChange = () => {
        clearTimeout(timerPedestrians.current);
        setPedestriansLoading(true);
        timerPedestrians.current = setTimeout(() => {
            setPedestriansLoading(false);
            setPedestriansState(pedestrians ? true : false);
        }, 2 * 1000);
        pedestriansControl(pedestriansState ? 0 : 1);
    };

    // CPM
    const switchCpmChange = () => {
        clearTimeout(timerCpm.current);
        setCpmLoading(true);
        timerCpm.current = setTimeout(() => {
            setCpmLoading(false);
            setCpmSwitchState(cpm_switch ? true : false);
        }, 2 * 1000);
        CPMControl(cpm_switch ? false : true);
    };
    // tracksFront
    const switchTracksFrontChange = () => {
        clearTimeout(timerTracksFront.current);
        setTracksFrontLoading(true);
        timerTracksFront.current = setTimeout(() => {
            setTracksFrontLoading(false);
            setTireTracksFrontState(tireTracksFront ? true : false);
        }, 2 * 1000);
        tracksFrontControl(tireTracksFrontState ? 0 : 1);
    };

    // tracksRear
    const switchTracksRearChange = () => {
        clearTimeout(timerTracksRear.current);
        setTracksRearLoading(true);
        timerTracksRear.current = setTimeout(() => {
            setTracksRearLoading(false);
            setTireTracksRearState(tireTracksRear ? true : false);
        }, 2 * 1000);
        tracksRearControl(tireTracksRearState ? 0 : 1);
    };

    // vehicleOutline
    const switchVehicleOutlineChange = () => {
        clearTimeout(timerVehicleOutline.current);
        setVehicleOutlineLoading(true);
        timerVehicleOutline.current = setTimeout(() => {
            setVehicleOutlineLoading(false);
            setVehicleOutlineState(tireTracksVehicleOutline ? true : false);
        }, 2 * 1000);
        vehicleOutlineControl(vehicleOutlineState ? 0 : 1);
    };

    // bumperLine
    const switchBumperLineChange = () => {
        clearTimeout(timerBumperLine.current);
        setBumperLineLoading(true);
        timerBumperLine.current = setTimeout(() => {
            setBumperLineLoading(false);
            setBumperLineState(tireTracksBumperLine ? true : false);
        }, 2 * 1000);
        bumperLineControl(bumperLineState ? 0 : 1);
    };

    // vehicleTransparency
    const switchVehicleTransparencyChange = () => {
        clearTimeout(timerVehicleTransparency.current);
        setVehicleTransparencyLoading(true);
        timerVehicleTransparency.current = setTimeout(() => {
            setVehicleTransparencyLoading(false);
            setVehicleTransparencyState(egoVehicleTransparency ? true : false);
        }, 2 * 1000);
        vehicleTransparencyControl(vehicleTransparencyState ? 0 : 1);
    };

    // syntheticBackground
    const switchSyntheticBackgroundChange = () => {
        clearTimeout(timerSyntheticBackground.current);
        setSyntheticBackgroundLoading(true);
        timerSyntheticBackground.current = setTimeout(() => {
            setSyntheticBackgroundLoading(false);
            setSyntheticBackgroundState(showSyntheticBackground ? true : false);
        }, 2 * 1000);
        syntheticBackgroundControl(syntheticBackgroundState ? 0 : 1);
    };

    const closeHandler = () => {
        store.dispatch({
            type: 'SetTab',
            data: '',
        });
    };

    useEffect(() => {
        const sceneAreaInfo = `${sceneArea?.x}, ${sceneArea?.y}, ${sceneArea?.width}, ${sceneArea?.height}`;
        dispatch({
            type: HOT_AREA_TYPE.sceneArea,
            data: sceneAreaInfo,
        });
        return () => {
            dispatch({
                type: HOT_AREA_TYPE.sceneArea,
                data: '',
            });
        };
    }, [sceneArea?.x, sceneArea?.y, sceneArea?.width, sceneArea?.height]);

    const isShowPts = (is_pts_data_back, userMode) => {
        return is_pts_data_back && userMode === 'D';
    };

    const isShowChassis = (is_chassis_transparent, userMode) => {
        return typeof is_chassis_transparent !== 'undefined' && userMode === 'D';
    };

    const isPedestrians = (pedestrians, userMode) => {
        return typeof pedestrians !== 'undefined' && userMode === 'D';
    };

    const isTracksFront = (tireTracksFront, userMode) => {
        return typeof tireTracksFront !== 'undefined' && userMode === 'D';
    };

    const isTracksRear = (tireTracksRear, userMode) => {
        return typeof tireTracksRear !== 'undefined' && userMode === 'D';
    };

    const isTracksVehicleOutline = (tireTracksVehicleOutline, userMode) => {
        return typeof tireTracksVehicleOutline !== 'undefined' && userMode === 'D';
    };

    const isTracksBumperLine = (tireTracksBumperLine, userMode) => {
        return typeof tireTracksBumperLine !== 'undefined' && userMode === 'D';
    };

    const isVehicleTransparency = (egoVehicleTransparency, userMode) => {
        return typeof egoVehicleTransparency !== 'undefined' && userMode === 'D';
    };

    const isSyntheticBackground = (showSyntheticBackground, userMode) => {
        return typeof showSyntheticBackground !== 'undefined' && userMode === 'D';
    };

    const isShowArp = (arp_switch_state, userMode) => {
        return arp_switch_state && userMode === 'D';
    };

    const isShowArpAttribute = (arp_attribute_state, userMode) => {
        return arp_attribute_state && userMode === 'D';
    };

    return (
        <div ref={sceneRef} className="lpnp-setting-page">
            <div className={`lpnp-setting-content ${userMode == 'D' ? 'd' : 'sr'}`}>
                <div className="lpnp-setting-close">
                    <img onClick={() => closeHandler()} src={userMode == 'D' ? closeD : closeSR} alt="close" />
                </div>

                <div className={`lpnp-setting-item ${userMode == 'D' ? 'd' : 'sr'}`}>
                    <Switch
                        checked={lpnpSwitchState}
                        loading={loading}
                        onChange={(checked) => switchLPNPChange(checked)}
                        className={'customSwitch'}
                    />
                    <span>记忆泊车领航辅助驾驶 LPNP</span>
                </div>

                <div className={`lpnp-setting-item ${userMode == 'D' ? 'd' : 'sr'}`}>
                    <Switch
                        loading={mebLoading}
                        checked={mebSwitchState}
                        onChange={(checked) => switchMEBChange(checked)}
                        className={'customSwitch'}
                    />
                    <span>{translate('parking.setting.meb')}</span>
                </div>

                {userMode === 'D' && (
                    <div className={`lpnp-setting-item ${userMode == 'D' ? 'd' : 'sr'}`}>
                        <Switch
                            checked={mtour}
                            onChange={() => {
                                localCache.set('unp_switch', !mtour);
                                store.dispatch({
                                    type: SWITH_MODE_ACTION.switchUnpState,
                                    data: !mtour,
                                });
                            }}
                            className={'customSwitch'}
                        />
                        <span>LPNP MTOUR</span>
                    </div>
                )}

                {isShowPts(is_pts_data_back, userMode) && (
                    <div className={`lpnp-setting-item ${userMode == 'D' ? 'd' : 'sr'}`}>
                        <Switch
                            loading={ptsLoading}
                            checked={ptsSwitchState}
                            onChange={(checked) => switchPtsChange(checked)}
                            className={'customSwitch'}
                        />
                        <span>{translate('parking.setting.PTS_SWITCH')}</span>
                    </div>
                )}
                {isShowPts(is_pts_data_back, userMode) && (
                    <div className={`lpnp-setting-item ${userMode == 'D' ? 'd' : 'sr'}`}>
                        <Switch
                            loading={ptsMuteLoading}
                            checked={ptsMuteState}
                            onChange={(checked) => switchPtsMuteChange(checked)}
                            className={'customSwitch'}
                        />
                        <span>{translate('parking.setting.PTS_MUTE')}</span>
                    </div>
                )}
                {isShowPts(is_pts_data_back, userMode) && (
                    <div className={`lpnp-setting-item ${userMode == 'D' ? 'd' : 'sr'}`}>
                        <Switch
                            loading={ptsSideProtectLoading}
                            checked={ptsSideProtectionState}
                            onChange={(checked) => switchPtsSideProtectChange(checked)}
                            className={'customSwitch'}
                        />
                        <span>{translate('parking.setting.PTS_SIDE_PROTECTION')}</span>
                    </div>
                )}
                {isShowChassis(is_chassis_transparent, userMode) && (
                    <div className={`lpnp-setting-item ${userMode == 'D' ? 'd' : 'sr'}`}>
                        <Switch
                            loading={chassisTransparentLoading}
                            checked={chassisTransparentState}
                            onChange={(checked) => switchChassisTransparentChange(checked)}
                            className={'customSwitch'}
                        />
                        <span>{translate('parking.setting.chassisTransparent')}</span>
                    </div>
                )}
                {isPedestrians(pedestrians, userMode) && (
                    <div className={`lpnp-setting-item ${userMode == 'D' ? 'd' : 'sr'}`}>
                        <Switch
                            loading={pedestriansLoading}
                            checked={pedestriansState}
                            onChange={(checked) => switchPedestriansChange(checked)}
                            className={'customSwitch'}
                        />
                        <span>{translate('parking.setting.pedestrians')}</span>
                    </div>
                )}
                {isTracksFront(tireTracksFront, userMode) && (
                    <div className={`lpnp-setting-item ${userMode == 'D' ? 'd' : 'sr'}`}>
                        <Switch
                            loading={tracksFrontLoading}
                            checked={tireTracksFrontState}
                            onChange={(checked) => switchTracksFrontChange(checked)}
                            className={'customSwitch'}
                        />
                        <span>{translate('parking.setting.tireTracksFront')}</span>
                    </div>
                )}
                {isTracksRear(tireTracksRear, userMode) && (
                    <div className={`lpnp-setting-item ${userMode == 'D' ? 'd' : 'sr'}`}>
                        <Switch
                            loading={tracksRearLoading}
                            checked={tireTracksRearState}
                            onChange={(checked) => switchTracksRearChange(checked)}
                            className={'customSwitch'}
                        />
                        <span>{translate('parking.setting.tireTracksRear')}</span>
                    </div>
                )}
                {isTracksVehicleOutline(tireTracksVehicleOutline, userMode) && (
                    <div className={`lpnp-setting-item ${userMode == 'D' ? 'd' : 'sr'}`}>
                        <Switch
                            loading={vehicleOutlineLoading}
                            checked={vehicleOutlineState}
                            onChange={(checked) => switchVehicleOutlineChange(checked)}
                            className={'customSwitch'}
                        />
                        <span>{translate('parking.setting.tireTracksVehicleOutline')}</span>
                    </div>
                )}
                {isTracksBumperLine(tireTracksBumperLine, userMode) && (
                    <div className={`lpnp-setting-item ${userMode == 'D' ? 'd' : 'sr'}`}>
                        <Switch
                            loading={bumperLineLoading}
                            checked={bumperLineState}
                            onChange={(checked) => switchBumperLineChange(checked)}
                            className={'customSwitch'}
                        />
                        <span>{translate('parking.setting.tireTracksBumperLine')}</span>
                    </div>
                )}
                {isVehicleTransparency(egoVehicleTransparency, userMode) && (
                    <div className={`lpnp-setting-item ${userMode == 'D' ? 'd' : 'sr'}`}>
                        <Switch
                            loading={vehicleTransparencyLoading}
                            checked={vehicleTransparencyState}
                            onChange={(checked) => switchVehicleTransparencyChange(checked)}
                            className={'customSwitch'}
                        />
                        <span>{translate('parking.setting.egoVehicleTransparency')}</span>
                    </div>
                )}

                {isSyntheticBackground(showSyntheticBackground, userMode) && (
                    <div className={`lpnp-setting-item ${userMode == 'D' ? 'd' : 'sr'}`}>
                        <Switch
                            loading={syntheticBackgroundLoading}
                            checked={syntheticBackgroundState}
                            onChange={(checked) => switchSyntheticBackgroundChange(checked)}
                            className={'customSwitch'}
                        />
                        <span>{translate('parking.setting.showSyntheticBackground')}</span>
                    </div>
                )}

                <div className={`lpnp-setting-item ${userMode == 'D' ? 'd' : 'sr'}`}>
                    <Switch
                        loading={cpmLoading}
                        checked={cpmSwitchState}
                        onChange={(checked) => switchCpmChange(checked)}
                        className={'customSwitch'}
                    />
                    <span>{translate('parking.setting.cpm')}</span>
                </div>

                <LightSwitch />

                <HMIMode parking />
                {/* {isShowArp(arp_switch_state, userMode) && <ArpSwitch parking />}
                {isShowArpAttribute(arp_attribute_state, userMode) && <ArpAttributeSwitch parking />} */}
            </div>
        </div>
    );
}
