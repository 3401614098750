import { useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { modalQueue, modalType } from '@/utils/modalQueue';
import HEIGHT_UNPASSABLE from '@/assets/hnp/adas/NRP/height_unpassable.png';
import WIDTH_UNPASSABLE from '@/assets/hnp/adas/NRP/width_unpassable.png';
import takeOverImg from '@/assets/parking/adas/icon_takeover.png';
import { AppFn } from '@/utils/native';
import localAudioNames from '@/constant/localAudioName';
import { useTranslation } from 'react-i18next';
import { fisrtUpper } from '../../main/lpnp/parkingTips/fn';

// 前方路况变化，语音提醒用户谨慎驾驶
const reminder = {
    HEIGHT_PASSABLE: 'HEIGHT_PASSABLE',
    WIDTH_PASSABLE_UNCONTROLLABLE: 'WIDTH_PASSABLE_UNCONTROLLABLE',
};
// 危险警告 ---> 既有语音提醒，又有弹窗提醒
const dangerWarningImg = {
    WIDTH_UNPASSABLE: WIDTH_UNPASSABLE, // 前方狭窄路段不可通行
    HEIGHT_UNPASSABLE: HEIGHT_UNPASSABLE, // 请注意前方高度不可通行
};

const showModal = ['COMPLETE', 'MRM'];
const modalTextMap = {
    COMPLETE: 'Automatic passage completed',
    MRM: 'Automatic passage finished. Please take control',
};
const modalShowDuration = {
    COMPLETE: 5000,
    MRM: 3000,
};

export function useNRP() {
    const { t: translate } = useTranslation();
    const NRPDangerWarningText = translate('settings.NRPDangerWarningText', { returnObjects: true });
    const noticeData = useSelector((state) => state?.featureModelParking?.alert?.data);
    const noticeType = useSelector((state) => state?.featureModelParking?.alert?.type);
    const noticeLevel = useSelector((state) => state?.featureModelParking?.alert?.level);
    const nrpWidget = useSelector((state) => state?.featureModelParking?.widget?.nrp);
    const timer = useRef(null);
    // 一期功能
    useEffect(() => {
        if (noticeType !== 'nrp_notice') return;
        const nrp_notice_mode = noticeData?.mode;
        const reminderList = Object.keys(reminder);
        if (reminderList.includes(nrp_notice_mode)) {
            // 语音播放逻辑
            AppFn.playLocalAudio(localAudioNames?.[nrp_notice_mode], 'Important');
            return;
        }

        const dangerWarningList = Object.keys(dangerWarningImg);
        if (dangerWarningList.includes(nrp_notice_mode)) {
            clearTimeout(timer.current);
            // 语音播放逻辑
            AppFn.playLocalAudio(localAudioNames?.[nrp_notice_mode], 'Important');
            // 弹窗展示逻辑
            modalQueue({
                type: modalType.NRP,
                modalData: { img: dangerWarningImg[nrp_notice_mode], text: NRPDangerWarningText[nrp_notice_mode] },
            });
        } else {
            // 弹窗销毁逻辑
            timer.current = setTimeout(() => {
                modalQueue({ type: modalType.NRP, modalData: null });
            }, 500);
        }
    }, [nrpWidget?.state, noticeData?.mode]);

    // 二期功能
    const modalTimer = useRef();
    useEffect(() => {
        if (noticeType !== 'nrp_notice') return;
        const nrpState = noticeData?.state;
        if (!showModal.includes(nrpState)) return;
        clearTimeout(modalTimer.current);
        AppFn.playLocalAudio(localAudioNames?.[`NRP_${nrpState}`], fisrtUpper(noticeLevel));
        modalQueue({
            type: modalType.PARKING_NRP,
            modalData: { img: takeOverImg, text: modalTextMap[nrpState] },
        });
        modalTimer.current = setTimeout(() => {
            modalQueue({ type: modalType.PARKING_NRP, modalData: null });
        }, modalShowDuration[nrpState]);
    }, [noticeType, noticeData?.state]);

    const nrpIconStatus = useMemo(() => {
        const isShow = nrpWidget?.state && nrpWidget.state !== 'OFF';
        const disabled = nrpWidget?.state === 'NOT_READY';
        return { isShow, disabled };
    }, [nrpWidget?.state]);

    return { nrpIconStatus };
}
