import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Switch } from 'antd-mobile';
import CardLayout from '../../../components/cardLayout';
import { useSelector } from 'react-redux';
import { SocketMap } from '@/constant/socketMap';
import FM from '@/utils/nativeSubAndPub';
import '@/components/slider/smartDrivingSetting/components/cardStyle/index.less';
import { singelPointReport } from '@/utils/report';
import reportEventName from '@/constant/reportEventName';

let timer = null;
export default function SlifHdMapCard() {
    const { t: translate } = useTranslation();
    const slif_hdmap_switch = useSelector((state) => state?.featureModelDriving?.switch?.adas?.slif?.slif_hdmap_switch);
    const [slifHdmapSwitch, setSlifHdmapSwitch] = useState(slif_hdmap_switch === 'OPEN');

    useEffect(() => {
        slif_hdmap_switch && setSlifHdmapSwitch(slif_hdmap_switch === 'OPEN');
        if (timer) {
            clearTimeout(timer);
            timer = null;
        }
    }, [slif_hdmap_switch]);

    // 数据上报
    const reportDataFunc = (v) => {
        singelPointReport({
            eventName: reportEventName.HMI_SLIF_HDMAP_SWITCH_CLICK,
            timestamp: new Date().getTime(),
            text: v,
        });
    };

    // swtich: SLIF HD Map 按钮
    const switchSlifHdMapChange = (value) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            setSlifHdmapSwitch(slif_hdmap_switch === 'OPEN');
        }, 2 * 1000);
        reportDataFunc(value ? 'ON' : 'OFF');
        slifHdMapControl(value);
    };

    // 通知底层：ihc
    const slifHdMapControl = async (value) => {
        FM.sendToHMIDriving(
            SocketMap.adas_slif_hdmap_control,
            {
                slif_hdmap_switch: value ? 'OPEN' : 'CLOSE',
            },
            'drive_node_res_fail_cb',
        );
    };

    const leftDom = (
        <div className={'left_dom_wrap'}>
            <div className={'switch_wrap'}>
                <div className={'content_wrap'}>
                    <div className={'text_wrap'}>SLIF HD Map</div>
                    {/*<div className={'text_wrap_detail'} style={{ whiteSpace: 'pre-wrap', opacity: 0 }}>*/}
                    {/*    占位文字*/}
                    {/*</div>*/}
                </div>
                <Switch
                    checked={slifHdmapSwitch}
                    onChange={(checked) => switchSlifHdMapChange(checked)}
                    className={'customSwitch'}
                />
            </div>
        </div>
    );

    const rightDom = <div className={'right_dom_wrap'} />;

    return (
        <>
            <CardLayout leftDom={leftDom} rightDom={rightDom} value={slifHdmapSwitch} />
        </>
    );
}
