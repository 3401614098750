import React from 'react';
import { useSelector } from 'react-redux';
import MtourMode from './mtourMode';
import NavigationTips from './navigationTips';
import RoadTestCollection from './roadTestCollection';
import FollowCarVoiceCard from './followCarVoiceCard';
import DrivingBroadcast from './drivingBroadcast';
import LastLaneChangeCard from './lastLaneChangeCard';
import VoiceAssistant from './Assistant';
import localCache from '@/utils/localCache';

import './index.less';

export default function BroadcastMode() {
    // let i18nLang = window.localStorage.getItem("i18nLang");
    const i18nLang = useSelector((state) => state?.themeAction?.i18nState);
    return (
        <React.Fragment>
            <div className={'broadcast_mode_wrap'}>
                {/*<div className={"left_wrap"}>*/}
                {/*    <MtourMode/>*/}
                {/*    <DrivingBroadcast></DrivingBroadcast>*/}
                {/*    <LastLaneChangeCard></LastLaneChangeCard>*/}
                {/*</div>*/}

                {/*<div className={"right_wrap"}>*/}
                {/*    {*/}
                {/*        i18nLang !== 'en-US' &&*/}
                {/*        <>*/}
                {/*            <NavigationTips/>*/}
                {/*            <RoadTestCollection />*/}
                {/*        </>*/}
                {/*    }*/}
                {/*    /!* 1107下掉跟车时距语音提醒功能 *!/*/}
                {/*    /!* <FollowCarVoiceCard /> *!/*/}
                {/*</div>*/}

                <MtourMode />
                <DrivingBroadcast></DrivingBroadcast>
                <LastLaneChangeCard></LastLaneChangeCard>
                {i18nLang !== 'en-US' && (
                    <>
                        <NavigationTips />
                        <RoadTestCollection />
                        <VoiceAssistant />
                    </>
                )}
            </div>
        </React.Fragment>
    );
}
