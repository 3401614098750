import { QuestionCircleOutline, RedoOutline } from 'antd-mobile-icons';
import React, { useState, useEffect } from 'react';

import { getTaskMileage } from '../api';

import eventBus from '@/utils/eventBus';
import eventMap from '../eventMap';
import { useBoxid } from '../hook';
import { useTranslation } from 'react-i18next';

export default React.memo(function DistanceInfo() {
    const { t: translate } = useTranslation();
    const unit = 'KM';
    const box_id = useBoxid();
    const [loading, setLoading] = useState(false);
    const [mileageData, setMileageData] = useState({
        // task_total: 1,
        // task_laps_nums: 1,
        // car_total: 1,
        // driver_total: 1,
    });
    // tooltips 事件
    useEffect(() => {
        let isVisible = false;
        let trigger = document.getElementById('trigger');
        let popover = document.getElementById('popover');

        function showPopover() {
            isVisible = true;
            popover.classList.add('show');
        }

        function hidePopover() {
            isVisible = false;
            popover.classList.remove('show');
        }

        function togglePopover() {
            if (isVisible) {
                hidePopover();
            } else {
                showPopover();
            }
        }

        trigger.addEventListener('click', function (e) {
            e.stopPropagation();
            togglePopover();
        });
        document.addEventListener('click', function (e) {
            if (isVisible && e.target !== popover && e.target.parentElement !== popover) {
                hidePopover();
            }
        });
    }, []);

    //获取里程
    const getTaskMileageFun = () => {
        if (loading) {
            return;
        }
        setLoading(true);
        getTaskMileage({ task_id: '', device_box_id: box_id })
            .then((res) => {
                setLoading(false);
                if (res.code === 200) {
                    setMileageData(res.data);
                }
            })
            .catch((e) => {
                setLoading(false);
                console.log(e);
            });
    };
    //里程数据获取
    useEffect(() => {
        eventBus.on(eventMap.refreshMileageDataByTaskDetail, getTaskMileageFun);
        getTaskMileageFun();
        const timer = setInterval(async () => {
            await getTaskMileageFun();
        }, 20 * 60 * 1000);
        return () => {
            eventBus.off(eventMap.refreshMileageDataByTaskDetail);
            clearInterval(timer);
        };
    }, []);
    return (
        <div className="work-bench-base">
            <div className="header-content">
                <h4 style={{ marginBottom: '0' }}>{translate('workBench.mileageInfo')}</h4>
                <div>
                    <RedoOutline
                        className={`icon-refresh ${loading ? 'loading' : ''}`}
                        onClick={() => {
                            getTaskMileageFun();
                            eventBus.emit(eventMap.refreshMileageDataByDistanceInfo);
                        }}
                    />
                    <QuestionCircleOutline id="trigger" className="icon-content" />
                    <div id="popover" className="tooltip top">
                        <p>{translate('workBench.mileageTip')}</p>
                        <div className="arrow"></div>
                    </div>
                </div>
            </div>
            <div className="work-bench-base-content-distance">
                <div className="work-bench-base-content-distance-item">
                    <span>{translate('workBench.drivingMileage')}</span>
                    <span>{mileageData?.task_total ? mileageData?.task_total?.toFixed(2) + unit : '--'}</span>
                </div>
                {/* <div className="work-bench-base-content-distance-item">
                    <span>{translate('workBench.drivingCircles')}</span>
                    <span>
                        {mileageData?.task_laps_nums
                            ? mileageData?.task_laps_nums + translate('workBench.drivingCirclesUnit')
                            : '--'}
                    </span>
                </div> */}
                <div className="work-bench-base-content-distance-item">
                    <span>{translate('workBench.vehicleMileage')}</span>
                    <span>{mileageData?.car_total ? mileageData?.car_total?.toFixed(2) + unit : '--'}</span>
                </div>
                <div className="work-bench-base-content-distance-item">
                    <span>{translate('workBench.driverMileage')}</span>
                    <span>{mileageData?.driver_total ? mileageData?.driver_total?.toFixed(2) + unit : '--'}</span>
                </div>
            </div>
        </div>
    );
});
