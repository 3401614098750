import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { SWITH_MODE_ACTION } from '@/store/action/type';
import { singelPointReport } from '@/utils/report';
import reportEventName from '@/constant/reportEventName';
import localCache from '@/utils/localCache';

export default function DrivingBroadcast() {
    const { t: translate } = useTranslation();
    const dispatch = useDispatch();
    const [checkedPlay, setCheckedPlay] = useState(null);
    const switchDrivingBroadcast = useSelector((state) => state?.switchModeAction?.switchDrivingBroadcast);
    const drivingBroadcastOptions = translate('settings.drivingBroadcastOptions', { returnObjects: true });

    useEffect(() => {
        localCache.set('driving_broadcast_switch', switchDrivingBroadcast);
        handleTap(switchDrivingBroadcast);
    }, []);

    const handleTap = (value) => {
        if (value === checkedPlay) {
            return;
        }
        setCheckedPlay(value);
        //（1228产品需求变动添加缓存）
        // localStorage.setItem("driving_broadcast_switch",value);
        localCache.set('driving_broadcast_switch', value);
        dispatch({
            type: SWITH_MODE_ACTION.switchDrivingBroadcast,
            data: value,
        });
        singelPointReport({
            eventName: reportEventName.HMI_AD_ALERT_CLICK,
            text: switchDrivingBroadcast,
            timestamp: new Date().getTime(),
        });
    };

    return (
        <div className={'navigation_tips_card_wrap'}>
            <div className={'title_wrap'}>{translate('settings.drivingBroadcastTitle')}</div>
            <div className={'sensitivity_wrap'}>
                <div className={'select_box'}>
                    {drivingBroadcastOptions.length &&
                        drivingBroadcastOptions.map((item, index) => {
                            return (
                                <span
                                    key={index}
                                    className={`select_box_item ${checkedPlay === item.value ? 'active' : ''}`}
                                    style={{ width: '2.5rem' }}
                                    onClick={() => handleTap(item.value)}
                                >
                                    {item.label}
                                </span>
                            );
                        })}
                </div>
            </div>
        </div>
    );
}
