import { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { AppFn } from '@/utils/native';
import { singelPointReport } from '@/utils/report';

const LimitedMap = {
    APA_SELECTING: 1,
    APA_PARKING: 1,
    APA_PARKING_PAUSE: 1,
    // lpnp_start_nearby_searching: 1,
};

//开启和继续的单独处理
const nearbyAlertMap = {
    lpnp_start_nearby_searching: {
        voice: 'nearby_apa_searching.mp3', //开始沿途找车位
    },
    lpnp_cancel_nearby_searching: {
        voice: 'nearby_change_lpnp.mp3', //切换到lpnp音效的语音
    },
    //继续巡航找车位
    lpnp_resume_nearby_searching: {
        voice: 'lpnp_resume_nearby_searching.mp3',
    },
    lpnp_nearby_parking_exit: {
        voice: 'lpnp_parking_fail.mp3',
    },
    lpnp_nearby_parking_fail: {
        // text: '', //LPNP is deactivated
        voice: 'lpnp_parking_fail.mp3',
    },
    lpnp_nearby_parking_succ: {
        // text: '', //'泊车完成'Parking In is Completed
        voice: 'lpnp_nearby_parking_succ.mp3',
    },
};

// 打点上报映射
const ReportMap = {
    lpnp_resume_nearby_searching: {
        eventName: 'HMI_parking_on_the_way_resume_search_view',
        text: 'HMI_parking_on_the_way_resume_search_view',
    },
    APA_SELECTING: {
        eventName: 'HMI_parking_on_the_way_found_spots_view',
        text: 'HMI_parking_on_the_way_found_spots_view',
    },
    NEARBY_SEARCHING: {
        eventName: 'HMI_apa_searching_overspeed_view',
        text: 'HMI_parking_on_the_way_start_view',
    },
    lpnp_nearby_parking_fail: {
        eventName: 'HMI_parking_on_the_way_failed_view',
        text: 'HMI_parking_on_the_way_failed_view',
    },
};
export default function useParkingApaTips() {
    const noticeType = useSelector((state) => state?.featureModelParking?.alert?.type);
    const noticeLevel = useSelector((state) => state?.featureModelParking?.alert?.level);
    // 重置次数
    const reSetApaNums = () => {
        Object.keys(LimitedMap).map((key) => (LimitedMap[key] = 1));
    };
    //播放语音
    const apaPlayLocalAudio = (name, noticeLevel) => {
        AppFn.playLocalAudio(name, noticeLevel);
    };

    //根据消息类型，上报各阶段埋点
    const reportHandle = (name, text) => {
        singelPointReport({
            eventName: name,
            timestamp: new Date().getTime(),
            text: text,
        });
    };
    //转译级别
    const fisrtUpper = (str) => {
        return str?.slice(0, 1).toUpperCase() + str?.slice(1).toLowerCase();
    };

    useEffect(() => {
        // nearbyAlertMap[noticeType]?.text && setAlertTip(nearbyAlertMap[noticeType].text);
        ReportMap[noticeType] && reportHandle(ReportMap[noticeType]?.eventName, ReportMap[noticeType]?.text);
        if (LimitedMap[noticeType]) {
            if (LimitedMap[noticeType] === 1) {
                apaPlayLocalAudio(nearbyAlertMap[noticeType].voice, fisrtUpper(noticeLevel));
                LimitedMap[noticeType] = LimitedMap[noticeType] + 1;
            }
        } else {
            nearbyAlertMap[noticeType]?.voice &&
                apaPlayLocalAudio(nearbyAlertMap[noticeType].voice, fisrtUpper(noticeLevel));
        }
        //成功或者失败或使出地下停车场后重置次数
        if (['lpnp_exit_park', 'lpnp_nearby_parking_succ', 'lpnp_nearby_parking_fail'].includes(noticeType)) {
            reSetApaNums();
        }
    }, [noticeType]);
}
