import { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { HOT_AREA_TYPE } from '@/store/action/type';
import { useTranslation } from 'react-i18next';
import DrivingAssistSetting from './drivingAssistSetting';
import RoadTestComponent from './roadTestComponent';
import HeadToast from './components/headToast';

import './index.less';

export default function SettingCom(props) {
    const { t: translate } = useTranslation();
    const dispatch = useDispatch();

    // 选中的tab
    const [checkedTab, setCheckedTab] = useState('');
    // tab页数据
    const settingTabOptions = translate('settings.settingTabOptions', { returnObjects: true });

    // 热区相关
    const settingModalDom = useRef(null);
    const area = useSelector((state) => state?.layout?.Area);
    const switchMvizState = useSelector((state) => state?.switchModeAction?.switchMvizState);

    useEffect(() => {
        const settingModalArea = settingModalDom?.current?.getBoundingClientRect();
        const settingModalInfo = `${settingModalArea?.x}, ${settingModalArea?.y}, ${settingModalArea?.width}, ${settingModalArea?.height}`;
        typeof settingModalArea !== 'undefined' &&
            dispatch({
                type: HOT_AREA_TYPE.settingModalDom,
                data: settingModalInfo,
            });
    }, [area?.isHorizontal, settingModalDom?.current?.getBoundingClientRect()?.width, switchMvizState]);

    useEffect(() => {
        handleTab(settingTabOptions[0]?.value);
    }, []);

    // 点击关闭按钮触发回调并关闭弹窗
    const handleCancel = () => {
        props.onCancel('close');
        window.momentaJsBridge.dispatch('changeDfdiWebView', null, 'close');
        const settingModalArea = settingModalDom?.current?.getBoundingClientRect();
        typeof settingModalArea !== 'undefined' &&
            dispatch({
                type: HOT_AREA_TYPE.settingModalDom,
                data: '',
            });
    };

    // 点击tab切换组件
    const handleTab = (value) => {
        console.log(value);
        setCheckedTab(value);
    };

    useEffect(() => {
        let list = document.querySelector('.modal-box-right');
        if (list) {
            list.scrollTop = 0;
        }
    }, [checkedTab]);

    return (
        <div className="setting-modal-mask" ref={settingModalDom}>
            <div className="setting-modal-bg" onClick={() => handleCancel()}></div>
            <div className="setting-modal-box">
                <HeadToast />
                <div className="modal-box-left">
                    <div className="close-button-group">
                        <div className="iconfont close" onClick={() => handleCancel()}>
                            {'\ue621'}
                        </div>
                        <span className="modal-title">{translate('sideBar.adSettings')}</span>
                    </div>
                    <div className="setting-modal-tabs">
                        {settingTabOptions.length &&
                            settingTabOptions.map((item, index) => (
                                <div
                                    key={index}
                                    className={`tab-item ${checkedTab === item.value ? 'active' : ''}`}
                                    onClick={() => handleTab(item.value)}
                                >
                                    {item.label}
                                </div>
                            ))}
                    </div>
                </div>
                <div className="modal-box-right">
                    {/* 两个大组件 */}
                    {checkedTab === '1' && <DrivingAssistSetting />}
                    {checkedTab === '2' && <RoadTestComponent />}
                </div>
            </div>
        </div>
    );
}
