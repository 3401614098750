import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Switch } from 'antd-mobile';
import CardLayout from '../../../components/cardLayout';
import nrp from '@/assets/settingComNew/activeSafety/nrp.png';
import { useSelector } from 'react-redux';
import { SocketMap } from '@/constant/socketMap';
import FM from '@/utils/nativeSubAndPub';
import '@/components/slider/smartDrivingSetting/components/cardStyle/index.less';
import { singelPointReport } from '@/utils/report';
import reportEventName from '@/constant/reportEventName';

let timer = null;
export default function NrpCard() {
    const i18nLang = useSelector((state) => state?.themeAction?.i18nState);
    const { t: translate } = useTranslation();
    const nrp_switch = useSelector((state) => state?.featureModelDriving?.switch?.adas?.nrp_switch);
    const [nrpSwitch, setNrpSwitch] = useState(nrp_switch === 'OPEN');

    useEffect(() => {
        nrp_switch && setNrpSwitch(nrp_switch === 'OPEN');
        if (timer) {
            clearTimeout(timer);
            timer = null;
        }
    }, [nrp_switch]);

    // switch: NRP
    const switchNrpChange = (value) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            setNrpSwitch(nrp_switch === 'OPEN');
        }, 2 * 1000);
        NrpControl(value);
        // 数据上报
        reportDataFunc(value);
    };

    // 通知底层：Nrp
    const NrpControl = async (value) => {
        FM.sendToHMIDriving(
            SocketMap.adas_nrp_control,
            {
                nrp_switch: value ? 'OPEN' : 'CLOSE',
            },
            'drive_node_res_fail_cb',
        );
    };
    // 数据上报
    const reportDataFunc = (value) => {
        singelPointReport({
            eventName: reportEventName.HMI_HRP_SWITCH_CLICK,
            timestamp: new Date().getTime(),
            reportData: {
                nrp_switch: value ? 'OPEN' : 'CLOSE',
            },
        });
    };

    const leftDom = (
        <div className={'left_dom_wrap'}>
            <div className={'switch_wrap'}>
                <div className={'content_wrap'} style={{ marginBottom: i18nLang !== 'en-US' ? '.14' : '.3rem' }}>
                    <div className={'text_wrap'} style={{ paddingTop: '0' }}>
                        NRP
                    </div>
                    <div className={'text_wrap_detail'}>{translate('settings.NRPSwitch')}</div>
                    <div
                        className={'text_wrap_detail'}
                        style={{ color: 'rgba(255,48,30,0.8)', whiteSpace: i18nLang !== 'en-US' ? 'none' : 'pre-wrap' }}
                    >
                        {translate('settings.testRecommended')}
                    </div>
                </div>
                <Switch
                    checked={nrpSwitch}
                    onChange={(checked) => switchNrpChange(checked)}
                    className={'customSwitch'}
                />
            </div>
        </div>
    );

    const rightDom = (
        <div className={'right_dom_wrap'}>
            <img src={nrp} />
        </div>
    );

    return (
        <>
            <CardLayout leftDom={leftDom} rightDom={rightDom} value={nrpSwitch} />
        </>
    );
}
