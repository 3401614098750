import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Switch } from 'antd-mobile';
import CardLayout from '../../../components/cardLayout';
import { useSelector, useDispatch } from 'react-redux';
import { SWITH_MODE_ACTION } from '@/store/action/type';
import mtour from '@/assets/settingComNew/otherSettings/mtour.png';
import '@/components/slider/smartDrivingSetting/components/cardStyle/index.less';
import localCache from '@/utils/localCache';
import { SocketMap } from '@/constant/socketMap';
import FM from '@/utils/nativeSubAndPub';

export default function MtourMode() {
    const { t: translate } = useTranslation();
    const dispatch = useDispatch();
    const switchUnpState = useSelector((state) => state?.switchModeAction?.switchUnpState);
    const tsr_switch = useSelector((state) => state?.featureModelDriving?.switch?.adas?.tsr?.tsr_switch);
    // const [unpSwitch, setUnpSwitch] = useState(JSON.parse(localStorage.getItem('unp_switch')) || false);

    //切换UNP开关
    const switchUnp = (value) => {
        // localStorage.setItem('unp_switch', value)
        localCache.set('unp_switch', value);
        dispatch({
            type: SWITH_MODE_ACTION.switchUnpState,
            data: value,
        });
        // Mtour开关手动打开的时候，关闭TLA开关
        if (value) {
            switchTAL(value);
            SLIFControl();
            // 关闭语音助手开关
            dispatch({
                type: SWITH_MODE_ACTION.switchAssistant,
                data: false,
            });
        }
    };

    //通知底层关闭TLA开关
    const switchTAL = async (value) => {
        FM.sendToHMIDriving(
            SocketMap.TALSwitchControl,
            {
                tal_switch: 'OFF',
            },
            'drive_node_res_fail_cb',
        );
    };

    // 底层是新版本不处理信号，老版本才给底层发信号
    const SLIFControl = async () => {
        if (tsr_switch) return;
        FM.sendToHMIDriving(
            SocketMap.adasSlifControl,
            {
                slif_switch: 'CLOSE',
            },
            'drive_node_res_fail_cb',
        );
    };

    const leftDom = (
        <div className={'left_dom_wrap'}>
            <div className={'switch_wrap'}>
                <div className={'content_wrap'}>
                    <div className={'text_wrap'}>Mtour</div>
                </div>
                <Switch
                    checked={switchUnpState}
                    onChange={(checked) => switchUnp(checked)}
                    className={'customSwitch'}
                />
            </div>
        </div>
    );

    const rightDom = (
        <div className={'right_dom_wrap'}>
            <img src={mtour} />
        </div>
    );

    return (
        <>
            <CardLayout leftDom={leftDom} rightDom={rightDom} value={switchUnpState} />
        </>
    );
}
