const INIT_STATE = {
    exportDisk: null,
    uploadFileList: [],
};

export default function diskDataCollection(state = INIT_STATE, action) {
    switch (action.type) {
        case 'exportDisk':
            return {
                ...state,
                ...{
                    exportDisk: action.data,
                },
            };
        case 'notifyPullFileList':
            console.log('notifyPullFileList', state, action.data);
            return {
                ...state,
                ...{
                    uploadFileList: action.data,
                },
            };
        default:
            return state;
    }
}
