import { getCookie } from '@/utils/tools';
import isCNfun from '@/utils/isCN';
import { isNativeLoad, isDebug } from '@/utils/isEnv';
import localCache from '@/utils/localCache';
const { REACT_APP_ENV } = process.env;
export const isProd = REACT_APP_ENV === 'prod';
export const isBeta = REACT_APP_ENV === 'beta';
export const isLocal = REACT_APP_ENV === 'development';
export const isStage = REACT_APP_ENV === 'dev' || REACT_APP_ENV === 'devversion';
export const isPre = REACT_APP_ENV === 'pre';
export const isOffline = REACT_APP_ENV === 'offline';

let MVIZ_URL, ROUTE_SELECT_URL, ROBONODE_WSS, REPORT_URL, ROUTE_TEST_URL, FMP_BASE_URL, FDI_BASE_URL, RMP_BASE_URL;
const isCN = isCNfun();
FMP_BASE_URL = isCN ? 'http://fleet-ops.momenta.cn' : 'https://fleet-ops.msd.eu.momenta.cn';

// RMP接口域名
RMP_BASE_URL = 'http://rmp.momenta.cn';

// 本地开发fmp接口跨域
if (isLocal) {
    // FMP_BASE_URL = isNativeLoad ? 'http://fleet-ops.momenta.cn' : '/fmp';
    // RMP_BASE_URL = isNativeLoad ? 'http://rmp.cla-dev.mmtdev.com' : '/rmp';
}

// debug包接口域名切换
if (isDebug && isCN) {
    localCache.get('debugFmpEnv', (data) => {
        FMP_BASE_URL = `http://${data ? '' : 'dev-'}fleet-ops.momenta.cn`;
    });
    localCache.get('debugRmpEnv', (data) => {
        RMP_BASE_URL = `http://rmp.${data ? '' : 'cla-dev.'}momenta.cn`;
    });
}

// 支持 HMI 生产环境后门配置
const url = window.location.href;
const url_reg = /(?<=hminode_ws)=(.+)/;
const fmp_reg = /(?<=fmp_url)=(.+)/;
const carType_reg = /(?<=carType)=(.+)/;
let hminode_ws = url_reg.exec(url);
const car_type = carType_reg.exec(url);
const fmp_url = fmp_reg.exec(url);
let carType = car_type ? car_type[1] : getCookie('carType');

// devcar、robocar  IP
const carIpMap = {
    Devcar: '192.168.1.10',
    Robocar: '192.168.30.42',
    Refcar: '192.168.60.200',
    Etcar: '192.168.30.200', // 雾灵山IP适配
    LHUAS: '192.168.195.200', // 莲花山IP适配
};

const mvizMap = {
    Devcar: 'http://192.168.1.10:3001/dmi?source=hmi',
    Robocar: 'http://192.168.30.43:3001',
    Refcar: 'http://192.168.60.200:3001/dmi',
    Etcar: 'http://192.168.30.200:3001/dmi', // 雾灵山mviz服务适配
    LHUAS: 'http://192.168.195.200:3001/dmi', // 莲花山mviz服务适配
};

console.log('car type', carType);
console.log('robo address', carIpMap[carType]);
console.log('mviz address', mvizMap[carType]);

const Area = {
    map: '40.0',
    mviz: '60.0',
    isHorizontal: '',
};

// Audio level
const AudioLevel = {
    Highest: 4,
    Important: 3,
    Normal: 2,
    Low: 1,
};

if (isProd || isBeta || isStage || isPre || isOffline) {
    // 生产环境和beta环境使用真实地址
    MVIZ_URL = mvizMap[carType] || mvizMap['Devcar'];
    ROUTE_SELECT_URL = 'http://192.168.1.10';
    ROBONODE_WSS = hminode_ws ? `ws://${hminode_ws[1]}` : `ws://${carIpMap[carType]}:5001`; // 'ws://192.168.1.10:15001'
    REPORT_URL = 'https://report.mgo-data.momenta.cn';
    ROUTE_TEST_URL = 'http://192.168.1.10:4536/condition';
    FMP_BASE_URL = fmp_url?.[1] || FMP_BASE_URL;
    FDI_BASE_URL = `http://${carIpMap[carType]}:9100`;
} else {
    ROUTE_SELECT_URL = 'https://www.momenta.cn';
    // MVIZ_URL = 'http://172.21.64.165:3001/dmi';
    // MVIZ_URL = 'http://10.10.2.180:3001/dmi';
    MVIZ_URL = 'http://10.10.2.180:3001/dmi';
    ROBONODE_WSS = 'ws://10.6.32.112:5001'; //"ws://htp-api.mgo.momenta.works/play?ws_bag_id=130&ws_type=hmi&ws_kind=driving&play_begin_time=2022-11-17 19:40:51&play_end_time=2022-11-17 19:46:02";
    REPORT_URL = 'https://dev-report.mgo-data.momenta.cn';
    ROUTE_TEST_URL = 'http://192.168.1.10:4536/condition';
    FDI_BASE_URL = 'http://172.24.9.145:9100';
}

export {
    MVIZ_URL, // Mviz地址
    ROUTE_SELECT_URL, // 路线选择地址
    ROBONODE_WSS, // Robonode websocket地址
    Area, // map、mvizb比例
    REPORT_URL, // 上报地址
    ROUTE_TEST_URL, // 路测助手地址
    AudioLevel, // Audio消息等级
    FMP_BASE_URL, // fmp接口域名
    FDI_BASE_URL, // fdi接口域名
    carIpMap,
    carType,
    RMP_BASE_URL,
};
