import { ParkingSocketMap } from '@/constant/socketMap';
import FM from '@/utils/nativeSubAndPub';

export default function useBumperLineSettingCommon() {
    // 通知底层：保险杠线开关
    const bumperLineControl = async (bumperLineValue) => {
        FM.sendToHMIParking(
            ParkingSocketMap.avm_control,
            {
                tireTracksBumperLine: bumperLineValue,
            },
            null,
        );
    };

    return {
        bumperLineControl,
    };
}
