import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PNPInfoTips from '../hmiInfo/index';
import FM from '@/utils/nativeSubAndPub';
import { Grid } from 'antd-mobile';
import { cusToast } from '@/components/customToast';
import { HOT_AREA_TYPE } from '@/store/action/type';
import { AppFn } from '@/utils/native';

import { useNavigate, useLocation } from 'react-router-dom';

import { RADS_CMD } from './config';
// import {
//     active_select,
//     disabled_select,
//     start_searching,
//     start_parking,
//     stop_parking,
//     resume_parking,
//     start_searching_out,
//     exit_apa,
// } from './fn';
import './index.less';
// const { playLocalAudio } = AppFn;

export default function DebugBtn() {
    // const parking_out_direction = useSelector((state) => state?.featureModelParking?.apaDebug?.canParkingOutDirection);
    const rads_state = useSelector((state) => state?.featureModelParking?.radsDebug?.state);
    const learnedTrajectoryLength = useSelector(
        (state) => state?.featureModelParking?.radsDebug?.learnedTrajectoryLength,
    );
    const distanceToDestination = useSelector((state) => state?.featureModelParking?.radsDebug?.distanceToDestination);

    // const mviz_msg = useSelector((state) => state?.parking?.mvizParkData);
    // const track_id = useSelector((state) => state?.parking?.mvizParkData?.track_id);
    const isHotAll = useSelector((state) => state.hotArea.isHotAll);
    const featureModelParking = useSelector((state) => state?.featureModelParking);
    console.log('xxxx-featureModelParking', featureModelParking);
    //apa debug返回按钮
    const debugBack = useSelector((state) => state.hotArea.debugBack);
    //apa debug操作区
    const debugOperator = useSelector((state) => state.hotArea.debugOperator);

    const backRef = useRef(null);
    const operatorRef = useRef(null);

    // const [curDirection, setCurDirection] = useState('');
    // const [showAlert, setShowAlert] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const handleClick = (dataCmd) => {
        console.log('xxxx-rads命令', dataCmd);
        cusToast.show({
            content: 'RADS Command: ' + RADS_CMD.data_cmd[dataCmd],
        });
        FM.sendToHMIParking(RADS_CMD.cmd, {
            cmd: RADS_CMD.data_cmd[dataCmd],
            debug: true,
        });
    };

    useEffect(() => {
        setTimeout(() => {
            const operatorArea = operatorRef?.current?.getBoundingClientRect();
            const backArea = backRef?.current?.getBoundingClientRect();
            const backAreaInfo = `${backArea?.x}, ${backArea?.y}, ${backArea?.width}, ${backArea?.height}`;
            const operatorAreaInfo = `${operatorArea?.x}, ${operatorArea?.y}, ${operatorArea?.width}, ${operatorArea?.height}`;
            dispatch({
                type: HOT_AREA_TYPE.debugBackArea,
                data: backAreaInfo,
            });
            dispatch({
                type: HOT_AREA_TYPE.debugOperatorArea,
                data: operatorAreaInfo,
            });
        }, 300);
    }, []);

    useEffect(() => {
        const HotArea = [];
        debugBack !== '' && HotArea.push(debugBack);
        debugOperator !== '' && HotArea.push(debugOperator);
        AppFn.setHotArea(HotArea, isHotAll);
    }, [isHotAll, debugBack, debugOperator]);
    // RADS_FUNC_START: "进入RADS功能",
    // RADS_EXIT: "RADS退出",
    // RADS_START_PARKING: "开始RADS",
    // RADS_PAUSE_PARKING: "RADS暂停倒车",
    // RADS_RESUME_PARKING: "RADS继续倒车",
    // RADS_STOP_PARKING: "结束RADS倒车",
    // RADS_SAVE_TRAJECTORY: "RADS落地轨迹",
    // RADS_ERASE_TRAJECTORY: "RADS清除轨迹"
    return (
        <div className="setting-mask-rads">
            <div ref={operatorRef} className="setting-content">
                <div
                    className="back-btn"
                    onClick={() => {
                        navigate(`/home/parking${location?.search}`);
                    }}
                >
                    Exit Debug Mode
                </div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div className="group">
                        <div
                            className="debug-btn"
                            onClick={() => {
                                handleClick('RADS_FUNC_START');
                            }}
                        >
                            Enter RADS Mode
                        </div>
                        <div
                            className="debug-btn"
                            onClick={() => {
                                handleClick('RADS_EXIT');
                            }}
                        >
                            Exit RADS Mode
                        </div>
                        <div
                            className="debug-btn"
                            onClick={() => {
                                handleClick('RADS_START_PARKING');
                            }}
                        >
                            Start RADS
                        </div>
                        <div
                            className="debug-btn"
                            onClick={() => {
                                handleClick('RADS_PAUSE_PARKING');
                            }}
                        >
                            Pause
                        </div>
                        <div
                            className="debug-btn"
                            onClick={() => {
                                handleClick('RADS_RESUME_PARKING');
                            }}
                        >
                            Resume
                        </div>
                        <div
                            className="debug-btn"
                            onClick={() => {
                                handleClick('RADS_STOP_PARKING');
                            }}
                        >
                            End RADS
                        </div>
                        <div
                            className="debug-btn"
                            onClick={() => {
                                handleClick('RADS_SAVE_TRAJECTORY');
                            }}
                        >
                            Save Trajectory
                        </div>
                        <div
                            className="debug-btn"
                            onClick={() => {
                                handleClick('RADS_ERASE_TRAJECTORY');
                            }}
                        >
                            Delete Trajectory
                        </div>
                    </div>
                </div>
            </div>
            <div className="rads-debug-info">
                <div className="rads-row" columns={4} gap={16}>
                    <div className="rads-row-item">
                        RADS Switch: <span>{rads_state === 'StateRADSNotIn' || !rads_state ? 'OFF' : 'ON'}</span>
                    </div>

                    <div className="rads-row-item">
                        RADS Status:<span>{rads_state ? ' ' + rads_state : '--'}</span>
                    </div>

                    <div className="rads-row-item">
                        Trajectory Distance:{' '}
                        <span>{learnedTrajectoryLength >= 0 ? learnedTrajectoryLength.toFixed(2) : '--'} m</span>
                    </div>

                    <div className="rads-row-item">
                        Remaining Distance:
                        <span>{distanceToDestination >= 0 ? distanceToDestination.toFixed(2) : '--'} m</span>
                    </div>
                </div>
                {/* <Grid className='rads-row' columns={2} >
                    <Grid.Item>
                        <div>HMI Info:</div>
                    </Grid.Item>
                    <Grid.Item>
                        <div>Button Status Info:</div>
                    </Grid.Item>
                 
                </Grid> */}
            </div>

            <PNPInfoTips />
        </div>
    );
}
