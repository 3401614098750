import { useSelector } from 'react-redux';
import './index.less';
const build_status_map = ['LEARNING', 'APA_PARKING', 'APA_SELECTING', 'APA_PARKING_PAUSE'];
const parking_status_map = ['CRUISING'];

export default function Perception() {
    const status = useSelector((state) => state?.featureModelParking?.lpnp?.state);
    const statistic = useSelector((state) => state?.featureModelParking?.lpnp?.statistic);
    const parkingMode = useSelector((state) => state?.featureModelParking?.hmi?.parkingMode);

    return (
        <>
            {build_status_map.indexOf(status) > -1 && (parkingMode === 'LEARNING' || parkingMode === undefined) ? (
                <div className="p-info-perception">
                    <div className="p-info-perception-item">
                        <span>Speed Bump</span>
                        <span className="p-info-perception-num">{statistic?.speedBumpsCount}</span>
                        {/* <span>个</span> */}
                    </div>
                    {parkingMode === undefined ? (
                        ''
                    ) : (
                        <div className="p-info-perception-item">
                            <span>Ramp</span>
                            <span className="p-info-perception-num">
                                {statistic?.rampCount ? statistic?.rampCount : 0}
                            </span>
                        </div>
                    )}
                </div>
            ) : (
                ''
            )}
            {parking_status_map.indexOf(status) > -1 ? (
                <div className="p-info-perception">
                    <div className="p-info-perception-item">
                        <span>Pedestrian Alert</span>
                        <span className="p-info-perception-num">
                            {statistic?.avoidPeopleCount ? statistic?.avoidPeopleCount : 0}
                        </span>
                        {/* <span>次</span> */}
                    </div>
                    <div className="p-info-perception-item">
                        <span>Vehicle Avoidance</span>
                        <span className="p-info-perception-num">
                            {statistic?.avoidVehiclesCount ? statistic?.avoidVehiclesCount : 0}
                        </span>
                        {/* <span>次</span> */}
                    </div>
                </div>
            ) : (
                ''
            )}
        </>
    );
}
