import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useSetHotArea } from '@/hook';
import { HOT_AREA_TYPE } from '@/store/action/type';
import IconFail from '@/assets/parking/apa/apa_icon_fail.png';
import IconSuccess from '@/assets/parking/apa/apa_icon_success.png';
import closeIcon from '@/assets/parking/guide/guide_close_d.png';
import apa_success_img from '@/assets/parking/apa/apa_complete_success.png';
import apa_failed_img from '@/assets/parking/apa/apa_complete_failed.png';
import apa_success_img_c from '@/assets/parking/apa/apa_complete_success_c.png';
import apa_failed_img_c from '@/assets/parking/apa/apa_complete_failed_c.png';
import Modal from '@/components/parkingContainer/globalComponent/modal/index';
import { enterSeaching } from '@/components/parkingContainer/config';
import guide_close from '@/assets/parking/guide/guide_close.png';
import './index.less';

export default function APAComplete() {
    const [showPage, setShowPage] = useState(false);
    const userMode = useSelector((state) => state?.parking?.userMode);
    const [success, setSuccess] = useState(false);
    const noticeType = useSelector((state) => state?.featureModelParking?.alert?.type);
    const parkingFailReason = useSelector((state) => state?.featureModelParking.apa?.parkingFailReason);

    useEffect(() => {
        if (noticeType === 'apa_parking_fail' || noticeType === 'apa_parking_succ') {
            window?.momentaJsBridge?.dispatch('changeDfdiWebView', null, 'hide');
            if (noticeType === 'apa_parking_succ') {
                setShowPage(true);
            } else {
                if (!['APAPlanningCompleteFail', 'PlanningOkOverTime', ''].includes(parkingFailReason)) {
                    setShowPage(true);
                }
            }

            setSuccess(noticeType === 'apa_parking_succ');
            Modal.clear();
        }
    }, [noticeType]);

    const { nodeRef: closeRef } = useSetHotArea(HOT_AREA_TYPE.apaCancel);
    const { nodeRef: confirmRef } = useSetHotArea(HOT_AREA_TYPE.apaConfirm);

    if (!showPage) return null;
    const getImg = () => {
        if (userMode === 'D') {
            if (success) {
                return apa_success_img;
            } else {
                return apa_failed_img;
            }
        } else {
            if (success) {
                return apa_success_img_c;
            } else {
                return apa_failed_img_c;
            }
        }
    };
    const imgSrc = getImg();
    const Icon = success ? IconSuccess : IconFail;
    const title = success ? 'APA Complete' : 'APA deactivation';
    return (
        <div>
            {userMode === 'D' ? (
                <div className="apa-compelete-status">
                    <div className="status-left">
                        {success ? (
                            <p
                                ref={confirmRef}
                                onClick={() => {
                                    window?.momentaJsBridge?.dispatch('changeDfdiWebView', null, 'close');
                                    enterSeaching();
                                    setShowPage(false);
                                }}
                                className="study-btn"
                            >
                                Complete
                            </p>
                        ) : (
                            <div
                                className="close-btn"
                                ref={closeRef}
                                onClick={() => {
                                    window?.momentaJsBridge?.dispatch('changeDfdiWebView', null, 'close');
                                    enterSeaching();
                                    setShowPage(false);
                                }}
                            >
                                <img src={closeIcon} alt="" />
                            </div>
                        )}

                        <h3>
                            <img className="p-study-complete" src={Icon} alt="" />
                            {title}
                        </h3>
                        {success ? null : (
                            <>
                                <label>Failure Reason:</label>
                                <p>{parkingFailReason}</p>
                            </>
                        )}
                    </div>
                    <div className="status-right">
                        <img src={imgSrc} alt="" />
                    </div>
                </div>
            ) : (
                <div className="apa-compelete-status-C">
                    <img className="content-bg" src={imgSrc} alt="" />
                    <div className="status-left—C">
                        {success ? (
                            <p
                                ref={confirmRef}
                                onClick={() => {
                                    window?.momentaJsBridge?.dispatch('changeDfdiWebView', null, 'close');
                                    enterSeaching();
                                    setShowPage(false);
                                }}
                                className="study-btn-C"
                            >
                                Complete
                            </p>
                        ) : (
                            <div
                                className="close-btn-C"
                                ref={closeRef}
                                onClick={() => {
                                    window?.momentaJsBridge?.dispatch('changeDfdiWebView', null, 'close');
                                    enterSeaching();
                                    setShowPage(false);
                                }}
                            >
                                <img src={guide_close} alt="" />
                            </div>
                        )}

                        <div className="titile-content-C">
                            <h3>
                                <img className="p-study-complete-C" src={Icon} alt="" />
                                {title}
                            </h3>
                            {success ? null : (
                                <>
                                    <label>Failure Reason:</label>
                                    <p>{parkingFailReason}</p>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
