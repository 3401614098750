import { ParkingSocketMap } from '@/constant/socketMap';
import FM from '@/utils/nativeSubAndPub';

export default function useTracksFrontSettingCommon() {
    // 通知底层：前轮轨迹线开关
    const tracksFrontControl = async (tracksFrontValue) => {
        FM.sendToHMIParking(
            ParkingSocketMap.avm_control,
            {
                tireTracksFront: tracksFrontValue,
            },
            null,
        );
    };

    return {
        tracksFrontControl,
    };
}
