import localCache from '@/utils/localCache';

// const warning_automatic = localStorage.getItem('warning_automatic') || 'OFF'

const INIT_STATE = {
    takeOverSwitchStatus: 'OFF',
};

export default function warningAutomatic(state = INIT_STATE, action) {
    switch (action.type) {
        case 'switchStatus':
            localCache.set('warning_automatic', action.data);
            return {
                ...state,
                ...{
                    takeOverSwitchStatus: action.data,
                },
            };
        default:
            return state;
    }
}
