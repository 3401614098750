import { Tag } from 'antd-mobile';
import dayjs from 'dayjs';
import { TASK_TAG_STYLE_MAP } from '../const';
import { useTranslation } from 'react-i18next';
import { useWorkflowDeployText } from '../../hook';

export default function TaskTitle({ taskStatus, taskType, startTime, endTime, taskName, workflow_infos }) {
    const { t: translate } = useTranslation();
    const TASK_TTATUS_TEXT_MAP = translate('workBench.task.taskStatusMap', { returnObjects: true });
    // const softwareStatusMap = translate('workBench.task.softwarePackageStatus', { returnObjects: true });
    const { isCurrentWorkflow, deployText } = useWorkflowDeployText(workflow_infos);
    return (
        <div className="task-title">
            <div className="task-desc">
                <div className="task-name">
                    <Tag fill="outline" style={TASK_TAG_STYLE_MAP[taskStatus] || TASK_TAG_STYLE_MAP.default}>
                        {TASK_TTATUS_TEXT_MAP[taskStatus] || TASK_TTATUS_TEXT_MAP.default}
                    </Tag>
                    <span>{taskName}</span>
                </div>
                <div className="task-time">
                    {taskType}｜{dayjs(startTime).format('HH:mm')}-{dayjs(endTime).format('HH:mm')}
                </div>
            </div>
            {isCurrentWorkflow && <div className="deploy-status">{deployText}</div>}
        </div>
    );
}
