import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import FM from '@/utils/nativeSubAndPub';
import { useSelector } from 'react-redux';
import { UPDATE_APA_STYLE_CMD, GET_APA_STYLE_CMD } from './config';
import { cusToast } from '@/components/customToast';
import { HOT_AREA_TYPE } from '@/store/action/type';
// import { TextArea, CapsuleTabs} from 'antd-mobile';
// import { Button } from 'antd-mobile';
import closeD from '@/assets/parking/d_new/cancel_d.png';
import store from '@/store';

import './index.less';

export default function StyleData(props) {
    const operatorRef = useRef(null);
    const dispatch = useDispatch();
    const [textValue, setTextValue] = useState(null);
    const operatorArea = operatorRef?.current?.getBoundingClientRect();

    const driving_style_config = useSelector((state) => JSON.stringify(state?.featureModelParking?.drivingStyleConfig));
    console.log('driving_style_config', driving_style_config);
    const isJSON = (str) => {
        try {
            JSON.parse(str);
        } catch (e) {
            console.log('ddddddddd-2', e);
            return false;
        }
        return true;
    };
    const closeHandler = () => {
        store.dispatch({
            type: 'SetTab',
            data: '',
        });
    };
    const changeStyle = async (value) => {
        if (isJSON(driving_style_config)) {
            const newObj = JSON.parse(driving_style_config);
            newObj['planning.drive_style'] = value;
            console.log('UPDATE_APA_STYLE_CMD', UPDATE_APA_STYLE_CMD);
            FM.sendToHMIParking(UPDATE_APA_STYLE_CMD, newObj);
            FM.sendToHMIParking(GET_APA_STYLE_CMD, {
                keys: [],
            });
        } else {
            cusToast.show({
                content: '不是json格式数据，请检查数据源',
                duration: 2000,
            });
        }
    };

    useEffect(() => {
        if (isJSON(driving_style_config)) {
            let obj = JSON.parse(driving_style_config);
            setTextValue(obj['planning.drive_style']);
        } else {
            setTextValue(null);
        }
    }, [driving_style_config]);
    useEffect(() => {
        FM.sendToHMIParking(GET_APA_STYLE_CMD, {
            keys: [],
        });
    }, []);
    const handleType = (value, isClick) => {
        // setTextValue(value);
        changeStyle(value);
    };
    useEffect(() => {
        const operatorAreaInfo = `${operatorArea?.x}, ${operatorArea?.y}, ${operatorArea?.width}, ${operatorArea?.height}`;
        dispatch({
            type: HOT_AREA_TYPE.LPNPDebugOperatorArea,
            data: operatorAreaInfo,
        });
        return () => {
            dispatch({
                type: HOT_AREA_TYPE.LPNPDebugOperatorArea,
                data: '',
            });
        };
    }, [operatorArea?.x, operatorArea?.y, operatorArea?.width, operatorArea?.height]);
    return (
        <div ref={operatorRef} className="pnp-style-wrap">
            <div className="style-content">
                <div className="lpnp-setting-close">
                    <img onClick={() => closeHandler()} src={closeD} alt="close" />
                </div>
                {/* <TextArea
                    style={{
                        '--font-size': '.24rem',
                    }}
                    className="textarea"
                    value={textValue}
                    onChange={onChange}
                    placeholder="请编辑驾驶风格数据"
                /> */}
                <div className={'sensitivity_wrap'}>
                    <p className="subtitle_wrap">驾驶风格设置</p>
                    <div className={'select_box'}>
                        {[
                            {
                                label: '激进',
                                value: 0,
                            },
                            {
                                label: '正常',
                                value: 1,
                            },
                            {
                                label: '保守',
                                value: 2,
                            },
                        ].map((item, index) => {
                            return (
                                <span
                                    key={index}
                                    className={`select_box_item ${textValue === item.value ? 'active' : ''}`}
                                    onClick={() => handleType(item.value, 'click')}
                                >
                                    {item.label}
                                </span>
                            );
                        })}
                    </div>
                </div>
            </div>
            {/* <div className="style-btn-group">
                <Button
                    className="cancel"
                    onClick={() => {
                        setTextValue('');
                        props?.close();
                    }}
                >
                    取消
                </Button>
                <Button
                    className="confirm"
                    color="primary"
                    onClick={() => {
                        changeStyle();
                    }}
                >
                    确认
                </Button>
            </div> */}
        </div>
    );
}
