export const TASK_TAG_STYLE_MAP = {
    2: {
        '--background-color': 'rgba(244, 236, 255, 1)',
        '--border-color': 'rgba(205, 175, 244, 1)',
        '--text-color': 'rgba(205, 175, 244, 1)',
    },
    3: {
        '--background-color': 'rgba(255, 241, 227, 1)',
        '--border-color': 'rgba(255, 154, 61, 1)',
        '--text-color': 'rgba(255, 154, 61, 1)',
    },
    4: {
        '--background-color': 'rgba(230, 255, 251, 1)',
        '--border-color': 'rgba(0, 203, 182, 1)',
        '--text-color': 'rgba(0, 203, 182, 1)',
    },
    5: {
        '--background-color': 'rgba(237, 239, 243, 1)',
        '--border-color': 'rgba(154, 158, 170, 1)',
        '--text-color': 'rgba(154, 158, 170, 1)',
    },
    default: {
        '--background-color': 'rgba(217, 241, 255, 1)',
        '--border-color': 'rgba(28, 173, 255, 1)',
        '--text-color': 'rgba(28, 173, 255, 1)',
    },
};
