import React from 'react';
import AebCard from './aebCard';
import BsdCard from './bsdCard';
import CustomFcwCard from './customFcwCard';
import CustomLdwCard from './customLdwCard';
import DowCard from './dowCard';
import ElkCard from './elkCard';
import CustomElkCard from './customElk';
import FcwCard from './fcwCard';
import LdpCard from './ldpCard';
import LdwCard from './ldwCard';
import RcwCard from './rcwCard';
import TsrCard from './tsrCard';
import SLifCard from './slifCard';
import TlaCard from './tlaCard';
import AbsmCard from './absmCard';
import CswCard from './cswCard';
import EssCard from './essCard';
import IHCCard from './IhcCard';
import RctaCard from './rctaCard';
import RctbCard from './rctbCard';
import CustomSlifTsrCard from './customSlifTsrCard';
import AmapCard from './amapCard';
import NrpCard from './nrpCard';
import IsaCard from './isaCard';
import RIMCard from './rimCard';
import CdwCard from './cdwCard';
import CustomRimCard from './customRimCard';
import SlifHdMapCard from './slifHdMap';
import TsaCard from './tsaCard';
import TsaAlertCard from './tsaCard/tsaAlert';

import EESFCard from './eesfCard';
import AESCard from './aesCard';
import CURVECard from './curveCard';
import './index.less';
import { useSelector } from 'react-redux';

export default function SafetyModule() {
    const ess_switch = useSelector((state) => state?.featureModelDriving?.switch?.adas?.aeb?.ess_switch);
    const ihc_switch = useSelector((state) => state?.featureModelDriving?.switch?.adas?.ihc_switch);
    const i18nLang = useSelector((state) => state?.themeAction?.i18nState);
    const amap_switch = useSelector((state) => state?.featureModelDriving?.switch?.adas?.amap_switch);
    const nrp_switch = useSelector((state) => state?.featureModelDriving?.switch?.adas?.nrp_switch);
    const isa_switch = useSelector((state) => state?.featureModelDriving?.switch?.adas?.isa_switch);
    const rcta_switch = useSelector((state) => state?.featureModelDriving?.switch?.adas?.rcta_switch);
    const rctb_switch = useSelector((state) => state?.featureModelDriving?.switch?.adas?.rctb_switch);
    const tsr_switch = useSelector((state) => state?.featureModelDriving?.switch?.adas?.tsr?.tsr_switch);
    const tsa_switch = useSelector((state) => state?.featureModelDriving?.switch?.adas?.tsa?.tsa_switch);

    const rim_switch = useSelector((state) => state?.featureModelDriving?.switch?.adas?.rim_switch);
    const cdw_switch = useSelector((state) => state?.featureModelDriving?.switch?.adas?.cdw_switch);
    const rcta_rctb_switch = useSelector((state) => state?.featureModelDriving?.switch?.adas?.rcta_rctb_switch);
    const rpcm_switch = useSelector((state) => state?.featureModelDriving?.switch?.adas?.rpcm_switch);
    const rvb_switch = useSelector((state) => state?.featureModelDriving?.switch?.adas?.rvb_switch);
    const slif_tsr_switch = useSelector(
        (state) => state?.featureModelDriving?.switch?.driving?.auto_follow_slif_speed_control?.switch,
    );
    const slif_hdmap_switch = useSelector((state) => state?.featureModelDriving?.switch?.adas?.slif?.slif_hdmap_switch);
    const eesf_switch = useSelector((state) => state?.featureModelDriving?.switch?.driving?.eesf_control?.switch);
    const elk_roadedge_working_mode = useSelector(
        (state) => state?.featureModelDriving?.switch?.adas?.lka_switch?.elk_roadedge_working_mode,
    );
    const aes_switch = useSelector((state) => state?.featureModelDriving?.switch?.adas?.aeb?.aes_switch);
    const curve_switch = useSelector((state) => state?.featureModelDriving?.switch?.driving?.curve_control?.switch);

    return (
        <React.Fragment>
            <div className={'safety_modular_wrap'}>
                <div className={'safety_modular_wrap'}>
                    <div>
                        <AebCard />
                        {cdw_switch && <CdwCard />}
                        <RcwCard />
                        <LdpCard />
                        <ElkCard />
                        {elk_roadedge_working_mode && <CustomElkCard />}
                        <BsdCard />
                        {!cdw_switch && <TlaCard />}
                    </div>
                    <div>
                        <FcwCard />
                        <CustomFcwCard />
                        <div>
                            <LdwCard />
                            <CustomLdwCard />
                        </div>
                        <DowCard />
                    </div>
                    {cdw_switch && <TlaCard />}
                    {tsr_switch ? <TsrCard /> : <SLifCard />}
                    <AbsmCard />
                    {slif_tsr_switch && <CustomSlifTsrCard />}
                    {ess_switch && <EssCard />}
                    {ihc_switch && <IHCCard />}
                    {amap_switch && <AmapCard />}
                    {nrp_switch && <NrpCard />}
                    {isa_switch && <IsaCard />}
                    {rcta_switch && <RctaCard />}
                    {rctb_switch && <RctbCard />}
                    {slif_hdmap_switch && <SlifHdMapCard />}
                    {eesf_switch && <EESFCard />}
                    {aes_switch && <AESCard />}
                    {curve_switch && <CURVECard />}
                    <CswCard />
                    {rim_switch && (
                        <div className={'right_wrap'}>
                            <RIMCard />
                            {rpcm_switch && rvb_switch && <CustomRimCard />}
                        </div>
                    )}
                    <div>
                        {tsa_switch && <TsaCard />}
                        {tsa_switch && <TsaAlertCard />}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
