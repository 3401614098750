import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Switch } from 'antd-mobile';
import CardLayout from '../../../components/cardLayout';
import { useSelector } from 'react-redux';
import { SocketMap } from '@/constant/socketMap';
import FM from '@/utils/nativeSubAndPub';
import '@/components/slider/smartDrivingSetting/components/cardStyle/index.less';
import { singelPointReport } from '@/utils/report';
import reportEventName from '@/constant/reportEventName';

const controlsList = [
    { path: SocketMap.adas_rcta_rctb_control, key: 'rcta_rctb_switch' },
    { path: SocketMap.adas_rpcm_control, key: 'rpcm_switch' },
    { path: SocketMap.adas_rvb_control, key: 'rvb_switch' },
];
let timer = null;
export default function RIMCard() {
    const { t: translate } = useTranslation();
    const rim_switch = useSelector((state) => state?.featureModelDriving?.switch?.adas?.rim_switch);
    const [rimSwitch, setRimSwitch] = useState(rim_switch === 'OPEN');

    useEffect(() => {
        rim_switch && setRimSwitch(rim_switch === 'OPEN');
        if (timer) {
            clearTimeout(timer);
            timer = null;
        }
    }, [rim_switch]);

    // switch: RIM
    const switchRimChange = (value) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            setRimSwitch(rim_switch === 'OPEN');
        }, 2 * 1000);
        RimControl(value);
        // 数据上报
        reportDataFunc(value);
    };

    // 通知底层：RIM
    const RimControl = async (value) => {
        FM.sendToHMIDriving(
            SocketMap.adas_rim_control,
            {
                rim_switch: value ? 'OPEN' : 'CLOSE',
            },
            'drive_node_res_fail_cb',
        );
        // 打开RIM开关时，同时打开三个子开关，关闭RIM开关时，同时关闭三个子开关
        for (let i = 0; i < controlsList.length; i++) {
            const controls = controlsList[i];
            sendControl(controls.path, { [controls.key]: value ? 'OPEN' : 'CLOSE' });
        }
    };

    const sendControl = async (path, params) => {
        FM.sendToHMIDriving(path, params, 'drive_node_res_fail_cb');
    };

    // 数据上报
    const reportDataFunc = (value) => {
        singelPointReport({
            eventName: reportEventName.HMI_RIM_SWITCH_CLICK,
            timestamp: new Date().getTime(),
            reportData: {
                rim_switch: value ? 'OPEN' : 'CLOSE',
            },
        });
    };

    const leftDom = (
        <div className={'left_dom_wrap'}>
            <div className={'switch_wrap'}>
                <div className={'content_wrap'}>
                    <div className={'text_wrap'}>RIM</div>
                    <div className={'text_wrap_detail'} style={{ whiteSpace: 'pre-wrap' }}>
                        {translate('settings.RIMSwitch')}
                    </div>
                </div>
                <Switch
                    checked={rimSwitch}
                    onChange={(checked) => switchRimChange(checked)}
                    className={'customSwitch'}
                />
            </div>
        </div>
    );

    const rightDom = <div className={'right_dom_wrap'} />;

    return (
        <>
            <CardLayout leftDom={leftDom} rightDom={rightDom} value={rimSwitch} />
        </>
    );
}
