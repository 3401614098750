import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { formate_num } from './fn';
import { Chart, registerGeometry, registerComponentController, registerEngine } from '@antv/g2/lib/core';
import * as CanvasEngine from '@antv/g-canvas';
import Tooltip from '@antv/g2/lib/chart/controller/tooltip';
import Interval from '@antv/g2/lib/geometry/interval';
import Polygon from '@antv/g2/lib/geometry/polygon';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import './index.less';

registerGeometry('Polygon', Polygon);
registerGeometry('Interval', Interval);
registerComponentController('tooltip', Tooltip);
registerEngine('canvas', CanvasEngine);

export default function DataCollection() {
    const { t: translate } = useTranslation();
    const exportDisk = useSelector((state) => state?.diskDataCollection?.exportDisk);
    const chartRef = useRef();

    const setLevelColor = (used) => {
        if (used <= 20) {
            return '#00D8A4';
        }
        if (used > 20 && used <= 40) {
            return '#2A69E2';
        }
        return '#FF9A3D';
    };

    useEffect(() => {
        if (exportDisk?.status !== 'mounted') {
            chartRef.current?.destroy();
            chartRef.current = null;
        }
        if (!exportDisk?.space_used && !exportDisk?.space_size) return;
        let space_used_percent = (exportDisk?.space_used / exportDisk?.space_size).toFixed(2);
        let space_size_remain = (1 - space_used_percent).toFixed(2);

        let color = setLevelColor(space_used_percent * 100);
        const data = [
            {
                item: 'space_used',
                count: exportDisk?.space_used,
                percent: parseFloat(space_used_percent),
            },
            {
                item: 'space_size',
                count: exportDisk?.space_size,
                percent: parseFloat(space_size_remain),
            },
        ];

        console.log('cv', data);

        if (!chartRef.current && exportDisk?.status === 'mounted') {
            chartRef.current = new Chart({
                container: 'container',
                autoFit: true,
                defaultInteractions: [],
            });
            window.chart = chartRef.current;
            chartRef.current.data(data);
        } else if (exportDisk?.status === 'mounted') {
            chartRef.current.changeData(data);
        }
        chartRef.current?.coordinate('theta', {
            radius: 0.75,
            innerRadius: 0.6,
        });
        chartRef.current?.annotation()?.text({
            position: ['50%', '50%'],
            content: parseInt(space_size_remain * 100) + '%',
            style: {
                fontSize: 30,
                fill: '#8991A0',
                textAlign: 'center',
            },
            offsetX: 0,
            offsetY: 0,
        });
        chartRef.current?.legend(false);
        chartRef.current?.interval().adjust('stack').position('percent').color('item', ['#eaf0fc', color]);
        chartRef.current?.render();
    }, [exportDisk?.space_used, exportDisk?.status]);

    return (
        <div className="work-bench-base">
            <div className="work-bench-data-tool">
                <h4 style={{ marginBottom: '0.06rem' }}>{translate('workBench.dataCollectionInfo')}</h4>
            </div>

            {exportDisk && exportDisk?.health && (
                <div className="work-bench-base-content-disk">
                    <div className="work-bench-base-content-disk-item">
                        <span>{translate('workBench.dataDiskhealth')}</span>
                        <span>{exportDisk?.health || '--'}%</span>
                    </div>
                    <div className="work-bench-base-content-disk-item">
                        <span>{translate('workBench.dataDiskStatus')}</span>
                        <span>{exportDisk?.status || '--'}</span>
                    </div>
                    <div id="container" className={classNames({ hide: exportDisk?.status !== 'mounted' })}></div>
                    <div
                        className={classNames('work-bench-base-dick-info', { hide: exportDisk?.status !== 'mounted' })}
                    >
                        <span className="work-bench-base-dick-info-left">
                            <span>{formate_num(exportDisk?.space_size - exportDisk?.space_used ?? 0) || 0}</span>
                        </span>
                        <span className="work-bench-base-dick-info-middle">/</span>
                        <span className="work-bench-base-dick-info-right">
                            {formate_num(exportDisk?.space_size ?? 0)} G
                        </span>
                    </div>
                </div>
            )}
            {exportDisk && exportDisk?.status === 'inserted' && (
                <div className="exception-content">
                    <p className="exception-title">{translate('workBench.dataDiskNotMounted')}</p>
                </div>
            )}
            {(!exportDisk || !exportDisk?.health) && (
                <div className="exception-content">
                    <svg width="70" height="61" viewBox="0 0 70 61" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M68.4582 53.86L38.7716 2.63833C38.0416 1.3 36.5816 0.448334 34.9999 0.448334C33.4182 0.448334 31.9582 1.3 31.2282 2.63833L1.54158 53.86C0.689915 55.1983 0.689915 56.9017 1.54158 58.24C2.39325 59.5783 3.73158 60.43 5.31325 60.43H64.6866C66.2682 60.43 67.7282 59.5783 68.4582 58.24C69.1882 56.9017 69.3099 55.32 68.4582 53.86ZM37.4333 47.7767C37.4333 49.115 36.3382 50.21 34.9999 50.21C33.6616 50.21 32.5666 49.115 32.5666 47.7767V46.3167C32.5666 44.9783 33.6616 43.8833 34.9999 43.8833C36.3382 43.8833 37.4333 44.9783 37.4333 46.3167V47.7767ZM34.9999 39.3817C33.6616 39.3817 32.5666 38.2867 32.5666 36.9483V21.375C32.5666 20.0367 33.6616 18.9417 34.9999 18.9417C36.3382 18.9417 37.4333 20.0367 37.4333 21.375V36.9483C37.4333 38.2867 36.3382 39.3817 34.9999 39.3817Z"
                            fill="#E6EBF1"
                        />
                    </svg>
                    <p className="exception-title">{translate('workBench.dataDiskError')}</p>
                </div>
            )}
            <span className="work-bench-data-tool-tag">{translate('workBench.dataDiskInfoDataSources')}</span>
        </div>
    );
}
