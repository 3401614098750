import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import localCache from '@/utils/localCache';
import { getRecorderDiskStatus } from '../api';
import getCarIp from '@/utils/getCarIp';

export function useDataAcquisitionDiskStatus() {
    const dispatch = useDispatch();
    const isWorkbenchOpen = useSelector((state) => state?.workbench?.isOpen);
    useEffect(() => {
        const ip = getCarIp();
        let timer = null;
        localCache.get('OTA_IP', (ota_ip) => {
            const initialFun = async () => {
                let res = await getRecorderDiskStatus(ota_ip || ip);
                if (res.success) {
                    let index = res?.disk_status?.disk_type?.indexOf('export');
                    let health = res?.disk_status?.availability[index] || undefined;
                    let status = res?.disk_status?.status[index] || '--';
                    let space_size = res?.disk_status?.space_size[index] ?? '--';
                    let space_used = res?.disk_status?.space_used[index] ?? '--';
                    let diskData =
                        index >= 0
                            ? {
                                  health,
                                  status,
                                  space_size,
                                  space_used,
                              }
                            : undefined;
                    dispatch({
                        type: 'exportDisk',
                        data: diskData,
                    });
                }
            };
            initialFun();
            timer = setInterval(initialFun, isWorkbenchOpen ? 3000 : 1000 * 60);
        });

        window.__test_export_disk__ = (data) => {
            dispatch({
                type: 'exportDisk',
                data,
            });
        };

        return () => {
            clearInterval(timer);
        };
    }, [isWorkbenchOpen]);
}
