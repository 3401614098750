import { ParkingSocketMap } from '@/constant/socketMap';
import FM from '@/utils/nativeSubAndPub';

export default function useChassisSettingCommon() {
    // 通知底层：透明底盘开关
    const chassisTransparentControl = async (chassis_transparent) => {
        FM.sendToHMIParking(
            ParkingSocketMap.avm_control,
            {
                is_chassis_transparent: chassis_transparent,
            },
            null,
        );
    };

    return {
        chassisTransparentControl,
    };
}
