import bg from '@/assets/workbench/recode_item_bg.png';
import dayjs from 'dayjs';
import ProgressiveImg from './image';
import sound from '@/assets/workbench/sound.png';
import sound_play from '@/assets/workbench/sound_play.gif';
import { Button, Tag } from 'antd-mobile';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import isCNfun from '@/utils/isCN';
import icon_auto from '@/assets/workbench/event_tag_auto.png';
import icon_manual from '@/assets/workbench/event_tag_manual.png';

const isCN = isCNfun();

const noop = () => {};

export default function ReportItem({
    item,
    onTitleClick = noop,
    onImgClick,
    onPlayAudio,
    isPlay,
    onAdditional,
    className,
    Tag: TagName = 'div',
}) {
    const { t: translate } = useTranslation();
    const tagMap = translate('workBench.report.tagMap', { returnObjects: true });
    return (
        <TagName className={className}>
            <div className="work-bench-base-lists-status">
                {item?.event_labels && <img src={icon_manual} alt="icon_manual" />}
                {item?.auto_labels && <img src={icon_auto} alt="icon_auto" />}
            </div>
            <div
                className={classNames('work-bench-base-lists-title', { link: onTitleClick !== noop && isCN })}
                onClick={() => {
                    if (!isCN) return;
                    onTitleClick();
                }}
            >
                <span className="time">{dayjs(item?.car_time).format('YYYY-MM-DD HH:mm:ss')}</span>
                {tagMap[item?.event_name] && (
                    <Tag
                        style={{
                            '--background-color': 'rgba(230, 255, 251, 1)',
                            '--border-color': 'rgba(0, 203, 182, 1)',
                            '--text-color': 'rgba(0, 203, 182, 1)',
                            fontSize: '.24rem',
                        }}
                        fill="outline"
                    >
                        {tagMap[item?.event_name]}
                    </Tag>
                )}
            </div>
            <div className="work-bench-base-lists-content">
                <ProgressiveImg
                    className="work-bench-img"
                    openSomeImage={(url) => onImgClick(url)}
                    src={item?.pic_content?.url}
                />
                <div onClick={onPlayAudio} className="right-content-img">
                    <img className="work-bench-audio" src={bg} alt="audio record" />
                    <p className="voice-times">
                        {item?.voice_content?.duration}
                        <span>"</span>
                    </p>
                    {!!item?.voice_content?.duration && (
                        <div className="work-bench-audio-icon">
                            <img src={isPlay ? sound_play : sound} alt="sound_icon" />
                        </div>
                    )}
                    {item?.voice_content?.end_type === -1 && (
                        <div className="additional-btn">
                            <Button
                                onClick={(e) => {
                                    e.stopPropagation();
                                    onAdditional();
                                }}
                                style={{
                                    '--background-color': '#F0F3FF',
                                    '--text-color': '#2A69E2',
                                    '--border-color': '#2A69E2',
                                    '--border-radius': '.05rem',
                                    fontSize: '.2rem',
                                    height: '.42rem',
                                }}
                                fill="outline"
                            >
                                {translate('workBench.report.addRecord')}
                            </Button>
                        </div>
                    )}
                    {item?.voice_content?.end_type === 1 && (
                        <div className="additional-btn">
                            <Button
                                disabled
                                style={{
                                    '--border-radius': '.05rem',
                                    fontSize: '.2rem',
                                    height: '.42rem',
                                }}
                            >
                                {translate('workBench.report.recordComplete')}
                            </Button>
                        </div>
                    )}
                </div>
            </div>
        </TagName>
    );
}
