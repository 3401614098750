import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { HMI_MODE_KEY } from '@/constant/HMIMode';
import { singelPointReport } from '@/utils/report';
import { USER_MODE } from '@/store/action/parkingType';
import reportEventName from '@/constant/reportEventName';
import localCache from '@/utils/localCache';
import setHMIMode from '@/utils/setHMIMode';

// C、D的icon有调换，目前D版显示C的icon，C版显示D的icon
const modeMap = {
    D: '\ue605',
    C: '\ue606',
};

export default function UserModeButton(props) {
    const i18nLang = useSelector((state) => state?.themeAction?.i18nState);
    const currentUserMode = useSelector((state) => {
        var mode = state?.parking?.userMode;
        // if (i18nLang === 'en-US' && mode !== 'D') {
        //     mode = 'D';
        // }
        return mode;
    });

    const dispatch = useDispatch();

    function changeMode(currentMode) {
        let mode = currentMode === 'D' ? 'C' : 'D';
        singelPointReport({
            eventName: reportEventName.HMI_customer_develop_click,
            timestamp: Date.now(),
            text: mode,
        });
        localCache.set(HMI_MODE_KEY, mode);
        dispatch({
            type: USER_MODE,
            data: mode,
        });
        setHMIMode(mode);
        props.onCancel('close');
    }

    return (
        <div className="slider-item" onClick={() => changeMode(currentUserMode)}>
            <span className="iconfont iconfont-slider">{modeMap[currentUserMode]}</span>
        </div>
    );
}
