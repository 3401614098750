import { Mask } from 'antd-mobile';
import closeImg from '@/assets/workbench/close_img.png';

export default function ImgPreviewModal({ visible, onClose, url }) {
    return (
        <Mask visible={visible} onMaskClick={onClose}>
            <div className="img-view-content">
                <img src={url} alt="图片加载失败" />
                <div onClick={onClose} className="cricle-close">
                    <img src={closeImg} alt="图片" />
                </div>
            </div>
        </Mask>
    );
}
