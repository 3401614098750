import { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AppFn } from '@/utils/native';
import { singelPointReport } from '@/utils/report';
import reportEventName from '@/constant/reportEventName';
// import hnpErrorTip from '@/assets/hnp/hnp_error_tip.png';
import { HOT_AREA_TYPE } from '@/store/action/type';
// import {cusToast} from '@/components/customToast';
/*import DistanceControll from './distanceControll/index';*/
import DrivingLevelNotice from './drivingLevelNotice/index';
import SlifComponent from './adas/slifComponent/index';
import LKAComponent from './adas/LKAComponent/index';
import BSDLCADOWRCWComponent from './adas/BSDLCADOWRCWComponent/index';
import AmapComponent from './adas/AmapComponent';
import NrpComponent from './adas/NrpComponent';
import LaneChangeComponet from './laneChange/index';
import OddComponent from './oddComponent';
import TLALights from './TLALights/index';
import DrivingState from './drivingState/index';
import SharpCurve from './sharpCurve/index';
import CarRunAction from './carRunAction/index';
/*import MRCTakeOver from "./MRCTakeOver/index"*/
import MRCShiftGear from './MRCShiftGear/index';
import ComityPedestrian from './comityPedestrian/index';
import SetSpeedNotice from './setSpeedNotice/index';
import StopSign from './adas/StopSign';
import RightInfoShow from './rightInfoShow';
import LatestLaneChangeComponet from './latestLaneChange/index';
import LatestRoadMergeComponet from './latestRoadMerge/index';
import CollisionAvoidCAR from './collisionAvoid/CAR/index';
import FollowCarWarning from './followCarWarning';
import WidgetZero from '../widgetZero';
import TakeOverModal from './../takeOverModal/index';
import TaperedBarrel from './taperedBarrel/index';
import HardDisk from '@/components/drivingContainer/hardDisk/index';
import EssIcon from '@/components/drivingContainer/adas/essIcon/index';
import IhcIcon from '@/components/drivingContainer/adas/ihcIcon/index';
import MRCTor from '@/components/drivingContainer/MRCTor/index';
import workBenchEventName from '@/constant/workBenchEventName';
import PhysicalKeyReport from './physicalKeyReport';
import CpTFL from './cpTFL';
import CollisionAlert from './accCpcollisionAlert';
import LaneChangeLampRemind from './laneChangeLampRemind';
import CpWarning from './cpWarning';
import IsaWarning from './isaWarning';
import RoundaboutNotification from './roundaboutNotification';
import IntersectionInfo from './IntersectionInfo';
import DangerousTarget from './dangerousTarget/index';
import LateralDodgeInfo from './lateralDodgeInfo/index';
import { useTranslation } from 'react-i18next';
import SecondNrpComponent from './adas/SecondNrpComponent';
import NrpIcon from './adas/nrpIcon';
import RIMComponent from './adas/RimComponent';

import './index.less';
import WidgetThreeComponent from '../widgetThree/index';
import WidgetTwoComponent from '../widgetTwo/index';
const { jsButtonCallBack, pushNoticeData } = AppFn;

export default function DrivingContainer() {
    const userMode = useSelector((state) => state?.parking?.userMode);
    const automatic_state = useSelector((state) => state?.featureModelDriving?.baseData?.os?.driving?.automatic_state);
    const running_mode = useSelector((state) => state?.featureModelDriving?.baseData?.os?.driving?.running_mode);
    const car_follow_level = useSelector(
        (state) => state?.featureModelDriving?.baseData?.os?.driving?.car_follow_level,
    );
    // const position_state = useSelector(state => state?.featureModelDriving?.baseData?.os?.position_state);
    // const safety_notice_type = useSelector(state => state?.featureModelDriving?.safetyDiagnose?.notice?.data?.type);
    // const safety_notice_fatal_err = useSelector(state => state?.featureModelDriving?.safetyDiagnose?.notice?.data?.fatalErrList) || [];
    // const safety_notice_fatal_severe = useSelector(state => state?.featureModelDriving?.safetyDiagnose?.notice?.data?.severeErrList) || [];
    // const notice_hardware_action = useSelector(state => state?.func?.currentState?.notice_list?.notice_hardware_action);
    // const notice_hardware_action_type = useSelector(state => state?.func?.currentState?.notice_list?.notice_hardware_action?.data?.type);
    // const notice_hardware_action_state = useSelector(state => state?.func?.currentState?.notice_list?.notice_hardware_action?.data?.state);
    const switchModeState = useSelector((state) => state?.switchModeAction?.switchModeState);
    // 按键设置速度
    const notice_dirver_speed = useSelector(
        (state) => state?.func?.currentState?.notice_list?.notice_dirver_speed_limit_action?.data?.speed,
    );
    const switchSpeedAudio = useSelector((state) => state?.switchModeAction?.switchSpeedAudio);
    const notice_dirver_speed_pre = useRef(null);
    const [hnpTips, setHnpTips] = useState(false);
    const [positionStateShow, setPositionStateShow] = useState(false);
    const [hnpControlState, setHnpControlState] = useState(false);
    const [distanceControllShow, setDistanceControllShow] = useState(false);
    const dispatch = useDispatch();
    const noticeHardwareActionToast = useRef(null);
    // 热区相关
    const mviz = useRef(null);
    const btnRefresh = useRef(null);
    /* const distanceControllDom = useRef(null);*/

    const area = useSelector((state) => state?.layout?.Area);
    const switchMvizState = useSelector((state) => state?.switchModeAction?.switchMvizState);
    const TLA_switch = useSelector((state) => state?.featureModelDriving?.switch?.adas?.tla_switch) || 'OFF';
    const switchWorkBench = useSelector((state) => state?.switchModeAction?.switchWorkBench);
    const carType = useSelector((state) => state?.featureModelDriving?.baseData?.hmi?.car_type);
    const { t: translate } = useTranslation();

    const takeroverContinueState = useSelector((state) => state?.warningData?.takeroverContinueState);
    const dev_msg_switch = useSelector((state) => state?.featureModelDriving?.switch?.devMsgSwitch);

    // always btton hotArea
    useEffect(() => {
        const btnRefreshArea = btnRefresh?.current?.getBoundingClientRect();
        const btnRefreshInfo =
            typeof btnRefreshArea?.width !== 'undefined'
                ? `${btnRefreshArea?.x}, ${btnRefreshArea?.y}, ${btnRefreshArea?.width}, ${btnRefreshArea?.height}`
                : '0,0,0,0';
        if (mviz?.current?.getBoundingClientRect()?.width !== 0) {
            dispatch({
                type: HOT_AREA_TYPE.btnRefreshDom,
                data: btnRefreshInfo,
            });
        }
    }, [
        area?.isHorizontal,
        mviz?.current?.getBoundingClientRect()?.width,
        mviz?.current?.getBoundingClientRect()?.height,
        btnRefresh?.current?.getBoundingClientRect()?.x,
        btnRefresh?.current?.getBoundingClientRect()?.y,
        switchMvizState,
    ]);

    /* // 跟车时距显示、消失
    useEffect(() => {
        // 前端兜底-1
        if ((automatic_state === 'ACTIVE' || automatic_state === 'STANDBY') && typeof(car_follow_level) !== 'undefined') {
            if (distanceControllShow) return;
            setDistanceControllShow(true);
        }
        else {
            setDistanceControllShow(false);
        }
    }, [automatic_state, car_follow_level])*/

    /*// 跟车时距热区控制
    useEffect(() => {
        if (distanceControllShow) {
            const distanceControllArea = distanceControllDom?.current?.getBoundingClientRect();
            const distanceControllInfo = `${distanceControllArea?.x}, ${distanceControllArea?.y}, ${distanceControllArea?.width}, ${distanceControllArea?.height}`;
            if (typeof(distanceControllArea) !== 'undefined' && mviz?.current?.getBoundingClientRect()?.width !== 0) {
                dispatch({
                    type: HOT_AREA_TYPE.distanceControllDom,
                    data: distanceControllInfo
                });
            }
        }
        else {
            const distanceControllArea = distanceControllDom?.current?.getBoundingClientRect();
            typeof(distanceControllArea) !== 'undefined' && dispatch({
                type: HOT_AREA_TYPE.distanceControllDom,
                data: '',
            });
        }
    }, [
        distanceControllShow,
        area?.isHorizontal,
        mviz?.current?.getBoundingClientRect()?.width,
        distanceControllDom?.current?.getBoundingClientRect().x,
        distanceControllDom?.current?.getBoundingClientRect().y,
        switchMvizState
    ])*/

    useEffect(() => {
        if (running_mode === 'HNP') {
            // 开启
            dispatch({
                type: 'reportSwitchStatus',
                data: true,
            });
        } else {
            // 关闭
            dispatch({
                type: 'reportSwitchStatus',
                data: false,
            });
        }
    }, [running_mode]);

    // 设定速度通知
    SetSpeedNotice();

    const refreshEvent = (data) => {
        singelPointReport({
            text: 'refresh',
            eventName: reportEventName.HMI_HOME_MVIZREFRESH_CLICK,
            timestamp: new Date().getTime(),
        });
        //调取native协议
        jsButtonCallBack(data);
        pushNoticeData(JSON.stringify({ eventName: 'Web_Click_Mviz_Refresh', data: {} }));
    };

    // 车辆油门相关
    // useEffect(() => {
    //     let text = '';
    //     if (notice_hardware_action?.data) {
    //         switch(notice_hardware_action_type) {
    //             case 'THROTTLE':
    //                 if (notice_hardware_action_state === 'STEP') {
    //                     text ='智驾系统将不会自动减速，您正在控制车速，请注意安全'
    //                 }
    //                 break;
    //             default:
    //                 break;
    //         }
    //         text !== '' && (
    //             noticeHardwareActionToast.current = cusToast.show({
    //                 content: text,
    //                 duration: 0,
    //             })
    //         );
    //     }
    //     else {
    //         noticeHardwareActionToast.current && noticeHardwareActionToast.current?.close();
    //         noticeHardwareActionToast.current = null;
    //         return;
    //     }

    // }, [ running_mode, notice_hardware_action, notice_hardware_action_type, notice_hardware_action_state ]);

    // 车端工作台启用状态上报
    useEffect(() => {
        // data report
        singelPointReport({
            eventName: workBenchEventName.WB_active_view,
            timestamp: new Date().getTime(),
            reportData: {
                active: switchWorkBench ? 'on' : 'off',
                carType,
            },
        });
    }, [switchWorkBench, carType]);

    return (
        <div id="hnp-container-driving-state" ref={mviz} className={`hnp-Container`}>
            {/* <div className={`${takeroverContinueState ? 'warningBackground' : ''}`}></div>*/}
            {/*0330C版暂时去掉*/}
            {/*<div className="hnp-Container-middle">
                <TLALights />
            </div>*/}
            <div className="hnp-Container-left-container">
                {/* <div className="hnp-container-driving-state">
                    <WidgetZero />
                </div>*/}
                {/* 变道*/} {/*0330C版暂时去掉*/}
                {/* <LaneChangeComponet />*/}
                {/* 躲闪*/}
                {/* 因UNP体验较差，0829产品让去掉避让*/}
                {/* <CollisionAvoidCAR />*/}
                {/*礼让行人*/}
                {/* 0831产品让去掉礼让行人*/}
                {/*<ComityPedestrian />*/}
                {/*最晚变道点*/}
                {/* 0822下掉最晚变道点  11.1增加最晚变道点提示*/} {/*0330C版暂时去掉*/}
                {/* <LatestLaneChangeComponet />*/}
                {/* 最晚汇流点 */}
                {/* <LatestRoadMergeComponet />*/}
                {/*急转弯*/}
                {/* 0822下掉急转弯 */}
                {/* <SharpCurve /> */}
                {/*parking 提示、TOR*/}
                {/*0330C版暂时去掉*/}
                {/*<WidgetTwoComponent />*/}
                {/* adas相关icon*/}
                {/*0330C版暂时去掉*/}
                {/*<WidgetThreeComponent />*/}
                {/*{
                    distanceControllShow && (
                        <div ref={distanceControllDom} className='ditance-controll'>
                            <DistanceControll />
                        </div>
                    )
                }*/}
                {userMode !== 'C' && (
                    <div className="refreshBtn" ref={btnRefresh} onClick={() => refreshEvent('refreshMViz')}>
                        <span className="iconfont fontStyle">{'\ue626'}</span>
                    </div>
                )}
                {userMode !== 'C' && dev_msg_switch === 'OPEN' && (
                    <div className="debugmode">
                        <p className="modeTitle">Debug模式中，可在“智驾设置”中进行关闭</p>
                    </div>
                )}
                {/* {
                    switchModeState==='testMode' && (
                        <div className='positionState'>
                            <p className='positionNum'>{ position_state }</p>
                            <p>Accuracy</p>
                        </div>
                    )
                }*/}
            </div>
            {/*{*/}
            {/*    switchModeState==='testMode'*/}
            {/*    && automatic_state === 'ACTIVE'*/}
            {/*    && (safety_notice_fatal_err.length > 0 ||*/}
            {/*        (safety_notice_fatal_err.length === 0 && safety_notice_fatal_severe.length > 0 && safety_notice_fatal_severe[0] === '0xF002'))*/}
            {/*    && safety_notice_type === 'running_error'*/}
            {/*    && (*/}
            {/*        <div className='tipsItem'>*/}
            {/*            <img src={ hnpErrorTip }></img>*/}
            {/*            <p>请立即接管</p>*/}
            {/*        </div>*/}
            {/*    )*/}
            {/*}*/}
            {/* LKA:LDP、LDW、灵敏度、声音警告、方向盘震动*/}
            {/*0330C版暂时去掉*/}
            {/*<LKAComponent />*/}
            {/*adas: SLIF/TSR*/}
            {/*<SlifComponent/>*/}
            {/*0330C版暂时去掉*/}
            {/* <BSDLCADOWRCWComponent></BSDLCADOWRCWComponent>*/}
            {/*adas: Stop Sign提醒*/}
            {/*<StopSign/>*/}
            {/*右上角信息展示*/}
            {/*0330C版暂时去掉*/}
            {/*<RightInfoShow />*/}
            {/* 车辆动作相关的 */}
            {/*0831产品让去掉车辆动作相关的*/}
            {/*<CarRunAction />*/}
            {/* MRC信号接管*/}
            {/*<MRCTakeOver />*/}
            {/* MRC刹停后*/}
            {/*0330C版暂时去掉*/}
            {/*<MRCShiftGear />*/}
            {/*接管弹窗*/} {/*0330C版暂时去掉*/}
            {/*<TakeOverModal />*/}
            {/* ODD信息 */}
            {/* <OddComponent />*/}
            {/* 升降级处理*/}
            {/*  <DrivingLevelNotice/>*/}
            {/* 跟车时距提醒 */}
            {/* 1107下掉跟车时距语音提醒（别删组件） */}
            {/* <FollowCarWarning /> */}
            {/*锥形桶提醒*/} {/*0330C版暂时去掉*/}
            {/* <TaperedBarrel />*/}
            {/*数采盘未插好*/} {/*0330C版暂时去掉*/}
            {/*<HardDisk />*/}
            {/*车辆物理按键埋点上报*/} {/*0330C版暂时去掉*/}
            {/*<PhysicalKeyReport />*/}
            {/*WidgetTwo: TOR*/} {/*0330C版暂时去掉*/}
            {/*<MRCTor />*/}
            {/*CP-TFL 红绿灯直行控车*/} {/*0330C版暂时去掉*/}
            {/*<CpTFL />*/}
            {/*ADAS ESS紧急转向辅助*/} {/*0330C版暂时去掉*/}
            {/*<EssIcon />*/}
            {/*ADAS IHC 智能大灯控制*/}
            {/*0330C版暂时去掉*/}
            {/*<IhcIcon /> */}
            {/*ACC、CP碰撞风险提示*/} {/*0330C版暂时去掉*/}
            {/*<CollisionAlert />*/}
            {/*HNP、CP下的交互式变道关闭转向灯提醒*/} {/*0330C版暂时去掉*/}
            {/*<LaneChangeLampRemind />*/}
            {/*莲花山油门防误踩*/} {/*0330C版暂时去掉*/}
            {/*<AmapComponent />*/}
            {/*莲花山窄道辅助功能*/} {/*0330C版暂时去掉*/}
            {/*<NrpComponent />*/}
            {/*莲花山窄道通行功能 有按钮*/}
            {/* <SecondNrpComponent />*/}
            {/*莲花山窄道通行功能 widget3*/}
            {/*<NrpIcon />*/}
            {/*CP后车碰撞报警和加速提示、CP车道线压线提醒、CP道路边缘碰撞提醒*/}
            {/*0330C版暂时去掉*/}
            {/* <CpWarning />*/}
            {/* 莲花山未来轨迹交叉（碰撞）的风险 */}
            {/*0330C版暂时去掉 只有语音*/}
            {/* <IsaWarning />*/}
            {/* 国际版Harz车环岛提示 */}
            {/*0526C版暂时去掉*/}
            {/*<RoundaboutNotification />*/}
            {/* 危险态目标物 0526C版暂时去掉*/}
            {/*<DangerousTarget />*/}
            {/*白云山RCTA&RCTB合并为RIM 有语音和widget3 */}
            {/*<RIMComponent />*/}
            {/* 0621暂时去掉 */}
            {/*<IntersectionInfo />*/}
            {/*路口VRU/CUT in 减速提示 0621暂时去掉*/}
            {/*<LateralDodgeInfo />*/}
        </div>
    );
}
