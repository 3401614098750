import { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { HOT_AREA_TYPE } from '@/store/action/type';
import { singelPointReport } from '@/utils/report';
import Icon from '@/assets/parking/d_new/complete_icon.png';
import bg_nearby_complete_d from '@/assets/parking/nearby/bg_nearby_complete_d.png';
import bg_nearby_complete_c from '@/assets/parking/nearby/bg_nearby_complete_c.png';
import Modal from '@/components/parkingContainer/globalComponent/modal/index';
import './index.less';

export default function ParkingComplete() {
    const userMode = useSelector((state) => state?.parking?.userMode);
    const noticeType = useSelector((state) => state?.featureModelParking?.alert?.type);
    const statistic = useSelector((state) => state?.featureModelParking?.lpnp?.statistic);
    const [isShow, setIsShow] = useState(false);
    const comfirmBtnRef = useRef(null);
    const dispatch = useDispatch();
    const comfirmBtn = comfirmBtnRef?.current?.getBoundingClientRect();
    const hide = () => {
        window?.momentaJsBridge?.dispatch('changeDfdiWebView', null, 'close');
        setIsShow(false);
    };

    useEffect(() => {
        //沿途泊入成功页面
        if (noticeType === 'lpnp_nearby_parking_succ') {
            window?.momentaJsBridge?.dispatch('changeDfdiWebView', null, 'hide');

            singelPointReport({
                eventName: 'HMI_parking_on_the_way_complete_view',
                timestamp: new Date().getTime(),
                text: 'on_the_way_complete',
            });
            //LPNP完成
            setIsShow(true);
            Modal.clear();
        }
        //驶出地下停车场直接关闭。
        if (noticeType === 'lpnp_exit_park') {
            window?.momentaJsBridge?.dispatch('changeDfdiWebView', null, 'close');
            setIsShow(false);
        }
    }, [noticeType]);

    useEffect(() => {
        const comfirmBtnInfo = `${comfirmBtn?.x}, ${comfirmBtn?.y}, ${comfirmBtn?.width}, ${comfirmBtn?.height}`;
        comfirmBtn &&
            dispatch({
                type: HOT_AREA_TYPE.studyComplete,
                data: comfirmBtn ? comfirmBtnInfo : [],
            });
    }, [isShow, comfirmBtn?.x, comfirmBtn?.y, comfirmBtn?.width, comfirmBtn?.height]);

    const idD = userMode == 'D';
    return (
        <div>
            {isShow ? (
                <div className={`nearby-complete study-status ${idD ? '' : 'sr'}`}>
                    <img src={idD ? bg_nearby_complete_d : bg_nearby_complete_c} alt="" className="bg" />
                    <div className="study-status-left">
                        <h3>
                            <img className="p-study-complete" src={Icon} />
                            Parking on the way completed
                        </h3>
                        <label>Route Info:</label>
                        <p>
                            Cruising Distance{' '}
                            <i>{parseInt(statistic?.cruisingDistance - statistic?.remainingCruisingDistance || 0)}</i>m
                        </p>
                        <p>
                            Pedestrian Avoidance <i>{parseInt(statistic?.avoidPeopleCount || 0)}</i>
                        </p>
                        <p>
                            Vehicle Avoidance <i>{parseInt(statistic?.avoidVehiclesCount || 0)}</i>
                        </p>
                        <p ref={comfirmBtnRef} onClick={() => hide()} className="study-btn">
                            Complete
                        </p>
                    </div>
                </div>
            ) : (
                ''
            )}
        </div>
    );
}
