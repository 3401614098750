import { useSelector } from 'react-redux';
import iconApaIng from '@/assets/parking/icons/icon_apa_ing.png';
import './index.less';

const parking_status_map = {
    PARKING: {
        iconD: iconApaIng,
        icon: iconApaIng,
        txt: 'APA Is Working',
    },
    PARKING_OUT: {
        iconD: iconApaIng,
        icon: iconApaIng,
        txt: 'APA Is Working',
    },
};

export default function AllStatus() {
    const userMode = useSelector((state) => state?.parking?.userMode);
    const status = useSelector((state) => state?.featureModelParking?.apa?.state);

    return (
        ['PARKING', 'PARKING_OUT'].includes(status) && (
            <div className="apa-show-area">
                <div className={`p-status-item ${userMode == 'D' ? 'd' : 'sr'}`}>
                    <img
                        className="p-status-icon"
                        style={parking_status_map[status]?.iconSize}
                        src={parking_status_map[status]?.icon}
                        alt="status_icon"
                    />

                    <div className="p-status-txt-wrap">
                        <span
                            className={`p-status-txt ${userMode == 'D' ? 'd' : 'sr'} ${
                                ['NOT_IN'].indexOf(status) !== -1 ? 'disabled' : ''
                            }`}
                        >
                            {parking_status_map[status]?.txt}
                        </span>
                        {parking_status_map[status]?.subT ? (
                            <span className="p-status-sub-txt">{parking_status_map[status]?.subT}</span>
                        ) : (
                            ''
                        )}
                    </div>
                </div>
            </div>
        )
    );
}
