import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AppFn } from '@/utils/native';
import { SWITH_MODE_ACTION } from '@/store/action/type';
import { singelPointReport } from '@/utils/report';
import reportEventName from '@/constant/reportEventName';
import localCache from '@/utils/localCache';
import '@/components/slider/smartDrivingSetting/drivingAssistSetting/broadcastMode/navigationTips/index.less';

export default function MvizLayout() {
    const dispatch = useDispatch();
    const { t: translate } = useTranslation();
    const switchMvizState = useSelector((state) => state?.switchModeAction?.switchMvizState);
    const currentHMIMode = useSelector((state) => state?.parking?.userMode);
    let mvizLayoutOptions = translate('settings.mvizLayoutOptions', { returnObjects: true });

    useEffect(() => {
        localCache.set('switch_mviz_layout', switchMvizState);
        // const mviz_layout_local = localStorage.getItem('switch_mviz_layout') || 'left';
        /* dispatch({
            type: SWITH_MODE_ACTION.switchMvizState,
            data: mviz_layout_local,
        });*/
    }, []);

    // Mviz布局切换
    const switchMvizLayout = (value) => {
        if (value === switchMvizState) return;
        singelPointReport({
            eventName: reportEventName.HMI_SETTING_LAYOUTSWITCH_CLICK,
            text: value === 'right' ? 'MRight' : 'MLeft',
            timestamp: new Date().getTime(),
        });
        if (value === 'right') {
            // localStorage.setItem('switch_mviz_layout', 'right');
            localCache.set('switch_mviz_layout', 'right');
            AppFn.setChangeMviz('right');
            dispatch({
                type: SWITH_MODE_ACTION.switchMvizState,
                data: 'right',
            });
        }
        if (value === 'left') {
            // localStorage.setItem('switch_mviz_layout', 'left');
            localCache.set('switch_mviz_layout', 'left');
            AppFn.setChangeMviz('left');
            dispatch({
                type: SWITH_MODE_ACTION.switchMvizState,
                data: 'left',
            });
        }
    };

    return (
        <div className={'navigation_tips_card_wrap'}>
            <div className={'title_wrap'}>
                {currentHMIMode === 'C' ? translate('settings.mCLayout') : translate('settings.mvizLayout')}
            </div>

            <div className={'sensitivity_wrap'}>
                <div className={'select_box'}>
                    {mvizLayoutOptions.length &&
                        mvizLayoutOptions.map((item, index) => {
                            return (
                                <span
                                    key={index}
                                    className={`select_box_item ${switchMvizState === item.value ? 'active' : ''}`}
                                    style={{ width: '2.5rem' }}
                                    onClick={() => switchMvizLayout(item.value)}
                                >
                                    {item.label}
                                </span>
                            );
                        })}
                </div>
            </div>
        </div>
    );
}
