const INIT_STATE = {};

export default function featModelHmi(state = INIT_STATE, action) {
    switch (action.type) {
        case 'featureModelHmi':
            return {
                ...{
                    ...action.data,
                },
            };
        default:
            return state;
    }
}
