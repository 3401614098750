import isCNFn from '@/utils/isCN';
import { useSelector } from 'react-redux';
import { useWorkflowDeployText } from '../hook';
import { Tag } from 'antd-mobile';

const isCN = isCNFn();

export default function WorkflowList({ workflow_infos, onSelect }) {
    const workflowStatus = useSelector((state) => state.workflow?.wf_status);
    const { deployText, tagColor } = useWorkflowDeployText(workflow_infos);
    if (!isCN) return null;

    return workflow_infos.map((workflow, index) => (
        <li className="task-info-item" key={workflow.wf_name}>
            <span style={{ color: '#2A69E2' }} onClick={() => onSelect(workflow)}>
                部署工作流{index + 1}：{workflow.wf_name}
            </span>
            {workflowStatus?.wf_id === workflow.wf_id && (
                <Tag
                    color={tagColor}
                    fill="outline"
                    style={{ fontSize: '.20rem', marginLeft: '.1rem', padding: '.03rem .04rem 0' }}
                >
                    {deployText}
                </Tag>
            )}
            <br />
            {workflow.description && (
                <Tag
                    style={{
                        '--background-color': 'rgba(230, 255, 251, 1)',
                        '--border-color': 'rgba(0, 203, 182, 1)',
                        '--text-color': 'rgba(0, 203, 182, 1)',
                        fontSize: '.24rem',
                        marginTop: '.1rem',
                        padding: '.04rem',
                    }}
                    fill="outline"
                >
                    {workflow.description}
                </Tag>
            )}
        </li>
    ));
}
