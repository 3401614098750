import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { HOT_AREA_TYPE } from '@/store/action/type';
import close from '@/assets/workbench/close.png';
import TaskList from './Task/TaskList';
import CarInfo from './CarInfo/index';
import DataCollection from './DataCollection/index';
import DistanceInfo from './DistanceInfo/index';
import Report from './Report/index';
import { singelPointReport } from '@/utils/report';
import workBenchEventName from '@/constant/workBenchEventName';
import { useSetHotArea } from '@/hook/useSetHotArea';
import { useTranslation } from 'react-i18next';
import { useWorkflowStatus, useIsWorkbenchRoute } from './hook';
import { AppFn } from '@/utils/native';
import { WORKBENCH_ACTION } from '@/store/action/type';
import './index.less';

export default function WorkBench(props) {
    const { t: translate } = useTranslation();
    const carType = useSelector(
        (state) =>
            state?.featureModelParking?.hmi?.carType ||
            state?.featureModelDriving?.baseData?.hmi?.car_type ||
            state?.featureModelCommon?.carType ||
            'Devcar',
    );
    const area = useSelector((state) => state?.layout?.Area);
    const dispatch = useDispatch();
    const pollTimer = useRef(null);
    useWorkflowStatus();

    const isWorkbenchRoute = useIsWorkbenchRoute();

    const handleCancel = () => {
        props.onCancel('close');
        //工作台页面关闭的时候已经Report调用了关闭掉录音功能，不需要在单独处理
        // window?.momentaJsBridge?.dispatch('ReleaseCloudAudio', null, '');
        singelPointReport({
            eventName: workBenchEventName.WB_home_click,
            timestamp: new Date().getTime(),
            reportData: {
                openWorkbench: 'close',
                carType,
            },
        });
    };

    const getLocalList = () => {
        window.getLocalCallback = (res) => {
            try {
                let data = JSON.parse(JSON.stringify(res));
                dispatch({
                    type: 'notifyPullFileList',
                    data: data?.fileList,
                });
            } catch (err) {
                console.log('err', err);
            }
        };
        pollTimer.current = setInterval(() => {
            window?.momentaJsBridge?.dispatch('getLocalFileList', 'getLocalCallback', '');
        }, 3000);
    };

    useEffect(() => {
        getLocalList();
        AppFn.setWorkbenchStatus(true);
        dispatch({
            type: WORKBENCH_ACTION.open,
            data: true,
        });
        return () => {
            clearInterval(pollTimer.current);
            AppFn.setWorkbenchStatus(false);
            dispatch({
                type: WORKBENCH_ACTION.open,
                data: false,
            });
        };
    }, []);

    const { nodeRef: refCb, updateHotArea } = useSetHotArea(HOT_AREA_TYPE.workbenchDom, { mode: 'custom' });
    useEffect(updateHotArea, [area?.isHorizontal]);

    return (
        <div ref={refCb} className="work-bench">
            {!isWorkbenchRoute && (
                <div className="work-bench-title">
                    <div className="work-bench-close" onClick={() => handleCancel()}>
                        <img src={close} />
                    </div>
                    <h3>{translate('workBench.workBenchTitle')}</h3>
                </div>
            )}
            <div className="work-bench-content">
                <div className="left">
                    <CarInfo />
                    <DataCollection />
                    <DistanceInfo />
                </div>
                <TaskList className="work-bench-base" />
                <Report workBenchClose={handleCancel}></Report>
            </div>
        </div>
    );
}
