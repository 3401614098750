import ApaOperateStatus from './apaOperateStatus';
import ApaShowStatus from './apaShowStatus';

export default function ApaSingle() {
    return (
        <>
            {/* <ApaTips /> */}
            <ApaShowStatus />
            <ApaOperateStatus />
        </>
    );
}
