import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SWITH_MODE_ACTION } from '@/store/action/type';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from 'antd-mobile';
import { Switch } from 'antd-mobile';
import workbench from '@/assets/workbench/workbench.png';
import './index.less';
import RecordCard from './recordTimes';
import localCache from '@/utils/localCache';
import logSend from '@/utils/aLog';
import { isDebug } from '@/utils/isEnv';
import { AppFn } from '@/utils/native';
import localAudioNames from '@/constant/localAudioName';
const { playLocalAudio } = AppFn;

export default function WorkbenchCard() {
    const Mtour = useSelector((state) => state?.switchModeAction?.switchUnpState);
    const switchWorkBench = useSelector((state) => state?.switchModeAction?.switchWorkBench);
    const toastFlag = useSelector((state) => state?.smartDrivingSettingMsg?.flag);

    const [isFirstRender, setIsFirstRender] = useState(true);

    const { t: translate } = useTranslation();
    const dispatch = useDispatch();
    // const [workbenchSwitch, setWorkbenchSwitch] = useState(false);
    useEffect(() => {
        if (Mtour) {
            localCache.set('momenta_hmi_workbench_switch', !Mtour);
            dispatch({
                type: SWITH_MODE_ACTION.switchWorkBench,
                data: !Mtour,
            });
            // setWorkbenchSwitch(!Mtour);
        }
        //  else {
        //   // workbench Mtour
        //   const workbench_switch = JSON.parse(localStorage.getItem("momenta_hmi_workbench_switch")) || false;
        //   // switchWorkbench(workbench_switch);
        //   dispatch({
        //     type: SWITH_MODE_ACTION.switchWorkBench,
        //     data: workbench_switch,
        //   });
        // }
    }, [Mtour]);
    //切换workbench开关
    const switchWorkbench = (value) => {
        if (Mtour) {
            dispatch({
                type: 'setUpMsg',
                data: {
                    tooltipMsg: translate('workBench.switchWorkBenchTip'),
                    flag: !toastFlag,
                },
            });
            return;
        }

        localCache.get('isWorkbenchEnable', (data) => {
            if (isDebug && !data) {
                return dispatch({
                    type: 'setUpMsg',
                    data: {
                        tooltipMsg: translate('workBench.switchWorkBenchDebugTip'),
                        flag: !toastFlag,
                    },
                });
            }
            localCache.set('momenta_hmi_workbench_switch', value);
            dispatch({
                type: SWITH_MODE_ACTION.switchWorkBench,
                data: value,
            });
            logSend({
                action: 'switchWorkBench',
                data: { switch: value },
            });
        });
    };

    useEffect(() => {
        if (isFirstRender) {
            setIsFirstRender(false);
        } else {
            if (switchWorkBench) {
                playLocalAudio(localAudioNames.SETTING_SWITCH_ON, 'Low');
            } else {
                playLocalAudio(localAudioNames.SETTING_SWITCH_OFF, 'Low');
            }
        }
    }, [switchWorkBench]);

    const leftDom = (
        <div className={'left_dom_wrap'}>
            <div className={'switch_wrap'}>
                <div className={'content_wrap'} style={{ marginBottom: '.15rem' }}>
                    <div className={'text_wrap'}>{translate('settings.workbench')}</div>
                </div>
                <Switch
                    checked={switchWorkBench}
                    onChange={(checked) => switchWorkbench(checked)}
                    className={'customSwitch'}
                />
            </div>
        </div>
    );

    const rightDom = (
        <div style={{ marginTop: '0.31rem', float: 'right' }}>
            <img style={{ width: '1.3923rem', height: '1.3791rem' }} src={workbench} alt="error workbench" />
        </div>
    );

    return (
        <div className="workbench-card">
            <div className={'card_wrap'}>
                <Grid columns={2}>
                    <Grid.Item>{leftDom}</Grid.Item>
                    <Grid.Item>{rightDom}</Grid.Item>
                </Grid>
                <div style={{ marginLeft: '0.33rem' }}>
                    <RecordCard />
                </div>
            </div>
        </div>
    );
}
