import { useState, useEffect, useMemo } from 'react';
import { Switch } from 'antd-mobile';
import { useSelector, useDispatch } from 'react-redux';
import FM from '@/utils/nativeSubAndPub';
import { SocketMap } from '@/constant/socketMap';
import { useTranslation } from 'react-i18next';
import lane_preference_off from '@/assets/slider/lane_preference_off.png';
import lane_preference_left1 from '@/assets/slider/lane_preference_left1.png';
import lane_preference_left2 from '@/assets/slider/lane_preference_left2.png';

let preferredLaneSwitchTimer = null;
const bgMap = {
    close: lane_preference_off,
    LEFTFIRST: lane_preference_left1,
    LEFTSECOND: lane_preference_left2,
    default: lane_preference_off,
};

export default function PreferredlaneCard() {
    const { t: translate } = useTranslation();
    const options = translate('settings.lanePreferenceOptions', { returnObjects: true });
    const dispatch = useDispatch();
    const flag = useSelector((state) => state?.smartDrivingSettingMsg?.flag);
    const themeColorState = useSelector((state) => state?.themeAction?.themeColorState);
    const preferred_lane_switch = useSelector(
        (state) => state?.featureModelDriving?.switch?.driving?.lane_change_mode?.preferred_lane_switch,
    );

    const [preferredLaneSwitch, setPreferredLaneSwitch] = useState(preferred_lane_switch === 'OPEN');
    useEffect(() => {
        preferred_lane_switch && setPreferredLaneSwitch(preferred_lane_switch === 'OPEN');
        if (preferredLaneSwitchTimer) {
            clearTimeout(preferredLaneSwitchTimer);
            preferredLaneSwitchTimer = null;
        }
    }, [preferred_lane_switch]);

    function preferredLaneControl(value) {
        FM.sendToHMIDriving(
            SocketMap.preferred_lane_change,
            {
                switch: value ? 'OPEN' : 'CLOSE',
            },
            'drive_node_res_fail_cb',
        );
    }

    function handlepreferredLaneChange(checked) {
        clearTimeout(preferredLaneSwitchTimer);
        preferredLaneSwitchTimer = setTimeout(() => {
            setPreferredLaneSwitch(preferred_lane_switch === 'OPEN');
        }, 2 * 1000);
        preferredLaneControl(checked);
    }

    const preferred_lane = useSelector(
        (state) => state?.featureModelDriving?.switch?.driving?.lane_change_mode?.preferred_lane,
    );

    function handlePreferredLaneChange(value) {
        if (value === preferred_lane) return;
        if (!preferredLaneSwitch) {
            dispatch({
                type: 'setUpMsg',
                data: {
                    tooltipMsg: translate('settings.lanePreferenceTip'),
                    flag: !flag,
                },
            });
            return;
        }
        FM.sendToHMIDriving(
            SocketMap.preferred_lane_change,
            {
                preferred_lane: value,
            },
            'drive_node_res_fail_cb',
        );
    }

    const bgIcon = useMemo(() => {
        if (preferred_lane_switch !== 'OPEN') return bgMap.close;
        return bgMap[preferred_lane] || bgMap.default;
    }, [preferred_lane, preferred_lane_switch]);

    return (
        <div className="switch-box">
            <div
                className="main-swtich"
                style={{
                    backgroundColor: themeColorState === 'black' ? '#383D54' : '#F3F3F3',
                    backgroundImage: `url(${bgIcon})`,
                }}
            >
                <div className="switch-type">{translate('settings.lanePreferenceTitle')}</div>
                <div className="switch-title">&nbsp;</div>
                <Switch checked={preferredLaneSwitch} onChange={handlepreferredLaneChange} className="common-switch" />
            </div>
            <div className={`customise-content ${!preferredLaneSwitch && 'is_disabled'}`}>
                <div className="customise-title">{translate('settings.customLanePreferenceTitle')}</div>
                <div className={`select-group-item`}>
                    <p className="select-group-item-name"></p>
                    <div className={`select-box ${preferredLaneSwitch ? 'disabled' : ''}`}>
                        {options.map((item, index) => {
                            return (
                                <span
                                    key={index}
                                    className={`select-box-item ${preferred_lane === item.value ? 'active' : ''}`}
                                    onClick={() => handlePreferredLaneChange(item.value)}
                                >
                                    {item.label}
                                </span>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
}
