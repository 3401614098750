import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Switch } from 'antd-mobile';
import CardLayout from '../../../components/cardLayout';
import { cusToast } from '@/components/customToast';
import { useSelector } from 'react-redux';
import { SocketMap } from '@/constant/socketMap';
import FM from '@/utils/nativeSubAndPub';
import '@/components/slider/smartDrivingSetting/components/cardStyle/index.less';

let timer = null;
export default function AbsmCard() {
    const { t: translate } = useTranslation();
    const ABSM_switch = useSelector((state) => state?.featureModelDriving?.switch?.adas?.absm_switch);
    const [ABSMSwitch, setABSMSwitch] = useState(ABSM_switch === 'OPEN');

    useEffect(() => {
        ABSM_switch && setABSMSwitch(ABSM_switch === 'OPEN');
        if (timer) {
            clearTimeout(timer);
            timer = null;
        }
    }, [ABSM_switch]);

    // swtich: ABSM
    const switchABSMChange = (value) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            setABSMSwitch(ABSM_switch === 'OPEN');
        }, 2 * 1000);
        ABSMControl(value);
    };

    // 通知底层：ABSM
    const ABSMControl = async (value) => {
        FM.sendToHMIDriving(
            SocketMap.absmControl,
            {
                switch: value ? 'OPEN' : 'CLOSE',
            },
            'drive_node_res_fail_cb',
        );
    };

    const leftDom = (
        <div className={'left_dom_wrap'}>
            <div className={'switch_wrap'}>
                <div className={'content_wrap'}>
                    <div className={'text_wrap'}>ABSM</div>
                    <div className={'text_wrap_detail'} style={{ whiteSpace: 'pre-wrap' }}>
                        {translate('settings.absmSwitch')}
                    </div>
                </div>
                <Switch
                    checked={ABSMSwitch}
                    onChange={(checked) => switchABSMChange(checked)}
                    className={'customSwitch'}
                />
            </div>
        </div>
    );

    const rightDom = <div className={'right_dom_wrap'}></div>;

    return (
        <>
            <CardLayout leftDom={leftDom} rightDom={rightDom} value={ABSMSwitch} />
        </>
    );
}
