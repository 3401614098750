import { ParkingSocketMap } from '@/constant/socketMap';
import FM from '@/utils/nativeSubAndPub';

export default function useCPMSettingCommon() {
    // 通知底层：CPM开关
    const CPMControl = async (cpm_switch) => {
        FM.sendToHMIParking(
            ParkingSocketMap.hmi_avm_control,
            {
                command: 'cpm_switch',
                param: {
                    value: cpm_switch,
                },
            },
            null,
        );
    };

    return {
        CPMControl,
    };
}
