import { useSelector } from 'react-redux';
import { useEffect, useMemo } from 'react';
import { singelPointReport } from '@/utils/report';
import reportEventName from '@/constant/reportEventName';
import MEB_READY_GREEN from '@/assets/parking/meb/ready_green.png';
import MEB_FAIL_YELLOW from '@/assets/parking/meb/fail_yellow.png';
import MEB_ACTIVE_RED from '@/assets/parking/meb/active_red.png';
import classNames from 'classnames';

import './index.less';
const Map_Status = {
    TURN_GREEN: { '0x1': 'Ready' },
    TURN_YELLOW: { '0x3': 'Failure' },
    TURN_RED: { '0x2': 'Active' },
};
export default function MebWiget() {
    const meb_state = useSelector((state) => state?.featureModelParking?.meb?.fcnState);
    const userMode = useSelector((state) => state?.parking?.userMode);

    //MEB 提示上报
    useEffect(() => {
        if (Map_Status[meb_state]) {
            singelPointReport({
                eventName: reportEventName.HMI_MEB_VIEW,
                timestamp: new Date().getTime(),
                reportData: {
                    ...Map_Status[meb_state],
                },
            });
        }
    }, [meb_state]);
    const MEB_STATUS = (v) => {
        switch (v) {
            case 'TURN_GREEN':
                return <img src={MEB_READY_GREEN} alt="ready"></img>;
            case 'TURN_YELLOW':
                return <img src={MEB_FAIL_YELLOW} alt="fail"></img>;
            case 'TURN_RED':
                return <img src={MEB_ACTIVE_RED} alt="active"></img>;
            default:
                break;
        }
    };
    return (
        <div className={classNames('meb-wiget-wrapper', { sr: userMode === 'C' })}>
            {useMemo(() => MEB_STATUS(meb_state))}
        </div>
    );
}
