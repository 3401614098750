import { ParkingSocketMap } from '@/constant/socketMap';
import FM from '@/utils/nativeSubAndPub';

export default function useCarLightSettingCommon() {
    // 通知底层：CPM开关
    const CarLightControl = async ({
        egoTrunkOpen,
        egoMirrorLeftFolded,
        egoMirrorRightFolded,
        egoHeadlightsLow,
        egoHeadlightsHigh,
        egoLightsDaytime,
        egoBrakeLights,
        egoReverseLights,
        egoTailLights,
    }) => {
        FM.sendToHMIParking(
            ParkingSocketMap.hmi_avm_control,
            {
                egoTrunkOpen, //后盖
                egoMirrorLeftFolded, // 左后视镜折叠
                egoMirrorRightFolded, // 右后视镜折叠
                egoHeadlightsLow, //近光灯
                egoHeadlightsHigh, //远光灯
                egoLightsDaytime, // 日行灯
                egoBrakeLights, //刹车灯
                egoReverseLights, //倒车灯
                egoTailLights, //尾灯
            },
            null,
        );
    };

    return {
        CarLightControl,
    };
}
