import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Switch } from 'antd-mobile';
import CardLayout from '../../../components/cardLayout';
import amap from '@/assets/settingComNew/activeSafety/amap.png';
import { useSelector } from 'react-redux';
import { SocketMap } from '@/constant/socketMap';
import FM from '@/utils/nativeSubAndPub';
import '@/components/slider/smartDrivingSetting/components/cardStyle/index.less';
import { singelPointReport } from '@/utils/report';
import reportEventName from '@/constant/reportEventName';

let timer = null;
export default function AmapCard() {
    const i18nLang = useSelector((state) => state?.themeAction?.i18nState);
    const { t: translate } = useTranslation();
    const amap_switch = useSelector((state) => state?.featureModelDriving?.switch?.adas?.amap_switch);
    const [amapSwitch, setAmapSwitch] = useState(amap_switch === 'OPEN');

    useEffect(() => {
        amap_switch && setAmapSwitch(amap_switch === 'OPEN');
        if (timer) {
            clearTimeout(timer);
            timer = null;
        }
    }, [amap_switch]);

    // swtich: AMAP
    const switchAMAPChange = (value) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            setAmapSwitch(amap_switch === 'OPEN');
        }, 2 * 1000);
        AmapControl(value);
        // 数据上报
        reportDataFunc(value);
    };

    // 通知底层：AMAP
    const AmapControl = async (value) => {
        FM.sendToHMIDriving(
            SocketMap.adas_amap_control,
            {
                amap_switch: value ? 'OPEN' : 'CLOSE',
            },
            'drive_node_res_fail_cb',
        );
    };
    // 数据上报
    const reportDataFunc = (value) => {
        singelPointReport({
            eventName: reportEventName.HMI_AMAP_SWITCH_CLICK,
            timestamp: new Date().getTime(),
            reportData: {
                amap_switch: value ? 'OPEN' : 'CLOSE',
            },
        });
    };

    const leftDom = (
        <div className={'left_dom_wrap'}>
            <div className={'switch_wrap'}>
                <div className={'content_wrap'} style={{ marginBottom: i18nLang !== 'en-US' ? '.14' : '.3rem' }}>
                    <div className={'text_wrap'} style={{ paddingTop: '0' }}>
                        AMAP
                    </div>
                    <div className={'text_wrap_detail'} style={{ whiteSpace: 'pre-wrap' }}>
                        {translate('settings.AMAPSwitch')}
                    </div>
                    <div className={'text_wrap_detail'} style={{ color: 'rgba(255,48,30,0.8)' }}>
                        {translate('settings.testRecommended')}
                    </div>
                </div>
                <Switch
                    checked={amapSwitch}
                    onChange={(checked) => switchAMAPChange(checked)}
                    className={'customSwitch'}
                />
            </div>
        </div>
    );

    const rightDom = (
        <div className={'right_dom_wrap'}>
            <img style={{ width: '3.2rem', height: '2rem', margin: '.4rem 0 0 0' }} src={amap} />
        </div>
    );

    return (
        <>
            <CardLayout leftDom={leftDom} rightDom={rightDom} value={amapSwitch} />
        </>
    );
}
