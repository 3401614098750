import httpWorkbench from './workbenchRequest';
import http from '@/utils/http';
import { FMP_BASE_URL, RMP_BASE_URL } from '@/constant/config';
import getCarIp from '@/utils/getCarIp';

// 获取任务列表
export const getTaskList = (params) =>
    httpWorkbench.get(`${FMP_BASE_URL}/api/v1/workbench/list_vehicle_tasks`, { params });

// 获取软件包状态
export const getSoftwareStatus = (params) =>
    httpWorkbench.get(`${FMP_BASE_URL}/api/v1/workbench/software/status`, { params });

// 下载部署软件包
export const downloadAndDeploy = (params) =>
    httpWorkbench.post(`${FMP_BASE_URL}/api/v1/workbench/software/downloadAndDeploy`, { ...params });

// 获取车辆信息
export const getCarInfo = (params) => httpWorkbench.get(`${FMP_BASE_URL}/api/v1/workbench/car_list`, { params });

// 获取上传列表
export const getFileList = (params) => httpWorkbench.get(`${FMP_BASE_URL}/api/v1/workbench/get_file_list`, { params });

// 获取FDI磁盘信息
export const getRecorderDiskStatus = (ip, params) =>
    httpWorkbench.get(`http://${ip ? ip : getCarIp()}:9100/api/v1/recorder_disk_status`, { params });

//开始任务
export const startTask = (params) => http.post(`${FMP_BASE_URL}/api/v1/workbench/start_task_manual`, { ...params });

//结束任务
export const endTask = (params) => http.post(`${FMP_BASE_URL}/api/v1/workbench/end_task_manual`, { ...params });

//获取任务里程
export const getTaskMileage = (params) =>
    httpWorkbench.get(`${FMP_BASE_URL}/api/v1/workbench/get_mileage_by_task`, { params });

// 获取标记类型标签
export const getMarkTypeEnum = (params) => httpWorkbench.get(`${FMP_BASE_URL}/api/v1/workbench/enum`, { params });

// 更新标记
export const updateEventMark = (params) => httpWorkbench.post(`${FMP_BASE_URL}/api/v1/workbench/update_labels`, params);

// 获取workflow状态

export const getWorkflowStatus = () => httpWorkbench.get(`/ota/api/v1/status/summary`);

// workflow部署
export const deployWorkflow = (params) =>
    httpWorkbench.post(`${RMP_BASE_URL}/api/v1/workflow/openapi/creata_workflow_task`, params);

// 上报workflow部署状态到fmp
export const reportWorkflowStatusToFMP = (params) =>
    httpWorkbench.post(`${FMP_BASE_URL}/api/v1/workbench/workflow_status`, params);
