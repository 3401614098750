import { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ParkingSocketMap } from '@/constant/socketMap';
import FM from '@/utils/nativeSubAndPub';
import BtnItem from './btnItem/index';
import { cusToast } from '@/components/customToast';
import { singelPointReport } from '@/utils/report';
import { HOT_AREA_TYPE } from '@/store/action/type';
import { MVIZ_APA_PARKING_DATA } from '@/store/action/parkingType';
import store from '@/store/index';
import logSend from '@/utils/aLog';
import { useSetHotArea } from '@/hook';
import './index.less';

const ReportMap = {
    APA_START: { eventName: 'HMI_apa_start_park_in_click', text: 'HMI_apa_start_park_in_click' },
    RESTART_PARKING: { eventName: 'HMI_apa_restart_park_in_click', text: 'HMI_apa_restart_park_in_click' },
};
export default function OperateBtnsSr() {
    const apaStatus = useSelector((state) => state?.featureModelParking?.apa?.state);
    const mviz_msg = useSelector((state) => state?.parking?.mvizParkData);
    //根据消息类型，上报各阶段埋点
    const reportHandle = (noticeType, text) => {
        console.log(999, noticeType, ReportMap[noticeType]);
        if (ReportMap[noticeType]) {
            singelPointReport({
                eventName: ReportMap[noticeType].eventName,
                timestamp: new Date().getTime(),
                text: text || ReportMap[noticeType].text,
            });
        }
    };
    const clearCarSite = () => {
        // reset
        console.log('111222-重制之前数据', mviz_msg);
        store.dispatch({
            type: MVIZ_APA_PARKING_DATA.mvizData,
            data: {
                parking_out_direction: mviz_msg?.parking_out_direction,
                is_default_parking_out_direction: mviz_msg?.is_default_parking_out_direction,
            },
        });
    };

    const startApa = () => {
        console.log('xxxxx-选择车位', mviz_msg);
        logSend({
            action: 'hmi_apa_mviz_msg',
            data: {
                mviz_msg,
            },
        });
        //开启泊车还是继续泊车
        if (apaStatus === 'SELECTING') {
            if (!mviz_msg?.track_id) {
                return cusToast.show({
                    content: 'Select the Parking Space and Click the “Park In”!',
                    duration: 3000,
                });
            }
            logSend({
                action: 'hmi_apa_control_start_parking',
                data: {
                    cmd: 'START_PARKING',
                    parking_space_id: parseInt(mviz_msg?.track_id),
                },
            });
            const obj = {
                cmd: 'START_PARKING',
                parking_space_id: parseInt(mviz_msg?.track_id),
            };
            if (mviz_msg?.parking_in_direction) {
                obj.parking_in_direction = parseInt(mviz_msg?.parking_in_direction);
            }
            FM.sendToHMIParking(ParkingSocketMap.apa_control, obj);
            reportHandle(ReportMap['APA_START']);
        } else if (apaStatus === 'PARKING_PAUSE') {
            logSend({
                action: 'hmi_apa_control_resume_parking',
                data: {
                    cmd: 'RESTART_PARKING',
                },
            });
            FM.sendToHMIParking(ParkingSocketMap.apa_control, {
                cmd: 'RESUME_PARKING',
            });
            reportHandle(ReportMap['RESTART_PARKING']);
        }
    };
    // const resertApa = () => {

    //     // FM.sendToHMIParking(ParkingSocketMap.apa_control, {
    //     //     cmd: 'RESUME_PARKING',
    //     //     parking_space_id: parseInt(mviz_msg?.track_id),
    //     // });
    // };

    // 清除车位Id
    useEffect(() => {
        if (apaStatus !== 'SELECTING') {
            logSend({
                action: 'hmi_clear_mviz_msg_apa',
                data: {
                    apaStatus,
                    mviz_msg,
                },
            });
            clearCarSite();
        }
    }, [apaStatus]);
    const { nodeRef: apaBtnRef } = useSetHotArea(HOT_AREA_TYPE.parkingApaBtn);

    return (
        <div className="apa-p-operate-btns">
            <div ref={apaBtnRef} className="apa-p-operate-btn-group">
                {/* {
                    //开始泊车
                    apaStatus === 'SELECTING' && (
                        <BtnItem handler={startApa} name={'SELECTING'} disabled={!mviz_msg ? true : false} />
                    )
                } */}
                {
                    //继续泊车
                    apaStatus === 'PARKING_PAUSE' && (
                        <BtnItem handler={startApa} name={'PARKING_PAUSE'} disabled={false} />
                    )
                }
                {/* {
                    //重置apa进入searching
                    apaStatus === 'STANDBY'  && (
                        <BtnItem handler={resertApa} name={'STANDBY'} disabled={false} />
                    ) 
                } */}
            </div>
        </div>
    );
}
