import isCNFn from '@/utils/isCN';
import { getWorkflowDeployStatus } from '../utils';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useWorkflowRunningStatus } from './useWorkflowRunningStatus';

const isCN = isCNFn();
const deployCodeMap = {
    0: '部署失败',
    1: '部署成功',
    2: '部署中',
    different: '版本不一致',
};
const tagColorMap = {
    0: 'danger',
    1: 'success',
    2: 'success',
    different: 'danger',
};

export function useWorkflowDeployText(workflow_infos) {
    const { isDifferent } = useWorkflowRunningStatus();
    const workflowStatus = useSelector((state) => state.workflow?.wf_status);
    const isCurrentWorkflow = useMemo(() => {
        if (!isCN) return false;
        if (!workflowStatus?.wf_id) return false;
        return workflow_infos?.some(({ wf_id }) => wf_id === workflowStatus.wf_id);
    }, [workflow_infos, workflowStatus]);

    const { deployText, tagColor } = useMemo(() => {
        if (!workflowStatus) return '';
        const code = getWorkflowDeployStatus(workflowStatus);
        // 成功 不一致  -> 不一致
        // 失败 不一致 -> 失败
        // 部署中 不一致 -> 部署中
        if (!isDifferent) return { deployText: deployCodeMap[code], tagColor: tagColorMap[code] };
        if (code === 1) return { deployText: deployCodeMap.different, tagColor: tagColorMap.different };
        return { deployText: deployCodeMap[code], tagColor: tagColorMap[code] };
    }, [workflowStatus, isDifferent]);

    return { isCurrentWorkflow, deployText, tagColor, deployCodeMap };
}
