import { Button } from 'antd-mobile';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import FM from '@/utils/nativeSubAndPub';
import { HOT_AREA_TYPE } from '@/store/action/type';
import { useSetHotArea } from '@/hook/useSetHotArea';
import './index.less';

export default function NrpBtn() {
    const showNrpButton = useSelector((state) => state?.featureModelParking?.widget?.nrp?.auto_drive_button);
    const timer = useRef();
    const [loading, setLoading] = useState(false);
    function startControl() {
        setLoading(true);
        FM.sendToHMIParking('nrp_auto_control', {
            cmd: 'START',
        });
        timer.current = setTimeout(setLoading, 3000, false);
    }

    useEffect(() => {
        if (showNrpButton) return;
        clearTimeout(timer.current);
    }, [showNrpButton]);

    const { nodeRef: btnRef } = useSetHotArea(HOT_AREA_TYPE.parkingNRPBtn, { mode: 'watch' });

    return (
        <>
            {showNrpButton && (
                <div className="nrp-btn-box" ref={btnRef}>
                    <Button
                        color="primary"
                        style={{ '--border-radius': '0.16rem', '--background-color': '#2a69e3' }}
                        loading={loading}
                        onClick={startControl}
                    >
                        Vehicle Control
                    </Button>
                </div>
            )}
        </>
    );
}
