import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Switch } from 'antd-mobile';
import CardLayout from '../../../components/cardLayout';
import { useSelector, useDispatch } from 'react-redux';
import { SWITH_MODE_ACTION } from '@/store/action/type';
import '@/components/slider/smartDrivingSetting/components/cardStyle/index.less';
import localCache from '@/utils/localCache';
import { AppFn } from '@/utils/native';

export default function VoiceAssistant() {
    const { t: translate } = useTranslation();
    const dispatch = useDispatch();
    const switchAssistantState = useSelector((state) => state?.switchModeAction?.switchAssistant);
    const switchUnpState = useSelector((state) => state?.switchModeAction?.switchUnpState);

    useEffect(() => {
        localCache.set('assistant_switch', switchAssistantState);
    }, []);

    //切换语音小助手开关
    const switchUnp = (value) => {
        if (switchUnpState) return;
        localCache.set('assistant_switch', value);
        let data = { action: value };
        AppFn.switchToAssistant(data);
        dispatch({
            type: SWITH_MODE_ACTION.switchAssistant,
            data: value,
        });
    };

    const leftDom = (
        <div className={'left_dom_wrap'}>
            <div className={'switch_wrap'}>
                <div className={'content_wrap'}>
                    <div className={'text_wrap'}>{translate('settings.voiceAssistant')}</div>
                </div>
                <Switch
                    checked={switchAssistantState}
                    onChange={(checked) => switchUnp(checked)}
                    className={'customSwitch'}
                />
            </div>
        </div>
    );

    const rightDom = <></>;

    return (
        <>
            <CardLayout leftDom={leftDom} rightDom={rightDom} value={switchAssistantState} />
        </>
    );
}
