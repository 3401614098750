import { useSelector } from 'react-redux';
import './index.less';
import useShowApa from '@/components/parkingContainer/hooks/useShowApaAndLpnp';

export default function Speed(porps) {
    const speed = useSelector((state) => state?.featureModelParking?.body?.speed);
    const [isShowAPA] = useShowApa();

    const isShowTips = () => {
        const isShow = isShowAPA && speed > 25;
        return isShow;
    };

    return (
        <div className={`p-info-speed ${porps?.userMode == 'D' ? 'd' : 'sr'} ${isShowTips() ? 'fast' : ''}`}>
            <span className="p-info-speed-num">{speed ? parseInt(speed) : 0}</span>
            <span className="unit">km/h</span>
        </div>
    );
}
