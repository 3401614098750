import { ROBONODE_TYPE } from '@/store/action/type';
import store from '@/store';

let timer = 0; // 判断断连的时间戳

window.recevieFeatueModelData = function (res) {
    let modelData = {};
    if (timer === 0) {
        store.dispatch({
            type: ROBONODE_TYPE.ready,
            data: { ready: true },
        });
    }
    // 当前实时时间
    timer = Date.now();
    try {
        modelData = JSON.parse(JSON.stringify(res));
    } catch (err) {
        console.log('json解析错误', err);
    }
    let { type, data, carType } = modelData;

    store.dispatch({
        type: 'featureModelCommon',
        data: {
            type,
            carType,
        },
    });
    switch (type) {
        case 'driving':
            store.dispatch({
                type: 'featureModelDriving',
                data: data,
            });
            store.dispatch({
                type: 'currentScene',
                data: 'driving',
            });
            break;
        case 'parking':
            store.dispatch({
                type: 'featureModelParking',
                data: data,
            });
            store.dispatch({
                type: 'currentScene',
                data: 'parking',
            });
            break;
        case 'avm':
            store.dispatch({
                type: 'featureModelAvm',
                data: {
                    is_pts_data_back: true,
                    ...data?.avm_data,
                },
            });
            break;
        case 'hmi':
            store.dispatch({
                type: 'featureModelHmi',
                data: {
                    ...data?.hmi_info,
                },
            });
            break;
        default:
            break;
    }
};

setInterval(() => {
    // app端8s没有推送新数据，默认断开链接
    const currentTimer = Date.now();
    const ready = store?.getState()?.robonode?.ready;
    console.log(ready, '$$connect');
    if (currentTimer - timer >= 8 * 1000) {
        if (!ready) return;
        store.dispatch({
            type: ROBONODE_TYPE.ready,
            data: { ready: false },
        });
    } else {
        if (ready) return;
        store.dispatch({
            type: ROBONODE_TYPE.ready,
            data: { ready: true },
        });
    }
}, 1000);
