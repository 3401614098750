const INIT_STATE = {
    is_pts_data_back: false,
};

export default function FeatureModelAvm(state = INIT_STATE, action) {
    switch (action.type) {
        case 'featureModelAvm':
            return {
                // ...state,
                ...{
                    ...action.data,
                },
            };
        default:
            return state;
    }
}
