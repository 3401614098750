import { useSelector } from 'react-redux';
import MebWiget from './mebWiget';
import ADASModalComponent from '@/components/adasModal';
import { useAMAP } from './hook';
import NRP from './nrp';

export default function ADAS() {
    const lpnpMtour = useSelector((state) => state?.parking?.lpnpMtour);
    // 避障辅助驾驶[AMAP]
    useAMAP();

    return (
        <div>
            {!lpnpMtour && <MebWiget />}
            <ADASModalComponent />
            <NRP />
        </div>
    );
}
