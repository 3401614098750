import { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AppFn } from '@/utils/native';
import { singelPointReport } from '@/utils/report';
import { compareVersion } from '@/utils/compareVersion';
import reportEventName from '@/constant/reportEventName';
// import Scene from "@/components/scene/index";
import DrivingContainer from '@/components/drivingContainer';
// import StopAndGo from "@/components/drivingContainer/stopAndGo/index";
import AebAndFcwLayout from '@/components/drivingContainer/adas/aebAndFcwLayout';
import InforTips from '@/components/inforTips';
// import CollisionAvoidCAR from "@/components/drivingContainer/collisionAvoid/CAR/index";
import { SWITH_MODE_ACTION, THEME_ACTION } from '@/store/action/type';
import Slider from '@/components/slider';
import NaviSet from '@/components/sideBar/naviSet/index';
import store from '@/store';
import logSend from '@/utils/aLog';
import padAdaptMap from '@/constant/padAdaptMap';
import EventInfo from '@/components/eventInfo';
import { cusToast } from '@/components/customToast';
import workBenchEventName from '@/constant/workBenchEventName';
import SceneChange from '@/components/scene/index';
import LPNPEntry from '@/components/lpnpRecommend/index';
import FM from '@/utils/nativeSubAndPub';
import localCache from '@/utils/localCache';
import classNames from 'classnames';
import isCN from '@/utils/isCN';
import { isRelease } from '@/utils/isEnv';

import './index.less';

function getCarType() {
    const state = store.getState();
    return state?.featureModelDriving?.baseData?.hmi?.car_type || state?.featureModelCommon?.carType || 'Devcar';
}

// 获取APP版本信息
window.getAppVersion = function (data) {
    // const {versionName, versionCode, deviceId} = data;
    // localStorage.setItem('appInfo', JSON.stringify({...data}));
    localCache.set('appInfo', data);
};
// window.enterAPPEvent = function() {
//     compareVersion()
// }

// APP上报
window.nativeEvent = function (data) {
    let eventName = Object.keys(data)[0] || '';
    let reportData = typeof data?.[eventName] === 'object' ? { ...data?.[eventName] } : {};
    let text = typeof data?.[eventName] === 'string' ? data?.[eventName] : '';
    switch (eventName) {
        // 热启动上报
        case reportEventName.HMI_APP_RELIVE_VIEW:
            text = 'relive';
            break;
        // APP心跳每分钟上报
        case reportEventName.HMI_APP_ALIVE_VIEW:
            text = 'alive';
            break;
        // 开始点对点导航搜索
        case reportEventName.HMI_SEARCH_POI_CLICK:
            text = 'click';
            break;
        // 使用点对点导航算路
        case reportEventName.HMI_POI_NAVI_CLICK:
            text = 'use';
            break;
        // 路线类进行拦截，不通过这里上报，通过reportEventAtt
        case reportEventName.HMP_ROUTE_INFO:
            reportData = '';
            store.dispatch({
                type: 'changeRouterInfo',
                data: {
                    router_name: data[eventName]?.route_name,
                    router_city: data[eventName]?.route_city,
                    router_type: data[eventName]?.route_type,
                },
            });
            break;
        case workBenchEventName.WB_record_result_view:
            reportData = { ...data?.[eventName], carType: getCarType() };
            // 非release版不做数据上报
            if (!isRelease) {
                eventName = '';
            }
            logSend({
                action: workBenchEventName.WB_record_result_view,
                data: { ...reportData, isRelease },
            });
            break;
        // // 起点导航上报
        // case reportEventName.HMI_TOROUTE_NAVI_CLICK:
        //     text = data[eventName];
        //     break;
        // // 路线应用成功类型上报
        // case reportEventName.HMI_HOME_ROUTEAPPLIED_CLICK:
        //     text = data[eventName];
        //     break;
        // // 取消算路上报
        // case reportEventName.HMI_HOME_UNROUTE_CLICK:
        //     text = data[eventName];
        //     break;
        // // 点对点算路结果
        // case reportEventName.HMI_POI_CAL_VIEW:
        //     text = data[eventName];
        //     break;
        // // 点击高德"平行路"Button
        // case reportEventName.HMI_HOME_PARALLELROAD_CLICK:
        //     reportData = {...data[eventName]};
        //     break;
        // // 点击高德"车头向南"Button
        // case reportEventName.HMI_HOME_CARMODE_CLICK:
        //     reportData = {...data[eventName]};
        //     break;
        // // 点击高德"回到自身"Button
        // case reportEventName.HMI_HOME_SELFCAR_CLICK:
        //     reportData = {...data[eventName]};
        //     break;
        // // 点击高德"路径全览"Button
        // case reportEventName.HMI_HOME_OVERVIEW_CLICK:
        //     reportData = {...data[eventName]};
        //     break;
        // // 点对点导航重新算路
        // case reportEventName.HMI_HOME_RECALCULATE_ROUTE:
        //     reportData = {...data[eventName]};
        //     break;
        default:
            break;
    }
    eventName &&
        singelPointReport({
            reportData,
            text,
            eventName,
            timestamp: new Date().getTime(),
        });
};

// 获取主题颜色
window.themeColorCallback = function (data) {
    store.dispatch({
        type: THEME_ACTION.themeColorState,
        data: data,
    });
    //处理崩溃主题颜色不一致的情况
    localCache.set('themeColor', data);
    // localStorage.setItem("themeColor",data);
};
export default function Layout(props) {
    const area = useSelector((state) => state?.layout?.Area);
    const [mapPercent, SetMapPercent] = useState(0);
    const [mvizPercent, SetMvizPercent] = useState(0);
    const is_show = useSelector((state) => state?.featureModelDriving?.lpnpRecommendEntrypoint?.isShow);
    const hmi_version = useSelector((state) => state?.featureModelDriving?.baseData?.hmi?.hmi_version);
    const controlStateItem = useSelector((state) => state.hotArea.controlStateItem);
    const hnpStateBtn = useSelector((state) => state.hotArea.hnpStateBtn);
    const hnpSwitch = useSelector((state) => state.hotArea.hnpSwitch);
    const settingBtnDom = useSelector((state) => state.hotArea.settingBtnDom);
    const settingModalDom = useSelector((state) => state.hotArea.settingModalDom);
    const btnRefreshDom = useSelector((state) => state.hotArea.btnRefreshDom);
    const testAssistantDom = useSelector((state) => state.hotArea.testAssistantDom);
    const distanceControllDom = useSelector((state) => state.hotArea.distanceControllDom);
    const sceneArea = useSelector((state) => state.hotArea.sceneArea);
    const resumeControlDom = useSelector((state) => state.hotArea.resumeControlDom);
    const switchMvizState = useSelector((state) => state?.switchModeAction?.switchMvizState);
    const sliderDom = useSelector((state) => state.hotArea.sliderDom);
    const drivingStateDom = useSelector((state) => state.hotArea.drivingStateDom);
    const drivingLevelDom = useSelector((state) => state.hotArea.drivingLevelDom);
    const oddComDom = useSelector((state) => state.hotArea.oddComDom);
    const recordStateDom = useSelector((state) => state.hotArea.recordStateDom);
    const workbenchDom = useSelector((state) => state.hotArea.workbenchDom);
    const themeColorState = useSelector((state) => state?.themeAction?.themeColorState);
    const switchWorkBench = useSelector((state) => state?.switchModeAction?.switchWorkBench);
    const eventInfoDom = useSelector((state) => state?.hotArea?.eventInfoDom);
    const LPNPEntryDom = useSelector((state) => state.hotArea.LPNPEntryDom);
    const drivingNRPBtn = useSelector((state) => state.hotArea.drivingNRPBtn);

    const dispatch = useDispatch();
    const sliderArea = useRef(null);
    const [sliderWidth, setSliderWidth] = useState(0);
    const [checkObj, setChecked] = useState(null);

    const Mtour = useSelector((state) => state?.switchModeAction?.switchUnpState);
    const userMode = useSelector((state) => state?.parking?.userMode);
    // const release_platform = useSelector((state) => state?.featureModelDriving?.baseData?.hmi?.function_mode);
    SceneChange();
    // 动态匹配版本
    // useEffect(() => {
    //     compareVersion()
    // }, [ hmi_version ]);

    //动态设置工作台开关
    useEffect(() => {
        if (Mtour) {
            localCache.set('momenta_hmi_workbench_switch', !Mtour);
            dispatch({
                type: SWITH_MODE_ACTION.switchWorkBench,
                data: !Mtour,
            });
        } else {
            localCache.get('momenta_hmi_workbench_switch', (data) => {
                // 下掉release_platform字段默认true
                const workbench_switch = typeof data === 'boolean' ? data : switchWorkBench;
                dispatch({
                    type: SWITH_MODE_ACTION.switchWorkBench,
                    data: workbench_switch,
                });
            });
        }
        console.log('mmmmm-开关', Mtour);
    }, [Mtour]);

    // 动态设置map、mviz区域
    useEffect(() => {
        SetMapPercent(`${area.map}%`);
        SetMvizPercent(`${area.mviz}%`);
    }, [area]);

    // 上报热区矩阵
    useEffect(() => {
        const HotArea = [];
        controlStateItem !== '' && HotArea.push(controlStateItem);
        hnpStateBtn !== '' && HotArea.push(hnpStateBtn);
        hnpSwitch !== '' && HotArea.push(hnpSwitch);
        settingBtnDom !== '' && HotArea.push(settingBtnDom);
        settingModalDom !== '' && HotArea.push(settingModalDom);
        btnRefreshDom !== '' && HotArea.push(btnRefreshDom);
        testAssistantDom !== '' && HotArea.push(testAssistantDom);
        distanceControllDom !== '' && HotArea.push(distanceControllDom);
        sceneArea !== '' && HotArea.push(sceneArea);
        resumeControlDom !== '' && HotArea.push(resumeControlDom);
        sliderDom !== '' && HotArea.push(sliderDom);
        drivingStateDom !== '' && HotArea.push(drivingStateDom);
        recordStateDom !== '' && HotArea.push(recordStateDom);
        workbenchDom !== '' && HotArea.push(workbenchDom);
        eventInfoDom !== '' && HotArea.push(eventInfoDom);
        drivingLevelDom !== '' && HotArea.push(drivingLevelDom);
        oddComDom !== '' && HotArea.push(oddComDom);
        LPNPEntryDom !== '' && HotArea.push(LPNPEntryDom);
        drivingNRPBtn !== '' && HotArea.push(drivingNRPBtn);
        console.log('HotArea', HotArea);
        HotArea.length && AppFn.setHotArea(HotArea);
    }, [
        controlStateItem,
        hnpStateBtn,
        hnpSwitch,
        settingBtnDom,
        settingModalDom,
        testAssistantDom,
        distanceControllDom,
        btnRefreshDom,
        resumeControlDom,
        sliderDom,
        drivingStateDom,
        recordStateDom,
        workbenchDom,
        eventInfoDom,
        drivingLevelDom,
        oddComDom,
        LPNPEntryDom,
        drivingNRPBtn,
    ]);

    useEffect(() => {
        singelPointReport({
            text: 'appinit',
            eventName: reportEventName.HMI_APP_INIT_VIEW,
            timestamp: new Date().getTime(),
        });
    }, []);

    useEffect(() => {
        // 通知native：mvizLayout
        // const mviz_layout_local = localStorage.getItem('switch_mviz_layout') || 'left';
        AppFn.setChangeMviz(switchMvizState);
        /*dispatch({
            type: SWITH_MODE_ACTION.switchMvizState,
            data: mviz_layout_local,
        });*/

        // const switch_mode_local = localStorage.getItem('switch_mode') || 'testMode';
        /*dispatch({
            type: SWITH_MODE_ACTION.switchModeState,
            data: switch_mode_local,
        });*/

        /* let isHorizontal = '';
        //判断横竖屏
        if (document.documentElement.clientWidth > document.documentElement.clientHeight) {
            // 横屏
            isHorizontal = 'horizontal';
        } else if (document.documentElement.clientWidth < document.documentElement.clientHeight) {
            // 竖屏
            isHorizontal = 'vertical';
        } else {
            // 默认横屏
            isHorizontal = 'horizontal';
        }*/
        let isHorizontal = 'horizontal';
        const bounding_width = document.documentElement.getBoundingClientRect().width;
        const client_width = document.documentElement.clientWidth;
        const screen_width = window.screen.width;
        const avail_width = window.screen.availWidth;
        const compatible_width =
            document.documentElement.getBoundingClientRect().width > document.documentElement.clientWidth
                ? document.documentElement.clientWidth
                : document.documentElement.getBoundingClientRect().width;
        const slider_Width_getBounding = sliderArea?.current?.getBoundingClientRect()?.width;
        const slider_Width_client = sliderArea?.current?.clientWidth;
        // const sliderAreaWidth = ((width / 10.8) * 1.35 /  window.devicePixelRatio) - 4
        const sliderAreaWidth = padAdaptMap[client_width]
            ? padAdaptMap[client_width]
            : sliderArea?.current?.offsetWidth;
        setSliderWidth(sliderAreaWidth);
        dispatch({
            type: 'resetArea',
            data: {
                map: '35.0',
                mviz: '65.0',
                slider: sliderAreaWidth,
                isHorizontal: isHorizontal,
            },
        });
        let data = {
            mapArea: '35.0',
            mVizArea: '65.0',
            verticalMapArea: '35.0',
            verticalMvizArea: '65.0',
            sliderArea: sliderAreaWidth,
        };
        logSend({
            action: 'setAreaToNative',
            data: {
                ...data,
                bounding_width,
                client_width,
                screen_width,
                avail_width,
                compatible_width,
                slider_Width_getBounding,
                slider_Width_client,
            },
        });
        console.log(data);
        AppFn.setMviz(data);
    }, [sliderArea?.current?.offsetWidth, switchMvizState]);

    useEffect(() => {
        const layout_offset_width = document.documentElement.offsetWidth;
        const layout_client_width = document.documentElement.clientWidth;
        const layout_screen_width = window.screen.width;
        const layout_getBounding_width = document.documentElement.getBoundingClientRect().width;
        const layout_compatible_width =
            document.documentElement.getBoundingClientRect().width > document.documentElement.clientWidth
                ? document.documentElement.clientWidth
                : document.documentElement.getBoundingClientRect().width;

        const slider_offset_width = sliderArea?.current?.offsetWidth;
        const slider_client_width = sliderArea?.current?.clientWidth;
        const slider_getBounding_width = sliderArea?.current?.getBoundingClientRect()?.width;

        logSend({
            action: 'getAreaWidth',
            data: {
                layout_offset_width,
                layout_client_width,
                layout_screen_width,
                layout_getBounding_width,
                layout_compatible_width,
                slider_offset_width,
                slider_client_width,
                slider_getBounding_width,
            },
        });
    }, [
        document.documentElement.offsetWidth,
        document.documentElement.clientWidth,
        window.screen.width,
        document.documentElement.getBoundingClientRect().width,
        sliderArea?.current?.offsetWidth,
        sliderArea?.current?.clientWidth,
        sliderArea?.current?.getBoundingClientRect()?.width,
    ]);

    // useEffect(() => {
    //     const sliderAreaWidth = sliderArea?.current?.getBoundingClientRect()?.width;
    //     setSliderWidth(sliderAreaWidth);
    //     dispatch({
    //         type: 'resetArea',
    //         data: {
    //             map: '35.0',
    //             mviz: '65.0',
    //             slider: sliderAreaWidth,
    //             isHorizontal: isHorizontal,
    //         },
    //     })
    //     AppFn.setMviz({mapArea:'35.0',mVizArea: '65.0', verticalMapArea:'35.0',verticalMvizArea: '65.0', sliderArea: sliderAreaWidth});
    // }, [ sliderArea?.current?.getBoundingClientRect()?.width ])

    window.getOrientation = function (data) {
        dispatch({
            type: 'resetArea',
            data: {
                map: '35.0',
                mviz: '65.0',
                slider: sliderWidth,
                isHorizontal: data,
            },
        });
        // AppFn.setMviz({mapArea:'35.0',mVizArea: '65.0',verticalMapArea:'35.0',verticalMvizArea: '65.0'});
    };

    //  native click navi setting
    window.getNaviView = function (data) {
        // 布尔值 1 0
        setChecked(data ? 'navi' : null);
    };
    const handleCancel = (item) => {
        if (item === 'close') {
            setChecked(null);
        }
    };

    useEffect(() => {
        //is_show 状态 清除标识
        if (!is_show) {
            localCache.set('lpnp_entry_closed', false);
        }
    }, [is_show]);

    /*useEffect(() => {
        let themeColorValue;
        localCache.get('themeColor', (data) => {
            themeColorValue = data;
        });
        dispatch({
            type: THEME_ACTION.themeColorState,
            data: themeColorValue,
            // data: localStorage.getItem("themeColor"),
        });
    }, []);*/
    return (
        <div className={`Layout-box ${themeColorState}`}>
            <div className={`sliderArea ${userMode === 'C' ? 'sliderC' : ''}`} ref={sliderArea}>
                <Slider />
            </div>
            <div className={`${area.isHorizontal} Layout-Container ${switchMvizState}`}>
                <div style={{ width: mapPercent }} className="mapArea">
                    {/* <StopAndGo /> */}
                    <div className={classNames('mapRightBox', { 'show-record': switchWorkBench })}>
                        <EventInfo />
                        <InforTips />
                    </div>

                    {checkObj === 'navi' && <NaviSet onCancel={handleCancel}></NaviSet>}
                </div>
                <div style={{ width: mvizPercent }} className="mvizArea">
                    <DrivingContainer />
                    {/*0330C版暂时去掉*/}
                    {/*<AebAndFcwLayout />*/}
                    {is_show ? <LPNPEntry /> : ''}
                    {/*  <CollisionAvoidCAR />*/}
                </div>
            </div>
        </div>
    );
}
