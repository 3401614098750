export const ROBONODE_TYPE = {
    // ready: true
    ready: 'ready',
};

export const NATIVE_ACTION = {
    hnpChangeState: 'hnpChangeState', // hnp 状态 => 进入：ACTIVE   退出：OFF
    naviState: '', // 导航状态 => start: 开始导航    exit: 手动退出导航
};

// 热区事件type
export const HOT_AREA_TYPE = {
    isHotAll: 'isHotAll',
    controlStateItem: 'nav', // hnp、acc、cp升降级区域
    hnpStateBtn: 'reload', // hnp 激活、退出区域
    hnpSwitch: 'card', // hnp 开关预期
    settingBtnDom: 'settingBtn', // 设置按钮
    settingModalDom: 'settingModal', // 设置按钮弹框
    btnRefreshDom: 'btnReload', // 刷新按钮
    testAssistantDom: 'testAssistantModal', // 路测帮手
    sceneArea: 'sceneArea', // driving和parking场景切换
    parkingBtn: 'parkingBtn', // parking按钮点击区
    parkingApaBtn: 'parkingApaBtn', // apa按钮点击区
    backBtn: 'backBtn', // parking返回点击区
    cancelBtn: 'cancelBtn', // parking取消按钮点击区
    backHomeBtn: 'backHomeBtn', // parking返回首页按键
    guideLpnpBtn: 'guideLpnpBtn', //导航页开启LPNP功能
    startApaBtn: 'startApaBtn', //开启apa中间按钮
    cancelStudyBtn: 'cancelStudyBtn', //学习完成页面取消弹窗
    apaConfirm: 'apaConfirm', //泊车完成确认按钮
    apaCancel: 'apaCancel', //泊车完成取消按钮
    studyComplete: 'studyComplete', // parking学习完成按钮
    pnpSoftSwitch: 'pnpSoftSwitch', // LPNP软开关
    distanceControllDom: 'distanceControll', // 跟车时距
    resumeControlDom: 'resumeControl', // resume按钮功能
    sliderDom: 'sliderDom', // 侧边栏
    drivingStateDom: 'drivingStateDom', // 智驾状态区
    drivingLevelDom: 'drivingLevelDom', // 升降级
    oddComDom: 'oddComDom', // odd区
    recordStateDom: 'recordStateDom', // 工作台录音按键区
    workbenchDom: 'workbenchDom',
    eventInfoDom: 'eventInfoDom', // 事件上报
    debugBackArea: 'debugBackArea', // apa debug返回
    debugOperatorArea: 'debugOperatorArea', // apa debug操作区
    LPNPDebugOperatorArea: 'LPNPDebugOperatorArea', // apa debug操作区
    LPNPEntryDom: 'LPNPEntryDom',
    parkingNRPBtn: 'parkingNRPBtn',
    drivingNRPBtn: 'drivingNRPBtn',
};

export const MAP_TYPE = {
    locateInfo: 'locateInfo',
    route: 'route',
    drivingState: 'drivingState',
    routeLoading: 'routeLoading',
    routeResult: 'routeResult',
};

// theme
export const THEME_ACTION = {
    themeColorState: 'themeColorState', // 主题模式
    i18nState: 'i18nState', // i18n
};

// switch_mode
export const SWITH_MODE_ACTION = {
    switchModeState: 'switchModeState', // 模式切换
    switchMvizState: 'switchMvizState', // Mviz布局切换
    switchSpeedAudio: 'OFF', // 车速调整语音开关  0905产品逻辑默认改为OFF
    switchUnpState: 'switchUnpState', // UNP Mtour抑制开关
    switchEventState: 'switchEventState', // event 自动产线开关
    switchWorkBench: 'switchWorkBench', // workbench开关
    recordTimes: 'recordTimesState', // 录音时长
    isRecording: 'isRecordinState', // 是否正在录音
    switchFollowCarState: 'switchFollowCarState', // 跟车时距开关
    switchDrivingBroadcast: 'switchDrivingBroadcast', // 智驾播报模式切换
    switchTFLState: 'switchTFLState', // TFL开关
    switchRoundaboutState: 'switchRoundaboutState', // roundabout开关
    // 接管录音开关
    switchTakeoverRecord: 'switchTakeoverRecord',
    // 工作台所有录音开关
    switchAllRecord: 'switchAllRecord',
    switchAssistant: 'switchAssistant', // 语音小助手开关
    // 快捷选路
    switchShortcutRouteWitch: 'switchShortcutRouteWitch',
    //tsa告警
    switchTsaAlertWitch: 'switchTsaAlertWitch',
};

// widget data
export const WIDGET_ACTION = {
    widget1Action: 'widget1Action',
    widget2Action: 'widget2Action',
    widget3Action: 'widget3Action',
};
//modal data
export const MODAL_ACTION = {
    modalAction: 'modalAction',
};
//taker over modal data
export const TAKE_OVER_ACTION = {
    takerOverState: 'takerOverState', // 是否接管，持续3S消失
    takeroverContinueState: 'takeroverContinueState', //是否接管，跟随信号持续出现
};

// widget 0 data
export const WIDGET_ZERO_ACTION = {
    drivinglevelState: 'drivinglevelState', // 升降级
    oddComState: 'oddComState', // odd
};

export const WORKFLOW_ACTION = {
    workflowStatus: 'workflowStatus',
};

export const WORKBENCH_ACTION = {
    open: 'open',
};
