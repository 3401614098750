import { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ParkingSocketMap } from '@/constant/socketMap';
import FM from '@/utils/nativeSubAndPub';
import { getStudyStatus, statusMap } from './util';
import Modal from '../../../globalComponent/modal';
import BtnItem from './btnItem/index';
import { cusToast } from '@/components/customToast';
import { singelPointReport } from '@/utils/report';
import reportEventName from '@/constant/reportEventName';
import { HOT_AREA_TYPE } from '@/store/action/type';
import { MVIZ_APA_PARKING_DATA } from '@/store/action/parkingType';
import store from '@/store/index';
import { AppFn } from '@/utils/native';
import localCache from '@/utils/localCache';
import SwitchBtn from '@/components/parkingContainer/main/operateArea/switchBtn';
import logSend from '@/utils/aLog';

import './index.less';

// const NOT_CHANGE_SCENE = ["LEARNING", "MAP_BEING_VERIFIED", "CRUISING", "PARKING", "APA_SELECTING", "APA_PARKING"];

export default function OperateBtnsSr() {
    const userMode = useSelector((state) => state?.parking?.userMode);
    const status = useSelector((state) => state?.featureModelParking?.lpnp?.state);
    const apaStatus = useSelector((state) => state?.featureModelParking?.apa?.state);
    const oneClickEgoParkingSlotTrackId = useSelector(
        (state) => state?.featureModelParking?.apa?.oneClickEgoParkingSlotTrackId,
    );
    const canOneClickParkIn = useSelector((state) => state?.featureModelParking?.apa?.canOneClickParkIn);

    const lpnp_switch = useSelector((state) => state?.featureModelParking?.switch?.lpnpSwitch);
    const apa_switch = useSelector((state) => state?.featureModelParking?.switch?.apaSwitch);
    const canSearching = useSelector((state) => state?.featureModelParking?.apa?.canSearching);
    const canSearchingOut = useSelector((state) => state?.featureModelParking?.apa?.canSearchingOut);
    const isStationary = useSelector((state) => state?.featureModelParking?.body?.isStationary);
    const parkingMode = useSelector((state) => state?.featureModelParking?.hmi?.parkingMode);
    const condition = useSelector((state) => state?.featureModelParking?.lpnp?.condition);
    const mviz_msg = useSelector((state) => state?.parking?.mvizParkData);
    const isOnRamp = useSelector((state) => state?.featureModelParking?.lpnp?.condition?.isOnRamp);
    const gear = useSelector((state) => state?.featureModelParking?.body?.gear);
    const dispatch = useDispatch();
    const parkingBtnRef = useRef(null);
    const parkingBtn = parkingBtnRef?.current?.getBoundingClientRect();

    const clearCarSite = () => {
        console.log('111222-重制之前数据-2', mviz_msg);
        // reset
        store.dispatch({
            type: MVIZ_APA_PARKING_DATA.mvizData,
            data: {
                parking_out_direction: mviz_msg?.parking_out_direction,
                is_default_parking_out_direction: mviz_msg?.is_default_parking_out_direction,
            },
        });
    };
    //重置按钮共存状态
    const getIsInSingleAPA = () => {
        const needStatusList = ['NOT_IN', 'PASSIVE', 'STANDBY'];
        const needApaStatusList = ['STANDBY', 'NOT_IN'];
        const baseRule =
            // userMode === 'D' &&
            apa_switch === 'OPEN' && needStatusList.includes(status) && needApaStatusList.includes(apaStatus);
        return baseRule;
    };
    const handleStartStudy = async () => {
        localCache.set('lpnp_start_apa_selecting', false);
        if (condition?.isMapBuilt) {
            // 重新学习
            singelPointReport({
                eventName: reportEventName.HMI_LPNP_ROUTEUPDATE_CLICK,
                timestamp: new Date().getTime(),
                text: 'lpnp routeupdate',
            });
            return Modal.info({
                onOk: async () => {
                    // 重新学习确认
                    singelPointReport({
                        eventName: reportEventName.HMI_LPNP_ROUTEUPDATE_CONFIRM_CLICK,
                        timestamp: new Date().getTime(),
                        text: 'lpnp routeupdate confirm',
                    });
                    FM.sendToHMIParking(ParkingSocketMap.lpnp_cmd, {
                        cmd: 'START_LEARNING',
                    });
                },
                okText: 'YES',
                cancelText: 'NO',
                content: 'Are you sure to re-learn a new route and replace  the old one?',
                centered: true,
                isD: userMode === 'D',
            });
        }

        // 开始学习
        singelPointReport({
            eventName: reportEventName.HMI_LPNP_START_LEARNING_CLICK,
            timestamp: new Date().getTime(),
            text: 'lpnp start learning',
        });
        FM.sendToHMIParking(ParkingSocketMap.lpnp_cmd, {
            cmd: 'START_LEARNING',
        });
    };

    //点击建图完成
    const handleCompleteMap = async () => {
        store.dispatch({
            type: 'SHOWLEARNINGCOMPLETE',
            data: true,
        });
    };

    const handleParking = async () => {
        //启动LPNP[开始LPNP]
        singelPointReport({
            eventName: reportEventName.HMI_LPNP_CRUISE_START_CLICK,
            timestamp: new Date().getTime(),
            text: 'lpnp start',
        });
        FM.sendToHMIParking(ParkingSocketMap.lpnp_cmd, {
            cmd: 'START_PARKING',
        });
    };

    window.apa_control_cb_start_parking_new = (res) => {
        if (res?.data?.code === 0) {
            clearCarSite();
        } else {
            cusToast.show({
                content: res?.msg || 'system error',
            });
        }
    };

    const handleAPAParking = async (name) => {
        //启动apa[开始泊车]
        singelPointReport({
            eventName: name,
            timestamp: new Date().getTime(),
            text: 'apa start',
        });
        if (!mviz_msg?.track_id) return;
        const obj = {
            cmd: 'START_PARKING',
            parking_space_id: parseInt(mviz_msg?.track_id),
        };
        if (mviz_msg?.parking_in_direction) {
            obj.parking_in_direction = parseInt(mviz_msg?.parking_in_direction);
        }
        FM.sendToHMIParking(ParkingSocketMap.apa_control, obj, 'apa_control_cb_start_parking_new');
    };

    window.lpnp_control_cb_enter_nearby_parking = (res) => {
        if (res?.data?.code === 0) {
            console.log('沿途泊入进入成功');
        } else {
            cusToast.show({
                content: res?.msg || 'system error',
            });
        }
    };
    //启动沿途泊车
    const handleEenterNearbySearching = async () => {
        singelPointReport({
            eventName: 'HMI_lpnp_parking_on_the_way_switch_click',
            timestamp: new Date().getTime(),
            text: 'start on_the_way',
        });
        FM.sendToHMIParking(
            ParkingSocketMap.lpnp_cmd,
            {
                cmd: 'START_NEARBY_SEARCHING',
            },
            'lpnp_control_cb_enter_nearby_parking',
        );
    };

    //从沿途找车位->LPNP巡航
    const handleEenterLpnp = async () => {
        singelPointReport({
            eventName: 'HMI_lpnp_cruise_switch_click',
            timestamp: new Date().getTime(),
            text: 'change lpnp',
        });
        FM.sendToHMIParking(
            ParkingSocketMap.lpnp_cmd,
            {
                cmd: 'CANCEL_NEARBY_SEARCHING',
            },
            'lpnp_control_cb_enter_nearby_parking',
        );
    };

    //继续沿途泊车
    const handleResumeNearbySearching = async () => {
        singelPointReport({
            eventName: 'HMI_parking_on_the_way_resume_search_click',
            timestamp: new Date().getTime(),
            text: 'HMI_parking_on_the_way_resume_search_click',
        });
        // if (condition?.isCruisingComplete) {
        //     cusToast.show({
        //         content: 'Cruise parking endpoint has been reached!',
        //         duration: 3000,
        //     });
        //     return;
        // }
        FM.sendToHMIParking(ParkingSocketMap.lpnp_cmd, {
            cmd: 'RESUME_NEARBY_SEARCHING',
        });
    };

    const handleAPAContinueParking = async () => {
        //启动apa[开始泊车]
        singelPointReport({
            eventName: 'HMI_lpnp_apa_pause_click',
            timestamp: new Date().getTime(),
            text: 'HMI_lpnp_apa_pause_click',
        });
        // Loading.show();
        FM.sendToHMIParking(ParkingSocketMap.apa_control, {
            cmd: 'RESUME_PARKING',
        });
    };

    const canParkingUse = (condition) => {
        return (
            condition?.isMapBuilt &&
            condition?.isOnRoute &&
            condition?.isThroughMappingStartPos &&
            ['P', 'D'].includes(gear)
        );
    };

    const isNeedToOut = () => {
        return condition?.isLearningComplete || condition?.isLpnpComplete;
    };

    window.pilot_kind_control_cb_driving = (res) => {
        if (res?.data?.code === 0) {
            //退出parking点击
            singelPointReport({
                eventName: reportEventName.HMI_PARKING_EXIT_CLICK,
                timestamp: new Date().getTime(),
                text: 'parking exit',
            });
        }
    };

    //apa重置按钮
    const resertApa = () => {
        singelPointReport({
            eventName: 'HMI_apa_reset_click',
            timestamp: new Date().getTime(),
            text: 'HMI_apa_reset_click',
        });
        FM.sendToHMIParking(ParkingSocketMap.apa_control, {
            cmd: 'START_SEARCHING',
        });
    };

    //apa泊出按钮
    const apaParkOut = () => {
        singelPointReport({
            eventName: 'HMI_apa_reset_click',
            timestamp: new Date().getTime(),
            text: 'HMI_apa_reset_click',
        });
        FM.sendToHMIParking(ParkingSocketMap.apa_control, {
            cmd: 'START_PARKING_OUT',
            parking_out_direction: mviz_msg?.parking_out_direction,
        });
    };

    // 清除车位Id
    useEffect(() => {
        if (status != 'APA_SELECTING') {
            logSend({
                action: 'hmi_clear_mviz_msg_lpnp',
                data: {
                    mviz_msg,
                },
            });
            clearCarSite();
        }
    }, [status]);

    useEffect(() => {
        const parkingBtnInfo = `${parkingBtn?.x}, ${parkingBtn?.y}, ${parkingBtn?.width}, ${parkingBtn?.height}`;
        console.log('parkingBtnInfo', parkingBtnInfo);
        dispatch({
            type: HOT_AREA_TYPE.parkingBtn,
            data: parkingBtnInfo,
        });
    }, [status, parkingBtn?.x, parkingBtn?.y, parkingBtn?.width, parkingBtn?.height]);
    const showLPNPBtn = () => {
        const baseRule =
            getStudyStatus(status) != statusMap.NOT_IN && getStudyStatus(status) != undefined && lpnp_switch == 'OPEN';
        return baseRule;
    };

    const startApa = () => {
        //开启泊车还是继续泊车
        // if (apaStatus === 'SELECTING') {
        // if (!mviz_msg?.track_id) {
        //     return cusToast.show({
        //         content: 'Select the Parking Space and Click the “Park In”!',
        //         duration: 3000,
        //     });
        // }
        logSend({
            action: 'hmi_apa_mviz_msg',
            data: {
                mviz_msg,
                oneClickEgoParkingSlotTrackId,
                canOneClickParkIn,
            },
        });
        //一键泊入，满足有一键泊入车位且一键泊入车位id和mviz推荐车位id相同或者只有一键泊入id，没有推荐车位id
        if (
            canOneClickParkIn &&
            oneClickEgoParkingSlotTrackId >= 0 &&
            (oneClickEgoParkingSlotTrackId === mviz_msg?.track_id || !mviz_msg?.track_id)
        ) {
            const obj2 = {
                cmd: 'START_ONE_CLICK_PARKING',
                parking_space_id: oneClickEgoParkingSlotTrackId,
            };
            if (mviz_msg?.parking_in_direction) {
                obj2.parking_in_direction = parseInt(mviz_msg?.parking_in_direction);
            }
            FM.sendToHMIParking(ParkingSocketMap.apa_control, obj2);
        } else {
            const obj = {
                cmd: 'START_PARKING',
                parking_space_id: parseInt(mviz_msg?.track_id),
            };
            if (mviz_msg?.parking_in_direction) {
                obj.parking_in_direction = parseInt(mviz_msg?.parking_in_direction);
            }
            FM.sendToHMIParking(ParkingSocketMap.apa_control, obj);

            singelPointReport({
                eventName: 'HMI_apa_start_park_in_click',
                timestamp: new Date().getTime(),
                text: 'HMI_apa_start_park_in_click',
            });
        }
    };
    const apaSearchParkOut = () => {
        FM.sendToHMIParking(ParkingSocketMap.apa_control, {
            cmd: 'START_SEARCHING_OUT',
        });
    };
    // console.log(
    //     '111222-parking_out_direction',
    //     mviz_msg?.parking_out_direction,
    //     !mviz_msg?.parking_out_direction,
    // );
    return (
        <div ref={parkingBtnRef} className="p-operate-btns">
            <div className="p-operate-btn-group">
                {getStudyStatus(status) == statusMap.STANDBY && showLPNPBtn() && !isNeedToOut() ? (
                    <>
                        {Boolean(canParkingUse(condition)) ? (
                            <BtnItem handler={handleParking} name={'LPNP_OPEN'} disabled={false} />
                        ) : Boolean(condition?.isMapBuilt) ? (
                            <BtnItem name={'LPNP_OPEN'} disabled={true} />
                        ) : (
                            ''
                        )}
                    </>
                ) : (
                    ''
                )}
                {getStudyStatus(status) == statusMap.STANDBY && showLPNPBtn() && !isNeedToOut() ? (
                    <>
                        {condition?.isMapBuilt ? (
                            <BtnItem handler={handleStartStudy} name={'LEARNING_RESTART'} />
                        ) : (
                            <BtnItem handler={handleStartStudy} name={'LEARNING_START'} />
                        )}
                    </>
                ) : (
                    <></>
                )}
                {
                    //完成建图
                    (parkingMode === 'LEARNING' || parkingMode === 'LEARNING_OUT') && showLPNPBtn() ? (
                        <BtnItem
                            handler={() => {
                                handleCompleteMap();
                            }}
                            name={'LEARNING_COMPLETE'}
                            disabled={!isStationary}
                        />
                    ) : (
                        <></>
                    )
                }
                {(parkingMode === 'LEARNING' || parkingMode === undefined) &&
                getStudyStatus(status) == statusMap.APA_SELECTING &&
                showLPNPBtn() ? (
                    <BtnItem
                        handler={() => {
                            handleAPAParking(reportEventName.HMI_LPNP_APA_START_CLICK);
                        }}
                        name={'APA_PARKING'}
                        disabled={!mviz_msg ? true : false}
                    />
                ) : (
                    <></>
                )}

                {getStudyStatus(status) == statusMap.APA_PARKING_PAUSE && showLPNPBtn() ? (
                    //泊车暂停
                    <BtnItem handler={handleAPAContinueParking} name={'APA_PARKING_PAUSE'} disabled={false} />
                ) : (
                    <></>
                )}
                {getStudyStatus(status) == 'PARKING_PAUSE' && showLPNPBtn() ? (
                    //泊车暂停
                    <BtnItem handler={handleAPAContinueParking} name={'PARKING_PAUSE'} disabled={false} />
                ) : (
                    <></>
                )}
                {getStudyStatus(status) == statusMap.PARKING_PAUSE && showLPNPBtn() ? (
                    //巡航暂停
                    <BtnItem handler={handleAPAContinueParking} name={'PARKING_PAUSE'} disabled={false} />
                ) : (
                    <></>
                )}

                {(parkingMode === 'CRUISING' || parkingMode === undefined) &&
                    !isOnRamp &&
                    getStudyStatus(status) === statusMap.CRUISING &&
                    showLPNPBtn() && (
                        // 进入seaching
                        <SwitchBtn handler={handleEenterNearbySearching} name="CRUISING" />
                    )}

                {parkingMode === 'NEARBY' && getStudyStatus(status) == statusMap.APA_SELECTING && showLPNPBtn() && (
                    //开始泊车 继续训车位
                    <>
                        <BtnItem
                            handler={() => {
                                handleAPAParking('HMI_parking_on_the_way_start_APA_click');
                            }}
                            name={'APA_PARKING'}
                            disabled={!mviz_msg}
                        />
                        <BtnItem handler={handleResumeNearbySearching} name={'NEARBY_APA_SEARCHING'} />
                    </>
                )}
                {parkingMode === 'NEARBY' &&
                    !isOnRamp &&
                    getStudyStatus(status) === 'NEARBY_SEARCHING' &&
                    showLPNPBtn() && (
                        //切换到lpnp
                        <SwitchBtn handler={handleEenterLpnp} name="NEARBY_SEARCHING" />
                    )}
                {(getIsInSingleAPA() || apaStatus === 'SEARCHING_OUT') && canSearching && (
                    <div className="p-operate-btn-group">
                        <BtnItem handler={resertApa} name={'STANDBY'} disabled={false} />
                    </div>
                )}

                {
                    //泊出
                    (getIsInSingleAPA() || apaStatus === 'SEARCHING') && canSearchingOut && userMode === 'D' && (
                        <div className="p-operate-btn-group">
                            <BtnItem handler={apaSearchParkOut} name={'STANDBY_OUT'} disabled={false} />
                        </div>
                    )
                }

                {
                    // apa选车位
                    ((getStudyStatus(status) == statusMap.STANDBY || getStudyStatus(status) == statusMap.NOT_IN) &&
                        apaStatus === 'SELECTING') ||
                    canOneClickParkIn ? (
                        <>
                            <BtnItem
                                handler={startApa}
                                name={'SELECTING'}
                                disabled={mviz_msg || canOneClickParkIn ? false : true}
                            />
                        </>
                    ) : (
                        ''
                    )
                }
                {
                    // 开始泊出
                    (getStudyStatus(status) == statusMap.STANDBY || getStudyStatus(status) == statusMap.NOT_IN) &&
                    apaStatus === 'SEARCHING_OUT' &&
                    userMode === 'D' ? (
                        <>
                            <BtnItem
                                handler={apaParkOut}
                                name={'STANDBY_APA_OUT'}
                                disabled={!mviz_msg?.parking_out_direction}
                            />
                        </>
                    ) : (
                        ''
                    )
                }
            </div>
        </div>
    );
}
