import Router from './router/index';
import Record from '@/components/workbench/record';
import { useInitLocalCache } from './hook';
import { useSelector } from 'react-redux';
import { useDataAcquisitionDiskStatus, useDataAcquisitionDiskTip } from '@/components/workbench/hook';

function App() {
    // 初始化本地缓存的状态，提交到redux
    useInitLocalCache();
    // 获取数采盘状态，提交到redux
    useDataAcquisitionDiskStatus();
    // 数采盘相关提示
    useDataAcquisitionDiskTip();

    const switchWorkBench = useSelector((state) => state?.switchModeAction?.switchWorkBench);
    const switchAllRecord = useSelector((state) => state?.switchModeAction?.switchAllRecord);

    return (
        <>
            <Router></Router>
            {switchWorkBench && switchAllRecord && <Record />}
        </>
    );
}

export default App;
