import { useSelector } from 'react-redux';
import MvizLayout from './MvizLayout';
import AutoEventSwitch from './autoEventSwitch';
import WorkbenchCard from './WorkbenchCard';
import VcruiseSwitch from './vcruiseSwitch';
import UsStandardUnits from './UsStandardUnits';
import WarningCard from './WarningCard';
import isCNfun from '@/utils/isCN';
import DevMsgSwitch from './devMsgSwitch';
import WorkbenchSwitchCard from './WorkbenchSwitchCard';
import ShortcutRouteWitchCard from './ShortcutRouteWitchCard';
import './index.less';

const isCN = isCNfun();

export default function OtherSettings() {
    const event_collector = useSelector((state) => state?.featureModelDriving?.eventCollector);
    const dev_msg_switch = useSelector((state) => state?.featureModelDriving?.switch?.devMsgSwitch);
    const standard_unit_switch = useSelector((state) => state?.featureModelDriving?.switch?.standardUnitSwitch);
    return (
        <div className={'other_settings_wrap'}>
            <MvizLayout />
            <WorkbenchCard />
            <WorkbenchSwitchCard />
            {event_collector && <AutoEventSwitch />}
            <VcruiseSwitch />
            {isCN && <WarningCard />}
            {dev_msg_switch && <DevMsgSwitch />}
            {standard_unit_switch && <UsStandardUnits />}
            {isCN && <ShortcutRouteWitchCard />}
        </div>
    );
}
