import StudyComplete from './studyComplete';
import ParkingComplete from './parkingComplete';
import RouteLearningFailed from './RouteLearningFailed';
import CruisingFailed from './cruisingFailed';
import './index.less';

export default function LPNPComplete() {
    return (
        <>
            <StudyComplete />
            <ParkingComplete />
            <RouteLearningFailed />
            <CruisingFailed />
        </>
    );
}
