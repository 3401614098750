import { useState, useRef, useEffect } from 'react';
import toNumber from 'lodash/toNumber';
import workBenchEventName from '@/constant/workBenchEventName';
import { singelPointReport } from '@/utils/report';

export function useAudioPlay() {
    const [playId, setPlayId] = useState(null);

    const preAudioUrl = useRef(); //上一个点击的url
    const playTimer = useRef();
    const count = useRef(10);
    const playEffect = useRef(false);

    const reset = (url) => {
        playEffect.current = false;
        setPlayId(null);
        clearInterval(playTimer.current);
        console.log('ssss', JSON.stringify({ type: 'duplex', loop: false, action: 'stop', data: preAudioUrl.current }));
        window?.momentaJsBridge?.dispatch(
            'playCloudAudio',
            null,
            JSON.stringify({
                type: 'duplex',
                loop: false,
                action: 'stop',
                data: url || preAudioUrl.current,
            }),
        );
    };

    const playAudio = (url, duration, id) => {
        count.current = duration || 10;
        playEffect.current = true;
        setPlayId(id);
        preAudioUrl.current = url;
        window?.momentaJsBridge?.dispatch(
            'playCloudAudio',
            null,
            JSON.stringify({ type: 'duplex', loop: false, action: 'start', data: url }),
        );
        playTimer.current = setInterval(() => {
            if (toNumber(count.current) === 1) {
                return reset(url); //正常结束的给url
            }
            count.current = count.current - 1;
        }, 1000);
        singelPointReport({
            eventName: workBenchEventName.WB_record_play_click,
            timestamp: new Date().getTime(),
        });
    };

    const handleAudio = (url, duration, id) => {
        //处理未播放完毕的逻辑
        if (playEffect.current) {
            reset();
            //当重复点击时直接提前退出
            if (playId === id) return;
        } else {
            console.log('ssss-重新赋值');
            preAudioUrl.current = url; //没有在播放，说明新的开始
        }
        playAudio(url, duration, id);
    };

    // 销毁时录音播放中停止播放
    useEffect(() => {
        return () => {
            if (!playEffect.current) return;
            window?.momentaJsBridge?.dispatch(
                'playCloudAudio',
                null,
                JSON.stringify({
                    type: 'duplex',
                    loop: false,
                    action: 'stop',
                    data: preAudioUrl.current,
                }),
            );
        };
    }, []);

    return { handleAudio, reset, playId, playEffect };
}
