import store from '@/store';
import isParking from '@/utils/isParking';
import { carIpMap } from '@/constant/config';
import qs from 'query-string';

// eslint-disable-next-line import/no-anonymous-default-export
export default function () {
    const search = window.location.hash.split('?')?.[1];
    const params = qs.parse(search);
    if (params.ip) return params.ip;
    const state = store.getState();
    const firstCarType = isParking(state?.scene?.current_scene)
        ? state?.featureModelParking?.hmi?.carType
        : state?.featureModelDriving?.baseData?.hmi?.car_type;
    const carType = firstCarType || state?.featureModelCommon?.carType || 'Devcar';

    return carIpMap[carType];
}
