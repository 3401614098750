import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Switch } from 'antd-mobile';
import CardLayout from '../../../components/cardLayout';
import { useSelector } from 'react-redux';
import FM from '@/utils/nativeSubAndPub';
import { SocketMap } from '@/constant/socketMap';
import '@/components/slider/smartDrivingSetting/components/cardStyle/index.less';

export default function DevMsgSwitch() {
    const { t: translate } = useTranslation();
    const dev_msg_switch = useSelector((state) => state?.featureModelDriving?.switch?.devMsgSwitch);
    const [switchDevMsg, setSwitchDevMsg] = useState(dev_msg_switch === 'OPEN');
    const switchUnpState = useSelector((state) => state?.switchModeAction?.switchUnpState);

    //切换Event开关
    const changeSwitch = (value) => {
        if (switchUnpState) return;
        setSwitchDevMsg(value);
        FM.sendToHMIDriving(
            SocketMap.dev_msg_control,
            {
                switch: value ? 'OPEN' : 'CLOSE',
            },
            'drive_node_res_fail_cb',
        );
    };

    useEffect(() => {
        if (switchUnpState) {
            setSwitchDevMsg(false);
            FM.sendToHMIDriving(
                SocketMap.dev_msg_control,
                {
                    switch: 'CLOSE',
                },
                'drive_node_res_fail_cb',
            );
        }
    }, [switchUnpState]);

    const leftDom = (
        <div className={'left_dom_wrap'}>
            <div className={'switch_wrap'}>
                <div className={'content_wrap'}>
                    <div className={'text_wrap'}>{translate('settings.DevMsg')}</div>
                </div>
                <Switch
                    checked={switchDevMsg}
                    onChange={(checked) => changeSwitch(checked)}
                    className={'customSwitch'}
                />
            </div>
        </div>
    );

    /* const rightDom = (
        <div className={"right_dom_wrap"}>
            <img src={mtour}/>
        </div>
    )*/

    return (
        <div className={switchUnpState ? 'is_mtour_todisabled' : ''}>
            <CardLayout
                leftDom={leftDom}
                value={switchDevMsg}
                /*rightDom={rightDom}*/
            />
        </div>
    );
}
