import reportEventName from '@/constant/reportEventName';

export const apaTipsMapConfig = {
    // CAN_SEARCHING: 'Click "APA Start" to search for APA parking spots',
    SEARCHING: 'Please keep driving to search for parking spots', //'正在寻找车位，请继续行驶',
    FIND_SPACE: 'Parking spots are available, stop the vehicle and activate APA', //'已找到可用车位，如需APA泊入请停车',//'已找到可用车位，如需APA泊入请停车',
    SPEED_FAST: 'Please slow down to 25 km/h', //'车速有点快哟，请减速至25km/h',
    SELECTING: 'Please confirm the target parking spot and click "Park In"', //'已为您选好推荐车位，请确认后点击“开始泊车',
    PARKING_PAUSE: 'Release the brake and click "Resume APA"', //'APA is paused', //'泊车暂停',
    PARKING: 'APA is working',
    PARKING_OUT: 'Parking out is working',
    SEARCHING_OUT: 'Determine the unparking direction, and click the "Park Out"',
};
export const TipsMapConfig = {
    LEARNING_START: 'Start Route Learning',
    LEARNING: 'Please drive to the parking spot and park in',
    START_CRUSE_STOP: 'Please release the brake',
    START_CRUSE: 'Driving to your target parking spot',
    CRUISING: 'LPNP is working',
    PARKING: 'LPNP parking in', //'即将进入车位',
    APA_SELECTING: 'Select a parking spot and click “Park In”', //'已为您选好推荐车位，请确认后点击“开始泊车”',
    APA_PARKING: 'APA is working', //'正在泊入您的车位',
    APA_PARKING_PAUSE: 'Release the brake and click "Resume APA"',
    PARKING_PAUSE: 'Release the brake and click "Resume APA"', //巡航暂停
    NEARBY_SEARCHING: 'Searching for parking spots on the way', //'正在沿途找车位,

    // ...apaTipsMapConfig,
};

const APA_TOAST_CONFIG = {
    apa_start_parking: 'APA is parking in',
    apa_parking_fail: 'APA is deactivated', //'泊车失败',
    apa_start_parking_out: '', //开始泊出
    apa_start_parking_pause: '',
    apa_park_out_take_over: 'Vehicle is unparked, please take over and drive away', //半泊出文案
    apa_parking_out_succ: 'APA is completed, please take over and drive away',
};
export const TOAST_CONFIG = {
    ...APA_TOAST_CONFIG,
    lpnp_start_learning: 'Start route learning here, drive to a parking spot and park in', //'我从这里开始学习了，请开往你的车位并泊入',
    lpnp_start_cruising: 'LPNP is activated', //'LPNP已开始',LPNP is activated
    lpnp_start_parking: 'LPNP is parking in', //'开始泊车',
    lpnp_map_build_succ: '', //'路线学习完成', Route Learning Completed
    lpnp_map_build_fail: '', //Route Learning Failed
    lpnp_exit: '', //'LPNP已退出'LPNP Crusing is Deactivated,不展示文字@zhongning
    lpnp_parking_succ: 'LPNP Cruising Complete', //'LPNP已完成',
    lpnp_parking_fail: 'LPNP is Failed',
    lpnp_approaching_destination: 'Arriving at the parking spot soon', //'即将到达您的车位',
    lpnp_cruising_turn: {
        lText: 'Turning left ahead', //'前方即将左转',
        rText: 'Turning right ahead', //'前方即将右转',
        // 即将通过路口
        straight: 'Crossing the intersection ahead',
    },
    lpnp_space_occupied_unable_continue: 'LPNP deactivated for the target spot occupied', //'目标车位被占，无法继续泊车',
    lpnp_start_apa_selecting: '', //'Please confirm the target parking spot and click "Park In"', //拿掉与状态流转文案太接近，'已为您选好推荐车位，请确认后点击“开始泊车”',
    lpnp_start_apa_parking_2stips: 'Please release the brake', //fe hack
    lpnp_start_apa_parking: 'APA is parking in',
    apa_parking_pause: '',
    apa_parking_resume: '',
    lpnp_apa_parking_pause: 'Release the brake and click "Resume APA"',
    lpnp_apa_parking_resume: '', //继续泊车Please release the brake to resume APA

    lpnp_apa_parking_succ: '', //'泊车完成',APA is completed
    lpnp_apa_parking_fail: '', //'泊车失败',APA is deactivated
    lpnp_nearby_parking_fail: '', //沿途泊入失败 LPNP is deactivated
    lpnp_speed_bump_inc: 'Passing through a speed bump', //'经过减速带',
    lpnp_apa_selecting_without_parking_slot: 'Manually park in, and put into gear P to complete the route learning',
    lpnp_drive_to_map_starting_point: 'Driving to the start of the learned route', // 开往建图起点
    lpnp_ready: '', //'LPNP可用了',LPNP Cruising is Available //不展示文字@zhongning
    lpnp_exit_park: 'Driving away from the parking lot', //
    lpnp_interaction: {
        // 即将绕行前车
        avoid_vehicle: 'Trying to avoid the vehicle ahead',
        // 等待前车行驶
        wait_vehicle: 'Waiting for a vehicle ahead to drive away',
        // 等待行人通行
        yield_to_pedestrian: 'Waiting for a pedestrian ahead to leave',
        // 注意行人
        watch_out_pedestrian: 'Beware of the pedestrian',
    },
    // 窄道控车
    nrp_notice: {
        // 前方窄道自动通行可用，请按键激活
        PREACTIVE: 'Narrow road ahead, automatic passage available. Press button to activate',
        // 自动通行中
        ACTIVE: 'Automatic passage in progress',
    },
    // lpnp_learning_enter_ramp:"",
    lpnp_learning_exit_ramp: 'Exit the ramp',
    lpnp_cruising_enter_ramp: 'Entering the ramp',
    lpnp_cruising_exit_ramp: 'Exiting the ramp',
    lpnp_learning_ramp: {
        exit: 'Exit the ramp',
    },
    lpnp_cruising_ramp: {
        enter: 'Entering the ramp',
        exit: 'Exiting the ramp',
    },
    lpnp_cruising_destination: '',
    apa_start_selecting: '',
};
export const AudioMap = {
    lpnp_start_learning: 'lpnp_start_learning.mp3',
    lpnp_start_cruising: 'lpnp_start_cruising.mp3',
    lpnp_start_parking: 'lpnp_start_parking.mp3',
    lpnp_apa_parking_pause: 'lpnp_apa_parking_pause.mp3',
    lpnp_apa_parking_resume: 'apa_restart_parking.mp3',
    apa_parking_pause: 'lpnp_apa_parking_pause.mp3',
    apa_parking_resume: 'apa_restart_parking.mp3',
    lpnp_map_build_succ: 'lpnp_map_build_succ.mp3',
    lpnp_map_build_fail: 'lpnp_map_build_fail.mp3',
    lpnp_exit: 'lpnp_exit.mp3',
    lpnp_parking_succ: 'lpnp_parking_succ.mp3',
    lpnp_parking_fail: 'lpnp_parking_fail.mp3',
    lpnp_approaching_destination: 'lpnp_approaching_destination.mp3',
    lpnp_cruising_turn_left: 'lpnp_cruising_turn_left.mp3',
    lpnp_cruising_turn_right: 'lpnp_cruising_turn_right.mp3',
    lpnp_cruising_turn_straight: 'lpnp_cruising_turn_straight.mp3',
    lpnp_space_occupied_unable_continue: 'lpnp_space_occupied_unable_continue.mp3',
    lpnp_start_apa_selecting: 'lpnp_car_site_has_selected.mp3', //'lpnp_start_apa_selecting.mp3',
    lpnp_start_apa_parking_2stips: 'lpnp_start_apa_parking_2stips.mp3',
    lpnp_start_apa_parking: 'lpnp_start_apa_parking.mp3',
    lpnp_apa_parking_succ: 'lpnp_apa_parking_succ.mp3',
    lpnp_apa_parking_fail: 'lpnp_apa_parking_fail.mp3',
    lpnp_speed_bump_inc: 'lpnp_speed_bump_inc.mp3', // 减速带提醒
    lpnp_apa_selecting_without_parking_slot: 'lpnp_apa_selecting_without_parking_slot.mp3', // 附近无可泊车位，请手动倒入
    lpnp_drive_to_map_starting_point: 'lpnp_drive_to_map_starting_point.mp3', // 开往建图起点
    lpnp_ready: 'lpnp_ready.mp3', // lpnp已就绪
    lpnp_exit_park: 'lpnp_exit_park.mp3', // 车辆驶出停车场
    // 即将绕行前车
    lpnp_interaction_avoid_vehicle: 'lpnp_interaction_avoid_vehicle.mp3',
    // 等待前车行驶
    lpnp_interaction_wait_vehicle: 'lpnp_interaction_wait_vehicle.mp3',
    // 即将避让行人
    lpnp_interaction_yield_to_pedestrian: 'lpnp_interaction_yield_to_pedestrian.mp3',
    // 注意行人
    lpnp_interaction_watch_out_pedestrian: 'lpnp_interaction_watch_out_pedestrian.mp3',
    // 可控车状态提示
    nrp_notice_STAND_BY: 'nrp_stand_by.mp3',
    // 前方窄道自动通行可用，请按键激活
    nrp_notice_PREACTIVE: 'nrp_pre_active.mp3',
    // 自动通行中
    nrp_notice_ACTIVE: 'nrp_active.mp3',
    lpnp_learning_ramp_enter: 'learning_on_ramp.mp3', //驶入坡道
    lpnp_learning_ramp_exit: 'learning_exit_ramp.mp3', //驶出坡道，我记下了

    lpnp_cruising_ramp_enter: 'crusing_enter_ramp.mp3', //即将驶入坡道
    lpnp_cruising_ramp_exit: 'crusing_exit_ramp.mp3', //即将驶出坡道
    lpnp_cruising_destination: 'lpnp_cruising_destination.mp3', //巡航终点附近
    // apa_start_selecting: 'apa_selecting.mp3', //apa默认选中车位
};

// 打点上报映射
export const ReportMap = {
    //路线学习阶段上报
    lpnp_start_learning: { eventName: reportEventName.HMI_LPNP_START_LEARNING_VIEW, text: 'lpnp_start_learning' },
    lpnp_speed_bump_inc: { eventName: reportEventName.HMI_LPNP_BUMP_VIEW, text: 'lpnp_speed_bump_inc' },
    lpnp_start_apa_parking: { eventName: reportEventName.HMI_LPNP_APA_START_VIEW, text: 'lpnp_start_apa_parking' },
    lpnp_apa_parking_succ: { eventName: reportEventName.HMI_LPNP_APA_SUCC_VIEW, text: 'lpnp_apa_parking_succ' },
    lpnp_apa_parking_fail: { eventName: reportEventName.HMI_LPNP_APA_FAIL_VIEW, text: 'lpnp_apa_parking_fail' },
    lpnp_map_build_fail: { eventName: reportEventName.HMI_LPNP_MAP_BUILD_FAIL_VIEW, text: 'lpnp_map_build_fail' },

    //智能巡航阶段上报
    lpnp_start_cruising: { eventName: reportEventName.HMI_LPNP_START_CRUISE_VIEW, text: 'lpnp_start_cruising' },
    lpnp_start_apa_parking_2stips: {
        eventName: reportEventName.HMI_BRAKE_RELEASE_VIEW,
        text: 'lpnp_start_apa_parking_2stips',
    },
    lpnp_approaching_destination: {
        eventName: reportEventName.HMI_LPNP_APP_DES_VIEW,
        text: 'lpnp_approaching_destination',
    },
    lpnp_space_occupied_unable_continue: {
        eventName: reportEventName.HMI_LPNP_SPACE_OCCUPIED_VIEW,
        text: 'lpnp_space_occupied_unable_continue',
    },
    lpnp_parking_fail: { eventName: reportEventName.HMI_LPNP_PARKING_FAIL_VIEW, text: 'lpnp_parking_fail' },
    lpnp_exit: { eventName: reportEventName.HMI_LPNP_EXITED_VIEW, text: 'lpnp_exit' },

    lpnp_learning_ramp_enter_learning: {
        eventName: 'HMI_lpnp_learning_on_ramp_view',
        text: 'lpnp_learning_enter_ramp',
    },
    lpnp_learning_ramp_exit_learning: {
        eventName: 'HMI_lpnp_learning_exit_ramp_view',
        text: 'lpnp_learning_exit_ramp',
    },
    lpnp_cruising_ramp_enter_cruising: {
        eventName: 'HMI_lpnp_cruise_enter_ramp_view',
        text: 'lpnp_cruising_enter_ramp',
    },
    lpnp_cruising_ramp_exit_cruising: { eventName: 'HMI_lpnp_cruise_exit_ramp_view', text: 'lpnp_cruising_exit_ramp' },
    lpnp_cruising_ramp_enter_nearby: {
        eventName: 'HMI_parking_on_the_way_enter_ramp_view',
        text: 'lpnp_cruising_enter_ramp',
    },
    lpnp_cruising_ramp_exit_nearby: {
        eventName: 'HMI_parking_on_the_way_exit_ramp_view',
        text: 'lpnp_cruising_exit_ramp',
    },

    lpnp_cruising_on_ramp_cruising: {
        eventName: 'HMI_lpnp_cruise_drive_on_ramp_view',
        text: 'HMI_lpnp_cruise_drive_on_ramp_view',
    },
    lpnp_cruising_on_ramp_nearby: {
        eventName: 'HMI_parking_on_the_way_drive_on_ramp_view',
        text: 'HMI_parking_on_the_way_drive_on_ramp_view',
    },
    'lpnp_interaction_avoid_vehicle.mp3': {
        eventName: 'HMI_lpnp_avoid_vehicle_ahead_view',
        text: 'HMI_lpnp_avoid_vehicle_ahead_view',
    },
    'lpnp_interaction_wait_vehicle.mp3': {
        eventName: 'HMI_lpnp_wait_vehicle_ahead_view',
        text: 'HMI_lpnp_wait_vehicle_ahead_view',
    },
    'lpnp_interaction_yield_to_pedestrian.mp3': {
        eventName: 'HMI_lpnp_avoid_pedestrian_view',
        text: 'HMI_lpnp_avoid_pedestrian_view',
    },
    'lpnp_interaction_watch_out_pedestrian.mp3': {
        eventName: 'lpnp_interaction_watch_out_pedestrian',
        text: 'lpnp_interaction_watch_out_pedestrian',
    },
};
