const RADS_CMD = {
    cmd: 'rads_control',
    data_cmd: {
        RADS_FUNC_START: 'RADS_FUNC_START', //进入RADS功能
        RADS_EXIT: 'RADS_EXIT', //RADS退出
        RADS_START_PARKING: 'RADS_START_PARKING', //开始RADS
        RADS_PAUSE_PARKING: 'RADS_PAUSE_PARKING', //RADS暂停倒车
        RADS_RESUME_PARKING: 'RADS_RESUME_PARKING', //RADS继续倒车
        RADS_STOP_PARKING: 'RADS_STOP_PARKING', //结束RADS倒车
        RADS_SAVE_TRAJECTORY: 'RADS_SAVE_TRAJECTORY', //RADS落地轨迹
        RADS_ERASE_TRAJECTORY: 'RADS_ERASE_TRAJECTORY', //RADS清除轨迹
    },
};

export { RADS_CMD };
