import { ParkingSocketMap } from '@/constant/socketMap';
import FM from '@/utils/nativeSubAndPub';

export default function usePTSSettingCommon() {
    // 通知底层：PTS开关
    const PTSControl = async (pts_switch, pts_mute, pts_side_protection) => {
        FM.sendToHMIParking(
            ParkingSocketMap.pts_control,
            {
                is_switch_on: pts_switch,
                is_mute: pts_mute,
                is_side_protection_on: pts_side_protection,
            },
            null,
        );
    };

    return {
        PTSControl,
    };
}
