import { useNRP } from '../hook';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import icon_active from '@/assets/parking/adas/icon_active.png';
import icon_standby from '@/assets/parking/adas/icon_standby.png';

import './index.less';

export default function NRP() {
    // 窄路辅助[NRP]
    const { nrpIconStatus } = useNRP();
    const userMode = useSelector((state) => state?.parking?.userMode);
    return (
        <div className="nrp-container">
            {nrpIconStatus.isShow && (
                <img
                    className={classNames('icon-nrp', { sr: userMode === 'C' })}
                    src={nrpIconStatus.disabled ? icon_standby : icon_active}
                    alt=""
                />
            )}
        </div>
    );
}
