import { useMemo } from 'react';
import { useSelector } from 'react-redux';

export function useWorkflowRunningStatus() {
    const workflowNodes = useSelector((state) => state.workflow?.wf_status?.nodes);
    const runningStatus = useSelector((state) => state.workflow?.running_status);

    const isDifferent = useMemo(() => {
        if (!workflowNodes || !runningStatus) return false;
        return workflowNodes.some((node) => {
            const runningItem = runningStatus.find((item) => item.package_kind === node.package_kind);
            if (!runningItem) return true;
            return node.package_name !== runningItem.package_name;
        });
    }, [workflowNodes, runningStatus]);

    return { isDifferent, runningStatus };
}
