import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import FM from '@/utils/nativeSubAndPub';
import { SocketMap } from '@/constant/socketMap';
import './index.less';

let timer = null;
export default function CustomElkCard() {
    const dispatch = useDispatch();
    const { t: translate } = useTranslation();
    let sensiOptions = translate('ELKSetting.warningTypeOptions', { returnObjects: true });
    const elk_switch = useSelector((state) => state?.featureModelDriving?.switch?.adas?.lka_switch?.elk_switch);
    const elk_roadedge_working_mode = useSelector(
        (state) => state?.featureModelDriving?.switch?.adas?.lka_switch?.elk_roadedge_working_mode,
    );
    const flag = useSelector((state) => state?.smartDrivingSettingMsg?.flag);
    const [warningTypeSwitch, setWarningTypeSwitch] = useState(
        ['STEERING', 'WARNING', 'BOTH'].includes(elk_roadedge_working_mode) ? elk_roadedge_working_mode : 'CLOSE',
    );

    // const {FCWControl} = useFcwSettingCommon()

    useEffect(() => {
        if (['STEERING', 'WARNING', 'BOTH'].includes(elk_roadedge_working_mode)) {
            setWarningTypeSwitch(elk_roadedge_working_mode);
        } else {
            setWarningTypeSwitch('CLOSE');
            handleWarningType('CLOSE');
        }
    }, [elk_roadedge_working_mode]);

    // change: ELK播报模式
    const handleWarningType = (value, isClick) => {
        if (elk_switch !== 'OPEN') {
            if (isClick && isClick === 'click') {
                const text = translate('ELKSetting.onELKFirst');
                dispatch({
                    type: 'setUpMsg',
                    data: {
                        tooltipMsg: text,
                        flag: !flag,
                    },
                });
            }
            return;
        }
        FM.sendToHMIDriving(
            SocketMap.elkSwitchControl,
            {
                switch: elk_switch,
                roadedge_working_mode: value,
            },
            'drive_node_res_fail_cb',
        );
    };

    return (
        <div className={`custom_elk_card_wrap ${elk_switch === 'OPEN' ? '' : 'custom_elk_not_active'}`}>
            <div className={'title_wrap'}>{translate('ELKSetting.customELK')}</div>

            <div className={'sensitivity_wrap'}>
                <p className="subtitle_wrap">{translate('ELKSetting.elk_warning_type')}</p>
                <div className={'select_box'}>
                    {sensiOptions.length &&
                        sensiOptions.map((item, index) => {
                            return (
                                <span
                                    key={index}
                                    className={`select_box_item ${warningTypeSwitch === item.value ? 'active' : ''}`}
                                    onClick={() => handleWarningType(item.value, 'click')}
                                >
                                    {item.label}
                                </span>
                            );
                        })}
                </div>
            </div>
        </div>
    );
}
