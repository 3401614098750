import { ParkingSocketMap } from '@/constant/socketMap';
import FM from '@/utils/nativeSubAndPub';

export default function useSyntheticBackgroundSettingCommon() {
    // 通知底层：vr-apa开关
    const syntheticBackgroundControl = async (syntheticBackgroundValue) => {
        FM.sendToHMIParking(
            ParkingSocketMap.avm_control,
            {
                showSyntheticBackground: syntheticBackgroundValue,
            },
            null,
        );
    };

    return {
        syntheticBackgroundControl,
    };
}
