import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { cusToast } from '@/components/customToast';
import { SocketMap } from '@/constant/socketMap';
import { useDebounceFn } from '@/utils/common';
import { useTranslation } from 'react-i18next';
import { singelPointReport } from '@/utils/report';
import reportEventName from '@/constant/reportEventName';
import FM from '@/utils/nativeSubAndPub';
import { AppFn } from '@/utils/native';

import './index.less';

/*
let fontSize = {
    'fontSize': '.7rem',
    'display': 'flex',
    'justifyContent': 'center',
    'color': '#fff'
}
*/
const { getFollowDistanceStatus } = AppFn;

export default function FollowDistance() {
    // 底层默认1-5，前端显示根据底层返回不做+1处理
    const car_follow_level = useSelector(
        (state) => state?.featureModelDriving?.baseData?.os?.driving?.car_follow_level,
    );
    const { t: translate } = useTranslation();

    const hnp_state = useSelector((state) => state?.featureModelDriving?.baseData?.os?.driving?.automatic_state);
    const userMode = useSelector((state) => state?.parking?.userMode);
    const [followDistanceShow, setFollowDistanceShow] = useState(false);

    // 按钮点击
    const handleBtn = async (type) => {
        // getFollowDistanceStatus("DECREASE")
        // 数据上报
        reportDataFunc(type);

        if (car_follow_level === 1 && type === 'DECREASE') {
            // getFollowDistanceStatus('DECREASE');
            cusToast.show({
                content: translate('distanceControll.Low'),
                duration: 2000,
            });
            return;
        } else if (car_follow_level === 5 && type === 'INCREASE') {
            // getFollowDistanceStatus('INCREASE');
            cusToast.show({
                content: translate('distanceControll.Higest'),
                duration: 2000,
            });
            return;
        } else {
            FM.sendToHMIDriving(
                SocketMap.carFollowControl,
                {
                    action: type,
                },
                'car_follow_control_cb',
            );
        }
    };
    const clickDebounce = useDebounceFn(handleBtn, 300, false);

    // 数据上报
    const reportDataFunc = (type) => {
        let reportData = {};
        let Num = car_follow_level;
        if (type === 'DECREASE') {
            car_follow_level === 1 ? (Num = car_follow_level) : (Num = Number(car_follow_level) - 1);
        } else if (type === 'INCREASE') {
            car_follow_level === 5 ? (Num = car_follow_level) : (Num = Number(car_follow_level) + 1);
        }
        reportData['set_car_follow_level'] = Num;
        reportData['type'] = type;
        singelPointReport({
            eventName: reportEventName.HMI_CARGAP_CLICK,
            timestamp: new Date().getTime(),
            reportData,
        });
    };

    // 跟车时距
    useEffect(() => {
        // 前端兜底-1
        if ((hnp_state === 'ACTIVE' || hnp_state === 'STANDBY') && typeof car_follow_level !== 'undefined') {
            if (followDistanceShow) return;
            setFollowDistanceShow(true);
        } else {
            setFollowDistanceShow(false);
        }
    }, [hnp_state, car_follow_level]);

    return (
        <div className={`follow-distance-wrapper ${followDistanceShow} ${userMode === 'C' ? 'follow-distance-c' : ''}`}>
            <div className="follow-distance-btn-add" onClick={() => clickDebounce('INCREASE')}>
                <span className="iconfont distance-iconfont">{'\ue62e'}</span>
            </div>
            <div className="follow-distance-content">{car_follow_level || '--'}</div>
            <div className="follow-distance-btn-plus" onClick={() => clickDebounce('DECREASE')}>
                <span className="iconfont distance-iconfont">{'\ue62d'}</span>
            </div>
        </div>
    );
}
