import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import './index.css';
import store from './store/index';
import App from './App';
import './utils/robonode';
import './assets/iconfont/iconfont.css';
import './i18n/index';
import { performanceLog } from '@/utils/performance';
import { AppFn } from '@/utils/native';
import '@/utils/receiveFeatureModelData';
import FM from '@/utils/nativeSubAndPub';
import '@/utils/cmdCallback.js';

// import VConsole from 'vconsole';
// import reportWebVitals from './reportWebVitals';
window.initDpr = 1;

// if(process.env.REACT_APP_ENV==='development'){
//   new VConsole();
//   setTimeout(()=>{
//     AppFn.setHotArea([], true);
//   }, 2000);
// }

FM.registerFeatureModel(['driving', 'parking', 'avm', 'hmi'], 'recevieFeatueModelData');

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById('root'),
    () => {
        window.fristRenderTime = Date.now();
        AppFn.webViewPageState(JSON.stringify({ page: 'hmiHome', state: 'success' }));
    },
);

window.offlineEndRenderTime = Date.now();
// 放在这里上报,不会重新渲染
setTimeout(() => {
    performanceLog();
}, 10000);
window.addEventListener('load', (event) => {
    window.TotalTimeEnd = Date.now();
});
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
