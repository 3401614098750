import { Outlet } from 'react-router-dom';
import Mviz from '@/components/mviz/index';
import Layout from './layout';
import './index.less';
import '@/animate.css';
import { useDrivingLeverDetail } from '@/utils/smartDrive';
import { useSelector, useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';
import { SWITH_MODE_ACTION } from '@/store/action/type';
import { AppFn } from '@/utils/native';
function DrivingLayout() {
    const [triggerStyle, setTriggerStyle] = useState(null);
    const automatic_state = useSelector((state) => state?.featureModelDriving?.baseData?.os?.driving?.automatic_state);
    const type = useSelector((state) => state?.featureModelDriving?.safetyDiagnose?.notice?.data?.type);
    const fatal_err_list =
        useSelector((state) => state?.featureModelDriving?.safetyDiagnose?.notice?.data?.fatalErrList) || [];
    // const switchModeState = useSelector(state => state?.switchModeAction?.switchModeState);
    const first_fatal_err = fatal_err_list.length > 0 ? fatal_err_list[0] : '';
    const dispatch = useDispatch();
    useDrivingLeverDetail();
    console.log('test4');

    const trigger_error_style = () => {
        setTriggerStyle('normal');
        // if(switchModeState === 'userMode'){
        //     setTriggerStyle('normal');
        //     return;
        // }
        // if (automatic_state === 'ACTIVE' && type === 'running_error'){
        //     if (fatal_err_list.length > 0) {
        //         setTriggerStyle('err')
        //     }
        // } else {
        //     setTriggerStyle('normal')
        // }
    };

    useEffect(() => {
        trigger_error_style();
    }, [automatic_state, type, first_fatal_err]);
    useEffect(() => {
        window?.momentaJsBridge?.dispatch('changeDfdiWebView', null, 'close');
    }, []);

    /*useEffect(() => {
        // 通知native：mvizLayout
        const mviz_layout_local = localStorage.getItem('switch_mviz_layout') || 'left';
        AppFn.setChangeMviz(mviz_layout_local);
        dispatch({
            type: SWITH_MODE_ACTION.switchMvizState,
            data: mviz_layout_local
        });

        const switch_mode_local = localStorage.getItem('switch_mode') || 'testMode';
        dispatch({
            type: SWITH_MODE_ACTION.switchModeState,
            data: switch_mode_local
        });

        let isHorizontal = '';
        //判断横竖屏
        if(document.documentElement.clientWidth > document.documentElement.clientHeight){
            // 横屏
            isHorizontal = 'horizontal';
        }else if(document.documentElement.clientWidth < document.documentElement.clientHeight){
            // 竖屏
            isHorizontal = 'vertical';
        }else{
            // 默认横屏
            isHorizontal = 'horizontal';
        }
        dispatch({
            type: 'resetArea',
            data: {
                map: '40.0',
                mviz: '60.0',
                isHorizontal: isHorizontal,
            },
        })
        AppFn.setMviz({mapArea:'40.0',mVizArea: '60.0',verticalMapArea:'40.0',verticalMvizArea: '60.0'});
    },[])

    window.getOrientation = function(data){
        dispatch({
            type: 'resetArea',
            data: {
                map: '40.0',
                mviz: '60.0',
                isHorizontal: data,
            },
        })
        // AppFn.setMviz({mapArea:'40.0',mVizArea: '60.0',verticalMapArea:'40.0',verticalMvizArea: '60.0'});
    }*/

    return (
        <div className={`wrapper_${triggerStyle}`}>
            <div className={`main_${triggerStyle}`} id="main">
                <Mviz></Mviz>
                <Layout></Layout>
                {/* <Outlet/>*/}
            </div>
        </div>
    );
}

export default DrivingLayout;
