import React, { useState, useEffect } from 'react';
import { Switch } from 'antd-mobile';
import CardLayout from '../../../components/cardLayout';
import dow from '@/assets/settingComNew/activeSafety/dow.png';
import useBSDDOWRCWCommon from '../BSDDOWRCWCommon';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import '@/components/slider/smartDrivingSetting/components/cardStyle/index.less';

export default function DowCard() {
    const { t: translate } = useTranslation();
    const dow_switch = useSelector((state) => state?.featureModelDriving?.switch?.adas?.dow_switch);
    const [DOWSwitch, setDOWSwitch] = useState(dow_switch === 'OPEN');
    const [timer, setTimer] = useState(null); // 存放定时器句柄

    const { switchChange } = useBSDDOWRCWCommon({ timer: timer, setTimer: setTimer });

    useEffect(() => {
        dow_switch && setDOWSwitch(dow_switch === 'OPEN');
        if (timer) {
            clearTimeout(timer);
            setTimer(null);
        }
    }, [dow_switch]);

    const leftDom = (
        <div className={'left_dom_wrap'}>
            <div className={'switch_wrap'}>
                <div className={'content_wrap'}>
                    <div className={'text_wrap'}>DOW</div>
                    <div className={'text_wrap_detail'}>{translate('BSDLCADOWRCW.DOWSwitch')}</div>
                </div>
                <Switch
                    checked={DOWSwitch}
                    onChange={(checked) => switchChange(checked, 'DOW', setDOWSwitch)}
                    className={'customSwitch'}
                />
            </div>
        </div>
    );

    const rightDom = (
        <div className={'right_dom_wrap'}>
            <img src={dow} />
        </div>
    );

    return (
        <>
            <CardLayout leftDom={leftDom} rightDom={rightDom} value={DOWSwitch} />
        </>
    );
}
