import './index.less';
import { useState, useEffect, useRef } from 'react';
import classnames from 'classnames';
import { Collapse, Button, Empty } from 'antd-mobile';
import TaskTitle from '../TaskTitle';
import TaskDetail from '../TaskDetail';
import { getTaskList } from '../../api';
import dayjs from 'dayjs';
import localCache from '@/utils/localCache';
import { useBoxid } from '../../hook';
import { useTranslation } from 'react-i18next';
import WorkflowList from '../../WorkflowList';
import WorkflowModal from '../../WorkflowModal';

export default function TaskList({ className }) {
    const { t: translate } = useTranslation();
    const [taskInfo, setTaskInfo] = useState();
    const [taskDetailFlag, setTaskDetailFlag] = useState(false);
    const box_id = useBoxid();
    const [taskList, setTaskList] = useState([]);
    const [activeKey, setActiveKey] = useState(null);
    const firstFetch = useRef(true);

    useEffect(() => {
        if (taskDetailFlag || !box_id) return;
        let params = { box_id, date_time: dayjs().format('YYYY-MM-DD HH:mm:ss') };
        getTaskList(params).then(({ code, data, message }) => {
            if (code === 200) {
                if (firstFetch.current) {
                    setActiveKey(data.index + '');
                    firstFetch.current = false;
                }
                setTaskList(data?.tasks || []);
            }
        });
    }, [box_id, taskDetailFlag]);
    const mounted = useRef(false);
    useEffect(() => {
        if (!mounted.current && Array.isArray(taskList) && taskList.length) {
            localCache.get('momenta_hmi_workbench_task', (task_id) => {
                const task = taskList.find((item) => item._id === task_id);
                if (task) openTaskDetail(task);
            });

            mounted.current = true;
        }
    }, [taskList]);

    function openTaskDetail(task) {
        setTaskInfo(task);
        setTaskDetailFlag(true);
        localCache.set('momenta_hmi_workbench_task', task?._id);
    }

    const [workflowInfo, setworkflowInfo] = useState(null);
    const [workflowModalFlag, setWorkflowModalFlag] = useState(false);
    const [deployTaskInfo, setDeployTaskInfo] = useState(null);

    return (
        <div className={classnames(className, 'task-list-container')}>
            <h3 className="title">{translate('workBench.task.todayTask')}</h3>
            {taskList.length ? (
                <Collapse accordion className="task-list" activeKey={activeKey} onChange={(key) => setActiveKey(key)}>
                    {taskList.map((task, index) => (
                        <Collapse.Panel
                            title={
                                <TaskTitle
                                    taskName={task.task_name}
                                    taskType={task.task_form}
                                    startTime={task.estimate_start_time}
                                    endTime={task.estimate_end_time}
                                    taskStatus={task.real_task_status}
                                    workflow_infos={task.workflow_infos}
                                />
                            }
                            key={index}
                        >
                            <ul>
                                <li className="task-info-item">
                                    {translate('workBench.task.driver')}:{' '}
                                    {task.driver.driver_name || translate('workBench.task.unassigned')}
                                </li>
                                <li className="task-info-item">
                                    {translate('workBench.task.taskCategory')}: {task.task_function}
                                </li>
                                <li className="task-info-item">
                                    {translate('workBench.task.featureModule')}:{' '}
                                    {task.task_module || translate('workBench.task.null')}
                                </li>
                                <li className="task-info-item">
                                    {translate('workBench.task.city')}: {task.city}
                                </li>
                                <li className="task-info-item">
                                    {translate('workBench.task.softwarePackage')}:{' '}
                                    {task.software_version || translate('workBench.task.null')}
                                </li>
                                <WorkflowList
                                    workflow_infos={task.workflow_infos || []}
                                    onSelect={(info) => {
                                        setworkflowInfo(info);
                                        setWorkflowModalFlag(true);
                                        setDeployTaskInfo(task);
                                    }}
                                />

                                <li className="task-info-item">
                                    {translate('workBench.task.comment')}:{' '}
                                    {task.description || translate('workBench.task.null')}
                                </li>
                                <li className="task-info-item">
                                    <Button
                                        color="primary"
                                        fill="outline"
                                        className="btn-entry"
                                        onClick={() => openTaskDetail(task)}
                                    >
                                        {translate('workBench.task.enterTask')}
                                    </Button>
                                </li>
                            </ul>
                        </Collapse.Panel>
                    ))}
                </Collapse>
            ) : (
                <Empty description={translate('workBench.noData')} imageStyle={{ width: '2rem' }} className="tip" />
            )}

            <TaskDetail taskInfo={taskInfo} visbale={taskDetailFlag} onCancel={() => setTaskDetailFlag(false)} />
            <WorkflowModal
                workflowInfo={workflowInfo}
                visible={workflowModalFlag}
                taskInfo={deployTaskInfo}
                onClose={() => setWorkflowModalFlag(false)}
            />
        </div>
    );
}
