const LPNP_CMD = 'apa_control';
const DEBUG_CMD = {
    START_SEARCHING: 'START_SEARCHING', // 进入APA泊车模式
    START_PARKING: 'START_PARKING', // 开始泊车
    STOP_PARKING: 'STOP_PARKING', // 停止泊车（异常）
    RESUME_PARKING: 'RESUME_PARKING', // 继续泊车（暂停后恢复）
    START_SEARCHING_OUT: 'START_SEARCHING_OUT', // 进入APA泊出模式
    START_PARKING_OUT: 'START_PARKING_OUT', // 开始泊出
    EXIT_APA: 'EXIT_APA', // 退出APA
    START_SELECTING: 'START_SELECTING',
    STOP_SELECTING: 'STOP_SELECTING',
    // 选择泊车车位
    SELECT_PARKING_SLOT: 'SELECT_PARKING_SLOT',
    // 选择泊出方向
    SELECT_PARKING_DIRECTION: 'SELECT_PARKING_DIRECTION',
};
const STATUS_MAP = {
    APA_FUNCTION_STATE_NOT_IN: 'APA_FUNCTION_STATE_NOT_IN',
    APA_STANDBY: 'APA_STANDBY',
    APA_SEARCHING_IN: 'APA_SEARCHING_IN',
    APA_PARKING_IN: 'APA_PARKING_IN',
    APA_PARKING_PAUSED: 'APA_PARKING_PAUSED',
    APA_SEARCHING_OUT: 'APA_SEARCHING_OUT',
    APA_PARKING_OUT: 'APA_PARKING_OUT',
    UNKNOW: 'UNKNOW',
};

const DirectionMap = {
    AUTO_SELECT: 'AUTO_SELECT', // 自动选择
    PERPENDICULAR_FRONT: 'PERPENDICULAR_FRONT', // 直前
    PERPENDICULAR_FRONT_LEFT: 'PERPENDICULAR_FRONT_LEFT', // 左前
    PERPENDICULAR_FRONT_RIGHT: 'PERPENDICULAR_FRONT_RIGHT', // 右前
    PERPENDICULAR_REAR: 'PERPENDICULAR_REAR', // 直后
    PERPENDICULAR_REAR_LEFT: 'PERPENDICULAR_REAR_LEFT', // 左后
    PERPENDICULAR_REAR_RIGHT: 'PERPENDICULAR_REAR_RIGHT', // 右后
    PARALLEL_LEFT: 'PARALLEL_LEFT', // 左前脱困
    PARALLEL_LEFT_FRONT: 'PARALLEL_LEFT_FRONT', // 左前水平
    PARALLEL_RIGHT: 'PARALLEL_RIGHT', // 右前脱困
    PARALLEL_RIGHT_FRONT: 'PARALLEL_RIGHT_FRONT', // 右前水平
};

export { LPNP_CMD, DEBUG_CMD, STATUS_MAP, DirectionMap };
