import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch } from 'antd-mobile';
import { SocketMap } from '@/constant/socketMap';
import FM from '@/utils/nativeSubAndPub';
import { useTranslation } from 'react-i18next';
import { singelPointReport } from '@/utils/report';
import reportEventName from '@/constant/reportEventName';
import localCache from '@/utils/localCache';
import { SWITH_MODE_ACTION } from '@/store/action/type';

let timer = null;
export default function RoundaboutSwitch({ isDisabled }) {
    const dispatch = useDispatch();
    const flag = useSelector((state) => state?.smartDrivingSettingMsg?.flag);
    // 当前智驾
    const running_mode = useSelector((state) => state?.featureModelDriving?.baseData?.os?.driving?.running_mode);
    const automatic_state = useSelector((state) => state?.featureModelDriving?.baseData?.os?.driving?.automatic_state);
    // OPEN/CLOSE
    const roundabout_switch = useSelector(
        (state) => state?.featureModelDriving?.switch?.driving?.roundabout_info_control?.switch,
    );
    const [roundaboutSwitch, setRoundaboutSwitch] = useState(roundabout_switch === 'OPEN');
    const switchRoundaboutState = useSelector((state) => state?.switchModeAction?.switchRoundaboutState);
    const { t: translate } = useTranslation();

    useEffect(() => {
        roundabout_switch && setRoundaboutSwitch(roundabout_switch === 'OPEN');
        if (timer) {
            clearTimeout(timer);
            timer = null;
        }
    }, [roundabout_switch]);

    const switchChange = async (v) => {
        // CP开关关闭时，此时若用户点击环岛开关，智驾页弹窗提示“请先开启CP再使用”
        if (isDisabled) {
            const text = translate('settings.pleaseEnableCp');
            dispatch({
                type: 'setUpMsg',
                data: {
                    tooltipMsg: text,
                    flag: !flag,
                },
            });
            return;
        }

        // 若此时用户智驾运行时，点击roundabout开关，智驾页弹窗提示“请先处于手动驾驶时再更改”
        if (['HNP_PLUS', 'HNP', 'CP', 'ACC'].indexOf(running_mode) > -1 && automatic_state === 'ACTIVE') {
            const text = translate('settings.manualDrivingMode');
            dispatch({
                type: 'setUpMsg',
                data: {
                    tooltipMsg: text,
                    flag: !flag,
                },
            });
            return;
        }

        clearTimeout(timer);
        timer = setTimeout(() => {
            setRoundaboutSwitch(roundaboutSwitch === 'OPEN');
        }, 2 * 1000);
        // reportDataFunc(v ? 'OPEN' : 'CLOSE');
        await roundaboutSwitchControl(v);
    };

    useEffect(() => {
        async function fetchData() {
            // CP开关关闭时，如果roundabout开关是打开状态，则将roundabout开关关闭，且开关置灰
            if (isDisabled && roundabout_switch === 'OPEN') {
                // 记录用户状态
                localCache.set('is_roundabout_switch_state', 'OPEN');
                dispatch({
                    type: SWITH_MODE_ACTION.switchRoundaboutState,
                    data: 'OPEN',
                });
                await roundaboutSwitchControl(false);
            } else {
                // CP开关打开了, 恢复用户状态
                if (!isDisabled) {
                    if (switchRoundaboutState === 'OPEN') {
                        await roundaboutSwitchControl(true);
                        localCache.set('is_roundabout_switch_state', 'CLOSE');
                        dispatch({
                            type: SWITH_MODE_ACTION.switchRoundaboutState,
                            data: 'CLOSE',
                        });
                    }
                }
            }
        }
        fetchData();
    }, [isDisabled]);

    // 通知底层
    const roundaboutSwitchControl = async (value) => {
        FM.sendToHMIDriving(
            SocketMap.roundabout_info_control,
            {
                switch: value ? 'OPEN' : 'CLOSE',
            },
            'drive_node_res_fail_cb',
        );
    };

    // 数据上报
    // const reportDataFunc = (v) => {
    // singelPointReport({
    //     eventName: reportEventName.HMI_TFL_SWITCH_CLICK,
    //     timestamp: new Date().getTime(),
    //     reportData: {
    //         TFL: v,
    //     },
    // });
    // };

    return (
        <div className="select-group-item">
            <div className="select-group-switch">
                <Switch
                    checked={roundaboutSwitch}
                    // disabled={isDisabled}
                    onChange={(checked) => switchChange(checked)}
                    className="custom-switch"
                />
                <span className="switch-name">Roundabout Notification</span>
            </div>
        </div>
    );
}
