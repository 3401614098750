import { Switch } from 'antd-mobile';
import { useSelector, useDispatch } from 'react-redux';
import { SWITH_MODE_ACTION } from '@/store/action/type';
import CardLayout from '../../../components/cardLayout';
import { useTranslation } from 'react-i18next';
import { Grid } from 'antd-mobile';
import { useEffect } from 'react';
import localCache from '@/utils/localCache';
import './index.less';
export default function TsaAlert() {
    const switchTsaAlertWitch = useSelector((state) => state?.switchModeAction?.switchTsaAlertWitch);
    const tsa_switch = useSelector((state) => state?.featureModelDriving?.switch?.adas?.tsa?.tsa_switch);
    const flag = useSelector((state) => state?.smartDrivingSettingMsg?.flag);
    const { t: translate } = useTranslation();
    const dispatch = useDispatch();
    function handleChange(checked) {
        if (tsa_switch !== 'OPEN') {
            const text = translate('TSA.onTsaFirst');
            dispatch({
                type: 'setUpMsg',
                data: {
                    tooltipMsg: text,
                    flag: !flag,
                },
            });

            return;
        }
        dispatch({
            type: SWITH_MODE_ACTION.switchTsaAlertWitch,
            data: checked,
        });
    }
    useEffect(() => {
        localCache.get('hmi_tsa_alert_witch', (data) => {
            const value = data || false;
            dispatch({
                type: SWITH_MODE_ACTION.switchTsaAlertWitch,
                data: value,
            });
        });
    }, []);

    const leftDom = (
        <div className={`left_dom_wrap`}>
            <div className={'switch_wrap'}>
                <div className={'content_wrap'} style={{ marginBottom: '.4rem' }}>
                    <div className={'text_wrap'} style={{ whiteSpace: 'pre-wrap', width: '5rem' }}>
                        {translate('TSA.customTSA')}
                    </div>
                    <div className={'text_wrap_detail'} style={{ whiteSpace: 'pre-wrap', width: '5rem' }}>
                        {translate('TSA.tsaAlertDes')}
                    </div>
                </div>
                <Switch checked={switchTsaAlertWitch} onChange={handleChange} className={'customSwitch'} />
            </div>
        </div>
    );

    return (
        <div className={`card_wrap  ${tsa_switch !== 'OPEN' ? 'tsaalert' : ''}`}>
            <Grid columns={2}>
                <Grid.Item>{leftDom}</Grid.Item>
                <Grid.Item>{}</Grid.Item>
            </Grid>
        </div>
    );
}
