import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Switch } from 'antd-mobile';
import CardLayout from '../../../components/cardLayout';
import { useDispatch, useSelector } from 'react-redux';
import { SWITH_MODE_ACTION } from '@/store/action/type';
import mtour from '@/assets/settingComNew/otherSettings/mtour.png';
import '@/components/slider/smartDrivingSetting/components/cardStyle/index.less';

export default function MtourMode() {
    const { t: translate } = useTranslation();
    const dispatch = useDispatch();
    const switchEventState = useSelector((state) => state?.switchModeAction?.switchEventState);
    const [eventSwitch, setEventSwitch] = useState(switchEventState);

    //切换Event开关
    const switchEvent = (value) => {
        setEventSwitch(value);
        dispatch({
            type: SWITH_MODE_ACTION.switchEventState,
            data: value,
        });
    };

    const leftDom = (
        <div className={'left_dom_wrap'}>
            <div className={'switch_wrap'}>
                <div className={'content_wrap'}>
                    <div className={'text_wrap'}>{translate('settings.EventName')}</div>
                </div>
                <Switch checked={eventSwitch} onChange={(checked) => switchEvent(checked)} className={'customSwitch'} />
            </div>
        </div>
    );

    /* const rightDom = (
        <div className={"right_dom_wrap"}>
            <img src={mtour}/>
        </div>
    )*/

    return (
        <>
            <CardLayout
                leftDom={leftDom}
                value={eventSwitch}
                /*rightDom={rightDom}*/
            />
        </>
    );
}
