import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Switch } from 'antd-mobile';
import { useSelector } from 'react-redux';
import { SocketMap } from '@/constant/socketMap';
import CardLayout from '../../../components/cardLayout';
import FM from '@/utils/nativeSubAndPub';

let timer = null;

export default function EESFCard() {
    const { t: translate } = useTranslation();
    const eesf_switch = useSelector((state) => state?.featureModelDriving?.switch?.driving?.eesf_control?.switch);
    const [EESFSwitch, setEESFSwitch] = useState(eesf_switch === 'OPEN');

    useEffect(() => {
        eesf_switch && setEESFSwitch(eesf_switch === 'OPEN');
        if (timer) {
            clearTimeout(timer);
            timer = null;
        }
    }, [eesf_switch]);

    function EESFControl(value) {
        FM.sendToHMIDriving(
            SocketMap.eesf_control,
            {
                switch: value ? 'OPEN' : 'CLOSE',
            },
            'drive_node_res_fail_cb',
        );
    }

    function handleChange(checked) {
        clearTimeout(timer);
        timer = setTimeout(() => {
            setEESFSwitch(eesf_switch === 'OPEN');
        }, 2 * 1000);
        EESFControl(checked);
    }
    const leftDom = (
        <div className={'left_dom_wrap'}>
            <div className={'switch_wrap'}>
                <div className={'content_wrap'}>
                    <div className={'text_wrap'}>EESF</div>
                    <div className={'text_wrap_detail'} style={{ whiteSpace: 'pre-wrap' }}>
                        {translate('settings.eesf')}
                    </div>
                </div>
                <Switch checked={EESFSwitch} onChange={handleChange} className={'customSwitch'} />
            </div>
        </div>
    );

    const rightDom = <div className={'right_dom_wrap'} />;

    return <CardLayout leftDom={leftDom} rightDom={rightDom} value={EESFSwitch} />;
}
