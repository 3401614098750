import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// adas
import ADAS from './adas';

// main
import useCompleteHook from './main/lpnp/complateComfirm';
import StatusInfo from './main/statusInfo';
import OperateArea from './main/operateArea';
import StduyGuid from './main/lpnp/stduyGuid';
import LPNPComplete from './main/lpnp/commonComplete';
import nearbyApaHook from './main/lpnp/nearbyApa';
import apaTipsHook from './main/apa/apaTips';

import NearbyComplete from './main/lpnp/nearbyApa/nearbyComplete';
import Apa from './main/apa';
import ApaComplete from './main/apa/commonComplete';

// debug
import PNPInfoTips from './debug/hmiInfo';
import LPNPDashboard from './debug/lpnpDashboard';

//tool
import LPNPSettingSr from './tool/userSetting';
import StyleData from './main/styleData';

import Tool from './tool/index';

// gloabComponents
import ErrorAlert from './globalComponent/errroTips';
import ParkingTips from './main/lpnp/parkingTips';

// hooks
import useSetUserMode from './hooks/useSetSRMode';
import useSetMvizSelect from './hooks/useSetMvizSelect';
import useSetUserSelect from './hooks/useSetUserSelect';
import useSetUserGuide from './hooks/useSetUserGuide';
import useSetLoading from './hooks/useSetLoading';
import useShowApa from './hooks/useShowApaAndLpnp';

import WorkBench from '@/components/workbench/index';

// utils
import { getMtour, setParkingLayout } from './utils';

import './index.less';

export default function ParkingContainer() {
    const isShowGuide = useSelector((state) => state?.parking?.isShowGuide);
    const userMode = useSelector((state) => state?.parking?.userMode);
    const isShowDashboard = useSelector((state) => state?.parking?.isShowDashboard);
    const SetTab = useSelector((state) => state?.parking?.SetTab);
    const lpnpMtour = useSelector((state) => state?.switchModeAction?.switchUnpState);
    const dispatch = useDispatch();
    const [isShowAPA, isNotShowLPNP] = useShowApa();
    // LPNP完成页弹窗提醒
    useCompleteHook();
    // 监听Mviz选车位
    useSetMvizSelect();
    // 监听SR选车位
    useSetUserSelect();
    // 监听状态设置SR Mode
    useSetUserMode();
    // 监听用户引导页触发
    useSetUserGuide();
    // loading show/hide
    useSetLoading();
    //沿途泊入播报
    nearbyApaHook();
    //独立apa播报
    apaTipsHook();
    useEffect(() => {
        setParkingLayout();
        getMtour();
    }, []);

    function handleWorkbenchCancel() {
        dispatch({
            type: 'SetTab',
            data: '',
        });
    }

    return (
        <div className="parking-container">
            {isShowGuide ? (
                <StduyGuid />
            ) : (
                <>
                    {userMode === 'D' ? null : <div className="top-bg"></div>}
                    {isShowAPA && (
                        <>
                            <Apa />
                            <ApaComplete />
                        </>
                    )}
                    {!isNotShowLPNP && <OperateArea />}
                    <ParkingTips />
                    <ErrorAlert />
                    <StatusInfo />
                    <LPNPComplete />
                    <NearbyComplete />
                    <ADAS />
                    {!lpnpMtour ? <PNPInfoTips /> : ''}
                    <Tool />
                    {SetTab == 'set' ? <LPNPSettingSr /> : ''}
                    {SetTab == 'style' ? <StyleData /> : ''}

                    {isShowDashboard == true ? <LPNPDashboard /> : ''}
                    {SetTab === 'workbench' && <WorkBench onCancel={handleWorkbenchCancel}></WorkBench>}
                </>
            )}
        </div>
    );
}
