import React, { useState, useEffect, forwardRef } from 'react';
import classNames from 'classnames';
import styles from './index.module.less';

function Dialog(props, ref) {
    const {
        isD,
        visible,
        onOk,
        onCancel,
        onMidOk,
        width,
        style,
        centered,
        title,
        closable,
        closeIcon,
        mask,
        maskStyle,
        maskCloseable,
        okText,
        // okStyle,
        midText,
        showMid,
        cancelText,
        footer,
        // _type, // 消息类型
        // aniEnd,
    } = props;
    console.log('xxxxxx-消息类型', showMid);
    const modalStyle = {
        width: `${width}px`,
        ...style,
    };

    const [isOpen, setIsOpen] = useState(false);

    const modalClass = classNames({
        [`${styles.modal} ${isD ? styles.dModal : styles.srModal}`]: true,
        [`${styles.modalCenter_ani}`]: centered, // 垂直居中
        [`${styles.modalShow_ani}`]: centered ? false : true, // 非垂直居中
        [`${styles.modalHidden_ani}`]: !isOpen && !centered, // 非垂直居中 消失
        [`${styles.modalHidden_center_ani}`]: !isOpen && centered, // 垂直居中 消失
    });

    const maskClass = classNames({
        [`${styles.mask}`]: true,
        [`${styles.mask_hidden_ani}`]: !isOpen,
    });

    const closeBtn = (
        <span className={styles.close} onClick={handle_cancel}>
            X
        </span>
    );

    const _closeIcon = closeIcon
        ? React.cloneElement(closeIcon, {
              ...closeIcon.props,
              onClick: handle_cancel,
          })
        : closeBtn;

    function handle_ok() {
        setIsOpen(false);
        onOk && onOk();
    }
    function handle_cancel() {
        setIsOpen(false);
        onCancel && onCancel();
    }

    // 动画结束回调
    function watchAniEnd(e) {
        // typeof aniEnd === "function" && aniEnd(e.elapsedTime);
    }

    useEffect(() => {
        setIsOpen(visible);
    }, [visible]);

    return (
        <>
            {mask ? (
                <div
                    onClick={() => {
                        maskCloseable && onCancel();
                    }}
                    style={maskStyle}
                    className={maskClass}
                    ref={ref}
                    onAnimationEnd={watchAniEnd}
                ></div>
            ) : null}

            <div style={modalStyle} className={modalClass}>
                {title ? (
                    <div className={`${styles.title} ${isD ? styles.dTitle : styles.srTitle}`}>{title}</div>
                ) : null}
                <div className={styles.closeIcon}>{closable ? _closeIcon : null}</div>
                <div className={`${styles.body} ${isD ? styles.dBody : styles.srBody}`}>{props.children}</div>
                <div className={styles.footer}>
                    {footer === null ? null : React.isValidElement(footer) ? (
                        footer
                    ) : (
                        <div className={styles.footer_btn}>
                            <button
                                className={`${styles.btn_ok} ${isD ? styles.btn_ok_d : styles.btn_ok_sr}`}
                                onClick={handle_ok}
                            >
                                {okText}
                            </button>
                            {showMid && (
                                <button
                                    className={`${styles.btn_ok} ${isD ? styles.btn_ok_d : styles.btn_ok_sr}`}
                                    onClick={onMidOk}
                                >
                                    {midText}
                                </button>
                            )}
                            <button
                                className={`${styles.btn_cancel} ${isD ? styles.btn_cancel_d : styles.btn_cancel_sr}`}
                                onClick={handle_cancel}
                            >
                                {cancelText}
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}

export default forwardRef(Dialog);

Dialog.defaultProps = {
    centered: false,
    closable: false,
    mask: true,
    maskCloseable: 'false',
    okText: 'OK',
    cancelText: 'Cancel',
};
