const APAStatus = {
    APA_SELECTING: 'APA_SELECTING',
    APA_PARKING: 'APA_PARKING',
    APA_PARKING_PAUSE: 'APA_PARKING_PAUSE',
};
export const statusMap = {
    NOT_IN: 'NOT_IN',
    PASSIVE: 'PASSIVE',
    STANDBY: 'STANDBY',
    LEARNING: 'LEARNING',
    MAP_BEING_VERIFIED: 'MAP_BEING_VERIFIED',
    CRUISING: 'CRUISING',
    // CRUISING_PASUE: 'CRUISING_PASUE',
    PARKING_PAUSE: 'PARKING_PAUSE',
    PARKING: 'PARKING',
    NEARBY_SEARCHING: 'NEARBY_SEARCHING',
    ...APAStatus,
};

export const getStudyStatus = (type) => {
    return statusMap[type];
};
