import React, { useRef, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Switch } from 'antd-mobile';
import useCarLightSettingCommon from '../hooks/carLightSwitchHook';

export default function CarLightSwitch() {
    const { t: translate } = useTranslation();

    const userMode = useSelector((state) => state?.parking?.userMode);
    const {
        egoTrunkOpen,
        egoMirrorLeftFolded,
        egoMirrorRightFolded,
        egoHeadlightsLow,
        egoHeadlightsHigh,
        egoLightsDaytime,
        egoBrakeLights,
        egoReverseLights,
        egoTailLights,
    } = useSelector((state) => state.FeatureModelHmi);

    const { CarLightControl } = useCarLightSettingCommon();

    const [egoTrunkOpenState, setEgoTrunkOpenState] = useState(!!egoTrunkOpen);
    const [egoMirrorLeftFoldedState, setEgoMirrorLeftFoldedState] = useState(!!egoMirrorLeftFolded);
    const [egoMirrorRightFoldedState, setEgoMirrorRightFoldedState] = useState(!!egoMirrorRightFolded);
    const [egoHeadlightsLowState, setEgoHeadlightsLowState] = useState(!!egoHeadlightsLow);
    const [egoHeadlightsHighState, setEgoHeadlightsHighState] = useState(!!egoHeadlightsHigh);
    const [egoLightsDaytimeState, setEgoLightsDaytimeState] = useState(!!egoLightsDaytime);
    const [egoBrakeLightsState, setEgoBrakeLightsState] = useState(!!egoBrakeLights);
    const [egoReverseLightsState, setEgoReverseLightsState] = useState(!!egoReverseLights);
    const [egoTailLightsState, setEgoTailLightsState] = useState(!!egoTailLights);

    const [egoTrunkOpenLoading, setEgoTrunkOpenLoading] = useState(false);
    const [egoMirrorLeftFoldedLoading, setEgoMirrorLeftFoldedLoading] = useState(false);
    const [egoMirrorRightFoldedLoading, setEgoMirrorRightFoldedLoading] = useState(false);
    const [egoHeadlightsLowLoading, setEgoHeadlightsLowLoading] = useState(false);
    const [egoHeadlightsHighLoading, setEgoHeadlightsHighLoading] = useState(false);
    const [egoLightsDaytimeLoading, setEgoLightsDaytimeLoading] = useState(false);
    const [egoBrakeLightsLoading, setEgoBrakeLightsLoading] = useState(false);
    const [egoReverseLightsLoading, setEgoReverseLightsLoading] = useState(false);
    const [egoTailLightsLoading, setEgoTailLightsLoading] = useState(false);

    const timerTrunkOpen = useRef(null);
    const timerMirrorLeftFolded = useRef(null);
    const timerMirrorRightFolded = useRef(null);
    const timerHeadlightsLow = useRef(null);
    const timerHeadlightsHigh = useRef(null);
    const timerLightsDaytime = useRef(null);
    const timerBrakeLights = useRef(null);
    const timerReverseLights = useRef(null);
    const timerTailLights = useRef(null);

    // 切换 trunk 开关
    const switchEgoTrunkOpenChange = () => {
        setEgoTrunkOpenLoading(true);
        // 更新状态，并调用控制函数
        const newState = !egoTrunkOpenState;
        setEgoTrunkOpenState(newState);
        CarLightControl({
            egoTrunkOpen: newState ? 1 : 0,
            egoMirrorLeftFolded,
            egoMirrorRightFolded,
            egoHeadlightsLow,
            egoHeadlightsHigh,
            egoLightsDaytime,
            egoBrakeLights,
            egoReverseLights,
            egoTailLights,
        });

        clearTimeout(timerTrunkOpen.current);
        timerTrunkOpen.current = setTimeout(() => {
            setEgoTrunkOpenLoading(false);
        }, 2 * 1000);
    };

    // 切换 左mirror 折叠开关
    const switchEgoMirrorLeftFoldedChange = () => {
        setEgoMirrorLeftFoldedLoading(true);
        const newState = !egoMirrorLeftFoldedState;
        setEgoMirrorLeftFoldedState(newState);
        CarLightControl({
            egoTrunkOpen,
            egoMirrorLeftFolded: newState ? 1 : 0,
            egoMirrorRightFolded,
            egoHeadlightsLow,
            egoHeadlightsHigh,
            egoLightsDaytime,
            egoBrakeLights,
            egoReverseLights,
            egoTailLights,
        });

        clearTimeout(timerMirrorLeftFolded.current);
        timerMirrorLeftFolded.current = setTimeout(() => {
            setEgoMirrorLeftFoldedLoading(false);
        }, 2 * 1000);
    };

    // 切换 右mirror 折叠开关
    const switchEgoMirrorRightFoldedChange = () => {
        setEgoMirrorRightFoldedLoading(true);
        const newState = !egoMirrorRightFoldedState;
        setEgoMirrorRightFoldedState(newState);
        CarLightControl({
            egoTrunkOpen,
            egoMirrorLeftFolded,
            egoMirrorRightFolded: newState ? 1 : 0,
            egoHeadlightsLow,
            egoHeadlightsHigh,
            egoLightsDaytime,
            egoBrakeLights,
            egoReverseLights,
            egoTailLights,
        });

        clearTimeout(timerMirrorRightFolded.current);
        timerMirrorRightFolded.current = setTimeout(() => {
            setEgoMirrorRightFoldedLoading(false);
        }, 2 * 1000);
    };

    // 切换 近光灯开关
    const switchEgoHeadlightsLowChange = () => {
        setEgoHeadlightsLowLoading(true);
        const newState = !egoHeadlightsLowState;
        setEgoHeadlightsLowState(newState);
        CarLightControl({
            egoTrunkOpen,
            egoMirrorLeftFolded,
            egoMirrorRightFolded,
            egoHeadlightsLow: newState ? 1 : 0,
            egoHeadlightsHigh,
            egoLightsDaytime,
            egoBrakeLights,
            egoReverseLights,
            egoTailLights,
        });

        clearTimeout(timerHeadlightsLow.current);
        timerHeadlightsLow.current = setTimeout(() => {
            setEgoHeadlightsLowLoading(false);
        }, 2 * 1000);
    };

    // 切换 远光灯开关
    const switchEgoHeadlightsHighChange = () => {
        setEgoHeadlightsHighLoading(true);
        const newState = !egoHeadlightsHighState;
        setEgoHeadlightsHighState(newState);
        CarLightControl({
            egoTrunkOpen,
            egoMirrorLeftFolded,
            egoMirrorRightFolded,
            egoHeadlightsLow,
            egoHeadlightsHigh: newState ? 1 : 0,
            egoLightsDaytime,
            egoBrakeLights,
            egoReverseLights,
            egoTailLights,
        });

        clearTimeout(timerHeadlightsHigh.current);
        timerHeadlightsHigh.current = setTimeout(() => {
            setEgoHeadlightsHighLoading(false);
        }, 2 * 1000);
    };

    // 切换 日光灯开关
    const switchEgoLightsDaytimeChange = () => {
        setEgoLightsDaytimeLoading(true);
        const newState = !egoLightsDaytimeState;
        setEgoLightsDaytimeState(newState);
        CarLightControl({
            egoTrunkOpen,
            egoMirrorLeftFolded,
            egoMirrorRightFolded,
            egoHeadlightsLow,
            egoHeadlightsLow,
            egoLightsDaytime: newState ? 1 : 0,
            egoBrakeLights,
            egoReverseLights,
            egoTailLights,
        });

        clearTimeout(timerLightsDaytime.current);
        timerLightsDaytime.current = setTimeout(() => {
            setEgoLightsDaytimeLoading(false);
        }, 2 * 1000);
    };

    // 切换 刹车灯开关
    const switchEgoBrakeLightsChange = () => {
        setEgoBrakeLightsLoading(true);
        const newState = !egoBrakeLightsState;
        setEgoBrakeLightsState(newState);
        CarLightControl({
            egoTrunkOpen,
            egoMirrorLeftFolded,
            egoMirrorRightFolded,
            egoHeadlightsLow,
            egoHeadlightsLow,
            egoLightsDaytime,
            egoBrakeLights: newState ? 1 : 0,
            egoReverseLights,
            egoTailLights,
        });

        clearTimeout(timerBrakeLights.current);
        timerBrakeLights.current = setTimeout(() => {
            setEgoBrakeLightsLoading(false);
        }, 2 * 1000);
    };

    // 切换 倒车灯开关
    const switchEgoReverseLightsChange = () => {
        setEgoReverseLightsLoading(true);
        const newState = !egoReverseLightsState;
        setEgoReverseLightsState(newState);
        CarLightControl({
            egoTrunkOpen,
            egoMirrorLeftFolded,
            egoMirrorRightFolded,
            egoHeadlightsLow,
            egoHeadlightsLow,
            egoLightsDaytime,
            egoBrakeLights,
            egoReverseLights: newState ? 1 : 0,
            egoTailLights,
        });

        clearTimeout(timerReverseLights.current);
        timerReverseLights.current = setTimeout(() => {
            setEgoReverseLightsLoading(false);
        }, 2 * 1000);
    };

    // 尾灯
    const switchEgoTailLightsChange = () => {
        setEgoTailLightsLoading(true);
        const newState = !egoTailLightsState;
        setEgoTailLightsState(newState);
        CarLightControl({
            egoTrunkOpen,
            egoMirrorLeftFolded,
            egoMirrorRightFolded,
            egoHeadlightsLow,
            egoHeadlightsLow,
            egoLightsDaytime,
            egoBrakeLights,
            egoReverseLights,
            egoTailLights: newState ? 1 : 0,
        });

        clearTimeout(timerTailLights.current);
        timerTailLights.current = setTimeout(() => {
            setEgoTailLightsLoading(false);
        }, 2 * 1000);
    };

    useEffect(() => {
        setEgoTrunkOpenState(!!egoTrunkOpen);
        setEgoTrunkOpenLoading(false);
    }, [egoTrunkOpen]);

    useEffect(() => {
        setEgoMirrorLeftFoldedState(!!egoMirrorLeftFolded);
        setEgoMirrorLeftFoldedLoading(false);
    }, [egoMirrorLeftFolded]);

    useEffect(() => {
        setEgoMirrorRightFoldedState(!!egoMirrorRightFolded);
        setEgoMirrorRightFoldedLoading(false);
    }, [egoMirrorRightFolded]);

    useEffect(() => {
        setEgoHeadlightsLowState(!!egoHeadlightsLow);
        setEgoHeadlightsLowLoading(false);
    }, [egoHeadlightsLow]);

    useEffect(() => {
        setEgoHeadlightsHighState(!!egoHeadlightsHigh);
        setEgoHeadlightsHighLoading(false);
    }, [egoHeadlightsHigh]);

    useEffect(() => {
        setEgoLightsDaytimeState(!!egoLightsDaytime);
        setEgoLightsDaytimeLoading(false);
    }, [egoLightsDaytime]);

    useEffect(() => {
        setEgoBrakeLightsState(!!egoBrakeLights);
        setEgoBrakeLightsLoading(false);
    }, [egoBrakeLights]);

    useEffect(() => {
        setEgoReverseLightsState(!!egoReverseLights);
        setEgoReverseLightsLoading(false);
    }, [egoReverseLights]);

    useEffect(() => {
        setEgoTailLightsState(!!egoTailLights);
        setEgoTailLightsLoading(false);
    }, [egoTailLights]);

    return (
        <>
            <div className={`lpnp-setting-item ${userMode == 'D' ? 'd' : 'sr'}`}>
                <Switch
                    loading={egoTrunkOpenLoading}
                    checked={egoTrunkOpenState}
                    onChange={(checked) => switchEgoTrunkOpenChange(checked)}
                    className={'customSwitch'}
                />
                <span>{translate('parking.setting.CarLights.TrunkOpen')}</span>
            </div>

            <div className={`lpnp-setting-item ${userMode == 'D' ? 'd' : 'sr'}`}>
                <Switch
                    loading={egoMirrorLeftFoldedLoading}
                    checked={egoMirrorLeftFoldedState}
                    onChange={(checked) => switchEgoMirrorLeftFoldedChange(checked)}
                    className={'customSwitch'}
                />
                <span>{translate('parking.setting.CarLights.MirrorLeftFolded')}</span>
            </div>

            <div className={`lpnp-setting-item ${userMode == 'D' ? 'd' : 'sr'}`}>
                <Switch
                    loading={egoMirrorRightFoldedLoading}
                    checked={egoMirrorRightFoldedState}
                    onChange={(checked) => switchEgoMirrorRightFoldedChange(checked)}
                    className={'customSwitch'}
                />
                <span>{translate('parking.setting.CarLights.MirrorRightFolded')}</span>
            </div>

            <div className={`lpnp-setting-item ${userMode == 'D' ? 'd' : 'sr'}`}>
                <Switch
                    loading={egoHeadlightsLowLoading}
                    checked={egoHeadlightsLowState}
                    onChange={(checked) => switchEgoHeadlightsLowChange(checked)}
                    className={'customSwitch'}
                />
                <span>{translate('parking.setting.CarLights.HeadLightsLow')}</span>
            </div>

            <div className={`lpnp-setting-item ${userMode == 'D' ? 'd' : 'sr'}`}>
                <Switch
                    loading={egoHeadlightsHighLoading}
                    checked={egoHeadlightsHighState}
                    onChange={(checked) => switchEgoHeadlightsHighChange(checked)}
                    className={'customSwitch'}
                />
                <span>{translate('parking.setting.CarLights.HeadlightsHigh')}</span>
            </div>

            <div className={`lpnp-setting-item ${userMode == 'D' ? 'd' : 'sr'}`}>
                <Switch
                    loading={egoLightsDaytimeLoading}
                    checked={egoLightsDaytimeState}
                    onChange={(checked) => switchEgoLightsDaytimeChange(checked)}
                    className={'customSwitch'}
                />
                <span>{translate('parking.setting.CarLights.LightsDaytime')}</span>
            </div>

            <div className={`lpnp-setting-item ${userMode == 'D' ? 'd' : 'sr'}`}>
                <Switch
                    loading={egoBrakeLightsLoading}
                    checked={egoBrakeLightsState}
                    onChange={(checked) => switchEgoBrakeLightsChange(checked)}
                    className={'customSwitch'}
                />
                <span>{translate('parking.setting.CarLights.BrakeLights')}</span>
            </div>

            <div className={`lpnp-setting-item ${userMode == 'D' ? 'd' : 'sr'}`}>
                <Switch
                    loading={egoReverseLightsLoading}
                    checked={egoReverseLightsState}
                    onChange={(checked) => switchEgoReverseLightsChange(checked)}
                    className={'customSwitch'}
                />
                <span>{translate('parking.setting.CarLights.ReverseLights')}</span>
            </div>

            <div className={`lpnp-setting-item ${userMode == 'D' ? 'd' : 'sr'}`}>
                <Switch
                    loading={egoTailLightsLoading}
                    checked={egoTailLightsState}
                    onChange={(checked) => switchEgoTailLightsChange(checked)}
                    className={'customSwitch'}
                />
                <span>{translate('parking.setting.CarLights.TailLights')}</span>
            </div>
        </>
    );
}
