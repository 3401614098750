import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Switch } from 'antd-mobile';
import CardLayout from '../../../components/cardLayout';
import { useSelector } from 'react-redux';
import { SocketMap } from '@/constant/socketMap';
import FM from '@/utils/nativeSubAndPub';
import '@/components/slider/smartDrivingSetting/components/cardStyle/index.less';
import { singelPointReport } from '@/utils/report';
import reportEventName from '@/constant/reportEventName';

let timer = null;
export default function TsaCard() {
    const { t: translate } = useTranslation();
    const tsa_switch = useSelector((state) => state?.featureModelDriving?.switch?.adas?.tsa?.tsa_switch);
    const [tsaSwitch, setTsaSwitch] = useState(tsa_switch === 'OPEN');

    useEffect(() => {
        tsa_switch && setTsaSwitch(tsa_switch === 'OPEN');
        if (timer) {
            clearTimeout(timer);
            timer = null;
        }
    }, [tsa_switch]);

    // switch: tsa
    const switchTsaChange = (value) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            setTsaSwitch(tsa_switch === 'OPEN');
        }, 2 * 1000);
        IsaControl(value);
        // 数据上报
        reportDataFunc(value);
    };

    // 通知底层：Isa
    const IsaControl = async (value) => {
        FM.sendToHMIDriving(
            'tsa_control',
            {
                switch: value ? 'OPEN' : 'CLOSE',
            },
            'drive_node_res_fail_cb',
        );
    };
    // 数据上报
    const reportDataFunc = (value) => {
        singelPointReport({
            eventName: reportEventName.HMI_HRP_SWITCH_CLICK,
            timestamp: new Date().getTime(),
            reportData: {
                isa_switch: value ? 'OPEN' : 'CLOSE',
            },
        });
    };

    const leftDom = (
        <div className={'left_dom_wrap'}>
            <div className={'switch_wrap'}>
                <div className={'content_wrap'}>
                    <div className={'text_wrap'}>TSA</div>
                    <div className={'text_wrap_detail'} style={{ whiteSpace: 'pre-wrap' }}>
                        {translate('TSA.tsaDesc')}
                    </div>
                </div>
                <Switch
                    checked={tsaSwitch}
                    onChange={(checked) => switchTsaChange(checked)}
                    className={'customSwitch'}
                />
            </div>
        </div>
    );

    const rightDom = <div className={'right_dom_wrap'}>{/* <img src={nrp} /> */}</div>;

    return (
        <>
            <CardLayout leftDom={leftDom} rightDom={rightDom} value={tsaSwitch} />
        </>
    );
}
