import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { singelPointReport } from '@/utils/report';
import reportEventName from '@/constant/reportEventName';
import FM from '@/utils/nativeSubAndPub';
import { SocketMap } from '@/constant/socketMap';
import '@/components/slider/smartDrivingSetting/drivingAssistSetting/broadcastMode/navigationTips/index.less';

let timer = null;

export default function UsStandardUnits() {
    const { t: translate } = useTranslation();
    const standard_unit_switch = useSelector((state) => state?.featureModelDriving?.switch?.standardUnitSwitch);
    const [switchUnits, setSwitchUnits] = useState(standard_unit_switch ? standard_unit_switch : 'METRIC');
    let usStandardUnitsOptions = translate('settings.usStandardUnitsOptions', { returnObjects: true });
    useEffect(() => {
        standard_unit_switch && setSwitchUnits(standard_unit_switch);
        if (timer) {
            clearTimeout(timer);
            timer = null;
        }
    }, [standard_unit_switch]);

    // 制式单位切换
    const switchUnitls = (value) => {
        if (value === switchUnits) return;
        singelPointReport({
            eventName: reportEventName.HMI_SETTING_STANDARDUNITS_CLICK,
            text: value,
            timestamp: new Date().getTime(),
        });
        timer = setTimeout(() => {
            setSwitchUnits(switchUnits);
        }, 2 * 1000);
        FM.sendToHMIDriving(
            SocketMap.standard_unit_control,
            {
                state: value,
            },
            'drive_node_res_fail_cb',
        );
    };

    return (
        <div className={'navigation_tips_card_wrap'}>
            <div className={'title_wrap'}>{translate('settings.usStandardUnitsTitle')}</div>

            <div className={'sensitivity_wrap'}>
                <div className={'select_box'}>
                    {usStandardUnitsOptions.length &&
                        usStandardUnitsOptions.map((item, index) => {
                            return (
                                <span
                                    key={index}
                                    className={`select_box_item ${switchUnits === item.value ? 'active' : ''}`}
                                    style={{ width: '2.5rem' }}
                                    onClick={() => switchUnitls(item.value)}
                                >
                                    {item.label}
                                </span>
                            );
                        })}
                </div>
            </div>
        </div>
    );
}
