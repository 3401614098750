import { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useSetHotArea } from '@/hook';
import { HOT_AREA_TYPE } from '@/store/action/type';
import Unlock from '@/assets/parking/paking_build_unlock.png';
import { singelPointReport } from '@/utils/report';
import reportEventName from '@/constant/reportEventName';
import Modal from '@/components/parkingContainer/globalComponent/modal';
import Icon from '@/assets/parking/d_new/complete_icon.png';
import cancelIconD from '@/assets/parking/d_new/cancel_d.png';
import { AppFn } from '@/utils/native';
import { enterSeaching } from '@/components/parkingContainer/config';

export default function StudyComplete() {
    const status = useSelector((state) => state?.featureModelParking?.lpnp?.state);
    const statistic = useSelector((state) => state?.featureModelParking?.lpnp?.statistic);
    const userMode = useSelector((state) => state?.parking?.userMode);
    const parkingMode = useSelector((state) => state?.featureModelParking?.hmi?.parkingMode);

    const mapping_fail_reason = useSelector((state) => state?.featureModelParking?.lpnp?.failReason?.mappingFailReason);

    const preStatus = useRef(null);
    const preParkingMode = useRef(parkingMode);

    const curStatus = useRef(null);
    const [isShow, setIsShow] = useState(false);

    const comfirmBtnRef = useRef(null);
    const dispatch = useDispatch();

    const comfirmBtn = comfirmBtnRef?.current?.getBoundingClientRect();

    const invokeComplete = () => {
        let obj = {
            pageType: 1, //学习完成
            learnedDistance: parseInt(statistic?.learningDistance || 0),
            speedBump: parseInt(statistic?.speedBumpsCount || 0),
        };
        window?.momentaJsBridge?.dispatch('showLPNP', '', JSON.stringify(obj));
    };

    const hide = () => {
        window?.momentaJsBridge?.dispatch('changeDfdiWebView', null, 'hide');
        AppFn.learnParking({ action: 'failure' });
        setIsShow(false);
        dispatch({
            type: 'ISSHOWGUIDE',
            data: true,
        });
    };

    useEffect(() => {
        // 触发条件: MAP_BEING_VERIFIED -> PASSIVE && mapping_fail_reason为空
        console.log(
            'xxx-状态1',
            preStatus?.current?.current,
            status,
            mapping_fail_reason,
            userMode,
            preParkingMode?.current,
        );

        if (
            (preStatus?.current?.current === 'LEARNING' ||
                preStatus?.current?.current === 'APA_SELECTING' ||
                preStatus?.current?.current === 'MAP_BEING_VERIFIED' ||
                preStatus?.current?.current === 'APA_PARKING') &&
            (status === 'STANDBY' || status === 'PASSIVE') &&
            (preParkingMode?.current === 'LEARNING' || preParkingMode?.current === undefined) &&
            !mapping_fail_reason
        ) {
            if (userMode === 'D') {
                setIsShow(true);
            } else {
                setIsShow(false);
                invokeComplete();
            }
            //路线学习成功数据上报
            singelPointReport({
                eventName: reportEventName.HMI_LPNP_MAP_BUILD__SUCC_VIEW,
                timestamp: new Date().getTime(),
                reportData: {
                    learning_distance: statistic?.learningDistance,
                    speed_bumps_count: statistic?.speedBumpsCount,
                },
            });
            window?.momentaJsBridge?.dispatch('changeDfdiWebView', null, 'hide');
            Modal.clear();
            AppFn.learnParking({ action: 'end' });
        }
        // 建图失败
        // if (
        //     (preStatus?.current?.current == 'MAP_BEING_VERIFIED' || preStatus?.current?.current == 'LEARNING') &&
        //     status !== 'LEARNING' &&
        //     status !== 'MAP_BEING_VERIFIED' &&
        //     status !== 'APA_SELECTING'
        // ) {
        //     AppFn.learnParking({ action: 'end' });
        // }
        if (preStatus.current?.current != 'LEARNING ' && status == 'LEARNING') {
            setIsShow(false);
        }
        /*
        APA_selecting: 选择车位
        APA_Parking：APA进行中
        APA_Pause：APA暂停或终止
        */
        if (['APA_SELECTING', 'APA_PARKING', 'APA_PARKING_PAUSE'].includes(status)) {
            singelPointReport({
                eventName: reportEventName.HMI_LPNP_APA_STATUS_VIEW,
                timestamp: new Date().getTime(),
                reportData: {
                    APA: status,
                },
            });
        }
        /*
        Not Standby ：LPNP - Passive
        Standby ：LPNP - Standby
        Active：LPNP - Cruise/ Parking
        */
        if (['STANDBY', 'PASSIVE', 'CRUISING', 'PARKING'].includes(status)) {
            singelPointReport({
                eventName: reportEventName.HMI_LPNP_STATUS_VIEW,
                timestamp: new Date().getTime(),
                reportData: {
                    LPNP: status,
                },
            });
        }

        if (status != curStatus) {
            preStatus.current = curStatus;
            curStatus.current = status;
        }
        preParkingMode.current = parkingMode;
    }, [status]);

    useEffect(() => {
        const comfirmBtnInfo = `${comfirmBtn?.x}, ${comfirmBtn?.y}, ${comfirmBtn?.width}, ${comfirmBtn?.height}`;
        comfirmBtn &&
            dispatch({
                type: HOT_AREA_TYPE.studyComplete,
                data: comfirmBtn ? comfirmBtnInfo : [],
            });
    }, [isShow, comfirmBtn?.x, comfirmBtn?.y, comfirmBtn?.width, comfirmBtn?.height]);

    const { nodeRef: cancelStudyBtnRef } = useSetHotArea(HOT_AREA_TYPE.cancelStudyBtn);
    return (
        <div>
            {isShow ? (
                <div className="study-status">
                    <div className="study-status-left">
                        <div
                            ref={cancelStudyBtnRef}
                            onClick={() => {
                                window?.momentaJsBridge?.dispatch('changeDfdiWebView', null, 'close');
                                enterSeaching();
                                setIsShow(false);
                            }}
                            className={`d-cancel`}
                        >
                            <img src={cancelIconD} alt="cancel" />
                        </div>
                        <p ref={comfirmBtnRef} onClick={() => hide()} className="study-btn">
                            Have a try
                        </p>
                        <h3>
                            <img className="p-study-complete" src={Icon} />
                            Route Learning Completed
                        </h3>
                        <label>Route Info:</label>
                        <p>
                            Learned Distance <i>{parseInt(statistic?.learningDistance || 0)}</i>m
                        </p>
                        <p>
                            Speed Bump(s) <i>{statistic?.speedBumpsCount || 0}</i>
                        </p>
                        <p>
                            Ramp(s) <i>{statistic?.rampCount || 0}</i>
                        </p>
                    </div>
                    <div className="study-status-right">
                        <img src={Unlock} alt="Parking Unlocked" />
                    </div>
                </div>
            ) : (
                ''
            )}
        </div>
    );
}
