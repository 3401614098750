import setD from '@/assets/parking/d_new/tool/set_d.png';
import set from '@/assets/parking/d_new/tool/set.png';
import debug from '@/assets/parking/d_new/tool/debug.png';
import debugD from '@/assets/parking/d_new/tool/debug_d.png';
import workbench from '@/assets/parking/d_new/tool/workbench.png';
import workbenchD from '@/assets/parking/d_new/tool/workbench_d.png';
// import FM from '@/utils/nativeSubAndPub';
// import { ParkingSocketMap } from '@/constant/socketMap';
// import logSend from '@/utils/aLog';

import { useTranslation } from 'react-i18next';

export const ParkingLayoutConfig = {
    mapArea: '0.0',
    mVizArea: '100.0',
    verticalMapArea: '0.0',
    verticalMvizArea: '100.0',
    scene: 'parking',
};

export const enterSeaching = () => {
    // logSend({
    //     action: 'hmiInterSeaching',
    //     data: {
    //         name: ParkingSocketMap.apa_control,
    //         cmd: 'START_SEARCHING',
    //     },
    // });
    // FM.sendToHMIParking(ParkingSocketMap.apa_control, {
    //     cmd: 'START_SEARCHING',
    // });
};

export const ToolMap = () => {
    const { t: translate } = useTranslation();
    return [
        {
            name: 'set',
            iconD: setD,
            icon: set,
            txt: translate('parking.setting.setingText'),
            id: 1000,
        },
        {
            name: 'lpnp',
            icon: debug,
            iconD: debugD,
            txt: 'LPNP',
            id: 3000,
        },
        {
            name: 'apa',
            icon: debug,
            iconD: debugD,
            txt: 'APA',
            id: 4000,
        },
        {
            name: 'workbench',
            icon: workbench,
            iconD: workbenchD,
            txt: '',
            id: 5000,
        },
        {
            name: 'rads',
            icon: debug,
            iconD: debugD,
            txt: 'RADS',
            id: 6000,
        },
        {
            name: 'style',
            icon: debug,
            iconD: debugD,
            txt: 'STYLE',
            id: 7000,
        },
    ];
};

export const isParkingHome = () => {
    const isHome = window.location?.hash?.includes('home/parking');
    return isHome;
};

export const TOAST_TIME = 3000;
