import { useLocation } from 'react-router-dom';
import qs from 'query-string';
import { useIsParking } from './index';
import { useSelector } from 'react-redux';

export function useBoxid() {
    const location = useLocation();
    const isParking = useIsParking();
    const params = qs.parse(location.search);
    return useSelector(
        (state) =>
            params.boxid ||
            (isParking ? state?.featureModelParking?.body?.boxId : state?.featureModelDriving?.baseData?.body?.boxid),
    );
}
