import { useDispatch, useSelector } from 'react-redux';
import { USER_MODE } from '@/store/action/parkingType';
import classnames from 'classnames';
import { HMI_MODE_KEY } from '@/constant/HMIMode';
import { singelPointReport } from '@/utils/report';
import reportEventName from '@/constant/reportEventName';
import localCache from '@/utils/localCache';
import { useTranslation } from 'react-i18next';
import './index.less';
import { useRef } from 'react';
import setHMIMode from '@/utils/setHMIMode';

export default function HMIMode({ parking: isParking }) {
    const { t: translate } = useTranslation();
    const i18nLang = useSelector((state) => state?.themeAction?.i18nState);

    const MODE_MAP = useRef(
        i18nLang === 'en-US'
            ? [{ label: translate('parking.setting.HMIMode_D'), value: 'D' }]
            : [
                  { label: translate('parking.setting.HMIMode_D'), value: 'D' },
                  { label: translate('parking.setting.HMIMode_C'), value: 'C' },
              ],
    );

    const dispatch = useDispatch();
    const userMode = useSelector((state) => {
        var mode = state?.parking?.userMode;
        if (i18nLang === 'en-US' && mode !== 'D') {
            mode = 'D';
        }
        return mode;
    });
    const isD = userMode === 'D';

    function changeMode(mode) {
        singelPointReport({
            eventName: reportEventName.HMI_customer_develop_click,
            timestamp: Date.now(),
            text: mode,
        });
        localCache.set(HMI_MODE_KEY, mode);
        dispatch({
            type: USER_MODE,
            data: mode,
        });
        setHMIMode(mode);
    }

    return (
        <div
            className={classnames([
                'navigation_tips_card_wrap',
                'hmi-mode',
                isD ? 'd' : 'sr',
                { parking: isParking },
                i18nLang === 'en-US' && !isParking ? 'one' : '',
            ])}
        >
            <div className={'title_wrap'}>{translate('parking.setting.HMIMode')}</div>
            <div className={'sensitivity_wrap'}>
                <div className={'select_box'}>
                    {MODE_MAP.current.map(({ label, value }) => {
                        return (
                            <span
                                key={value}
                                className={classnames(['select_box_item', { active: userMode === value }])}
                                onClick={() => changeMode(value)}
                            >
                                {label}
                            </span>
                        );
                    })}
                </div>
            </div>
        </div>
    );
}
