import { useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ParkingSocketMap } from '@/constant/socketMap';
import FM from '@/utils/nativeSubAndPub';
import { cusToast } from '@/components/customToast';
import Modal from '../../../globalComponent/modal';
import Loading from '../../../globalComponent/loading';
import { enterSeaching } from '@/components/parkingContainer/config';
import logSend from '@/utils/aLog';

export default function useCompleteHook() {
    const userMode = useSelector((state) => state?.parking?.userMode);
    const gear = useSelector((state) => state?.featureModelParking?.body?.gear);
    const status = useSelector((state) => state?.featureModelParking?.lpnp?.state);
    // const learning_distance = useSelector((state) => state?.featureModelParking?.lpnp?.statistic?.learningDistance);
    const mviz_msg = useSelector((state) => state?.parking?.mvizParkData);
    const parkingMode = useSelector((state) => state?.featureModelParking?.hmi?.parkingMode);
    const isShowLearningComplete = useSelector((state) => state?.parking?.isShowLearningComplete);
    console.log('xxxxxxxx9999', parkingMode);
    const dispatch = useDispatch();
    const curShow = useRef(null);
    const curAlert = useRef(null);
    const curDangRef = useRef(null);
    const prevStatusRef = useRef(null);

    window.lpnp_cmd_cb = (res) => {
        console.log('lpnp_cmd_cb', res);
        if (res?.data?.code !== 0) {
            curAlert.current = null;
            curShow.current = null;
            Loading.hide();
            return cusToast.show({
                content: 'End Route Learning Failed',
            });
        }
        curAlert.current = null;
        curShow.current = null;
    };
    // 完成建图/继续学习泊出
    const handleComplete = async (Lcmd) => {
        const obj = {
            cmd: Lcmd,
        };

        //建图改版，完成时候需要携带id
        if (mviz_msg?.track_id) {
            obj.parking_space_id = parseInt(mviz_msg?.track_id);
        }

        logSend({
            action: 'hmi_lpnp_handle_complete',
            data: {
                ...obj,
            },
        });
        FM.sendToHMIParking(ParkingSocketMap.lpnp_cmd, obj, 'lpnp_cmd_cb');
    };

    const saveStatus = (status) => {
        if (status !== prevStatusRef.current) {
            prevStatusRef.current = status;
        }
    };
    useEffect(() => {
        // TODO: 处理 APA-PARKING -> LEARNING 不处理
        if (prevStatusRef.current == 'APA_PARKING' && status === 'LEARNING') {
            return saveStatus(status);
        }
        saveStatus(status);
        console.log('LEARNING-', prevStatusRef.current, status);
        // LEARNING状态扭转，隐藏loading
        // if ((prevStatusRef.current === 'LEARNING')&& status !== 'LEARNING') {
        //     Loading.hide();
        // }
        if (status === 'STANDBY') {
            Loading.hide();
        }

        // P档状态复位，二次P档复位
        if (curDangRef.current !== 'P' && gear == 'P') {
            curShow.current = true;
            curAlert.current = false;
        }
        curDangRef.current = gear;
        //learning_distance > 0 &&
        if (
            (gear == 'P' &&
                (status == 'LEARNING' || status == 'APA_SELECTING') &&
                !curAlert.current &&
                curShow.current) ||
            isShowLearningComplete
        ) {
            curAlert.current = true;
            Modal.info({
                onOk: async () => {
                    enterSeaching();
                    Loading.show();
                    await handleComplete('COMFIRM_LEARNING_COMPLETE');
                },
                onMidOk: async () => {
                    await handleComplete('CONTINUE_LEARNING_OUT');
                },
                onCancel: () => {
                    enterSeaching();
                    curAlert.current = null;
                },
                title: 'Learning Complete',
                okText: 'Complete',
                midText: 'Learning Parking Out',
                cancelText: 'Continue',
                content: 'Completing the route learning will save the route and end the process',
                centered: true,
                showMid: parkingMode === 'LEARNING',
                isD: userMode === 'D',
            });
        } else {
            curShow.current = null;
            // Modal.clear();
        }
    }, [gear, status, isShowLearningComplete, parkingMode]);

    // 模拟自测代码
    useEffect(() => {
        window.__updateState = (data) => {
            dispatch({
                type: 'parkingState',
                data: data,
            });
        };
        window.__updateNotice = (data) => {
            dispatch({
                type: 'parkingNotice',
                data: data,
            });
        };
    }, []);
}
