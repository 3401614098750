import { ParkingSocketMap } from '@/constant/socketMap';
import FM from '@/utils/nativeSubAndPub';

export default function useTracksRearSettingCommon() {
    // 通知底层：后轮轨迹线开关
    const tracksRearControl = async (tracksRearValue) => {
        FM.sendToHMIParking(
            ParkingSocketMap.avm_control,
            {
                tireTracksRear: tracksRearValue,
            },
            null,
        );
    };

    return {
        tracksRearControl,
    };
}
