import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import Loading from '../globalComponent/loading';

export default function useSetLoading() {
    const status = useSelector((state) => state?.featureModelParking.lpnp?.state);
    const noticeType = useSelector((state) => state?.featureModelParking?.alert?.type);

    const prevStatusRef = useRef();

    const saveStatus = (status) => {
        if (status !== prevStatusRef.current) {
            prevStatusRef.current = status;
        }
    };

    useEffect(() => {
        // APA完成停车后自动触发loading，等待状态切换
        // status
        if (noticeType === 'lpnp_apa_parking_succ') {
            Loading.show();
        }
        if (
            (prevStatusRef.current === 'APA_PARKING' && status != 'APA_PARKING') ||
            (prevStatusRef.current === 'APA_PARKING' && status == 'LEARNING') ||
            (prevStatusRef.current === 'LEARNING' && status !== 'LEARNING') ||
            status == 'MAP_BEING_VERIFIED' ||
            status == 'NOT_IN' ||
            status == 'PASSIVE'
        ) {
            Loading.hide();
        }

        saveStatus(status);
    }, [noticeType, status]);
}
