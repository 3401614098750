import { useEffect } from 'react';
import { getWorkflowStatus, reportWorkflowStatusToFMP } from '../api';
import { useDispatch } from 'react-redux';
import { WORKFLOW_ACTION } from '@/store/action/type';
import { getWorkflowDeployStatus } from '../utils';
import { useBoxid } from './useBoxid';
import { AppFn } from '@/utils/native';
import { isNativeLoad } from '@/utils/isEnv';

let preStatus = {};
const deployCodeMap = {
    0: 'failed',
    1: 'succeeded',
    2: 'deploying',
};

export function useWorkflowStatus() {
    const dispatch = useDispatch();
    const boxid = useBoxid();

    function handleReport(data) {
        if (!data) return;
        if (preStatus.is_completed !== data.is_completed || preStatus.wf_id !== data.wf_id) {
            preStatus = data;
            const deployCode = getWorkflowDeployStatus(data);
            reportWorkflowStatusToFMP({
                wf_id: data.wf_id,
                device_box_id: boxid,
                wf_status: deployCodeMap[deployCode],
            });
        }
    }

    function handleReceiveData(res) {
        if (!res) return;
        const { status, data } = res;
        if (status !== 'success') return;
        dispatch({
            type: WORKFLOW_ACTION.workflowStatus,
            data,
        });
        handleReport(data?.wf_status);
    }
    useEffect(() => {
        let timer = null;
        if (isNativeLoad) {
            const cbName = 'HMI_GetOtaStatus_callback';
            window[cbName] = handleReceiveData;
            AppFn.getOtaStatus(cbName);
            timer = setInterval(AppFn.getOtaStatus, 3000, cbName);
        } else {
            // 非app中加载，后续可能干掉，目前联调使用
            // function fetchWorkflowStatus() {
            //     getWorkflowStatus().then(handleReceiveData);
            // }
            // fetchWorkflowStatus();
            // timer = setInterval(fetchWorkflowStatus, 3000);
        }
        return () => {
            clearInterval(timer);
        };
    }, []);
}
