import { WORKFLOW_ACTION } from '../action/type';

/* {
    "running_status": [
        {
            "package_kind": "MF",
            "package_name": "xxxx",
        },
    ],
    "package_status":[
        {
            "package_kind":"MF",
            "download_size":0,
            "download_progress":0,
            "package_status":5,
            "is_increment":1,
            "running_status":0,
            "name":"Devcar-V3.2.11-mainstream_cuda11_4297-20230513.551.tgz"
        },
        {
            "package_kind":"MF",
            "download_size":769094378,
            "download_progress":100,
            "package_status":6,
            "is_increment":0,
            "download_speed":9552615,
            "download_cost":108,
            "download_total_size":769094378,
            "running_status":0,
            "name":"Devcar-V2.2.0-20230314.6-mainstream_1977.tgz"
        },
        {
            "package_kind":"MF",
            "download_size":0,
            "download_progress":0,
            "package_status":5,
            "is_increment":1,
            "running_status":0,
            "name":"Devcar-V3.2.16-mainstream_cuda11_4847-20230619.473.tgz"
        },
        {
            "package_kind":"MF",
            "download_size":769062114,
            "download_progress":100,
            "package_status":6,
            "is_increment":0,
            "download_speed":11684690,
            "download_cost":114,
            "download_total_size":769062114,
            "deploy_cost":11,
            "running_status":0,
            "name":"Devcar-V2.2.0-20230606.2-mainstream_2206.tgz"
        },
        {
            "package_kind":"VVP",
            "download_size":280422127,
            "download_progress":100,
            "package_status":3,
            "is_increment":0,
            "download_speed":7821430,
            "download_cost":39,
            "download_total_size":280422127,
            "deploy_cost":50,
            "running_status":0,
            "name":"VVP-V3.1.5-20220802.202-vvp_3.1.5.1_50_unstripped.tgz"
        }
    ],
    "wf_status":{
        "is_completed":1,
        "wf_sts_id":116,
        "wf_id":99,
        "nodes":[
            {
                "package_kind":"VVP",
                "node_status":1,
                "package_name":"VVP-V3.1.5-20220802.202-vvp_3.1.5.1_50_unstripped.tgz"
            },
            {
                "package_kind":"MF",
                "node_status":3,
                "package_name":"Devcar-V2.2.0-20230314.6-mainstream_1977.tgz"
            }
        ]
    }
} */
const defaultState = null;

export default function workflow(state = defaultState, action) {
    switch (action.type) {
        case WORKFLOW_ACTION.workflowStatus:
            return action.data;
        default:
            return state;
    }
}
