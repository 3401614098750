import { useRef, useEffect, useLayoutEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ParkingView from '@/components/parkingContainer/index';
import SceneChange from '@/components/scene/index';
import { AppFn } from '@/utils/native';
import FM from '@/utils/nativeSubAndPub';
import { SWITH_MODE_ACTION } from '@/store/action/type';
import localCache from '@/utils/localCache';

import './index.less';

// 获取APP版本信息
window.getAppVersion = function (data) {
    // const {versionName, versionCode, deviceId} = data;
    localStorage.setItem('appInfo', JSON.stringify({ ...data }));
};

export default function ParkingLayout() {
    const sceneArea = useSelector((state) => state.hotArea.sceneArea);
    const parkingBtn = useSelector((state) => state.hotArea.parkingBtn);
    const parkingApaBtn = useSelector((state) => state.hotArea.parkingApaBtn);
    const apaConfirm = useSelector((state) => state.hotArea.apaConfirm);
    const apaCancel = useSelector((state) => state.hotArea.apaCancel);

    const backBtn = useSelector((state) => state.hotArea.backBtn);
    const cancelBtn = useSelector((state) => state.hotArea.cancelBtn);
    const studyComplete = useSelector((state) => state.hotArea.studyComplete);
    const backHomeBtn = useSelector((state) => state.hotArea.backHomeBtn);
    const guideLpnpBtn = useSelector((state) => state.hotArea.guideLpnpBtn);
    const startApaBtn = useSelector((state) => state.hotArea.startApaBtn);
    const recordStateDom = useSelector((state) => state.hotArea.recordStateDom);
    const workbenchDom = useSelector((state) => state.hotArea.workbenchDom);
    const NRPBtn = useSelector((state) => state.hotArea.NRPBtn);

    const pnpSoftSwitch = useSelector((state) => state.hotArea.pnpSoftSwitch);
    //pnp 工程模式操作区
    const LPNPDebugOperator = useSelector((state) => state.hotArea.LPNPDebugOperator);
    const LPNPDashboardArea = useSelector((state) => state.hotArea.LPNPDashboardArea);

    const isHotAll = useSelector((state) => state.hotArea.isHotAll);
    const area = useSelector((state) => state?.layout?.Area);
    const Mtour = useSelector((state) => state?.switchModeAction?.switchUnpState);

    const dispatch = useDispatch();
    const parkingView = useRef(null);
    // useEffect(()=> {
    //     FM.registerFeatureModel(['parking'], "recevieFeatueModelParkingData")
    // }, [])
    SceneChange();

    useLayoutEffect(() => {
        if (Mtour) {
            localCache.set('momenta_hmi_workbench_switch', !Mtour);
            dispatch({
                type: SWITH_MODE_ACTION.switchWorkBench,
                data: !Mtour,
            });
        } else {
            localCache.get('momenta_hmi_workbench_switch', (data) => {
                dispatch({
                    type: SWITH_MODE_ACTION.switchWorkBench,
                    data: !!data,
                });
            });
        }
    }, [Mtour]);
    useEffect(() => {
        window?.momentaJsBridge?.dispatch('changeDfdiWebView', null, 'close');
    }, []);

    useEffect(() => {
        let isHorizontal = '';
        //判断横竖屏
        if (document.documentElement.clientWidth > document.documentElement.clientHeight) {
            // 横屏
            isHorizontal = 'horizontal';
        } else if (document.documentElement.clientWidth < document.documentElement.clientHeight) {
            // 竖屏
            isHorizontal = 'vertical';
        } else {
            // 默认横屏
            isHorizontal = 'horizontal';
        }
        dispatch({
            type: 'resetArea',
            data: {
                map: '0',
                mviz: '100.0',
                slider: 0,
                isHorizontal: isHorizontal,
                scene: 'parking',
            },
        });
        let data = {
            mapArea: '0.0',
            mVizArea: '100.0',
            verticalMapArea: '0.0',
            verticalMvizArea: '100.0',
            sliderArea: 0,
            scene: 'parking',
        };
        AppFn.setMviz(data);
    }, [area?.isHorizontal]);

    useEffect(() => {
        const HotArea = [];
        sceneArea !== '' && HotArea.push(sceneArea);
        parkingBtn !== '' && HotArea.push(parkingBtn);
        parkingApaBtn !== '' && HotArea.push(parkingApaBtn);
        apaConfirm !== '' && HotArea.push(apaConfirm);
        apaCancel !== '' && HotArea.push(apaCancel);
        backBtn !== '' && HotArea.push(backBtn);
        cancelBtn !== '' && HotArea.push(cancelBtn);
        studyComplete !== '' && HotArea.push(studyComplete);
        backHomeBtn !== '' && HotArea.push(backHomeBtn);
        guideLpnpBtn !== '' && HotArea.push(guideLpnpBtn);
        startApaBtn !== '' && HotArea.push(startApaBtn);
        pnpSoftSwitch !== '' && HotArea.push(pnpSoftSwitch);
        LPNPDebugOperator !== '' && HotArea.push(LPNPDebugOperator);
        LPNPDashboardArea !== '' && HotArea.push(LPNPDashboardArea);
        recordStateDom !== '' && HotArea.push(recordStateDom);
        workbenchDom !== '' && HotArea.push(workbenchDom);
        NRPBtn !== '' && HotArea.push(NRPBtn);
        console.log('HotArea parking', HotArea, LPNPDashboardArea);
        AppFn.setHotArea(HotArea, isHotAll);
    }, [
        isHotAll,
        sceneArea,
        startApaBtn,
        parkingBtn,
        parkingApaBtn,
        backBtn,
        cancelBtn,
        studyComplete,
        backHomeBtn,
        guideLpnpBtn,
        pnpSoftSwitch,
        LPNPDebugOperator,
        LPNPDashboardArea,
        recordStateDom,
        workbenchDom,
        NRPBtn,
    ]);

    return (
        <div ref={parkingView} className={`${area.isHorizontal} parking-layout`}>
            <ParkingView></ParkingView>
            {/* <ParkingSetting /> */}
        </div>
    );
}
