import { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import uploadImg from '@/assets/workbench/upload.png';
import bg from '@/assets/workbench/recode_item_bg.png';
import { getFileList } from '../api';
import { Button, Empty } from 'antd-mobile';
import status from '@/assets/workbench/uploading.png';
import BScroll from '@better-scroll/core';
import { singelPointReport } from '@/utils/report';
import workBenchEventName from '@/constant/workBenchEventName';
import Toast from '@/components/workbench/Toast';
import './index.less';
import { useBoxid } from '../hook';
import { useTranslation } from 'react-i18next';
import EventTagModal from '../EventTagModal';
import ReportItem from './ReportItem';
import ImgPreviewModal from './ImgPreviewModal';
import AdditionalRecordModal from './AdditionalRecordModal';
import { useAudioPlay } from '../hook';
import isCNfun from '@/utils/isCN';

const isCN = isCNfun();
let isScrollButtom = false;

export default function Report(props) {
    const { t: translate } = useTranslation();
    const box_id = useBoxid();
    const uploadFileList = useSelector((state) => state?.diskDataCollection?.uploadFileList);
    const workBenchRecording = useSelector((state) => state?.switchModeAction?.isRecording); // 工作台的录音状态
    const [list, setList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const hasMoreData = useRef(true);
    const listRef = useRef([]);
    const myScroll = useRef();
    const container = useRef();
    const content = useRef();

    const { handleAudio, reset, playId, playEffect } = useAudioPlay();

    const openSomeImage = (url) => {
        setImgPreviewVisible(true);
        setCurUrl(url);
        singelPointReport({
            eventName: workBenchEventName.WB_picture_play_click,
            timestamp: new Date().getTime(),
        });
    };

    const [isMark, setIsMark] = useState(false);

    const getFileListAgain = async () => {
        if (!box_id) return;
        setIsLoading(true);
        let list = listRef?.current;
        let res = await getFileList({
            device_box_id: box_id,
            last_id: list[list?.length - 1]?.id,
            limit: 10,
            need_tag: isMark ? 1 : 0,
        });
        if (res?.code == 200) {
            listRef.current = [...listRef.current, ...res?.data];
            setList(listRef.current);
            hasMoreData.current = res?.data?.length >= 10;
        }
        isScrollButtom = false;
        setIsLoading(false);
    };

    const getFileListAgainRef = useRef(getFileListAgain);

    useEffect(() => {
        getFileListAgainRef.current = getFileListAgain;
    }, [box_id, isMark]);

    const [updateReportList, setUpdateReportList] = useState(false);
    const preMark = useRef(isMark);
    // 刷新列表
    useEffect(() => {
        if (!box_id || isFirstFetch.current) return;
        const isChangeMark = preMark.current !== isMark;
        preMark.current = isMark;
        setIsLoading(true);
        isScrollButtom = true;
        getFileList({
            device_box_id: box_id,
            last_id: 0,
            limit: isChangeMark ? 10 : list?.length || 10,
            need_tag: isMark ? 1 : 0,
        })
            .then((res) => {
                if (res?.code == 200) {
                    listRef.current = [...res?.data];
                    setList(listRef.current);
                }
            })
            .finally(() => {
                setIsLoading(false);
                isScrollButtom = false;
            });
    }, [updateReportList]);

    // 上传完成后刷新列表
    useEffect(() => {
        if (uploadFileList?.length || isFirstFetch.current) return;
        setUpdateReportList(!updateReportList);
    }, [uploadFileList?.length]);

    const isFirstFetch = useRef(true);
    useEffect(
        () =>
            (async () => {
                isFirstFetch.current = false;
                if (!box_id) return;
                let res = await getFileList({
                    device_box_id: box_id,
                    last_id: 0,
                    limit: 10,
                    need_tag: isMark ? 1 : 0,
                });
                if (res?.code == 200) {
                    listRef.current = [...listRef.current, ...res?.data];
                    setList(listRef.current);
                    myScroll.current = new BScroll('#wrapper', {
                        probeType: 3,
                        wheel: true,
                        scrollbar: true,
                        click: true,
                        pullUpLoad: true,
                    });
                    myScroll?.current?.on('scroll', async () => {
                        if (
                            myScroll?.current?.y <= container?.current?.offsetHeight - content?.current?.offsetHeight &&
                            !isScrollButtom &&
                            hasMoreData.current
                        ) {
                            isScrollButtom = true;
                            return await getFileListAgainRef.current();
                        }
                    });
                }
            })(),
        [box_id],
    );

    useEffect(() => {
        myScroll?.current?.refresh();
    }, [isLoading, list.length]);

    //补录完成回调，重新刷新列表
    window.stopAmendRecordCB = (nres) => {
        console.log('mmmxxxxx', JSON.stringify(nres));
        nres.code === 200 && setUpdateReportList(!updateReportList);
    };

    const [additionalRecordModalFlag, setAdditionalRecordModalFlag] = useState(false);
    const [recordData, setRecordData] = useState(null); // 当前补录项

    const additionalBtn = (item) => {
        if (workBenchRecording) {
            Toast.info(translate('workBench.report.addRecordToast'), 2000);
            return;
        }
        console.log('playEffect.current', playEffect.current);
        if (playEffect.current) {
            reset();
        }
        setAdditionalRecordModalFlag(true);
        setRecordData(item);
    };

    const [imgPreviewVisible, setImgPreviewVisible] = useState(false);
    const [curUrl, setCurUrl] = useState('');

    const [eventTagModalVisable, setEventTagModalVisable] = useState(false);
    const [reportItme, setReportItme] = useState(null);
    function openEventTagModal(item) {
        setReportItme(item);
        setEventTagModalVisable(true);
        if (playEffect.current) reset();
    }

    function filterReportData() {
        hasMoreData.current = true;
        setIsMark(!isMark);
        setUpdateReportList(!updateReportList);
    }

    return (
        <div className="work-bench-base right">
            <div className="work-bench-base1">
                <h4 className="between">
                    <span>{translate('workBench.report.reportManagement')}</span>
                    {isCN && (
                        <Button
                            style={{ fontSize: '.20rem' }}
                            color={isMark ? 'primary' : 'default'}
                            onClick={filterReportData}
                        >
                            当天自动标记event
                        </Button>
                    )}
                </h4>
                <div ref={container} className="work-bench-base-upload-content" id="wrapper">
                    <ul ref={content} className="work-bench-base-lists">
                        {uploadFileList?.map((item, index) => {
                            return (
                                <li className="work-bench-base-lists-item" key={index}>
                                    <div className="work-bench-base-lists-status">
                                        <img src={status} alt="status" />
                                    </div>
                                    <div className="work-bench-base-lists-title">
                                        <span className="">{item?.file_name}</span>
                                    </div>
                                    <div className="work-bench-base-lists-content">
                                        <img className="work-bench-img" src={uploadImg} alt="screen shot" />
                                        <img className="work-bench-audio" src={bg} alt="audio record" />
                                        <div className="work-bench-base-lists-effect">
                                            <div className="work-bench-base-lists-effect-loading"></div>
                                        </div>
                                    </div>
                                </li>
                            );
                        })}
                        {list?.map((item, index) => {
                            return (
                                <ReportItem
                                    className="work-bench-base-lists-item"
                                    item={item}
                                    Tag="li"
                                    key={index}
                                    onTitleClick={() => openEventTagModal(item)}
                                    onImgClick={(url) => openSomeImage(url)}
                                    onPlayAudio={() =>
                                        handleAudio(item?.voice_content?.url, item?.voice_content?.duration, item.id)
                                    }
                                    isPlay={playId === item.id}
                                    onAdditional={() => additionalBtn(item)}
                                />
                            );
                        })}
                        {!isLoading && list?.length > 0 && (
                            <div className="list-no-more">{translate('workBench.report.noMoreData')}</div>
                        )}
                    </ul>
                    {!uploadFileList?.length && !list?.length && !isLoading && (
                        <Empty
                            description={translate('workBench.noData')}
                            imageStyle={{ width: '2rem' }}
                            className="tip"
                        />
                    )}
                </div>
            </div>
            <ImgPreviewModal visible={imgPreviewVisible} url={curUrl} onClose={() => setImgPreviewVisible(false)} />

            <AdditionalRecordModal
                visible={additionalRecordModalFlag}
                onClose={() => setAdditionalRecordModalFlag(false)}
                onInterrupt={() => {
                    setAdditionalRecordModalFlag(false);
                    props.workBenchClose();
                }}
                recordData={recordData}
            />
            <EventTagModal
                visible={eventTagModalVisable}
                onClose={() => {
                    setEventTagModalVisable(false);
                    setUpdateReportList(!updateReportList);
                }}
                item={reportItme}
                workBenchClose={props.workBenchClose}
            />
        </div>
    );
}
