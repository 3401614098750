import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Switch } from 'antd-mobile';
import CardLayout from '../../../components/cardLayout';
import { useSelector } from 'react-redux';
import ldw from '@/assets/settingComNew/activeSafety/ldw.png';
import useLKASettingCommon from '../LKASettingCommon';
import '@/components/slider/smartDrivingSetting/components/cardStyle/index.less';

export default function LdwCard() {
    const { t: translate } = useTranslation();
    const ldw_switch = useSelector((state) => state?.featureModelDriving?.switch?.adas?.lka_switch?.ldw_switch);
    const [LDWSwitch, setLDWSwitch] = useState(ldw_switch === 'OPEN');
    const [loading, setLoading] = useState(false);
    const [timer, setTimer] = useState(null); // 存放定时器句柄

    const { switchChange } = useLKASettingCommon({ timer: timer, setTimer: setTimer, setLoading: setLoading });

    useEffect(() => {
        ldw_switch && setLDWSwitch(ldw_switch === 'OPEN');
        if (timer) {
            clearTimeout(timer);
            setTimer(null);
        }
        setLoading(false);
    }, [ldw_switch]);

    const leftDom = (
        <div className={'left_dom_wrap'}>
            <div className={'switch_wrap'}>
                <div className={'content_wrap'}>
                    <div className={'text_wrap'}>LDW</div>
                    <div className={'text_wrap_detail'}>{translate('LKASetting.LDWSwitch')}</div>
                </div>
                <Switch
                    checked={LDWSwitch}
                    loading={loading}
                    onChange={(checked) => switchChange('LDW', checked, setLDWSwitch)}
                    className={'customSwitch'}
                />
            </div>
        </div>
    );

    const rightDom = (
        <div className={'right_dom_wrap'}>
            <div className={'right_dom_wrap'}>
                <img src={ldw} />
            </div>
        </div>
    );

    return (
        <>
            <CardLayout leftDom={leftDom} rightDom={rightDom} value={LDWSwitch} />
        </>
    );
}
