import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Switch } from 'antd-mobile';
import CardLayout from '../../../components/cardLayout';
import { singelPointReport } from '@/utils/report';
import reportEventName from '@/constant/reportEventName';
import { useSelector, useDispatch } from 'react-redux';
import '@/components/slider/smartDrivingSetting/components/cardStyle/index.less';
import localCache from '@/utils/localCache';

export default function VcruiseSwitch() {
    const dispatch = useDispatch();
    const { t: translate } = useTranslation();
    // 开关 ON/OFF
    const showVcruise = useSelector((state) => (state?.showVcruise?.vcruise === 'ON' ? true : false));
    // const vcruise = localStorage.getItem('vcruise_string') || 'OFF';
    // const [vcruiseSwitch, setVcruiseSwitch] = useState(vcruise === 'ON' ? true : false);
    useEffect(() => {
        switchRemind(showVcruise);
    }, []);

    const switchRemind = (value) => {
        if (value) {
            // localStorage.setItem('vcruise_string', 'ON')
            localCache.set('vcruise_string', 'ON');
            dispatch({
                type: 'setVcruiseSwitch',
                data: 'ON',
            });
        } else {
            // localStorage.setItem('vcruise_string', 'OFF')
            localCache.set('vcruise_string', 'OFF');
            dispatch({
                type: 'setVcruiseSwitch',
                data: 'OFF',
            });
        }
    };

    const leftDom = (
        <div className={'left_dom_wrap'}>
            <div className={'switch_wrap'}>
                <div className={'content_wrap'} style={{ marginBottom: '.4rem' }}>
                    <div className={'text_wrap'} style={{ whiteSpace: 'pre-wrap', width: '5rem' }}>
                        {translate('settings.vcruiseSwitch')}
                    </div>
                    <div className={'text_wrap_detail'} style={{ whiteSpace: 'pre-wrap', width: '5rem' }}>
                        {translate('settings.vcruiseSwitchDetail')}
                    </div>
                </div>
                <Switch
                    checked={showVcruise}
                    onChange={(checked) => switchRemind(checked)}
                    className={'customSwitch'}
                />
            </div>
        </div>
    );

    const rightDom = <div className={'right_dom_wrap'} />;

    return (
        <>
            <CardLayout leftDom={leftDom} rightDom={rightDom} value={showVcruise} />
        </>
    );
}
