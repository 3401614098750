import FM from '@/utils/nativeSubAndPub';

import { cusToast } from '@/components/customToast';
import { LPNP_CMD, DEBUG_CMD } from './config';

const getAPaCmd = (rpaCmd, data) => {
    const dataCMD = data;
    if (rpaCmd) {
        dataCMD.rpa = rpaCmd;
    }
    return dataCMD;
};
window.apa_control_cb_start_select = (res) => {
    if (res?.data?.code === 0) {
        return cusToast.show({
            content: 'Activate the parking spot selection!',
        });
    }
    return cusToast.show({
        content: 'Activate the parking spot selection failed!',
    });
};
window.apa_control_cb_stop_select = (res) => {
    if (res?.data?.code === 0) {
        return cusToast.show({
            content: 'Close the parking spot selection!',
        });
    }
    cusToast.show({
        content: 'Close the parking spot selection failed!',
    });
};

window.apa_control_cb_start_searching = (res) => {
    if (res?.data?.code === 0) {
        return cusToast.show({
            content: 'Park In Mode!',
        });
    }
    cusToast.show({
        content: 'Park In Mode Failed!',
    });
};

window.apa_control_cb_start_parking = (res) => {
    if (res?.data?.code === 0) {
        return cusToast.show({
            content: 'Start Park In!',
        });
    }
    cusToast.show({
        content: 'Park In Failed!',
    });
};
window.apa_control_cb_stop_parking = (res) => {
    if (res?.data?.code === 0) {
        return cusToast.show({
            content: 'Stop APA!',
        });
    }
    cusToast.show({
        content: 'Stop APA Failed!',
    });
};

window.apa_control_cb_resume_parking = (res) => {
    if (res?.data?.code === 0) {
        return cusToast.show({
            content: 'Resume APA!',
        });
    }
    cusToast.show({
        content: 'Resume APA Failed!',
    });
};

window.apa_control_cb_start_searching_out = (res) => {
    if (res?.data?.code === 0) {
        return cusToast.show({
            content: 'Park Out Mode!',
        });
    }
    cusToast.show({
        content: 'Park Out Mode Failed!',
    });
};

window.apa_control_cb_exit_apa = (res) => {
    if (res?.data?.code === 0) {
        return cusToast.show({
            content: 'Exit APA!',
        });
    }
    cusToast.show({
        content: 'Exit APA Failed!',
    });
};

const active_select = async (rpaCmd) => {
    const cmdDta = getAPaCmd(rpaCmd, {
        cmd: DEBUG_CMD.START_SELECTING,
        debug: true,
    });
    FM.sendToHMIParking(LPNP_CMD, cmdDta, 'apa_control_cb_start_select');
};

const disabled_select = async (rpaCmd) => {
    const cmdDta = getAPaCmd(rpaCmd, {
        cmd: DEBUG_CMD.STOP_SELECTING,
        debug: true,
    });
    FM.sendToHMIParking(LPNP_CMD, cmdDta, 'apa_control_cb_stop_select');
};

const start_searching = async (rpaCmd) => {
    const dataCMD = {
        cmd: DEBUG_CMD.START_SEARCHING,
        debug: true,
    };
    FM.sendToHMIParking(LPNP_CMD, getAPaCmd(rpaCmd, dataCMD), 'apa_control_cb_start_searching');
};

const start_parking = async (mviz_msg, rpaCmd) => {
    if (!mviz_msg?.track_id) {
        return cusToast.show({
            content: 'Please select a parking spot!',
        });
    }
    const obj = {
        cmd: DEBUG_CMD.START_PARKING,
        parking_space_id: parseInt(mviz_msg?.track_id),
        debug: true,
    };
    if (mviz_msg?.parking_in_direction) {
        obj.parking_in_direction = parseInt(mviz_msg?.parking_in_direction);
    }
    const dataCMD = getAPaCmd(rpaCmd, obj);
    FM.sendToHMIParking(LPNP_CMD, dataCMD, 'apa_control_cb_start_parking');
};

const stop_parking = async (rpaCmd) => {
    const dataCMD = getAPaCmd(rpaCmd, {
        cmd: DEBUG_CMD.STOP_PARKING,
        debug: true,
    });
    FM.sendToHMIParking(LPNP_CMD, dataCMD, 'apa_control_cb_stop_parking');
};

const resume_parking = async (rpaCmd) => {
    const dataCMD = getAPaCmd(rpaCmd, {
        cmd: DEBUG_CMD.RESUME_PARKING,
        debug: true,
    });
    FM.sendToHMIParking(LPNP_CMD, dataCMD, 'apa_control_cb_resume_parking');
};

const start_searching_out = async (rpaCmd) => {
    const cmdDta = getAPaCmd(rpaCmd, {
        cmd: DEBUG_CMD.START_SEARCHING_OUT,
        debug: true,
    });
    FM.sendToHMIParking(LPNP_CMD, cmdDta, 'apa_control_cb_start_searching_out');
};

const exit_apa = async (rpaCmd) => {
    const cmdDta = getAPaCmd(rpaCmd, {
        cmd: DEBUG_CMD.EXIT_APA,
        debug: true,
    });
    FM.sendToHMIParking(LPNP_CMD, cmdDta, 'apa_control_cb_exit_apa');
};

window.apa_control_cb_select_parking_slot = (res) => {
    if (res?.data?.code === 0) return;
    cusToast.show({
        content: 'Select Parking Slot Failed!',
    });
};

const select_parking_slot = (rpa, track_id) =>
    FM.sendToHMIParking(
        LPNP_CMD,
        getAPaCmd(rpa, {
            cmd: DEBUG_CMD.SELECT_PARKING_SLOT,
            parking_space_id: parseInt(track_id),
            debug: true,
        }),
        'apa_control_cb_select_parking_slot',
    );

window.apa_control_cb_select_parking_direction = (res) => {
    if (res?.data?.code === 0) return;
    cusToast.show({
        content: 'Select Parking Direction Failed!',
    });
};

const select_parking_direction = (rpa, parking_out_direction) =>
    FM.sendToHMIParking(
        LPNP_CMD,
        getAPaCmd(rpa, { cmd: DEBUG_CMD.SELECT_PARKING_DIRECTION, parking_out_direction, debug: true }),
        'apa_control_cb_select_parking_direction',
    );

export {
    active_select,
    disabled_select,
    start_searching,
    start_parking,
    stop_parking,
    resume_parking,
    start_searching_out,
    exit_apa,
    select_parking_slot,
    select_parking_direction,
};
