import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { SocketMap } from '@/constant/socketMap';
import FM from '@/utils/nativeSubAndPub';

import './index.less';

let timer = null;
export default function CustomSlifTsrCard() {
    // const slif_switch = useSelector((state) => state?.featureModelDriving?.switch?.adas?.slif?.slif_switch);
    const slif_tsr_switch = useSelector(
        (state) => state?.featureModelDriving?.switch?.driving?.auto_follow_slif_speed_control?.switch,
    );
    console.log('slifTsrSwitchslifTsrSwitchslifTsrSwitch', slif_tsr_switch);
    const [slifTsrSwitch, setSlifTsrSwitch] = useState(slif_tsr_switch);
    const { t: translate } = useTranslation();
    const slifTsrCustomOptions = translate('settings.slifTsrCustomOptions', {
        returnObjects: true,
    });
    console.log('slifTsrCustomOptionsslifTsrCustomOptions', slifTsrCustomOptions);

    useEffect(() => {
        slif_tsr_switch && setSlifTsrSwitch(slif_tsr_switch);
        if (timer) {
            clearTimeout(timer);
            timer = null;
        }
    }, [slif_tsr_switch]);

    const switchSlifTsrChange = (value) => {
        // if (slif_switch === 'CLOSE') return;
        clearTimeout(timer);
        timer = setTimeout(() => {
            setSlifTsrSwitch(slif_tsr_switch);
        }, 2 * 1000);
        autoFollowSlifSpeedControl(value);
    };

    const autoFollowSlifSpeedControl = async (value) => {
        FM.sendToHMIDriving(
            SocketMap.autoFollowSlifSpeedControl,
            {
                auto_follow_slif_speed_switch: value,
            },
            'drive_node_res_fail_cb',
        );
    };

    return (
        <div className={'card_wrap'}>
            <div className={`customise-content`}>
                <div className="customise-title">{translate('SLIFTSR.customizeTitle')}</div>
                <div className={`select-group-item`}>
                    <p className="select-group-item-name">{translate('SLIFTSR.secondTitle')}</p>
                    <div className={`select-box`}>
                        {slifTsrCustomOptions.length &&
                            slifTsrCustomOptions.map((item, index) => {
                                let value = item.value;
                                return (
                                    <span
                                        key={index}
                                        className={`select-box-item ${slifTsrSwitch === value ? 'active' : ''}`}
                                        onClick={() => switchSlifTsrChange(value)}
                                    >
                                        {item.label}
                                    </span>
                                );
                            })}
                    </div>
                </div>
            </div>
        </div>
    );
}
