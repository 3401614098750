import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { AppFn } from '@/utils/native';

const { wbPlayAudioCallback } = AppFn;
const cache = new Set();
const limit = 80;

function notify(count) {
    cache.add(count);
    wbPlayAudioCallback(
        null,
        JSON.stringify({ type: 'duplex', data: 'insufficient_storage_for_data_acquisition_disk.mp3' }),
    );
}

export function useDataAcquisitionDiskTip() {
    const exportDisk = useSelector((state) => state?.diskDataCollection?.exportDisk);

    useEffect(() => {
        if (exportDisk?.status !== 'mounted') {
            cache.size && cache.clear();
            return;
        }
        const space_used_percent = Math.floor((exportDisk?.space_used / exportDisk?.space_size) * 100);
        if (space_used_percent < limit) {
            cache.size && cache.clear();
            return;
        }
        // 初始化时空间超过阀值直接提示
        if (!cache.size) {
            notify(space_used_percent);
            return;
        }

        // 使用空间超过阀值后，每使用3%语音提示一次
        if (!((space_used_percent - limit) % 3) && !cache.has(space_used_percent)) {
            notify(space_used_percent);
            return;
        }
    }, [exportDisk?.space_used, exportDisk?.status]);
}
