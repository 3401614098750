const SocketMap = {
    // 获取版本信息
    getVersion: '17',
    operateDriveMode: 'operate_drive_mode',
    drivingControl: 'driving_control',
    hmiMsgSubscribe: 'hmi_msg_subscribe',
    transDriveMode: 'trans_drive_mode',
    carFollowControl: 'car_follow_control',
    operateLaneChangeMode: 'lane_change',
    adasControl: 'adas_control',
    drivingStyle: 'driving_style_control',
    laneChangeStyle: 'lane_change_style_control',
    adasSlifControl: 'adas_slif_control',
    adasTsrControl: 'adas_tsr_control',
    adasAebControl: 'adas_aeb_control',
    adasFcwControl: 'adas_fcw_control',
    TALSwitchControl: 'tla_control',
    elkSwitchControl: 'elk_control',
    lcaBsdControl: 'lca_bsd_control',
    dowControl: 'dow_control',
    rcwControl: 'rcw_control',
    absmControl: 'absm_control',
    resumeControl: 'running_mode_resume_control',
    autoLaneChangeControl: 'cp_lane_change',
    handsOffDriveMode: 'hands_off_drive_mode',
    tts: 'heartbeat_control',
    laneChangeTipControl: 'lane_change_tip_control',
    cswControl: 'csw_control',
    essControl: 'ess_control',
    ihcControl: 'ihc_control',
    event_trigger: 'event_trigger',
    lpnp_switch_control: 'lpnp_switch_control',
    apa_switch_control: 'apa_switch_control',
    tfl_stop_control: 'tfl_stop_control',
    collision_alert_control: 'collision_alert_control',
    latestLaneChangeControl: 'latest_lane_change_control',
    autoFollowSlifSpeedControl: 'auto_follow_slif_speed_control',
    adas_amap_control: 'adas_amap_control',
    adas_nrp_control: 'adas_nrp_control',
    adas_rim_control: 'adas_rim_control',
    adas_isa_control: 'adas_isa_control',
    cp_warning_control: 'cp_warning_control',
    nrp_auto_control: 'nrp_auto_control',
    roundabout_info_control: 'roundabout_info_control',
    adas_rcta_control: 'adas_rcta_control',
    adas_rctb_control: 'adas_rctb_control',
    npFunctionMode: 'np_function_mode',
    dev_msg_control: 'dev_msg_control',
    adas_cdw_control: 'adas_cdw_control',
    adas_rcta_rctb_control: 'adas_rcta_rctb_control',
    adas_rpcm_control: 'adas_rpcm_control',
    adas_rvb_control: 'adas_rvb_control',
    standard_unit_control: 'standard_unit_control',
    adas_slif_hdmap_control: 'adas_slif_hdmap_control',
    eesf_control: 'eesf_control',
    aes_control: 'aes_control',
    curve_control: 'curve_control',
    preferred_lane_change: 'preferred_lane_change',
};

const ParkingSocketMap = {
    pilot_kind_control: 'pilot_kind_control', // 切换场景
    lpnp_cmd: 'lpnp_control', // 用户状态切换指令
    lpnp_switch_control: 'lpnp_switch_control', // 切换LPNP开关
    meb_switch_control: 'meb_switch_control',
    apa_control: 'apa_control',
    pts_control: 'pts_control',
    avm_control: 'avm_control',
    arp_control: 'arp_control',
    arp_type_control: 'arp_type_control',
    hmi_cpm_request: 'hmi_cpm_request',
    hmi_avm_control: 'hmi_avm_control',
    hmi_apa_request: 'hmi_apa_request',
};

const LPNP_CMD_TYPE = {
    LEARNING_START: 'LEARNING_START',
    LEARNING_RESTART: 'LEARNING_RESTART',
    LEARNING_CANCEL: 'LEARNING_CANCEL',
    LEARNING_COMPLETE: 'LEARNING_COMPLETE',
    PARKING_START: 'PARKING_START',
    START_NEARBY_SEARCHING: 'START_NEARBY_SEARCHING', //开始沿途寻找车位
    CANCEL_NEARBY_SEARCHING: 'CANCEL_NEARBY_SEARCHING', //取消沿途寻找车位
    RESUME_NEARBY_SEARCHING: 'RESUME_NEARBY_SEARCHING', //继续沿途寻找车位
};

const commonScoketMap = {
    workbench_config: 'workbench_config',
};

// 驾驶/变道风格
/*// 驾驶/变道风格
const styleOptions=[{
  label: '正常',
  value: 'NORMAL'
}, {
  label: '激进',
  value: 'AGGRESIVE'
}, {
  label: '保守',
  value: 'CONSERVATIVE'
}];*/

export {
    SocketMap,
    ParkingSocketMap,
    LPNP_CMD_TYPE,
    commonScoketMap,
    // styleOptions
};
