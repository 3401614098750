import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Switch } from 'antd-mobile';
import CardLayout from '../../../components/cardLayout';
import elk from '@/assets/settingComNew/activeSafety/elk.png';
import { cusToast } from '@/components/customToast';
import { useSelector } from 'react-redux';
import { SocketMap } from '@/constant/socketMap';
import FM from '@/utils/nativeSubAndPub';
import '@/components/slider/smartDrivingSetting/components/cardStyle/index.less';
import { singelPointReport } from '@/utils/report';
import reportEventName from '@/constant/reportEventName';

let timer = null;
export default function ElkCard() {
    const { t: translate } = useTranslation();
    const elk_switch = useSelector((state) => state?.featureModelDriving?.switch?.adas?.lka_switch?.elk_switch);
    const [ELKSwitch, setELKSwitch] = useState(elk_switch === 'OPEN');
    const elk_roadedge_working_mode = useSelector(
        (state) => state?.featureModelDriving?.switch?.adas?.lka_switch?.elk_roadedge_working_mode,
    );
    useEffect(() => {
        elk_switch && setELKSwitch(elk_switch === 'OPEN');
        if (timer) {
            clearTimeout(timer);
            timer = null;
        }
    }, [elk_switch]);

    const switchChange = (value) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            setELKSwitch(elk_switch === 'OPEN');
        }, 2 * 1000);
        elkControl({
            ELKSwitch: value,
        });
        reportDataFunc(value);
    };

    // elk通知底层
    const elkControl = async (params) => {
        const { ELKSwitch } = params;
        FM.sendToHMIDriving(
            SocketMap.elkSwitchControl,
            {
                switch: ELKSwitch ? 'OPEN' : 'CLOSE',
                roadedge_working_mode: elk_roadedge_working_mode,
            },
            'drive_node_res_fail_cb',
        );
    };
    // 数据上报
    const reportDataFunc = (value) => {
        singelPointReport({
            eventName: reportEventName.HMI_ELK_SWITCH_CLICK,
            timestamp: new Date().getTime(),
            reportData: {
                ELK: value ? 'OPEN' : 'CLOSE',
            },
        });
    };

    const leftDom = (
        <div className={'left_dom_wrap'}>
            <div className={'switch_wrap'}>
                <div className={'content_wrap'}>
                    <div className={'text_wrap'}>ELK</div>
                    <div className={'text_wrap_detail'} style={{ whiteSpace: 'pre-wrap' }}>
                        {translate('LKASetting.ELKSwitch')}
                    </div>
                </div>
                <Switch checked={ELKSwitch} onChange={(checked) => switchChange(checked)} className={'customSwitch'} />
            </div>
        </div>
    );

    const rightDom = (
        <div className={'right_dom_wrap'}>
            <img src={elk} />
        </div>
    );

    return (
        <>
            <CardLayout leftDom={leftDom} rightDom={rightDom} value={ELKSwitch} />
        </>
    );
}
