import store from '../store';
import { WIDGET_ACTION } from '@/store/action/type';

const widgetStoreTypeEnum = {
    1: WIDGET_ACTION.widget1Action,
    2: WIDGET_ACTION.widget2Action,
};

export const widgetType = {
    LANE_CHANGE: 'lane_change',
    COLLISION_AVOID: 'collision_avoid',
    TAPERED_BARREL_AUTO_STOP: 'tapered_barrel_auto_stop',
    COMITY_PEDESTRIAN: 'comity_pedestrian',
    LAST_LANE_CHANGE: 'last_lane_change',
    LAST_ROAD_MERGE: 'last_road_merge',
    CORNERING: 'cornering',
    ODD: 'odd',
    RELEGATION: 'relegation',
    SCENE_CHANGE: 'scene_change',
    TOR: 'tor',
    SELF_CONFIRM: 'self_confirm',
    TFL: 'tfl',
    VCURISE_LIMIT: 'vcurise_limit',
    LANE_CHANGE_CONFIRM_FAIL_REASON: 'lane_change_confirm_fail_reason',
    CP_ROAD_BOUNDARY_COLLISION: 'cp_road_boundary_collision',
    CP_REAR_CAR_COLLISION: 'cp_rear_car_collision',
    CP_CROSS_BOUNDARY: 'cp_cross_boundary',
    LANE_CHANGE_FAIL_REASON: 'lane_change_fail_reason',
    NRP_AUTO_DRIVING: 'nrp_auto_driving',
    NRP_REMINDER_DO_AUTO: 'nrp_reminder_do_auto',
    INTERSECTION_INFO: 'intersection_info',
    ROUNDABOUT_INFO: 'roundabout_info',
    DANGEROUS_TARGET: 'dangerous_target',
    VRU_CUTIN: 'VRU_cutin',
};

/**
 * 数字越大消息等级越低
 */
export const widget1Level = {
    dangerous_target: 1, // 危险态目标
    nrp_auto_driving: 2, // NRP自动控车过程中(窄道通行)
    cp_road_boundary_collision: 3, // CP道路边缘碰撞提醒
    cp_rear_car_collision: 3, // CP后车碰撞报警和加速提示
    cp_cross_boundary: 3, // CP车道线压线提醒
    VRU_cutin: 4, // 路口VRU/CUT in 减速提示
    tapered_barrel_auto_stop: 5, // 锥形桶提醒  锥桶在自车道，系统可自动刹停
    last_road_merge: 6, // 最晚汇流点
    tfl: 7, // cp-TFL
    last_lane_change: 8, // 最晚变道点
    lane_change_confirm_fail_reason: 9, // 确认式变道
    lane_change_fail_reason: 10, // NP CP下交互式变道失败
    intersection_info: 11, // UNP路口通行交互
    lane_change: 12, // 变道
    self_confirm: 13, // CP下推荐变道
    nrp_reminder_do_auto: 14, // NRP引导用户点击控车按钮(窄道通行)
    roundabout_info: 15, // 环岛提示
    vcurise_limit: 16, // 目标车速限制
    collision_avoid: 17, // 避让搭车(组件暂时下掉)
    comity_pedestrian: 18, // 礼让行人(组件暂时下掉)
};

export const widget2Level = {
    tor: 1,
    // odd: 1,
    // yaw: 2,
    cornering: 3,
    relegation: 4,
    changeScene: 5,
};

export const widgetQueue = ({ widgetNumber, type, widgetContent }) => {
    const state = store?.getState();
    // 是否是UNP模式
    const isUnpRunning = state?.featureModelDriving?.baseData?.os?.isUnpRunning;
    let currentLevel =
        widgetStoreTypeEnum[widgetNumber] === WIDGET_ACTION.widget1Action ? widget1Level[type] : widget2Level[type];
    // widget的type
    let storeWidgetType = widgetStoreTypeEnum[widgetNumber];
    // store中存储的等级
    const storeWidgetLevel =
        widgetStoreTypeEnum[widgetNumber] === WIDGET_ACTION.widget1Action
            ? state?.widget1Data?.level
            : state?.widget2Data?.level;
    // store中存储的list消息队列
    const storeWidgetQueue =
        widgetStoreTypeEnum[widgetNumber] === WIDGET_ACTION.widget1Action
            ? state?.widget1Data?.list
            : state?.widget2Data?.list;
    let oldStoreWidgetQueue = [...storeWidgetQueue] || [];

    // // UNP 简洁版  不显示widget
    // if (isUnpRunning) {
    //     storeWidgetQueue?.length > 0 &&
    //         store.dispatch({
    //             type: widgetStoreTypeEnum[widgetNumber],
    //             data: {
    //                 level: null,
    //                 list: [],
    //             },
    //         });
    //     return;
    // }

    if (storeWidgetLevel === null) {
        // 如果内容为空不存store
        if (!widgetContent) return;

        let contentObj = {};
        contentObj[type] = widgetContent;
        oldStoreWidgetQueue.push(contentObj);
        store.dispatch({
            type: storeWidgetType,
            data: {
                level: currentLevel,
                list: oldStoreWidgetQueue,
            },
        });
    } else {
        // 当前消息等级小于存储的消息等级
        if (storeWidgetLevel < currentLevel) {
            return;
        }
        // 当前消息等级等于存储的消息等级
        else if (storeWidgetLevel === currentLevel) {
            // 如果内容不为空
            if (widgetContent) {
                let contentObj = {};
                contentObj[type] = widgetContent;
                if (oldStoreWidgetQueue.length < 5) {
                    oldStoreWidgetQueue?.push(contentObj);
                } else {
                    oldStoreWidgetQueue.shift();
                    oldStoreWidgetQueue?.push(contentObj);
                }

                store.dispatch({
                    type: storeWidgetType,
                    data: {
                        level: currentLevel,
                        list: oldStoreWidgetQueue,
                    },
                });
            } else {
                let oldStoreWidgetQueueFilter = oldStoreWidgetQueue?.filter((widgetItem) => {
                    return Object.keys(widgetItem)[0] !== type;
                });

                let currentWidgetQueue = [...oldStoreWidgetQueueFilter];

                if (currentWidgetQueue.length === 0) {
                    // 底层信号还趋于稳定，先下掉这个逻辑
                    // setTimeout(() => {
                    store.dispatch({
                        type: storeWidgetType,
                        data: {
                            level: null,
                            list: currentWidgetQueue,
                        },
                    });
                    // }, 500)
                } else {
                    store.dispatch({
                        type: storeWidgetType,
                        data: {
                            level: currentLevel,
                            list: currentWidgetQueue,
                        },
                    });
                }
            }
        } else if (storeWidgetLevel > currentLevel) {
            if (!widgetContent) return;

            let currentWidgetQueue = [];
            let contentObj = {};
            contentObj[type] = widgetContent;
            currentWidgetQueue.push(contentObj);
            store.dispatch({
                type: storeWidgetType,
                data: {
                    level: currentLevel,
                    list: currentWidgetQueue,
                },
            });
        }
    }
};
