const commonTagStyle = {
    '--border-color': '#9A9EAA',
    '--text-color': '#9A9EAA',
    '--background-color': '#EDEFF3',
};

export const workflowStatusMap = {
    0: {
        text: '待执行',
        tagStyle: {
            '--border-color': '#FF9A3D',
            '--text-color': '#FF9A3D',
            '--background-color': '#FFF1E3',
        },
    },
    1: {
        text: '执行成功',
        tagStyle: commonTagStyle,
    },
    2: {
        text: '执行中',
        tagStyle: {
            '--border-color': '#00CBB6',
            '--text-color': '#00CBB6',
            '--background-color': '#E6FFFB',
        },
    },
    3: {
        text: '执行失败',
        tagStyle: commonTagStyle,
    },
    4: {
        text: '跳过',
        tagStyle: commonTagStyle,
    },
    default: {
        text: 'default',
        tagStyle: commonTagStyle,
    },
};

export const deployCodeMap = {
    400: '工作流未绑定车辆',
    402: '工作流节点被锁定，请检查后再部署',
    405: '在使用其他部署方式，工作流暂不可用',
    406: '云端、场景配置不存在，请检查后再部署',
    407: '当前工作流正在部署，不可以重复操作',
    default: '工作流部署异常',
};
