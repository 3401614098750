import React, { Component } from 'react';

import ToastItem from './ToastItem';
import './ToastContainer.css';

class ToastContainer extends Component {
    state = {
        text: '',
    };
    timer = null;

    setText({ text, duration }) {
        clearTimeout(this.timer);
        this.setState({
            text,
        });
        this.timer = setTimeout(() => {
            this.setState({
                text: null,
            });
        }, duration);
    }

    render() {
        const { text } = this.state;
        return (
            <div className="toast-container">
                <div className="toast-wrap">
                    <ToastItem text={text} />
                </div>
            </div>
        );
    }
}

export default ToastContainer;
