import { Switch } from 'antd-mobile';
import { useSelector, useDispatch } from 'react-redux';
import { SWITH_MODE_ACTION } from '@/store/action/type';
import CardLayout from '../../../components/cardLayout';
import { AppFn } from '@/utils/native';

export default function ShortcutRouteWitchCard() {
    const switchShortcutRouteWitch = useSelector((state) => state?.switchModeAction?.switchShortcutRouteWitch);
    const dispatch = useDispatch();

    function handleChange(checked) {
        dispatch({
            type: SWITH_MODE_ACTION.switchShortcutRouteWitch,
            data: checked,
        });
        AppFn.setShortcutRoutingSwitch(checked);
    }

    const leftDom = (
        <div className={'left_dom_wrap'}>
            <div className={'switch_wrap'}>
                <div className={'content_wrap'} style={{ marginBottom: '.4rem' }}>
                    <div className={'text_wrap'} style={{ whiteSpace: 'pre-wrap', width: '5rem' }}>
                        快捷选路
                    </div>
                    <div className={'text_wrap_detail'} style={{ whiteSpace: 'pre-wrap', width: '5rem' }}>
                        开启后可以通过右下角选路按钮快速切换路线。
                    </div>
                </div>
                <Switch checked={switchShortcutRouteWitch} onChange={handleChange} className={'customSwitch'} />
            </div>
        </div>
    );

    return <CardLayout leftDom={leftDom} />;
}
