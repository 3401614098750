import React from 'react';
import { useSelector } from 'react-redux';
import { SpinLoading } from 'antd-mobile';
import './index.less';

export default function Reconnection() {
    const robonodeReady = useSelector((state) => state?.robonode?.ready);
    const userMode = useSelector((state) => state?.parking?.userMode);

    return (
        <React.Fragment>
            <div className={`reconnection_wrap ${!robonodeReady ? 'show' : 'hide'}`}>
                <SpinLoading color={userMode === 'C' ? '#657086' : 'white'} style={{ '--size': '0.5rem' }} />
                <span>Connecting</span>
            </div>
            {/*{
                !robonodeReady &&
                <div className={"reconnection_wrap"}>
                    <SpinLoading color='white' style={{'--size': '0.5rem'}}/>
                    <span>Connecting</span>
                </div>
            }*/}
        </React.Fragment>
    );
}
