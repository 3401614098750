import { useSelector } from 'react-redux';

export default function useShowApa() {
    const Lpnpstatus = useSelector((state) => state?.featureModelParking?.lpnp?.state);
    const apaStatus = useSelector((state) => state?.featureModelParking?.apa?.state);
    // const userMode = useSelector((state) => state?.parking?.userMode);
    const switch_mode = useSelector((state) => state?.featureModelParking?.switch?.apaSwitch);

    //表示在展示apa
    const getIsInSingleAPA = () => {
        const needStatusList = ['NOT_IN', 'PASSIVE', 'STANDBY'];
        const needApaStatusList = [
            'NOT_IN',
            'STANDBY',
            'SEARCHING',
            'SELECTING',
            'PARKING',
            'PARKING_PAUSE',
            'PARKING_PAUSE',
            'SEARCHING_OUT',
            'PARKING_OUT',
        ];
        const baseRule =
            switch_mode === 'OPEN' && needStatusList.includes(Lpnpstatus) && needApaStatusList.includes(apaStatus);
        return baseRule;
    };
    //表示在独立apa中
    const getNotShowLpnp = () => {
        const needStatusList = ['NOT_IN', 'PASSIVE', 'STANDBY'];
        const needApaStatusList = ['PARKING', 'PARKING_PAUSE', 'PARKING_OUT']; //表示在apa中，但是如何区别
        const baseRule = needStatusList.includes(Lpnpstatus) && needApaStatusList.includes(apaStatus);
        return baseRule;
    };

    //表示在独立lpnp中
    const getIsInSingleLpnp = () => {
        const needStatusList = [
            'LEARNING',
            'MAP_BEING_VERIFIED',
            'CRUISING',
            'PARKING',
            'NEARBY_PARKING_SEARCHING',
            'APA_SELECTING',
            'APA_PARKING',
            'APA_PARKING_PAUSE',
        ];
        const needApaStatusList = ['NOT_IN', 'STANDBY'];
        const baseRule = needStatusList.includes(Lpnpstatus) && needApaStatusList.includes(apaStatus);

        return baseRule;
    };

    return [getIsInSingleAPA(), getNotShowLpnp(), getIsInSingleLpnp()];
}
