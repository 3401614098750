import { useEffect, useMemo, useRef, useState } from 'react';
import { Button, Modal, Tag, Space, Dialog } from 'antd-mobile';
import { InformationCircleOutline } from 'antd-mobile-icons';
import closeIcon from '@/assets/workbench/close.png';
import { useBoxid, useWorkflowDeployText, useWorkflowRunningStatus } from '../hook';
import { workflowStatusMap, deployCodeMap } from './const';
import { useSelector } from 'react-redux';
import { deployWorkflow } from '../api';
import Toast from '../Toast';
import { singelPointReport } from '@/utils/report';
import reportEventName from '@/constant/reportEventName';
import classnames from 'classnames';
import logSend from '@/utils/aLog';

import './index.less';

const formatDeployArgs = (args) =>
    args?.map(({ package_kind, args }) => {
        const deploy_args = args?.reduce((pre, cur) => {
            pre[cur.name] = cur.value;
            return pre;
        }, {});
        return { package_kind, deploy_args };
    });

export default function WorkflowModal({ visible, onClose, workflowInfo, taskInfo }) {
    const boxid = useBoxid();
    const {
        isCurrentWorkflow,
        deployText,
        tagColor,
        deployCodeMap: customDeployCodeMap,
    } = useWorkflowDeployText(workflowInfo && [workflowInfo]);
    const workflowStatus = useSelector((state) => state.workflow?.wf_status);
    const isSelfWorkflow = workflowInfo?.wf_id === workflowStatus?.wf_id;
    const functionModeList = useMemo(() => {
        if (!workflowInfo) return [];
        return workflowInfo.wf_args?.reduce((pre, cur) => {
            pre.push({
                name: cur.package_kind,
                value: cur.args?.map((item) => item.value).join('/') || '',
            });
            return pre;
        }, []);
    }, [workflowInfo]);

    const nodeList = useMemo(() => {
        if (isSelfWorkflow) return workflowStatus?.nodes || [];
        return workflowInfo?.wf_json || [];
    }, [isSelfWorkflow, workflowStatus, workflowInfo?.wf_json]);

    const [isDeploy, setIsDeploy] = useState(false);
    const deployTimer = useRef(null);
    function showDeployLoading() {
        setIsDeploy(true);
        deployTimer.current = setTimeout(setIsDeploy, 10000, false);
    }
    function hideDeployLoading() {
        clearTimeout(deployTimer.current);
        setIsDeploy(false);
    }
    useEffect(() => {
        if (workflowStatus && !workflowStatus.is_completed) hideDeployLoading();
    }, [workflowStatus?.is_completed]);

    const dialogBox = useRef();
    function showDeployDialog() {
        Dialog.confirm({
            getContainer: dialogBox.current,
            content: '确定部署吗？',
            bodyClassName: 'workflow-dialog',
        }).then((flag) => {
            if (!flag) return;
            handleDeploy();
        });
    }

    const deployNotifyUser = taskInfo?.owner.user_name || taskInfo?.creator;
    function handleDeploy() {
        showDeployLoading();
        const params = {
            username: deployNotifyUser,
            hostname: boxid,
            wf_name: workflowInfo.wf_name,
            deploy_args: formatDeployArgs(workflowInfo.wf_args),
        };
        deployWorkflow(params)
            .then((res) => {
                if (res.hasOwnProperty('code')) {
                    Toast.info(
                        `部署失败，请联系${deployNotifyUser}。操作提醒：${
                            deployCodeMap[res.code] || deployCodeMap.default
                        }`,
                        3000,
                    );
                    hideDeployLoading();
                    if (!deployCodeMap[res.code]) {
                        logSend({
                            action: 'workflowDeployFail',
                            data: {
                                params,
                                res,
                            },
                        });
                    }
                    return;
                }
                Toast.info('部署开始');
                setTimeout(() => {
                    window.momentaJsBridge?.dispatch('RefreshOtaStatus', null, null);
                }, 3000);
                deployReport();
            })
            .catch(() => {
                Toast.info('网络异常不可用');
                hideDeployLoading();
            });
    }

    function deployReport() {
        const { wf_name: workflow_name, project, wf_id: workflow_id } = workflowInfo;
        singelPointReport({
            eventName: reportEventName.workflow_deploy_count,
            reportData: {
                box_id: boxid,
                workflow_name,
                task_id: taskInfo._id,
                project,
                workflow_id,
            },
        });
    }

    const { runningStatus } = useWorkflowRunningStatus();
    const runningListByWorkflowStatus = useMemo(() => {
        if (!runningStatus || !workflowStatus?.nodes) return [];
        return workflowStatus.nodes.reduceRight((pre, { package_kind }) => {
            const node = runningStatus.find((node) => node.package_kind === package_kind) || {
                package_kind,
                package_name: '--',
            };
            pre.push(node);
            return pre;
        }, []);
    }, [runningStatus, workflowStatus?.nodes]);
    const runningModal = useRef();
    function showRunningStatusModal() {
        runningModal.current = Modal.show({
            title: '车辆部署的版本与工作流不一致',
            content: (
                <ul style={{ maxHeight: '1.5rem', overflowY: 'scroll' }}>
                    {runningListByWorkflowStatus.map(({ package_kind, package_name }) => (
                        <li key={package_name} style={{ wordBreak: 'break-all', marginBottom: '.1rem' }}>
                            {package_kind}车端版本：{package_name}
                        </li>
                    ))}
                </ul>
            ),
            closeOnMaskClick: true,
            showCloseButton: true,
            getContainer: dialogBox.current,
            bodyClassName: 'running-status-modal',
            onClose() {
                runningModal.current = null;
            },
        });
    }

    useEffect(() => {
        if (!isCurrentWorkflow || deployText !== customDeployCodeMap[1]) return;
        runningModal.current?.close();
    }, [deployText, isCurrentWorkflow]);

    function handleClose() {
        onClose();
        runningModal.current?.close();
    }

    const content = (
        <div className="workflow-modal-container" ref={dialogBox}>
            <header>
                <img src={closeIcon} alt="" onClick={handleClose} />
                <span>{boxid}</span>
                <label className="workflow-modal-tip">数据来源：RMP</label>
            </header>

            <div className="workflow-info">
                <div className="info-title">工作流名称</div>
                <div className="info-item col1">
                    {workflowInfo?.wf_name}
                    {isCurrentWorkflow && (
                        <Tag
                            color={tagColor}
                            fill="outline"
                            style={{ fontSize: '.20rem', marginLeft: '.1rem', padding: '.03rem .04rem 0' }}
                        >
                            {deployText}
                        </Tag>
                    )}
                    {isCurrentWorkflow && deployText === customDeployCodeMap.different && (
                        <InformationCircleOutline
                            onClick={showRunningStatusModal}
                            color="var(--adm-color-danger)"
                            style={{ marginLeft: '.1rem' }}
                        />
                    )}
                </div>
                <div className="info-title">工作流信息</div>
                <div className="info-item col2">版本：{workflowInfo?.version}</div>
                <div className="info-item col2">项目：{workflowInfo?.project}</div>
                <div className="info-item col2">创建者：{workflowInfo?.create_name}</div>
                <div className="info-item col2">创建时间：{workflowInfo?.create_time}</div>
                <div className="info-item col2">部署知会人：{deployNotifyUser}</div>
                <div className="info-title">功能场景配置</div>
                {functionModeList?.map(({ name, value }) => (
                    <div className="info-item col2" key={name}>
                        {name}：{value}
                    </div>
                ))}
                {!functionModeList && <div className="info-item col2">暂无配置</div>}
            </div>
            <li className="workflow-status-list">
                {nodeList.map(({ package_kind, node_status, package_name, name }) => (
                    <div className="workflow-status-item" key={package_kind}>
                        <h5 className="status-title">{package_kind}</h5>
                        <p>{package_name || name}</p>
                        {isSelfWorkflow && (
                            <Tag fill="outline" style={workflowStatusMap[node_status].tagStyle}>
                                <img
                                    className={classnames({ rotate: node_status === 2 })}
                                    src={require(`@/assets/workbench/workflow/workflow_status_${node_status}.png`)}
                                    alt=""
                                />
                                {workflowStatusMap[node_status].text}
                            </Tag>
                        )}
                    </div>
                ))}
            </li>
            <Space className="btn-box" justify="center">
                <Button
                    style={{
                        '--border-color': '#fff',
                        '--background-color': '#00D8A4',
                        '--text-color': '#fff',
                    }}
                    disabled={!workflowInfo || (workflowStatus && !workflowStatus.is_completed)}
                    loading={isDeploy}
                    onClick={showDeployDialog}
                >
                    一键部署
                </Button>
            </Space>
        </div>
    );
    return <Modal visible={visible} content={content} closeOnMaskClick onClose={handleClose} />;
}
