import { HOT_AREA_TYPE } from '../action/type';

const INIT_STATE = {
    // hnp、acc、cp升降级区域
    controlStateItem: '',
    // hnp 激活、退出区域
    hnpStateBtn: '',
    // hnp 开关预期
    hnpSwitch: '',
    // 设置按钮
    settingBtnDom: '',
    // 设置弹框
    settingModalDom: '',
    //刷新按钮
    btnRefreshDom: '',
    // 路测助手弹窗
    testAssistantDom: '',
    // 跟车时距按钮
    distanceControllDom: '',
    // driving和parking场景切换按钮
    sceneArea: '',
    // parking场景按钮区
    parkingBtn: '',
    //apa按钮点击区
    parkingApaBtn: '',
    // parking返回按钮区
    backBtn: '',
    // parking返回取消区
    cancelBtn: '',
    // parking学习完成按钮
    studyComplete: '',

    //学习指导返回首页按钮
    backHomeBtn: '',
    //指导页开启开关
    guideLpnpBtn: '',
    //apa开启按钮
    startApaBtn: '',
    // LPNP软开关
    pnpSoftSwitch: '',
    isHotAll: '',
    // resume
    resumeControlDom: '',
    //  侧边栏
    sliderDom: '',
    // 智驾状态区域
    drivingStateDom: '',
    // 升降级区域
    drivingLevelDom: '',
    // ODD区域
    oddComDom: '',
    // 录音按钮区域
    recordStateDom: '',
    // 工作台区域
    workbenchDom: '',
    // 事件上报
    eventInfoDom: '',
    debugBack: '', //apa debug返回按钮
    debugOperator: '', //apa debug操作区
    apaConfirm: '', //泊车完成确认按钮
    apaCancel: '',
    LPNPDebugOperator: '', // LPNP 工程模式操作区
    LPNPEntryDom: '', // LPNP推荐入口
    LPNPDashboardArea: '', // LPNP 工程看板
    // parking nrp 开始控车按钮
    parkingNRPBtn: '',
    // driving nrp 开始控车按钮
    drivingNRPBtn: '',
};

export default function hotArea(state = INIT_STATE, action) {
    switch (action.type) {
        case HOT_AREA_TYPE.controlStateItem:
            return { ...state, controlStateItem: action.data };
        case HOT_AREA_TYPE.hnpStateBtn:
            return { ...state, hnpStateBtn: action.data };
        case HOT_AREA_TYPE.hnpSwitch:
            return { ...state, hnpSwitch: action.data };
        case HOT_AREA_TYPE.settingBtnDom:
            return { ...state, settingBtnDom: action.data };
        case HOT_AREA_TYPE.settingModalDom:
            return { ...state, settingModalDom: action.data };
        case HOT_AREA_TYPE.btnRefreshDom:
            return { ...state, btnRefreshDom: action.data };
        case HOT_AREA_TYPE.testAssistantDom:
            return { ...state, testAssistantDom: action.data };
        case HOT_AREA_TYPE.distanceControllDom:
            return { ...state, distanceControllDom: action.data };
        case HOT_AREA_TYPE.sceneArea:
            return { ...state, sceneArea: action.data };
        case HOT_AREA_TYPE.parkingBtn:
            return { ...state, parkingBtn: action.data };
        case HOT_AREA_TYPE.parkingApaBtn:
            return { ...state, parkingApaBtn: action.data };
        case HOT_AREA_TYPE.backBtn:
            return { ...state, backBtn: action.data };
        case HOT_AREA_TYPE.cancelBtn:
            return { ...state, cancelBtn: action.data };
        case HOT_AREA_TYPE.studyComplete:
            return { ...state, studyComplete: action.data };
        case HOT_AREA_TYPE.pnpSoftSwitch:
            return { ...state, pnpSoftSwitch: action.data };
        case HOT_AREA_TYPE.isHotAll:
            return { ...state, isHotAll: action.data };
        // case HOT_AREA_TYPE.resumeControlDom:
        case HOT_AREA_TYPE.resumeControlDom:
            return { ...state, resumeControlDom: action.data };
        case HOT_AREA_TYPE.sliderDom:
            return { ...state, sliderDom: action.data };
        case HOT_AREA_TYPE.drivingStateDom:
            return { ...state, drivingStateDom: action.data };
        case HOT_AREA_TYPE.recordStateDom:
            return { ...state, recordStateDom: action.data };
        case HOT_AREA_TYPE.workbenchDom:
            return { ...state, workbenchDom: action.data };
        case HOT_AREA_TYPE.eventInfoDom:
            return { ...state, eventInfoDom: action.data };
        case HOT_AREA_TYPE.drivingLevelDom:
            return { ...state, drivingLevelDom: action.data };
        case HOT_AREA_TYPE.oddComDom:
            return { ...state, oddComDom: action.data };
        case HOT_AREA_TYPE.debugBackArea:
            return { ...state, debugBack: action.data };
        case HOT_AREA_TYPE.debugOperatorArea:
            return { ...state, debugOperator: action.data };
        case HOT_AREA_TYPE.LPNPDebugOperatorArea:
            return { ...state, LPNPDebugOperator: action.data };
        case HOT_AREA_TYPE.LPNPEntryDom:
            return { ...state, LPNPEntryDom: action.data };
        case HOT_AREA_TYPE.backHomeBtn:
            return { ...state, backHomeBtn: action.data };
        case HOT_AREA_TYPE.guideLpnpBtn:
            return { ...state, guideLpnpBtn: action.data };
        case HOT_AREA_TYPE.apaConfirm:
            return { ...state, apaConfirm: action.data };
        case HOT_AREA_TYPE.apaCancel:
            return { ...state, apaCancel: action.data };
        case HOT_AREA_TYPE.startApaBtn:
            return { ...state, startApaBtn: action.data };
        case 'LPNPDashboardArea':
            return { ...state, LPNPDashboardArea: action.data };
        case HOT_AREA_TYPE.parkingNRPBtn:
            return { ...state, parkingNRPBtn: action.data };
        case HOT_AREA_TYPE.drivingNRPBtn:
            return { ...state, drivingNRPBtn: action.data };
        default:
            return state;
    }
}
