import React, { useEffect, useState } from 'react';
import { widgetIcon } from '@/constant/widget';
import { useSelector, useDispatch } from 'react-redux';
import { MODAL_ACTION } from '@/store/action/type';
import './index.less';

export default function ADASModalComponent() {
    const dispatch = useDispatch();
    // store中存储的modal数据
    const modalQueueList = useSelector((state) => state?.modalData?.list);
    const userMode = useSelector((state) => state?.parking?.userMode);
    const { img, text, imgSub } = modalQueueList.length > 0 && Object.values(modalQueueList[0])?.[0];
    const type = modalQueueList.length > 0 && Object.keys(modalQueueList[0])?.[0];
    const classEum = {
        LKA: 'imgImgsubText',
        FCW: 'imgColorText',
        AMAP: 'imgAmapWidth',
        NRP: 'imgNRPWidth',
        NRPCOMPLETE: 'imgAmapWidth',
        NRPMRM: 'imgAmapWidth',
        PARKING_NRP: 'imgNRPWidth',
    };
    const cVersionClassEum = {
        LKA: 'c_imgImgsubText',
        FCW: 'c_imgColorText',
        AMAP: 'c_imgAmapWidth',
        NRP: 'c_imgNRPWidth',
        NRPCOMPLETE: 'c_imgAmapWidth',
        NRPMRM: 'c_imgAmapWidth',
        PARKING_NRP: 'c_imgNRPWidth',
    };
    useEffect(() => {
        // 离开组件后，进行数据复位
        return () => {
            dispatch({
                type: MODAL_ACTION.modalAction,
                data: {},
            });
        };
    }, []);

    const modeClassName =
        userMode === 'D'
            ? classEum?.[type]
                ? classEum?.[type]
                : 'imgText'
            : cVersionClassEum?.[type]
            ? cVersionClassEum?.[type]
            : 'c_imgText';
    return (
        <>
            {(img || imgSub) && text && (
                <div className={`modalItemClass ${modeClassName}`}>
                    <p>{text}</p>
                    {img && <img src={img} alt=""></img>}
                    {imgSub && <img className="imgSub" src={imgSub} alt="" />}
                </div>
            )}
            {!((img || imgSub) && text) && <></>}
        </>
    );
}
