import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Switch } from 'antd-mobile';
import CardLayout from '../../../components/cardLayout';
import localCache from '@/utils/localCache';
import logSend from '@/utils/aLog';

export default function WarningCard() {
    const Mtour = useSelector((state) => state?.switchModeAction?.switchUnpState);
    const switchWorkBench = useSelector((state) => state?.switchModeAction?.switchWorkBench);
    const dispatch = useDispatch();
    const { t: translate } = useTranslation();

    const systemWarning = useSelector((state) => (state?.systemWarning?.systemWarning === 'ON' ? true : false));
    const toastFlag = useSelector((state) => state?.smartDrivingSettingMsg?.flag);

    const setWarningOpen = () => {
        localCache.set('system_warning_switch', 'ON');
        dispatch({
            type: 'setSystemWarningSwitch',
            data: 'ON',
        });
    };
    const setWarningClose = () => {
        localCache.set('system_warning_switch', 'OFF');
        dispatch({
            type: 'setSystemWarningSwitch',
            data: 'OFF',
        });
    };
    const switchRemind = (value) => {
        if (Mtour) {
            dispatch({
                type: 'setUpMsg',
                data: {
                    tooltipMsg: translate('systemWarning.switchSystemWarningTip'),
                    flag: !toastFlag,
                },
            });
            return;
        }

        if (value) {
            setWarningOpen();
        } else {
            setWarningClose();
        }
    };

    useEffect(() => {
        if (Mtour) {
            return setWarningClose();
        }
        // if (switchWorkBench) {
        //     return setWarningOpen();
        // }
    }, [Mtour, switchWorkBench]);

    useEffect(() => {
        logSend({
            action: 'SystemWarningSwitch',
            data: {
                systemWarning,
            },
        });
    }, [systemWarning]);

    const leftDom = (
        <div className={'left_dom_wrap'}>
            <div className={'switch_wrap'}>
                <div className={'content_wrap'} style={{ marginBottom: '.4rem' }}>
                    <div className={'text_wrap'} style={{ whiteSpace: 'pre-wrap', width: '5rem' }}>
                        {translate('settings.ErrorWarning')}
                    </div>
                    <div className={'text_wrap_detail'} style={{ whiteSpace: 'pre-wrap', width: '5rem' }}>
                        {translate('settings.ErrorWarningDetail')}
                    </div>
                </div>
                <Switch
                    checked={systemWarning}
                    onChange={(checked) => switchRemind(checked)}
                    className={'customSwitch'}
                />
            </div>
        </div>
    );

    const rightDom = <div className={'right_dom_wrap'} />;

    return (
        <>
            <CardLayout leftDom={leftDom} rightDom={rightDom} value={systemWarning} />
        </>
    );
}
