import { useEffect } from 'react';
import store from '@/store/index';
import { MVIZ_APA_PARKING_DATA } from '@/store/action/parkingType';
import localAudioNames from '@/constant/localAudioName';
import logSend from '@/utils/aLog';
import { isParkingHome } from '@/components/parkingContainer/config';
import { AppFn } from '@/utils/native';
import { ParkingSocketMap } from '@/constant/socketMap';
import FM from '@/utils/nativeSubAndPub';
import { useDebounceFn } from '@/utils/common';
const { playLocalAudio } = AppFn;

export default function useSetMvizSelect() {
    const notHandleMsg = (obj) => {
        return !obj?.hasOwnProperty('mviz_msg');
    };

    const playLocalAudioLocal = (name, level) => {
        isParkingHome() && playLocalAudio(name, level);
    };

    // 发送泊入方向+id
    const sentParkingInMsg = useDebounceFn(
        (data) => {
            const obj = {
                cmd: 'SELECT_PARKING_SLOT',
                parking_space_id: parseInt(data?.track_id),
            };
            const newObj = {
                command: 'selected_parking_slot',
                param: {
                    parking_space_id: parseInt(data?.track_id),
                },
            };
            obj.parking_in_direction = data?.parking_in_direction ? parseInt(data?.parking_in_direction) : 2002;
            newObj.param.parking_in_direction = data?.parking_in_direction
                ? parseInt(data?.parking_in_direction)
                : 2002;
            // 发给hmi_node
            FM.sendToHMIParking(ParkingSocketMap.apa_control, obj);
            // 发给mff_hmi
            FM.sendToHMIParking(ParkingSocketMap.hmi_apa_request, newObj);
        },
        100,
        true,
    );

    // 发送泊出方向
    const sendParkingOutMsg = useDebounceFn(
        (parking_out_direction) => {
            FM.sendToHMIParking(ParkingSocketMap.apa_control, {
                cmd: 'SELECT_PARKING_DIRECTION',
                parking_out_direction,
            });
        },
        100,
        true,
    );

    useEffect(() => {
        let momenta_mviz_msg = function (data) {
            if (data instanceof MessageEvent) {
                console.log('mviz_to_hmi_else-111222', data?.mviz_msg);
                logSend({
                    action: 'mviz_to_hmi_else',
                    data,
                });
                // 浏览器端处理逻辑
                if (notHandleMsg(data?.data)) return;
                store.dispatch({
                    type: MVIZ_APA_PARKING_DATA.mvizData,
                    data: data?.data,
                });
            } else {
                console.log('获取到了mviz数据-111222', data?.mviz_msg);
                // mviz消息处理逻辑
                logSend({
                    action: 'mviz_to_hmi_msg',
                    data,
                });
                try {
                    let MvizMsg = JSON.parse(data?.mviz_msg);
                    store.dispatch({
                        type: MVIZ_APA_PARKING_DATA.mvizData,
                        data: MvizMsg,
                    });

                    // 有车位id就发送
                    if (MvizMsg?.track_id) {
                        sentParkingInMsg(MvizMsg);
                    }
                    // 有泊出方向就发送
                    if (MvizMsg?.parking_out_direction) {
                        sendParkingOutMsg(MvizMsg?.parking_out_direction);
                    }

                    if (MvizMsg?.is_auto_selected)
                        return playLocalAudioLocal(localAudioNames.LPNP_CAR_SITE_HAS_SELECTED, 'Important');
                    if (MvizMsg?.is_default_parking_out_direction)
                        return playLocalAudioLocal(localAudioNames.SELECT_CAR_SITE_D, 'Normal');
                    playLocalAudioLocal(localAudioNames.SELECT_CAR_SITE_D, 'Normal');
                } catch (error) {
                    store.dispatch({
                        type: MVIZ_APA_PARKING_DATA.mvizData,
                        data: {},
                    });
                }
            }
        };
        window.notifyPickedParkingSpace = function (data) {
            logSend({
                action: 'selectCarSite',
                data,
            });
            console.log('111222-notifyPickedParkingSpace', data);
            store.dispatch({
                type: MVIZ_APA_PARKING_DATA.mvizData,
                data: {
                    num: data.code,
                    track_id: data.track_id,
                },
            });
            playLocalAudioLocal(localAudioNames.SELECT_CAR_SITE_D, 'Normal');
        };
        window['momenta_mviz_msg'] = momenta_mviz_msg;
        window.addEventListener('message', momenta_mviz_msg, false);
    }, []);
}
