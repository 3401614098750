import { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { AppFn } from '@/utils/native';
import { singelPointReport } from '@/utils/report';
import useShowApa from '@/components/parkingContainer/hooks/useShowApaAndLpnp';
import logSend from '@/utils/aLog';
import './apaTips.less';

// const TipsMapConfig = {
//     SEARCHING: 'Please keep driving to search for parking spots', //'正在寻找车位，请继续行驶',
//     FIND_SPACE: 'Stop the vehicle and put gear into R to activate APA', //'已找到可用车位，如需APA泊入请停车',//'已找到可用车位，如需APA泊入请停车',
//     SPEED_FAST: 'Please slow down to 25 km/h', //'车速有点快哟，请减速至25km/h',
//     SELECTING: 'Please confirm the target parking spot and click "Park In"', //'已为您选好推荐车位，请确认后点击“开始泊车',
//     PARKING_PAUSE: 'Release the brake and click "Resume APA"', //'APA is paused', //'泊车暂停',
//     PARKING: 'APA is working',
// };

const AudioMap = {
    FIND_SPACE: 'app_find_parking_space_nor.mp3',
    SPEED_FAST: 'apa_speed_fast.mp3',
    // SELECTING: 'apa_selecting.mp3',
    PARKING_PAUSE: 'apa_pause.mp3',
};
const LimitedMap = {
    // SELECTING: 1,
    PARKING_PAUSE: 1,
    apa_parking_resume: 1,
    apa_start_parking: 1,
};

//开启和继续的单独处理
const btnParingMap = {
    apa_start_parking: {
        // text: 'APA is parking in', //'即将波入车位，请您松开刹车',APA is working
        voice: 'apa_parking_in.mp3',
    },
    apa_parking_resume: {
        // text: 'Please release the brake to resume APA', //'泊车继续，请松开刹车',
        voice: 'apa_restart_parking.mp3',
    },
    apa_parking_fail: {
        // text: '', //'APA已退出',APA is deactivated
        voice: 'apa_parking_fail.mp3',
    },
    apa_parking_succ: {
        // text: '', //'泊车完成'Parking In is Completed
        voice: 'lpnp_apa_parking_succ.mp3',
    },
    apa_start_parking_out: {
        voice: 'apa_start_parking_out.mp3', //开始泊出
    },
    apa_parking_out_pause_active: {
        voice: 'apa_parking_out_pause_active.mp3', //主动暂停
    },
    apa_parking_out_pause_passive: {
        voice: 'apa_parking_out_pause_passive.mp3', //被动暂时
    },
    apa_parking_out_resume: {
        voice: 'apa_parking_out_resume.mp3', //泊出继续
    },
    apa_park_out_take_over: {
        voice: 'apa_park_out_take_over_new.mp3', //半泊出信号点
    },
    apa_parking_out_succ: {
        voice: 'apa_parking_out_succ.mp3', //全泊出完成
    },
    apa_parking_out_fail_active: {
        voice: 'apa_parking_out_fail_active.mp3', //主动接管
    },
    apa_parking_out_fail_passive: {
        voice: 'apa_parking_out_fail_passive.mp3', //被动接管
    },
};

// 打点上报映射
const ReportMap = {
    SEARCHING: { eventName: 'HMI_apa_searching_spot_view', text: 'HMI_apa_searching_spot_view' },
    FIND_SPACE: { eventName: 'HMI_apa_found_spot_view', text: 'HMI_apa_found_spot_view' },
    APA_FAST: { eventName: 'HMI_apa_searching_overspeed_view', text: 'HMI_apa_searching_overspeed_view' },
    APA_STATUS: { eventName: 'HMI_apa_status_view', text: '' },
    SELECTING: { eventName: 'HMI_apa_park_in_standby_view', text: 'HMI_apa_park_in_standby_view' },

    // alert标识的上报
    apa_start_parking: { eventName: 'HMI_apa_park_in_active_view', text: 'HMI_apa_park_in_active_view' },
    HMI_apa_park_in_complete_view: {
        eventName: 'HMI_apa_park_in_complete_view',
        text: 'HMI_apa_park_in_complete_view',
    },
    HMI_apa_park_in_deactive_view: {
        eventName: 'HMI_apa_park_in_deactive_view',
        text: 'HMI_apa_park_in_deactive_view',
    },
};
export default function useParkingApaTips() {
    const status = useSelector((state) => state?.featureModelParking.apa?.state);
    const canSearching = useSelector((state) => state?.featureModelParking?.apa?.canSearching);
    const speed = useSelector((state) => state?.featureModelParking?.body?.speed);
    const [isShowAPA] = useShowApa();
    const noticeType = useSelector((state) => state?.featureModelParking?.alert?.type);
    const noticeLevel = useSelector((state) => state?.featureModelParking?.alert?.level);
    const alertData = useSelector((state) => state?.featureModelParking?.alert?.data);
    const parking_fail_reason = useSelector((state) => state?.featureModelParking?.apa?.parkingFailReason);

    const apa_available_parking_slots_count = useSelector(
        (state) => state?.featureModelParking?.apa?.apaAvailableParkingSlotsCount,
    );
    const parkingFailReason = useSelector((state) => state?.featureModelParking.apa?.parkingFailReason);

    const [countFlag, setCountFlag] = useState(false);
    const [speedFlag, setSpeedFlag] = useState(speed >= 25);
    const fastFlagRef = useRef(false);
    const preStatus = useRef(status);
    const isShowGuide = useSelector((state) => state?.parking?.isShowGuide);

    // 控制是否满足进入apa的状态以及语音展示
    const isShowTips = () => {
        const baseRule = isShowAPA;
        const isShow = isShowAPA && speed <= 25;
        return {
            isShow,
            baseRule,
        };
    };

    useEffect(() => {
        //满足apa速度过快
        if (speed >= 25 && isShowTips().baseRule) {
            setSpeedFlag(true);
            if (!fastFlagRef.current) {
                apaPlayLocalAudio(AudioMap['SPEED_FAST'], fisrtUpper(noticeLevel));
                fastFlagRef.current = true;
                reportHandle(ReportMap['APA_FAST']);
            }
        } else {
            setSpeedFlag(false);
        }
    }, [speed]);

    useEffect(() => {
        //第一次必然上报，后面只有状态引起的变化才上报
        if (!countFlag) {
            reportHandle(ReportMap['APA_STATUS'], status);
        } else if (preStatus.current !== status) {
            reportHandle(ReportMap['APA_STATUS'], status);
            preStatus.current = status;
        }
        // 满足apa展示条后，处理状态变化的播报和上报
        if (isShowTips().isShow) {
            ReportMap[status] && reportHandle(ReportMap[status]); //埋点上报
            //增加播报限制，播报过就不播了
            if (AudioMap[status]) {
                if (LimitedMap[status]) {
                    LimitedMap[status] === 1 && apaPlayLocalAudio(AudioMap[status], fisrtUpper(noticeLevel));
                    LimitedMap[status] = LimitedMap[status] + 1;
                } else {
                    apaPlayLocalAudio(AudioMap[status], fisrtUpper(noticeLevel));
                }
            }
            //变成not-in清空一下文案展示,同时重置次数
            if (status === 'NOT_IN' || status === 'STANDBY') {
                reSetApaNums();
            }
            handlerFindSpace();
        }
    }, [status, apa_available_parking_slots_count, canSearching]);

    const handlerFindSpace = () => {
        //找到车位了,且保证只报一次
        if (status === 'SEARCHING' && apa_available_parking_slots_count > 0) {
            if (!countFlag) {
                setCountFlag(true);
                reportHandle(ReportMap['FIND_SPACE']); //埋点上包
                apaPlayLocalAudio(AudioMap['FIND_SPACE'], fisrtUpper('highest'));
            }
        }
    };

    // 重置次数
    const reSetApaNums = () => {
        Object.keys(LimitedMap).map((key) => (LimitedMap[key] = 1));
        setCountFlag(false);
    };
    window.start_parking_apa_exit_cb = async (res) => {
        logSend({
            action: 'start_parking_apa_exit_cb',
            data: {
                res,
            },
        });
    };
    useEffect(() => {
        console.log('isShowTips().isShow', isShowTips().isShow, noticeType);
        //独立apa模式下泊车完成或者失败后，直接切换到driving模式
        if (isShowTips().isShow) {
            ReportMap[noticeType] && reportHandle(ReportMap[noticeType]); //埋点上包
            //不是失败原因的播报
            if (noticeType !== 'apa_parking_fail') {
                if (LimitedMap[noticeType]) {
                    LimitedMap[noticeType] === 1 &&
                        btnParingMap[noticeType] &&
                        apaPlayLocalAudio(btnParingMap[noticeType].voice, fisrtUpper(noticeLevel));
                    LimitedMap[noticeType] = LimitedMap[noticeType] + 1;
                } else {
                    btnParingMap[noticeType] &&
                        apaPlayLocalAudio(btnParingMap[noticeType].voice, fisrtUpper(noticeLevel));
                }
                if (noticeType === 'apa_parking_out_pause') {
                    console.log('主动暂停', noticeType, alertData?.reason);
                    if (alertData?.reason !== '主动暂停') {
                        apaPlayLocalAudio('apa_parking_out_pause_active.mp3', fisrtUpper(noticeLevel));
                    } else {
                        apaPlayLocalAudio('apa_parking_out_pause_passive.mp3', fisrtUpper(noticeLevel));
                    }
                }

                if (noticeType === 'apa_parking_out_fail') {
                    if (parking_fail_reason !== '主动接管') {
                        apaPlayLocalAudio('apa_parking_out_fail_active.mp3', fisrtUpper(noticeLevel));
                    } else {
                        apaPlayLocalAudio('apa_parking_out_fail_passive.mp3', fisrtUpper(noticeLevel));
                    }
                }
            } else {
                //失败原因的播报
                if (['APAPlanningCompleteFail', 'PlanningOkOverTime'].includes(parkingFailReason)) {
                    apaPlayLocalAudio('apa_parking_fail2.mp3', fisrtUpper('higest'));
                } else {
                    apaPlayLocalAudio(btnParingMap[noticeType].voice, fisrtUpper('higest'));
                }
            }
        }
    }, [noticeType, alertData?.reason, parking_fail_reason]);

    const apaPlayLocalAudio = (name, noticeLevel) => {
        //不在学习引导页中才报
        if (!isShowGuide) {
            AppFn.playLocalAudio(name, noticeLevel);
        }
    };

    //根据消息类型，上报各阶段埋点
    const reportHandle = (noticeType, text) => {
        console.log(999, noticeType, ReportMap[noticeType]);
        if (ReportMap[noticeType]) {
            singelPointReport({
                eventName: ReportMap[noticeType].eventName,
                timestamp: new Date().getTime(),
                text: text || ReportMap[noticeType].text,
            });
        }
    };
    const fisrtUpper = (str) => {
        return str?.slice(0, 1).toUpperCase() + str?.slice(1).toLowerCase();
    };
}
