import axios from 'axios';
import axiosRetry from 'axios-retry';
import dayjs from 'dayjs';
import { customAlphabet } from 'nanoid/non-secure';
import store from '@/store';
import md5 from 'js-md5';
import logSend from '@/utils/aLog';
import NativeHttp from '@/utils/nativeHttp';
import { isNativeLoad } from '@/utils/isEnv';
import isParking from '@/utils/isParking';

const TIMEOUT = 20000;
const instance = axios.create({
    timeout: TIMEOUT,
});

const getHostName = () => {
    let state = store?.getState();
    let hostname = isParking(state?.scene?.current_scene)
        ? state?.featureModelParking?.body?.boxId
        : state?.featureModelDriving?.baseData?.body?.boxid;
    return hostname?.toUpperCase();
};

const getSign = (data, type) => {
    if (type == 'post') return md5(`${data}${getHostName()}|momentaFBz99E`)?.toUpperCase();
    let temp = '';
    for (let key in data) {
        if (key == 'ts') {
            temp += `${key}=${data[key]}${getHostName()}|momentaFBz99E&`;
            continue;
        }
        temp += `${key}=${data[key]}&`;
    }
    temp = temp.substring(0, temp.length - 1);
    return md5(temp)?.toUpperCase();
};

// 生成trace_id
const genTraceId = () => {
    const nanoid = customAlphabet('1234567890abcdef', 32);
    return nanoid();
};
const get_time_stamp = () => {
    return `${dayjs().unix()}`;
};

const get_car_timestamp = () => {
    let state = store?.getState();
    let timestamp = isParking(state?.scene?.current_scene)
        ? state?.featureModelParking?.hmi?.timestamp
        : state?.featureModelDriving?.baseData?.hmi?.timestamp;
    return `${timestamp}`;
};

axiosRetry(instance, {
    // 重试次数
    retries: 3,
    // 函数判断发生错误时是否重试
    retryCondition: (error) => axiosRetry.isNetworkOrIdempotentRequestError(error) || error?.response?.status !== 200,
    // 重试的时候是否重置超时时间
    shouldResetTimeout: false,
    // 每个请求之间的重试延迟时间
    retryDelay: (retryCount) => {
        return retryCount * 10000;
    },
});

const success = ({ data, status, statusText }) => {
    if (status >= 200 && status < 300) {
        return data;
    }
    return Promise.reject(new Error(statusText));
};

// 响应拦截器
instance.interceptors.response.use(success, (error) => {
    return Promise.reject(error);
});

// 请求拦截器
instance.interceptors.request.use(
    (config) => {
        logSend({
            action: 'httpLogActionInfo',
            data: {
                ...config,
            },
        });
        let data = config.params || {};
        config.headers['hostname'] = getHostName();
        if (config.method == 'post') {
            config.headers['sign'] = getSign(config.data, config.method);
        } else {
            config.headers['sign'] = getSign(data, config.method);
        }
        config.headers['fmp-trace-id'] = genTraceId();
        config.headers.post['Content-Type'] = 'application/json';
        return config;
    },
    (error) => {
        return Promise.reject(error);
    },
);

const http = {
    get(url, params) {
        if (params?.params) {
            params.params['ts'] = get_car_timestamp();
        }
        return instance.get(url, { ...params });
    },
    post(url, params) {
        params['ts'] = get_car_timestamp();
        return instance.post(url, JSON.stringify({ ...params }));
    },
};

const nativeHttp = new NativeHttp({
    connectTimeout: TIMEOUT / 1000,
    retryTimes: 3,
});
nativeHttp.beforeRequset((config) => {
    logSend({
        action: 'httpLogActionInfo',
        data: {
            ...config,
        },
    });
    config.headers = { ...config.headers };
    config.headers['hostname'] = getHostName();
    if (config.method == 'POST') {
        config.headers['sign'] = getSign(config.body, 'post');
        config.headers['Content-Type'] = 'application/json';
    } else {
        config.headers['sign'] = getSign(config.queries, 'get');
    }
    config.headers['fmp-trace-id'] = genTraceId();
    return config;
});
export default isNativeLoad ? nativeHttp.http : http;
