import localCache from '@/utils/localCache';
import { SWITH_MODE_ACTION } from '@/store/action/type';
import isCNfun from '@/utils/isCN';
import { isDebug } from '@/utils/isEnv';
import store from '@/store';

localCache.get('isWorkbenchEnable', (data) => {
    if (isDebug && data == false) {
        localCache.set('momenta_hmi_workbench_switch', false);
        store.dispatch({
            type: SWITH_MODE_ACTION.switchWorkBench,
            data: false,
        });
    }
});

const INIT_STATE = {
    switchModeState: 'testMode', // 智驾模式切换
    switchMvizState: 'left', // Mviz布局切换
    switchSpeedAudio: 'OFF', // 车速调整语音开关 0905产品逻辑默认改为OFF localStorage.getItem("switch_speed_audio")
    switchUnpState: false, // Mtour开关
    switchEventState: false, // event默认关闭， 没有缓存
    switchWorkBench: isCNfun(), // workbench开关
    switchFollowCarState: false, // 跟车时距开关
    recordTimes: 15, //录音按钮默认时间
    isRecording: false, // 是否正在录音中
    switchDrivingBroadcast: 'Alert', // 智驾播报模式切换: 默认简洁,（1228产品需求变动添加缓存）
    switchTFLState: 'CLOSE',
    switchRoundaboutState: 'CLOSE',
    // 接管录音开关
    switchTakeoverRecord: true,
    // 工作台所有录音开关
    switchAllRecord: true,
    switchAssistant: false, // 语音小助手开关
    // 快捷选路
    switchShortcutRouteWitch: false,
    //Tsa告警
    switchTsaAlertWitch: false,
};

export default function switchModeAction(state = INIT_STATE, action) {
    switch (action.type) {
        case SWITH_MODE_ACTION.switchModeState:
            localCache.set('switch_mode', action.data);
            return { ...state, switchModeState: action.data };
        case SWITH_MODE_ACTION.switchMvizState:
            localCache.set('switch_mviz_layout', action.data);
            return { ...state, switchMvizState: action.data };
        case SWITH_MODE_ACTION.switchSpeedAudio:
            return { ...state, switchSpeedAudio: action.data };
        case SWITH_MODE_ACTION.switchUnpState:
            localCache.set('unp_switch', action.data);
            return { ...state, switchUnpState: action.data };
        case SWITH_MODE_ACTION.switchWorkBench:
            return { ...state, switchWorkBench: action.data };
        case SWITH_MODE_ACTION.switchEventState:
            return { ...state, switchEventState: action.data };
        case SWITH_MODE_ACTION.switchFollowCarState:
            localCache.set('follow_car_switch', action.data);
            return { ...state, switchFollowCarState: action.data };
        case SWITH_MODE_ACTION.recordTimes:
            localCache.set('workbench_reocrd_times', action.data);
            return { ...state, recordTimes: action.data };
        case SWITH_MODE_ACTION.isRecording:
            return { ...state, isRecording: action.data };
        case SWITH_MODE_ACTION.switchDrivingBroadcast:
            localCache.set('driving_broadcast_switch', action.data);
            return { ...state, switchDrivingBroadcast: action.data };
        case SWITH_MODE_ACTION.switchTFLState:
            localCache.set('is_cp_tfl_switch_state', action.data);
            return { ...state, switchTFLState: action.data };
        case SWITH_MODE_ACTION.switchRoundaboutState:
            return { ...state, switchRoundaboutState: action.data };
        case SWITH_MODE_ACTION.switchTakeoverRecord:
            return { ...state, switchTakeoverRecord: action.data };
        case SWITH_MODE_ACTION.switchAllRecord:
            return { ...state, switchAllRecord: action.data };
        case SWITH_MODE_ACTION.switchAssistant:
            localCache.set('assistant_switch', action.data);
            return { ...state, switchAssistant: action.data };
        case SWITH_MODE_ACTION.switchShortcutRouteWitch:
            localCache.set('shortcut_route_witch', action.data);
            return { ...state, switchShortcutRouteWitch: action.data };
        case SWITH_MODE_ACTION.switchTsaAlertWitch:
            localCache.set('hmi_tsa_alert_witch', action.data);
            return { ...state, switchTsaAlertWitch: action.data };
        default:
            return state;
    }
}
