import { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Modal } from 'antd-mobile';
import { CloseOutline } from 'antd-mobile-icons';
import dayjs from 'dayjs';
import Toast from '@/components/workbench/Toast';
import { useTranslation } from 'react-i18next';
import { AppFn } from '@/utils/native';
import logSend from '@/utils/aLog';
import { useIsParking } from '../hook';

const { recordAudioFile, wbPlayAudioCallback } = AppFn;

export default function AdditionalRecordModal({ visible, onClose, recordData, onInterrupt }) {
    const { t: translate } = useTranslation();
    const isParking = useIsParking();
    // 触发录包标识
    const filter_meta = useSelector((state) =>
        isParking ? state?.featureModelParking?.filterMeta : state?.featureModelDriving?.filterMeta,
    );
    // hmi返回消息的时间戳
    const timestamp = useSelector((state) =>
        isParking ? state?.featureModelParking?.hmi?.timestamp : state?.featureModelDriving?.baseData?.hmi?.timestamp,
    );
    const recordSetingTimes = useSelector((state) => state?.switchModeAction?.recordTimes);
    const [currentRecording, setCurrentRecording] = useState(false); // 当前补录状态
    let [recordTime, setRecordTime] = useState(0); // 记录录音时长
    const fileNameRef = useRef();
    const timestampRef = useRef(); // 记录时间用
    const amendTimerRef = useRef(); //补录定时器

    /*监控录音的状态，被打断之后直接关闭补录,filter_meta?.time_stamp,
  只要有录包信号发出就，就先直接结束录音*/
    useEffect(() => {
        if (currentRecording) {
            logSend({
                action: 'stopAmendRecord',
                data: {
                    type: 'stopAmendRecord',
                    file_name: fileNameRef.current, //xxxx
                    id: recordData?.id,
                    execTime: Date.now(),
                    meta_data: {
                        endType: -1, // 补录打断结束
                        duration: recordTime,
                        boxid: recordData?.device_box_id,
                        time_zone: dayjs(timestampRef.current * 1000).format(), //fmp需要带时区时间
                    },
                },
            });
            // 补录直接被打断
            recordAudioFile(
                'stopAmendRecordCB',
                JSON.stringify({
                    type: 'stopAmendRecord',
                    file_name: fileNameRef.current, //xxxx
                    meta_data: {
                        endType: -1, // 补录打断结束
                        duration: recordTime,
                        boxid: recordData?.device_box_id,
                        id: recordData?.id,
                        time_zone: dayjs(timestampRef.current * 1000).format(), //fmp需要带时区时间
                    },
                }),
            );
            clearInterval(amendTimerRef.current);
            setRecordTime(0);
            onInterrupt();
        }
    }, [filter_meta?.timeStamp]);

    //触发补录
    const recordHandle = () => {
        // 手动结束录音
        if (currentRecording) {
            recordAudioFile(
                'stopAmendRecordCB',
                JSON.stringify({
                    type: 'stopAmendRecord',
                    file_name: fileNameRef.current, //xxxx
                    meta_data: {
                        endType: 1, // 补录结束
                        duration: recordTime,
                        boxid: recordData?.device_box_id,
                        id: recordData?.id,
                        time_zone: dayjs(timestampRef.current * 1000).format(), //fmp需要带时区时间
                    },
                }),
            );

            clearInterval(amendTimerRef.current);
            //结束录制音效
            wbPlayAudioCallback(null, JSON.stringify({ type: 'duplex', data: 'end_record_reminder.mp3' }));
            setCurrentRecording(false); //结束状态
            setTimeout(() => {
                setRecordTime(0);
                onClose();
            }, 500);
        } else {
            wbPlayAudioCallback(null, JSON.stringify({ type: 'duplex', data: 'start_record_reminder.mp3' }));
            let listNames = recordData?.voice_content?.filename?.split('_') || [];
            let newTime = dayjs(timestamp * 1000).format('YYYYMMDD-HHmmss');
            timestampRef.current = timestamp;
            console.log('uploadFile--timestamp', timestampRef.current, timestamp);
            listNames[listNames.length - 1] = newTime;
            fileNameRef.current = listNames.join('_');
            setCurrentRecording(true);
            //播报完成后，执行录音动画并读秒
            setTimeout(() => {
                play_record_callback(newTime);
                setRecordTime(recordTime++);
            }, 1000);
        }
    };

    const play_record_callback = (time_sec) => {
        //播放开始录音音效
        const params = JSON.stringify({
            type: 'startAmendRecord',
            //时间取新生成。
            file_name: fileNameRef.current,
            meta_data: {
                duration: recordSetingTimes,
                id: recordData?.id, //xxxx
                time_zone: dayjs(timestampRef.current * 1000).format(), //fmp需要带时区时间
                boxid: recordData?.device_box_id,
                name: recordData?.event_name,
                type: 'event',
                plate_num: recordData?.car_plate,
                time_sec,
                task_id: '',
            },
        });
        recordAudioFile('stopAmendRecordCB', params);
        console.log('uploadFile--start', params);

        amendTimerRef.current = setInterval(() => {
            //用户未主动触发结束,超过recordTimes自动结束
            if (recordTime > recordSetingTimes) {
                setRecordTime(0);
                clearInterval(amendTimerRef.current);
                setCurrentRecording(false);
                onClose();
                const meta_data = {
                    duration: recordTime - 1, //此处要减掉1，因为要走秒，不然音频文件少1s
                    id: recordData?.id, //xxxx
                    boxid: recordData?.device_box_id,
                    time_zone: dayjs(timestampRef.current * 1000).format(), //fmp需要带时区时间
                };
                meta_data.endType = 1; //录音结束
                recordAudioFile(
                    'stopAmendRecordCB',
                    JSON.stringify({
                        type: 'stopAmendRecord',
                        file_name: fileNameRef.current, //xxxx
                        meta_data,
                    }),
                );
                //结束录制音效
                wbPlayAudioCallback(null, JSON.stringify({ type: 'duplex', data: 'end_record_reminder.mp3' }));
            } else {
                setRecordTime(recordTime++);
            }
        }, 1000);
    };

    function handleClose() {
        if (currentRecording) {
            Toast.info(translate('workBench.report.closeTip'), 1500);
            return;
        }
        onClose();
    }

    const ModalContent = (
        <div>
            <CloseOutline className="close-icon" onClick={handleClose} />

            <p className="title"> {dayjs(recordData?.created_at).format('YYYY-MM-DD HH:mm:ss')}</p>
            <p className="title-two">{translate('workBench.report.addRecordTip')}</p>

            <div className="cricle-outer" onClick={recordHandle}>
                <div className={currentRecording ? 'cricle-inner active' : 'cricle-inner'}></div>
            </div>
            <div className="time-cutdown">
                <span className="red-icon"></span>
                <span className="cutdown-title">0:00:{recordTime.toString().padStart(2, '0')}</span>
            </div>
        </div>
    );
    return (
        <Modal
            bodyClassName="workBench-modal-content"
            visible={visible}
            closeOnAction
            content={ModalContent}
            closeOnMaskClick
            onClose={handleClose}
        />
    );
}
