import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SocketMap } from '@/constant/socketMap';
import FM from '@/utils/nativeSubAndPub';
import { useTranslation } from 'react-i18next';

export default function FunctionModeSwitch({ isDisabled }) {
    const dispatch = useDispatch();
    const flag = useSelector((state) => state?.smartDrivingSettingMsg?.flag);
    const switch_function_mode = useSelector(
        (state) => state?.featureModelDriving?.switch?.driving?.np_function_mode?.switch_function_mode,
    );

    const functionModeSwitch = switch_function_mode ? switch_function_mode : 'HDMAP';
    // const [isloading, setIsLoading] = useState(false)
    const { t: translate } = useTranslation();
    const functionModeOptions = translate('settings.functionModeOptions', { returnObjects: true });

    // 模式开关
    const handleFunctionMode = (value) => {
        console.log(value);
        if (value === functionModeSwitch) return;
        if (isDisabled) {
            const text = translate('settings.laneChangeToast');
            dispatch({
                type: 'setUpMsg',
                data: {
                    tooltipMsg: text,
                    flag: !flag,
                },
            });
            return;
        }
        // setFunctionModeSwitch(value);
        value && operateFunctionMode(value);
    };

    // 通知底层
    const operateFunctionMode = async (value) => {
        // setIsLoading(true)
        FM.sendToHMIDriving(
            SocketMap.npFunctionMode,
            {
                switch_function_mode: value,
            },
            'drive_node_res_fail_cb',
        );
    };

    // useEffect(() => {
    //     if (pre_switch_function_mode.current !== functionModeSwitch) {
    //         setIsLoading(false)
    //         pre_switch_function_mode.current = functionModeSwitch
    //     }
    // }, [functionModeSwitch])

    return (
        <div className={`select-group-item`}>
            {/*<p className="select-group-item-name">{translate('settings.functionModeSwitch')}</p>*/}
            <p className="select-group-item-name" style={{ opacity: 0 }}>
                {translate('settings.functionModeSwitch')}
            </p>
            <div className={`select-box`}>
                {functionModeOptions.length &&
                    functionModeOptions.map((item, index) => {
                        return (
                            <span
                                key={index}
                                className={`select-box-item ${functionModeSwitch === item.value ? 'active' : ''}`}
                                onClick={() => handleFunctionMode(item.value)}
                            >
                                {/* {isloading && functionModeSwitch === item.value && <div class="circle"></div>} */}
                                {item.label}
                            </span>
                        );
                    })}
            </div>
        </div>
    );
}
